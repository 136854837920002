import Modal, { ModalRef } from '@ui-components-3/ui/lib/components/Modal'
import ExternalLink from 'components/common/ExternalLink'
import { forwardRef } from 'react'
import { TERMS_CONDITIONS_LINK } from 'util/constants'

export type VisitWarningModalProps = {
  onDismiss: () => void
}

const VisitWarningModal = forwardRef<ModalRef, VisitWarningModalProps>(({ onDismiss, ...props }, ref) => {
  return (
    <Modal
      {...props}
      ref={ref}
      className="w-[564px] backdrop:backdrop-brightness-50"
      label="Don’t lose a visit"
      footer={() => (
        <button type="button" className="btn btn-neutral" onClick={onDismiss}>
          Ok
        </button>
      )}
    >
      <p className="typography-body-l font-medium text-neutral-800">
        Can’t make a visit? Cancel it 24+ hours before it’s scheduled. This instantly frees up your provider to help
        others, and you won’t lose one of your own visits. Please review the TimelyCare{' '}
        <ExternalLink href={TERMS_CONDITIONS_LINK} className="text-primary-500 underline">
          Terms & Conditions{' '}
        </ExternalLink>
        for more information.
      </p>
    </Modal>
  )
})

export default VisitWarningModal
