import { useMemo } from 'react'
import { useLocation } from 'react-router'

type QueryResult<T> = {
  [key in string]: string | null
}

export const useQuery = <T extends string>(paramNames: string[]) => {
  const { search } = useLocation()

  return useMemo(() => {
    const params = new URLSearchParams(search)
    const result: QueryResult<T> = {} as QueryResult<T>

    paramNames.forEach((paramName) => {
      const paramValue = params.get(paramName)
      result[paramName] = paramValue
    })

    return result
  }, [search, paramNames])
}
