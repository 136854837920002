import * as Yup from 'yup'
import configBuilder from '../config-builder'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object({
  memberId: Yup.string().required(),
})

type Request = Yup.InferType<typeof RequestSchema>

export const isValid = async (params: Request) => await RequestSchema.isValid(params)
export const validate = async (params: Request) => RequestSchema.validate(params)
export const make = async () => undefined

export const url = ({ memberId }: Request) => `/v1/members/${memberId}/duplicates/save-for-later`
export const execute = async (params: Request) => api.post(url(params), make(), configBuilder()).catch(catchApiFailure)
