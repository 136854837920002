import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import configBuilder from '../config-builder'
import { ConditionSchema, OtherConditionSchema } from '../../../schemas/MedicalHistorySchema'
import { normalizeJsonToApi } from '../../helpers'

const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  memberId: Yup.string().required(),
  conditions: Yup.array().of(ConditionSchema).required(),
  other: Yup.array().of(OtherConditionSchema).required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const make = async (params) => {
  const request = RequestSchema.cast(params)
  const { conditions, other } = request
  return normalizeJsonToApi({ conditions, other })
}
export const url = ({ memberId }) => `/v1/${memberId}/personal/history/conditions`
export const execute = async (params) =>
  api.put(url(params), await make(params), configBuilder(params)).catch(catchApiFailure)
