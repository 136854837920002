import { last, cloneDeep, capitalize } from 'lodash'
import moment from 'moment'
import { phoneFormat } from './formatters'

export const fullGender = (gender) => {
  switch (gender) {
    case 'M':
      return 'Male'
    case 'F':
      return 'Female'
    case 'X':
      return 'N/A'
    default:
      return 'Unknown'
  }
}

export const genderIdentities = [
  'Man',
  'Woman',
  'Transgender Woman',
  'Transgender Man',
  'Non-binary or non-conforming',
  'Prefer not to answer',
  'Other/not listed',
]

export const gradSemesters = ['Spring', 'Summer', 'Fall']
export const accountClassifications = ['undergraduate', 'graduate', 'faculty', 'staff']
export const getAcctOptionLabel = (o) => capitalize(o)
export const getAcctOptionValue = (o) => o

const futureGradYears = () => {
  const startDate = new Date(2020, 1, 1).getFullYear()
  const current = new Date().getFullYear()
  const diffDate = current - startDate
  let years = []
  for (let i = 0; i < diffDate + 10; i++) {
    years.push((startDate + i).toString())
  }
  return years
}
export const gradYears = futureGradYears()

export const pronouns = ['He/Him', 'She/Her', 'They/Them', 'Custom']

export const mapPronounToForm = (pronoun) => {
  if (!pronoun) {
    return {
      pronoun: '',
      customPronoun: '',
    }
  }
  const index = pronouns.findIndex((p) => p === pronoun)
  if (index >= 0) {
    return {
      pronoun: pronouns[index],
      customPronoun: '',
    }
  } else {
    return {
      pronoun: pronouns[3],
      customPronoun: pronoun,
    }
  }
}

// Format Preferred Name before posting.
export const cleanedPreferredName = (preferredName) => {
  const trimmed = preferredName.trim()
  if (!trimmed) {
    return null
  } else if (trimmed.includes(' ')) {
    const capitalized = trimmed
      .split(' ')
      .map((name) => name.charAt(0).toUpperCase() + name.slice(1))
      .join(' ')
    return capitalized
  } else {
    return trimmed.charAt(0).toUpperCase() + trimmed.slice(1)
  }
}

export const getPrintableShortName = ({ firstName, firstNamePreferred }) => {
  return firstNamePreferred || firstName
}

const buildName = ({ firstName, lastName, firstNamePreferred, displayName }) => {
  if (displayName) {
    return displayName
  } else {
    if (!firstNamePreferred) {
      if (!firstName) return lastName
      if (!lastName) return firstName
      return `${firstName} ${lastName}`
    } else {
      if (!lastName) return firstNamePreferred
      return `${firstNamePreferred} ${lastName}`
    }
  }
}

export const getPrintableFullName = (member) => {
  if (!member) return ''
  return buildName(member)
}

export const getPrintableShortMemberName = (member) => {
  if (!member) return ''
  const { firstName, lastName } = member
  return `${firstName[0]}${lastName[0]}`.toUpperCase()
}

export const getAge = (member, labelMonth = ' m.o.', labelYear = ' y.o.') => {
  const now = moment()
  const dob = moment(member.dob, 'YYYY-MM-DD')
  const years = now.diff(dob, 'years')
  if (!years) {
    const months = now.diff(dob, 'months')
    if (!months) {
      return 'Infant'
    }
    return `${months}${labelMonth}`
  }
  return `${years}${labelYear}`
}

export const getPrintableAddress = (address) => {
  if (!address) return ''
  const streetAddress = address.street2 ? `${address.street1} ${address.street2}` : address.street1
  return `${streetAddress}, ${address.city}, ${address.state} ${address.zip}`
}

export const getPreferredAddress = (member, addressType = 'mailing') => {
  if (!member?.addresses?.length) return null
  const preferred = member.addresses.find((address) => address.addressType === addressType && address.preferred)
  return (
    preferred ??
    last(member.addresses.filter((address) => address.addressType === addressType)) ??
    last(member.addresses)
  )
}

export const getPrintablePhone = (phone) => {
  if (!phone) return ''
  return phoneFormat(phone.number)
}

export const getPreferredPhone = (member) => {
  if (!member || !member.phones) return ''
  const preferred = member.phones.find((phone) => phone.preferred)
  return preferred || last(member.phones)
}
export const stripId = (document) => {
  if (!document || (!document._id && !document.id)) return document
  const clone = cloneDeep(document)
  delete clone._id
  delete clone.id
  return clone
}

export const getPrintableGender = (member) => {
  if (!member || !member.gender) return ''
  return fullGender(member.gender)
}

export const getPrintableBirthDate = (dob) => {
  const dobMo = moment(dob, 'YYYY-MM-DD')
  return dobMo.format('MMM Do, YYYY')
}

export const DOB_FORMAT = 'YYYY-MM-DD'

export const PhoneTypes = [
  { label: 'Mobile', value: 'mobile' },
  { label: 'Home', value: 'home' },
  { label: 'Office', value: 'office' },
]

const memberIdRegex = new RegExp(/^[0-9a-fA-F]{24}$/)

export const getMemberIdFromMedicalLocation = (pathname) => {
  const memberId = pathname.split('/').filter((el) => memberIdRegex.test(el))

  if (pathname === '' || !memberId.length) {
    return null
  }
  return memberId[0]
}
