import { createSlice } from '@reduxjs/toolkit'

const defaultState = {
  meta: {
    visitMessagesTotal: 0,
    taskMessagesTotal: 0,
  },
  records: {
    // [messageId]: message
  },
  newRecords: {
    // [messageId]: message
  },
  detailsRecords: {
    // [messageId]: message
  },
  unreadCounts: {
    message: 0,
    task: 0,
    visit: 0,
  },
}

const messagesTotalKeys = {
  visit: 'visitMessagesTotal',
  task: 'taskMessagesTotal',
}

const getId = (message) => message?.messageId || message?.id

const slice = createSlice({
  name: 'message',
  initialState: defaultState,
  reducers: {
    putMessages: (state, action) => {
      const { messages, total, messageType } = action.payload
      const totalKey = messagesTotalKeys[messageType]

      messages.forEach((message) => {
        const { messageId } = message
        state.records[messageId] = message
      })
      state.meta[totalKey] = total
    },
    putMessageDetails: (state, { payload }) => {
      const { messageId } = payload
      state.detailsRecords[messageId] = payload
      state.records[messageId] = payload
    },
    putMessageViewedAt: (state, { payload }) => {
      const { messageId, viewedAt } = payload
      if (state.detailsRecords[messageId]) {
        state.detailsRecords[messageId].viewedAt = viewedAt
      }
      if (state.records[messageId]) {
        state.records[messageId].viewedAt = viewedAt
      }
    },
    putMessage: (state, action) => {
      const { message } = action.payload
      state.records[getId(message)] = message
    },
    putNewMessage: (state, { payload }) => {
      const { messageId } = payload
      state.newRecords[messageId] = payload
    },
    putMoveNewMessages: (state, { payload }) => {
      const { messageType } = payload
      Object.values(state.newRecords)
        .filter((m) => m.messageType === messageType)
        .forEach((message) => {
          state.records[message.messageId] = state.newRecords[message.messageId]
          delete state.newRecords[message.messageId]
        })
    },
    putUnreadMessageCounts: (state, { payload }) => {
      const { unreadMessages } = payload
      state.unreadCounts = unreadMessages
    },
    clearNewMessages: (state) => {
      state.newRecords = { ...defaultState.newRecords }
    },
    clearMessages: (state) => {
      state.meta = { ...defaultState.meta }
      state.records = { ...defaultState.records }
      state.newRecords = { ...defaultState.newRecords }
      state.detailsRecords = { ...defaultState.meta }
    },
  },
})

const { actions, reducer } = slice
export const {
  putMessages,
  putMessageDetails,
  clearMessages,
  putMessageViewedAt,
  putMoveNewMessages,
  putNewMessage,
  putMessage,
  putUnreadMessageCounts,
  clearNewMessages,
} = actions
export default reducer
