import pickBy from 'lodash/pickBy'
import isEmpty from 'lodash/isEmpty'
import isPlainObject from 'lodash/isPlainObject'
import VisitTypes from './visit-types'

export const cleanPhoneNumberInput = (number: number) => ('' + number).replace(/\D/g, '').substring(0, 10)

export const cleanNumberInput = (number: number) => ('' + number).replace(/\D/g, '')
export const phoneFormat = (number: number) => {
  const clean = cleanNumberInput(number)
  const match = clean.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`
  }
}

export const phoneInputFormat = (number: number) => {
  const clean = cleanNumberInput(number)
  const area = clean.substring(0, 3)
  const exchange = clean.substring(3, 6)
  const remain = clean.substring(6, 10)

  if (clean.length > 6) return `(${area}) ${exchange}-${remain}`
  if (clean.length > 3) return `(${area}) ${exchange}`
  if (clean.length) return `(${area}`
}

export const buildPageKey = (
  page: number,
  limit: number,
  sort: { [key: string]: any },
  filter: { [key: string]: any },
) => {
  const sorts = Object.keys(sort).reduce((acc, curr) => {
    if (!acc) return `${curr}.${sort[curr]}`
    return `${acc}.${curr}.${sort[curr]}`
  }, '')
  const filters = Object.keys(filter).reduce((acc, curr) => {
    if (!acc) return `${curr}.${filter[curr]}`
    return `${acc}.${curr}.${filter[curr]}`
  }, '')
  return `${page}.${limit}.${sorts}.${filters}`
}

export const filterKeysToMap = (array = []) => {
  const result = array.reduce((acc, val, i) => {
    if (i % 2 !== 0) {
      // it's a value
      // use the immediately previous array value as the key
      acc[array[i - 1]] = val
    }
    return acc
  }, {})
  return result
}

export const removeNullObjects = (object: any) => {
  return pickBy(object, (value) => {
    if (isPlainObject(value)) {
      return !isEmpty(value)
    }
    return true
  })
}

export const isSuccess = (serviceType: string) =>
  serviceType === VisitTypes.successNow || serviceType === VisitTypes.success

export const isScheduledSuccess = (serviceType: string) => serviceType === VisitTypes.success

export const isOnDemandSuccess = (serviceType: string) => serviceType === VisitTypes.successNow
