import { createSlice } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'

const defaultState = {
  meta: {},
  clients: {
    // [clientId]: Client
  },
  records: {
    // [service_id]: Service
  },
  surveys: {
    // [survey_id]: Survey
  },
  needs: {
    // [need id]: Need
  },
  groups: {
    // [group id]: Group
  },
  restrictions: {},
}

const getId = (service) => service.serviceId

const slice = createSlice({
  name: 'services',
  initialState: defaultState,
  reducers: {
    putServices: (state, action) => {
      const { services } = action.payload
      services.forEach((service) => {
        state.records[getId(service)] = service
      })
    },
    putClient: (state, action) => {
      const { client } = action.payload
      state.clients[client.clientId] = client
      if (!isEmpty(client.surveys)) {
        client.surveys.forEach((survey) => (state.surveys[survey.surveyId] = survey))
      }
    },
    putGroup: (state, action) => {
      const { group } = action.payload
      state.groups[group.groupId] = group
    },
    putSurveys: (state, action) => {
      const { surveys } = action.payload
      if (!isEmpty(surveys)) {
        surveys.forEach((survey) => (state.surveys[survey.surveyId] = survey))
      }
    },
    putSurvey: (state, action) => {
      const { survey } = action.payload
      state.surveys[survey.surveyId] = survey
    },
    putSurveyQuestions: (state, action) => {
      const { questions, surveyId } = action.payload
      if (!isEmpty(questions)) {
        state.surveys[surveyId].surveyQuestions = questions
      }
    },
    putNeeds: (state, action) => {
      const { needs } = action.payload
      needs.forEach((need) => {
        state.needs[need.needId] = need
      })
    },
    putRestrictions: (state, action) => {
      const { restrictions } = action.payload
      restrictions.forEach((restriction) => {
        if (!state.restrictions[restriction.visitType]) {
          state.restrictions[restriction.visitType] = []
        }
        state.restrictions[restriction.visitType].push(restriction)
      })
    },
    clearNeed: (state, action) => {
      const { needId } = action.payload
      delete state.needs[needId]
    },
    clearService: (state, action) => {
      const { serviceId } = action.payload
      delete state.records[serviceId]
    },
    clearServices: (state) => {
      state.records = { ...defaultState.records }
    },
    clearNeeds: (state) => {
      state.needs = { ...defaultState.needs }
    },
    clearClients: (state) => {
      state.clients = { ...defaultState.clients }
      state.surveys = { ...defaultState.surveys }
      state.groups = { ...defaultState.groups }
      state.restrictions = { ...defaultState.restrictions }
    },
  },
})

const { actions, reducer } = slice
export const {
  putClient,
  putGroup,
  putRestrictions,
  putServices,
  putSurveys,
  putSurvey,
  putSurveyQuestions,
  putNeeds,
  clearNeed,
  clearNeeds,
  clearClients,
  clearService,
  clearServices,
} = actions
export default reducer
