import { createSlice } from '@reduxjs/toolkit'
import { Chat } from 'types/visit-chat-message/visitChatMessage'

export type ChatState = {
  meta: {
    lastFetchAt: null | string
  }
  records: {
    [chatId: string]: Chat
  }
}

const defaultState: ChatState = {
  meta: {
    lastFetchAt: null,
  },

  records: {
    // [chat_id]: Chat
  },
}

/* TODO: Remove this after upgrading typescript to version > 5 */
declare global {
  interface Array<T> {
    findLast(predicate: (value: T, index: number, obj: T[]) => unknown, thisArg?: any): T | undefined
  }
  interface Window {
    pendo: any
  }
}

const getId = (chat: Chat) => chat.id || chat.chatId

const readMessage = (state: ChatState, message: any) => {
  const chat = state.records[message.chatId]
  if (chat) {
    const tgt = chat.messages.find((m: any) => m.id === message.id)
    if (tgt) {
      // encrypted message must not be merged
      const { message: unused, ...restmessage } = message
      Object.assign(tgt, restmessage)
    }
  } else {
    throw new Error('trying to read a message of the chat which does not exist')
  }
}

const slice = createSlice({
  name: 'chats',
  initialState: defaultState,
  reducers: {
    putChats: (state, action) => {
      const { chats } = action.payload
      chats.forEach((chat: Chat) => {
        state.records[getId(chat) as string] = chat
      })
    },
    putChat: (state, action) => {
      const { chat } = action.payload
      state.records[getId(chat) as string] = chat
    },
    clearChats: (state) => {
      state.records = { ...defaultState.records }
    },
    clearChat: (state, action) => {
      const { chatId } = action.payload
      delete state.records[chatId]
    },
    readChatMessage: (state, action) => {
      const { message } = action.payload
      readMessage(state, message)
    },
    readChatMessages: (state, action) => {
      const { messages } = action.payload
      for (const message of messages) {
        readMessage(state, message)
      }
    },
  },
})

const { actions, reducer } = slice
export const { putChat, putChats, clearChat, clearChats, readChatMessage, readChatMessages } = actions
export default reducer
