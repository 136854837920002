import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'successAvailability',
  initialState: { availabilityInfo: {} },
  reducers: {
    putSuccessAvailability: (state, action) => {
      state.availabilityInfo = action.payload.availability
    },
    clearSuccessAvailability: (state) => {
      state.availabilityInfo = {}
    },
  },
})

const { actions, reducer } = slice

export const { putSuccessAvailability, clearSuccessAvailability } = actions

export default reducer
