import * as Yup from 'yup'
import configBuilder from '../config-builder'
import catchApiError from '../catch-api-error'
import { normalizeJsonToApi } from '../../helpers'
import ApiFactory, { ApiTypes } from '../../index'
const api = ApiFactory.getApi(ApiTypes.auth)

const RequestSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  userType: Yup.string().oneOf(['member']).required('Required'),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const make = (params) => normalizeJsonToApi(params)
export const url = () => '/v1/forgot'
export const execute = async (params) => api.post(url(), make(params), configBuilder(params)).catch(catchApiError)
