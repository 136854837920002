import { createSelector } from 'reselect'
import moment from 'moment'
import { uniq } from 'lodash'
import includes from 'lodash/includes'
import { FilterGenders, RaceFilterMap, mapTherapyVisitFiltersToDiagnosticExpertise } from '../../util/provider'
import { RECOMMENDED_PROVIDER_COUNT } from '../../util/constants'

export const getRecords = (state) => state.providers.records
export const getMeta = (state) => state.providers.meta
export const getProviders = createSelector(getRecords, (records) => Object.values(records))

export const getAvailableProviders = ({ location, visitType, modality } = {}) =>
  createSelector(getProviders, (records = []) =>
    records.filter((provider) => {
      if (!provider?.modalities?.includes?.(modality)) return false
      if (!provider?.schedule || !provider?.schedule.available || !provider?.schedulable) {
        return false
      }
      const visitTypeFilter = provider?.schedulable?.visitType === visitType

      if (location?.length) {
        return visitTypeFilter && provider?.schedulable?.location === location
      }
      return visitTypeFilter
    }),
  )

export const getSectionedAvailableProviders = ({ location, visitType, modality }) =>
  createSelector(getAvailableProviders({ location, visitType, modality }), (providers = []) => {
    return [...providers].reduce(
      (acc, curr) => {
        if (acc.recommended.length < RECOMMENDED_PROVIDER_COUNT && curr.isRecommended) {
          acc.recommended.push(curr)
        } else {
          acc.general.push(curr)
        }
        return acc
      },
      { recommended: [], general: [] },
    )
  })

function getFilterValues(filters = {}) {
  const expertise = Object.keys(filters?.specialtyFocus ?? {}).filter((key) => filters?.specialtyFocus[key])
  return {
    race: filters?.race === 'All' ? '' : filters?.race,
    expertise,
  }
}

export const getDiagnosticExpertiseFilters = (selectedOptions) => {
  if (!selectedOptions?.length) {
    return []
  }

  return uniq(
    selectedOptions.reduce((acc, curr) => {
      return [...acc, ...mapTherapyVisitFiltersToDiagnosticExpertise[curr]]
    }, []),
  )
}

export const mappedProviderGenderForFilters = (provider) => {
  if (provider.profile?.genderIdentity === 'Male' || provider.profile?.genderIdentity === 'Female') {
    return provider.profile?.genderIdentity
  }
  return '-'
}

export const mappedProviderEthnicityForFilters = (provider) => {
  const raceEthnicity = provider.profile?.raceEthnicity

  if (
    raceEthnicity === 'Unknown/None of the above' ||
    raceEthnicity === 'Prefer not to answer' ||
    raceEthnicity === undefined
  ) {
    return '-'
  }

  const matchingRace = RaceFilterMap.find((race) => race.value === raceEthnicity)

  return matchingRace ? matchingRace.label : raceEthnicity
}

export const getBestMatchProvider = (pendingVisit, filters = {}) =>
  createSelector(getAvailableProviders(pendingVisit), (providers = []) => {
    const values = getFilterValues(filters)
    // Ensure that 'values' is defined and 'providers' array is not empty
    if (!values || !providers.length) {
      return []
    }

    return [...providers].filter((provider) => {
      // Check if the provider has the required expertise
      const hasExpertise =
        values.expertise?.length &&
        values.expertise.every((category) =>
          mapTherapyVisitFiltersToDiagnosticExpertise[category]?.some((expertise) =>
            provider.profile?.diagnosticExpertise?.includes(expertise),
          ),
        )

      const hasGender =
        filters.gender === 'Another Gender Identity'
          ? FilterGenders.includes(provider?.profile?.genderIdentity)
          : provider?.profile?.genderIdentity === filters.gender

      const hasEthnicity = values.race?.includes(mappedProviderEthnicityForFilters(provider))

      const filterConditions = [
        { keys: getDiagnosticExpertiseFilters(values.expertise), condition: hasExpertise },
        { keys: filters.gender ? [filters.gender] : [], condition: hasGender },
        { keys: values.race, condition: hasEthnicity },
      ]

      const activeFilters = filterConditions.filter((filter) => filter?.keys?.length > 0)

      if (activeFilters.length === 0) {
        return true
      }

      return activeFilters.every((filter) => filter.condition)
    })
  })

export const getCloseMatchProvider = (pendingVisit, filters = {}, bestMatch) =>
  createSelector(getAvailableProviders(pendingVisit), (providers = []) => {
    const values = getFilterValues(filters)

    // Ensure that 'values' is defined and 'providers' array is not empty
    if (!values || !providers.length) {
      return []
    }

    const closeMatch = [...providers].filter((provider) => {
      const hasExpertise = getDiagnosticExpertiseFilters(values.expertise).some((expertise) =>
        provider.profile?.diagnosticExpertise?.includes(expertise),
      )

      const matchesGender =
        filters.gender?.length && filters.gender === 'Another Gender Identity'
          ? FilterGenders.includes(provider?.profile?.genderIdentity)
          : provider?.profile?.genderIdentity === filters.gender

      const matchesEthnicity = values.race?.length && values.race.includes(mappedProviderEthnicityForFilters(provider))

      return !bestMatch.includes(provider) && (hasExpertise || matchesGender || matchesEthnicity)
    })
    return closeMatch
  })

export const getRemainingAvailableProvider = (pendingVisit, bestMatchResults, closeMatchResults) =>
  createSelector(getAvailableProviders(pendingVisit), (providers = []) => {
    if (!providers.length) {
      return []
    }
    return providers.filter((provider) => {
      return !bestMatchResults.includes(provider) && !closeMatchResults.includes(provider)
    })
  })

export const getProvider = (providerId) => createSelector(getRecords, (records = {}) => records[providerId])

export const getProviderName = (providerId) =>
  createSelector(getProvider(providerId), (provider) => provider && `${provider.firstName} ${provider.lastName}`)

export const getProviderNameAndCredentials = (providerId) =>
  createSelector(getProvider(providerId), (provider) => {
    if (!provider) return null
    let name
    if (provider.prefix) name = `${provider.prefix} `
    name += `${provider.firstName} ${provider.lastName}`
    if (provider.profile?.licenses?.length) name += `, ${provider.profile.licenses.join(', ')}`
    return name
  })

export const getProviderNameObject = (providerId) =>
  createSelector(getProvider(providerId), (provider = {}) => ({
    id: provider.providerId,
    firstName: provider.firstName,
    lastName: provider.lastName,
  }))

export const getProviderLastFetch = (providerId) => createSelector(getMeta, (meta = {}) => meta.fetches[providerId])

export const isProviderFetching = (providerId) =>
  createSelector(getMeta, (meta = {}) => meta.fetching.includes(providerId))

export const getProviderFetchError = (providerId) =>
  createSelector(getMeta, (meta = {}) => meta.fetches.errors[providerId])

export const getProviderType = (providerId) =>
  createSelector(getProvider(providerId), (provider) => {
    if (!provider) return ''
    const now = moment()
    return provider.licenses.reduce((acc, license) => {
      const expirationDate = moment(license.expDate, 'YYYY-MM-DD')
      if (expirationDate.isAfter(now)) {
        const licenseType = license.type === 'medical' ? 'Physician' : 'Therapist'
        if (!acc.includes(licenseType)) {
          if (!acc) {
            acc = licenseType
          } else {
            acc += `, ${licenseType}`
          }
        }
      }
      return acc
    }, '')
  })
