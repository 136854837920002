import { ReactNode } from 'react'
import classNames from '@ui-components-3/ui/lib/utils/classNames'

type ActionDividerProps = {
  className: string
  children: ReactNode
}

const ActionDivider = ({ className, children }: ActionDividerProps) => {
  return (
    <div className={classNames('flex items-center justify-center gap-4', className)}>
      <hr className="w-full border-neutral-400" />
      {children}
      <hr className="w-full border-neutral-400" />
    </div>
  )
}

export default ActionDivider
