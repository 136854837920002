import { capitalize, isEmpty } from 'lodash'

export const capEveryWord = (address) => {
  const words = address.split(' ')
  return words.reduce((acc, word) => {
    if (!acc) return capitalize(word)
    return `${acc} ${capitalize(word)}`
  }, '')
}

export const specialtyOptions = [
  { value: 'mail_order', label: 'Mail Order' },
  { value: 'retail', label: 'Retail' },
  { value: 'specialty', label: 'Specialty' },
  { value: 'long_term_care', label: 'Long-Term Care' },
  { value: 'open_24', label: '24 Hour' },
]

export const distanceOptions = [
  { value: '', label: 'Select a distance', disabled: true },
  { value: 5, label: 'Within 5 miles' },
  { value: 10, label: 'Within 10 miles' },
  { value: 25, label: 'Within 25 miles' },
  { value: 50, label: 'Within 50 miles' },
]

export const specialtyBitMap = {
  1: 'Mail Order',
  8: 'Retail',
  16: 'Specialty',
  32: 'Long Term Care',
  64: 'Open 24 hours',
}

export const getPharmacySpecialties = (pharmacy) => {
  if (!pharmacy) return ''
  const specialties = Object.keys(specialtyBitMap).reduce((acc, bit) => {
    const hasBit = bit & pharmacy.specialty
    if (hasBit) {
      if (!acc) return specialtyBitMap[bit]
      return `${acc}, ${specialtyBitMap[bit]}`
    }
    return acc
  }, '')
  return specialties // || 'No Specialties'
}

export const pharmacyLocation = (pharmacy) => {
  if (!pharmacy || !pharmacy.point || isEmpty(pharmacy.point.coordinates)) {
    return null
  }
  const [lng, lat] = pharmacy.point.coordinates
  return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}&label=${pharmacy.store_name}`
}
