import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import configBuilder from '../config-builder'
import { normalizeJsonToApi } from '../../helpers'
import AllergySchema from '../../../schemas/AllergySchema'

const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  memberId: Yup.string().required(),
  allergy: AllergySchema.required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const make = async (params) => {
  const allergy = AllergySchema.cast(params.allergy)
  return normalizeJsonToApi({ allergy })
}
export const url = ({ memberId }) => `/v1/${memberId}/allergies`
export const execute = async (params) =>
  api.post(url(params), await make(params), configBuilder(params)).catch(catchApiFailure)
