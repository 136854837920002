import { createSlice } from '@reduxjs/toolkit'
import { requiredAccountMergeAttributes } from 'constants/accountMerge'
import cloneDeep from 'lodash/cloneDeep'

const defaultState = {
  meta: {
    loggedInMemberId: '',
    loggedInMemberGroup: null,
    lookupId: null,
    fetches: {
      // [memberId]: Date
    },
  },
  names: {
    // [memberId]: membername
  },
  records: {
    // [member_id]: Member
    create: {
      memberId: '',
      dateOfBirth: '',
      name: '',
      gender: '',
      address: {
        street1: '',
        city: '',
        state: '',
        zip: '',
      },
      phone: {
        type: '',
        number: '',
      },
    },
  },
  dependents: {
    meta: {
      membersLoaded: [],
    },
    records: {
      // [primary_member_id]: dependent Member
    },
  },
  providers: {
    // [memberId]: { [providerId]: MemberProvider }
  },
  acknowledgments: {
    // [acknowledgmentKey]: Acknowledgment
  },
  duplicates: {
    // [memberId]: {
    //[duplicateMemberId]: DuplicateMember
    //}
  },
}

const getId = (obj) => obj.memberId || obj._id || obj.id

const slice = createSlice({
  name: 'member',
  initialState: defaultState,
  reducers: {
    putMember: (state, action) => {
      const { member } = action.payload
      const memberId = getId(member)
      state.records[memberId] = member
      const providerArray = member.memberProviders || []
      let providers = cloneDeep(state.providers)
      providers = providerArray.reduce((acc, curr) => {
        if (!acc[memberId]) {
          acc[memberId] = {}
        }
        acc[memberId][getId(curr)] = curr
        return acc
      }, providers)
      state.providers = providers
    },
    putMembers: (state, action) => {
      const { members } = action.payload
      let providers = cloneDeep(state.providers)
      members.forEach((member) => {
        const providerArray = member.memberProviders || []
        const memberId = getId(member)
        providers = providerArray.reduce((acc, curr) => {
          if (!acc[memberId]) acc[memberId] = {}
          acc[memberId][getId(curr)] = curr
          return acc
        }, providers)
        state.records[memberId] = member
      })
      state.providers = providers
    },
    putLoggedInMember: (state, action) => {
      const { memberId } = action.payload
      state.meta.loggedInMemberId = memberId
    },
    putLoggedInMemberGroup: (state, action) => {
      state.meta.loggedInMemberGroup = action.payload
    },
    putMemberLastFetch: (state, action) => {
      const { memberId, lastFetch = new Date() } = action.payload
      state.meta.fetches[memberId] = lastFetch
    },
    putLookupId: (state, action) => {
      const { lookupId } = action.payload
      state.meta.lookupId = lookupId
    },
    putNewMember: (state, action) => {
      const { member } = action.payload
      state.records.create = { ...member }
    },
    putDependent: (state, action) => {
      const { dependent } = action.payload
      const memberId = getId(dependent)
      state.dependents.records[memberId] = dependent
      state.records[memberId] = dependent
      const providerArray = dependent.memberProviders || []
      const providers = providerArray.reduce((acc, curr) => {
        if (!acc[memberId]) acc[memberId] = {}
        acc[memberId][getId(curr)] = curr
        return acc
      }, state.providers)
      state.providers = providers
    },
    putDependents: (state, action) => {
      const { dependents } = action.payload
      let providers = cloneDeep(state.providers)
      dependents.forEach((dependent) => {
        const providerArray = dependent.memberProviders || []
        const memberId = getId(dependent)
        state.dependents.records[memberId] = dependent
        state.records[memberId] = dependent
        providers = providerArray.reduce((acc, curr) => {
          if (!acc[memberId]) acc[memberId] = {}
          acc[memberId][getId(curr)] = curr
          return acc
        }, providers)
      })
      state.providers = providers
    },
    putMemberProviders: (state, action) => {
      const { memberId, providers: providersArray } = action.payload
      let providers = cloneDeep(state.providers)
      providers = providersArray
        ? providersArray.reduce((acc, curr) => {
            if (!acc[memberId]) {
              acc[memberId] = {}
            }
            acc[memberId][curr.providerId] = curr
            return acc
          }, providers)
        : {}
      state.providers = providers
    },
    putMemberNames: (state, action) => {
      const { memberNames } = action.payload
      memberNames.forEach((name) => {
        state.names[name.memberId] = name
      })
    },
    clearNewMember: (state) => {
      state.records.create = { ...defaultState.records.create }
    },
    clearMember: (state, action) => {
      const { memberId } = action.payload
      delete state.records[memberId]
      delete state.providers[memberId]
    },
    clearMembers: (state) => {
      state.records = defaultState.records
      state.names = defaultState.names
      state.providers = defaultState.providers
    },
    clearDependent: (state, action) => {
      const { dependentId } = action.payload
      delete state.dependents[dependentId]
      delete state.records[dependentId]
      delete state.providers[dependentId]
    },
    clearDependents: (state) => {
      const dependentIds = Object.keys(state.dependents)
      dependentIds.forEach((id) => {
        delete state.dependents[id]
        delete state.records[id]
        delete state.providers[id]
      })
    },
    putOptimisiticTermsAgreement: (state, action) => {
      const { memberId, termsAcceptedDate } = action.payload
      const termsConditions = state.records[memberId].termsConditions || []
      const tcAppTermIndex = termsConditions.findIndex((term) => term.type === 'tc-app')
      if (tcAppTermIndex !== -1) {
        // If tc-app agreement already exists update the terms accepted date
        state.records[memberId].termsConditions[tcAppTermIndex] = {
          ...state.records[memberId].termsConditions[tcAppTermIndex],
          lastAcceptedAt: termsAcceptedDate,
        }
      } else {
        // If tc-app agreement doesn't exist add it to the array
        state.records[memberId].termsConditions = [
          ...termsConditions,
          { type: 'tc-app', lastAcceptedAt: termsAcceptedDate },
        ]
      }
    },
    putAcknowledgments: (state, action) => {
      const updatedAcknowledgments = action.payload
      state.acknowledgments = {
        ...state.acknowledgments,
        ...updatedAcknowledgments,
      }
    },
    clearAcknowledgments: (state) => {
      state.acknowledgments = {}
    },
    putDuplicateMembers: (state, action) => {
      const { memberId, duplicateMembers } = action.payload
      const newDupeRecords = duplicateMembers.reduce((acc, dupe) => ({ ...acc, [dupe.duplicateMemberId]: dupe }), {})
      const dupeRecords = { ...state.duplicates[memberId], ...newDupeRecords }
      state.duplicates[memberId] = dupeRecords
    },
    clearDuplicateMember: (state, action) => {
      const { memberId, duplicateMemberId } = action.payload
      delete state.duplicates[memberId][duplicateMemberId]
    },
    clearDuplicateMembers: (state) => {
      state.duplicates = {}
    },
    putDuplicateMemberAttributes: (state, action) => {
      const { memberId, duplicateMemberId, memberAttributes } = action.payload
      const { email, mobilePhones = [] } = memberAttributes || {}
      state.duplicates[memberId][duplicateMemberId].member = { email, mobilePhones }
    },
  },
})

const { actions, reducer } = slice
export const {
  putMember,
  putMembers,
  putNewMember,
  putDependent,
  putDependents,
  putMemberNames,
  clearMember,
  clearMembers,
  clearNewMember,
  clearDependent,
  clearDependents,
  putLoggedInMember,
  putLoggedInMemberGroup,
  putMemberLastFetch,
  putLookupId,
  putMemberProviders,
  putOptimisiticTermsAgreement,
  putAcknowledgments,
  clearAcknowledgments,
  putDuplicateMembers,
  clearDuplicateMembers,
  clearDuplicateMember,
  putDuplicateMemberAttributes,
} = actions
export default reducer
