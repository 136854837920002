import { createSelector } from 'reselect'

export const getMessagesState = (state) => state.message

export const getMessagesRecords = createSelector(getMessagesState, ({ records }) => records)
export const getNewMessagesRecords = createSelector(getMessagesState, ({ newRecords }) => newRecords)

export const getMessageDetails = (messageId) =>
  createSelector(getMessagesState, ({ detailsRecords }) => detailsRecords[messageId])

export const getVisitMessagesTotal = createSelector(getMessagesState, ({ meta }) => meta.visitMessagesTotal)
export const getVisitMessages = createSelector(getMessagesRecords, (records) =>
  Object.values(records)
    .filter((m) => m.messageType === 'visit')
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
)

export const getTaskMessagesTotal = createSelector(getMessagesState, ({ meta }) => meta.taskMessagesTotal)
export const getTaskMessages = createSelector(getMessagesRecords, (records) =>
  Object.values(records)
    .filter((m) => m.messageType === 'task')
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
)

export const countNewMessages = (messageType) =>
  createSelector(
    getNewMessagesRecords,
    (newRecords) => Object.values(newRecords).filter((m) => m.messageType === messageType).length,
  )

export const getUnreadMessageCounts = createSelector(getMessagesState, ({ unreadCounts }) => unreadCounts)
