import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as ApiSelectors from 'redux/selectors/api'
import FetchTypes from 'util/fetch-types'
import { isEmpty } from 'lodash'
import { addMemberPharmacy, fetchMemberPharmacies, removeMemberPharmacy } from 'redux/thunks/pharmacies'
import * as PharmacyThunks from 'redux/thunks/pharmacies'

export const usePharmacyControl = (memberId: string) => {
  const dispatch = useDispatch()

  const isRemoving = useSelector(ApiSelectors.isTypeFetching(FetchTypes.removeMemberPharmacy))
  const isFetchingMemberPharmacies = useSelector(ApiSelectors.isTypeFetching(FetchTypes.getMemberPharmacies))
  const isSearchingPharmacies = useSelector(ApiSelectors.isTypeFetching(FetchTypes.getPharmacies))
  const isAddingPharmacies = useSelector(ApiSelectors.isTypeFetching(FetchTypes.addMemberPharmacy))

  const handleFetchMemberPharmacies = useCallback(async () => {
    if (isEmpty(memberId)) return null
    return dispatch(fetchMemberPharmacies(memberId))
  }, [dispatch, memberId])

  const handleFetchPharmacies = useCallback(
    (filter) => {
      return dispatch(PharmacyThunks.fetchPharmacies(filter))
    },
    [dispatch],
  )

  const handleAddPharmacy = useCallback(
    async (pharmacy) => {
      return dispatch(addMemberPharmacy(memberId, pharmacy.id))
    },
    [memberId],
  )

  const handleRemovePharmacy = useCallback(
    async (pharmacyId: string) => {
      return await dispatch(removeMemberPharmacy(memberId, pharmacyId))
    },
    [dispatch, memberId],
  )

  const isPharmacyLoading = isRemoving || isFetchingMemberPharmacies || isSearchingPharmacies || isAddingPharmacies

  return {
    handleFetchMemberPharmacies,
    handleFetchPharmacies,
    handleAddPharmacy,
    handleRemovePharmacy,
    isPharmacyLoading,
  }
}
