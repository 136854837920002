import * as Yup from 'yup'

export const PasswordConfirmPasswordSchema = Yup.object({
  password: Yup.string()
    // TODO - this label causes poor validation experience
    .label('Password')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/g,
      'Password must have 10 characters, and must contain 1 capital letter,' +
        ' 1 lowercase letter, 1 special character, and 1 number',
    )
    .required(),
  confirmPassword: Yup.string()
    .label('Confirm Password')
    .oneOf([Yup.ref('password'), null], 'Confirm Password must match Password!')
    .required(),
})

export type PasswordConfirmPasswordValues = Yup.InferType<typeof PasswordConfirmPasswordSchema>
