import { camelCase, snakeCase, isEqual } from 'lodash'
import RequestFactory from './requests/factory'
import { putAlert } from '../redux/slices/view'
import * as APM from 'util/apm'
import escape from 'escape-html'

export const normalizeJsonArrayFromApi = (array = [], emptyObjectValue) => {
  return array.map((item) => {
    if (Array.isArray(item)) {
      return normalizeJsonArrayFromApi(item, emptyObjectValue)
    } else if (typeof item === 'object') {
      return normalizeJsonFromApi(item, emptyObjectValue)
    } else {
      return item
    }
  })
}

const mongoIdRegex = new RegExp(/^[0-9a-fA-F]{24}$/)
export const normalizeJsonFromApi = (data = {}, emptyObjectValue = null) => {
  return Object.keys(data || {}).reduce((acc, key) => {
    const normalizedKey = mongoIdRegex.test(key) ? key : camelCase(key)
    if (Array.isArray(data[key])) {
      acc[normalizedKey] = normalizeJsonArrayFromApi(data[key], emptyObjectValue)
    } else if (data[key] === null) {
      acc[normalizedKey] = null
    } else if (typeof data[key] === 'object' && !!data[key]) {
      acc[normalizedKey] = normalizeJsonFromApi(data[key], emptyObjectValue)
    } else {
      acc[normalizedKey] = data[key]
    }
    return acc
  }, {})
}

export const normalizeJsonArrayToApi = (array = [], emptyObjectValue = null, idField = '_id') => {
  return array.map((item) => {
    if (Array.isArray(item)) {
      return normalizeJsonArrayToApi(item, emptyObjectValue, idField)
    } else if (typeof item === 'object') {
      return normalizeJsonToApi(item, emptyObjectValue, idField)
    } else {
      return item
    }
  })
}

export const normalizeJsonToApi = (data = {}, emptyObjectValue = null, idField = '_id') => {
  return Object.keys(data || {}).reduce((acc, key) => {
    let normalizedKey = snakeCase(key)
    if (isEqual(normalizedKey, 'id')) {
      normalizedKey = idField
    }
    if (Array.isArray(data[key])) {
      acc[normalizedKey] = normalizeJsonArrayToApi(data[key], emptyObjectValue, idField)
    } else if (typeof data[key] === 'object') {
      if (data[key] === null || Object.keys(data[key]).length === 0) {
        acc[normalizedKey] = emptyObjectValue
      } else {
        acc[normalizedKey] = normalizeJsonToApi(data[key], emptyObjectValue, idField)
      }
    } else {
      acc[normalizedKey] = data[key]
    }
    return acc
  }, {})
}

export const makeRequest = async (fetchType, _params, onSuccess, onFailure) => {
  try {
    // const token = getFromStorage(SessionKeys.TOKEN);
    // console.log({ token });
    const params = { ..._params }
    // if (token) params.token = token;
    const request = RequestFactory.getRequest(fetchType)
    if (!!request && (await request.isValid(params))) {
      const response = await request.execute(params).catch((error) => {
        console.log({ error })
        if (error.response) return error.response
        throw error
      })
      if (response) {
        switch (response.status) {
          case 200:
          case 201:
          case 204: {
            if (response.config.responseType === 'blob') {
              await onSuccess(response.data)
              return true
            }
            let data = normalizeJsonFromApi(response.data)
            if (data.data) data = data.data
            await onSuccess(data)
            return true
          }
          default: {
            console.error(`Response was a ${response.status} error`)
            console.error(response)
            let error = ''
            if (Number(response.status) === 502) {
              error = 'There was an issue communicating with the server, please try again'
            } else if (response.data?.errors?.length > 0) {
              console.log(response.data.errors)
              const [first] = response.data.errors
              error = first.message || first.detail
            } else {
              console.log(response.data)
              error = `Received a ${response.status} response`
            }
            await onFailure(error, response)
            return false
          }
        }
      } else {
        const error = 'Error getting response from API'
        console.error(error)
        await onFailure(error)
        return false
      }
    } else {
      let error = 'Error creating request'
      if (!request) {
        error = 'No request was created'
      } else {
        try {
          console.error({ params }, await request.validate?.(params))
        } catch (e) {
          console.error(params, e)
        }
      }
      console.error(error, params, { fetchType })
      await onFailure(error)
      return false
    }
  } catch (e) {
    const error = 'Error making request'
    APM.captureException(e)
    console.error(error)
    console.error(e)
    await onFailure(error)
    return false
  }
}

export const stringifyError = (error) => {
  if (!error) return 'Error returning error'
  const errorType = typeof error
  if (errorType === 'string') {
    return error
  }
  if (errorType === 'object') {
    if (Array.isArray(error)) {
      if (error.length > 0) {
        const [first] = error
        if (first?.message) return first.message
        if (first?.detail) return first.detail
        if (first?.errors) return JSON.stringify(first.errors)
        return 'There were multiple errors'
      }
      return 'An unknown error occurred'
    } else {
      if (error.message) return error.message
      if (error.detail) return error.detail
      if (error.errors) return JSON.stringify(error.errors)
    }
  }
  return error
}

export const handleRequestFailure = (dispatch, callback) => (error) => {
  const transformedError = escape(stringifyError(error))
  dispatch(
    putAlert({
      isAlert: true,
      type: 'error',
      body: transformedError,
      title: 'Error',
      success: false,
    }),
  )
  if (callback) callback()
}
