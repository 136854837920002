import useAccountMerge, { useAccountMergeContext } from 'hooks/useAccountMerge'
import PasswordInput from '@ui-components-3/ui/lib/components/PasswordInput'
import FormControl from '@ui-components-3/ui/lib/components/FormControl'
import { ModalRenderPropParams } from '@ui-components-3/ui/lib/components/Modal'
import { PasswordConfirmPasswordValues } from 'schemas/PasswordConfirmPasswordSchema'
import { CHALLENGE_NAME_SCENE_MAP } from 'constants/accountMerge'
import { ChallengeResponse } from 'types/account-merge/AccountMergeChallenge'
import { toastMergeFailed } from '../AccountMerge'

const getFormId = (idPrefix: string) => idPrefix + 'form:verify-password'

export const VerifyPasswordFooter = ({ idPrefix }: ModalRenderPropParams) => {
  const formId = getFormId(idPrefix)
  const { handleCancel, verifyPasswordForm } = useAccountMergeContext()
  const {
    formState: { isValid, isSubmitting, isDirty },
  } = verifyPasswordForm
  const { isSubmittingChallenge } = useAccountMerge()
  const isSubmitDisabled = !isValid || isSubmitting || !isDirty || isSubmittingChallenge

  return (
    <>
      <button
        type="button"
        onClick={() => handleCancel()}
        disabled={isSubmittingChallenge}
        className="btn btn-neutral-outlined self-end"
      >
        Cancel
      </button>
      <button type="submit" form={formId} disabled={isSubmitDisabled} className="btn btn-neutral self-end">
        Next
      </button>
    </>
  )
}

export const VerifyPasswordContent = ({ idPrefix }: ModalRenderPropParams) => {
  const formId = getFormId(idPrefix)
  const context = useAccountMergeContext()
  const { verifyPasswordForm, handleNextScene, selectedMemberId, handleCancel, challenge, setError, resetForms } =
    context
  const { submitChallenge, handleFailedChallenge, selectedDuplicateAccount } = useAccountMerge()
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = verifyPasswordForm

  const onSubmit = handleSubmit(async (values: PasswordConfirmPasswordValues) => {
    const { password } = values
    // @ts-ignore
    const { success, data } = await submitChallenge(challenge, { password, duplicateMemberId: selectedMemberId })
    if (success) {
      const { authenticationResult }: ChallengeResponse = data
      if (data?.challengeName) {
        const challenge = data.challengeName
        handleNextScene(CHALLENGE_NAME_SCENE_MAP[challenge], challenge, true)
      } else if (authenticationResult?.attemptsRemaining === 0) {
        handleCancel(true)
        toastMergeFailed()
      } else {
        await handleFailedChallenge()
        resetForms()
        setError('Your answer is incorrect – please try again')
      }
    }
  })

  return (
    <form id={formId} onSubmit={onSubmit} className="gap-m flex flex-col">
      <p className="typography-body-xl mt-3 font-medium">
        Please enter the password for the following account to confirm: {selectedDuplicateAccount.member.email}
      </p>
      <FormControl
        label="Password*"
        helperVariant="error"
        helper={errors?.password ? (errors.password.message as string) : undefined}
      >
        <PasswordInput autoComplete="off" {...register('password')} />
      </FormControl>
      <FormControl
        label="Confirm Password*"
        helperVariant="error"
        helper={errors?.confirmPassword ? (errors.confirmPassword.message as string) : undefined}
      >
        <PasswordInput autoComplete="off" {...register('confirmPassword')} />
      </FormControl>
    </form>
  )
}
