import FormikSelect from '@ui-components-3/compat/lib/components/FormikSelect'
import FormikTextField from '@ui-components-3/compat/lib/components/FormikTextField'
import Checkbox from '@ui-components-3/ui/lib/components/Checkbox'
import FormControl from '@ui-components-3/ui/lib/components/FormControl'
import classNames from '@ui-components-3/ui/lib/utils/classNames'
import { useFormikContext } from 'formik'
import TestIds from 'util/TestIds'
import { distanceOptions, specialtyOptions } from 'util/pharmacies'
import States from 'util/states'
import Loading from '@ui-components-3/ui/lib/components/Loading'
import { useCallback, useEffect, useState } from 'react'
import { isNil } from 'lodash'
import { useGeolocated } from 'react-geolocated'

const getOptionLabel = (g) => g.label
const getOptionValue = (g) => g.value
const isOptionDisabled = (o) => o.disabled

type PharmacySearchFormContentProps = {
  formId: string
  className?: string
}
const PharmacySearchFormContent = ({ formId, className }: PharmacySearchFormContentProps) => {
  const { handleSubmit, initialValues, setFieldValue } = useFormikContext<{ city: string; state: string }>()

  const { isGeolocationEnabled, isGeolocationAvailable, coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
  })
  const [isUsingLocation, setIsUsingLocation] = useState(false)
  const [canUseLocation, setCanUseLocation] = useState(false)
  const [isLoadingLocation, setIsLoadingLocation] = useState(false)
  useEffect(() => {
    setCanUseLocation(isGeolocationAvailable && isGeolocationEnabled && !isNil(coords))
    if (isUsingLocation) setIsUsingLocation(isGeolocationAvailable && isGeolocationEnabled)
    setIsLoadingLocation(isGeolocationAvailable && isGeolocationEnabled ? isNil(coords) : false)
  }, [isGeolocationAvailable, isGeolocationEnabled, coords])

  const handleCheck = useCallback(
    (event) => {
      const nextValue = !isNil(coords) && event.target.checked
      setIsUsingLocation(nextValue)
      if (nextValue) {
        setFieldValue('lat', coords.latitude)
        setFieldValue('lng', coords.longitude)
        setFieldValue('city', '')
        setFieldValue('state', '')
        setFieldValue('zip', '')
      } else {
        setFieldValue('city', initialValues.city)
        setFieldValue('state', initialValues.state)
        setFieldValue('lat', '')
        setFieldValue('distance', '')
        setFieldValue('lng', '')
      }
    },
    [coords],
  )

  return (
    <form onSubmit={handleSubmit} id={formId}>
      <div className={className}>
        <div className="mb-4 grid grid-cols-1 gap-y-4">
          <FormikTextField
            fullWidth
            autoComplete="off"
            label={'Pharmacy Name'}
            data-testid={TestIds.newVisit.input.pharmacyName}
            helper={'Optional'}
            name={'storeName'}
          />
          <FormikSelect
            multiple
            data-testid={TestIds.newVisit.input.pharmacySpecialty}
            label={'Specialty'}
            name={'specialty'}
            helperText={'Optional'}
            options={specialtyOptions}
            isOptionDisabled={isOptionDisabled}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
          />
          <FormikTextField
            fullWidth
            autoComplete="address-line1"
            label={'City'}
            data-testid={TestIds.newVisit.input.pharmacyCity}
            name={'city'}
          />
          <FormikTextField
            fullWidth
            autoComplete="postal-code"
            label={'Zipcode'}
            data-testid={TestIds.newVisit.input.pharmacyZip}
            name={'zip'}
          />
          <FormikSelect
            label={'State'}
            data-testid={TestIds.newVisit.input.pharmacyState}
            name={'state'}
            clearable
            options={States}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
          />
          <FormControl
            label={
              <div
                className={classNames('flex items-center', canUseLocation ? 'text-neutral-700' : 'text-neutral-500')}
              >
                Use my location
                {!!isLoadingLocation && <Loading className="py-0" />}
              </div>
            }
            labelPlacement="right"
            data-testid={TestIds.newVisit.input.pharmacyLocation}
          >
            <Checkbox disabled={!canUseLocation} checked={isUsingLocation} onChange={handleCheck} />
          </FormControl>
          {!!isUsingLocation && (
            <FormikSelect
              data-testid={TestIds.newVisit.input.pharmacyDistance}
              label={'Distance'}
              name={'distance'}
              helperText={'Optional'}
              options={distanceOptions}
              isOptionDisabled={isOptionDisabled}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
            />
          )}
        </div>
      </div>
    </form>
  )
}

export default PharmacySearchFormContent
