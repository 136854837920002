import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import { normalizeJsonToApi } from '../../helpers'
import configBuilder from '../config-builder'
import ApiFactory, { ApiTypes } from '../../index'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  messageId: Yup.string().required(),
  status: Yup.string().oneOf(['pending', 'completed']).required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => await RequestSchema.validate(params)
export const make = async ({ status }) => {
  return normalizeJsonToApi({ meta: { status } })
}
export const url = ({ messageId }) => `/v1/messages/${messageId}`
export const execute = async (params) =>
  api.put(url(params), await make(params), configBuilder(params)).catch(catchApiFailure)
