import { DASHBOARD } from '../routes/paths'
import { isSuccess } from './helpers'
import { getPrintableShortProviderName } from './provider'
import { toast } from './toast'

const VisitStatus = {
  inProgress: 'in-progress',
  pending: 'pending',
  pendingConfirmation: 'pending-confirmation',
  completed: 'completed',
  cancelled: 'cancelled',
  inReview: 'in-review',
}
Object.freeze(VisitStatus)

export const isVisitUnavailable = (status) => {
  return status === VisitStatus.cancelled || status === VisitStatus.pending
}

const alertStyle = { lineHeight: '18px', margin: '5px' }
export const alertVisitUnavailability = ({ pendingVisit, pendingVisitId, status, redirectToHome, history }) => {
  let message = ''
  let alertId = ''
  if (pendingVisit) {
    const successVisit = isSuccess(pendingVisit.visitType)
    const visitTitle = successVisit ? 'session' : 'visit'
    const action = pendingVisit.status === VisitStatus.cancelled ? 'cancelled' : 'sent back to queue'
    alertId = `${pendingVisit.status}-${pendingVisit.pendingVisitId}`
    const providerNameAndCreds = getPrintableShortProviderName(pendingVisit.providerName, successVisit)
    const provider = pendingVisit.providerName ? `with ${providerNameAndCreds} ` : ''
    message = `Your ${visitTitle} ${provider}was ${action}.`
    if (pendingVisit.statusReason) {
      message += ` Reason: ${pendingVisit.statusReason}`
    }
  } else if (status) {
    const action = status === VisitStatus.cancelled ? 'cancelled' : 'sent back to queue'
    alertId = pendingVisitId ? `${status}-${pendingVisitId}` : status
    message = `Your visit was ${action}.`
  }
  toast.success(<p style={alertStyle}>{message}</p>, { toastId: alertId })
  if (redirectToHome) history.push(DASHBOARD)
}

export const alertVisitCompletion = (pendingVisit) => {
  if (pendingVisit) {
    const visitTitle = isSuccess(pendingVisit.visitType) ? 'session' : 'visit'
    const providerNameAndCreds = getPrintableShortProviderName(pendingVisit.providerName)
    const action = pendingVisit.status === VisitStatus.completed ? 'was completed' : 'is in review'
    const alertId = `${pendingVisit.status}-${pendingVisit.pendingVisitId}`
    const provider = pendingVisit.providerName ? `with ${providerNameAndCreds} ` : ''
    const message = `Your ${visitTitle} ${provider} ${action}.`

    toast.success(<p style={alertStyle}>{message}</p>, { toastId: alertId })
  }
}

export default VisitStatus
