import * as Yup from 'yup'
import configBuilder from '../config-builder'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import { normalizeJsonToApi } from '../../helpers'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  memberId: Yup.string().required(),
  need: Yup.object()
    .shape({
      requestTypes: Yup.array()
        .of(
          Yup.string().oneOf([
            'food',
            'housing',
            'goods',
            'transit',
            'health',
            'finance',
            'care',
            'work',
            'legal',
            'education',
            'other',
          ]),
        )
        .required(),
      requestNotes: Yup.string(),
    })
    .required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const make = (params) => {
  const clean = RequestSchema.cast(params)
  if (!clean.need.requestNotes) {
    delete clean.need.requestNotes
  }
  return normalizeJsonToApi({ need: clean.need })
}
export const url = ({ memberId }) => `/v1/members/${memberId}/needs`
export const execute = async (params) =>
  api.post(url(params), make(params), configBuilder(params)).catch(catchApiFailure)
