import useAccountMerge, { useAccountMergeContext } from 'hooks/useAccountMerge'
import { CHALLENGE_NAME_SCENE_MAP } from 'constants/accountMerge'

export const TermsAndPolicyFooter = () => {
  const { handleNextScene, setError, task, handleCancel } = useAccountMergeContext()
  const { accountMergeInit, handleSaveForLater, isSavingForLater, isSubmittingChallenge } = useAccountMerge()

  const handleConfirm = async () => {
    // @ts-ignore
    const { success, data } = await accountMergeInit()
    if (success && data?.challengeName) {
      const challenge = data.challengeName
      handleNextScene(CHALLENGE_NAME_SCENE_MAP[challenge], challenge)
    } else {
      setError('Failed to initiate account merge. Please contact support')
    }
  }

  const isTask = !!task

  const onCancel = () => {
    if (isTask) {
      handleCancel()
    } else {
      handleSaveForLater()
    }
  }
  return (
    <>
      <button
        type="button"
        className="btn btn-neutral-outlined self-end"
        disabled={isSavingForLater}
        onClick={onCancel}
      >
        {isTask ? 'Cancel' : 'Save for Later'}
      </button>
      <button
        type="button"
        className="btn btn-neutral self-end"
        onClick={handleConfirm}
        disabled={isSubmittingChallenge || isSavingForLater}
      >
        Acknowledge
      </button>
    </>
  )
}

export const TermsAndPolicyContent = () => (
  <div className="gap-s flex flex-col">
    <p className="typography-body-l">
      By proceeding with the merging of student accounts, along with accompanying student personal information, you
      acknowledge and accept that the information being consolidated is accurate to the best of your knowledge. You
      understand that any errors or discrepancies in the merged records should be reported immediately to TimelyCare for
      correction. Additionally, you agree that this process is undertaken at your sole direction and that you shall
      fully indemnify, release and hold harmless TimelyCare and its officers, employees and agents from any and claims
      and damages that result from errors in the requested merging of student accounts and that you take full
      responsibility for any consequences arising from the merging of these student accounts and accompanying student
      information.
    </p>
  </div>
)
