import classNames from '@ui-components-3/ui/lib/utils/classNames'
import InfoIcon from '@ui-components-3/icons/lib/duotone/CircleInfo'

type InfoCardProps = {
  className?: string
  title: string
  description: string
}

const InfoCard = ({ className, title, description }: InfoCardProps) => {
  return (
    <div
      className={classNames('bg-primary-100 flex items-start gap-x-1 rounded-lg px-4 py-3 text-neutral-800', className)}
    >
      <div className="h-8 w-8 shrink-0 p-1.5">
        <InfoIcon className="text-primary-600 h-full w-full" aria-hidden="true" />
      </div>
      <div className="pt-1.5">
        <div className="typography-body-xl mb-1 font-semibold">{title}</div>
        <div className="typography-body-l">{description}</div>
      </div>
    </div>
  )
}

export default InfoCard
