import { isMessageRead } from 'components/VisitChatMessages/VisitChatMessages'
import { ChatState } from 'redux/slices/chats'
import { createSelector } from 'reselect'
import { Chat } from 'types/visit-chat-message/visitChatMessage'

export const getRecords = (state: any): ChatState['records'] => state.chats?.records

export const getChats = createSelector(getRecords, (records: ChatState['records']) => Object.values(records || {}))

export const getChat = (chatId: string) => createSelector(getRecords, (records = {}) => records?.[chatId])

export const getPendingVisitChats = (pendingVisitId: string) =>
  createSelector(getChats, (chats) => chats.filter((chat) => chat.pendingVisitId === pendingVisitId))

export const getUnreadMessagesCount = (memberId: string, chatId: string) =>
  createSelector(getChat(chatId), (chat: Chat) => {
    if (!chat) {
      return 0
    }

    let result = 0

    for (let i = chat.messages.length; i--; ) {
      if (isMessageRead(chat.messages[i], memberId)) {
        break
      } else {
        result++
      }
    }

    return result
  })
