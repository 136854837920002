import * as Yup from 'yup'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import configBuilder from '../config-builder'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import NewVisitSchema from '../../../schemas/NewVisitSchema'
import { normalizeJsonToApi } from '../../helpers'
import VisitScheduleType from '../../../util/visit-schedule-types'
import moment from 'moment-timezone'
import { isLocationOutsideUS } from 'util/visit-location'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  pendingVisit: NewVisitSchema.required(),
  memberId: Yup.string().label('Member ID').required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => await RequestSchema.validate(params)
export const url = ({ memberId, pendingVisit }) => {
  const pv = NewVisitSchema.cast(pendingVisit)
  const { scheduledType, visitType, providerId } = pv
  let url = `/v1/${memberId}/visits/${scheduledType}/${visitType}`
  if (!isEmpty(providerId) && scheduledType !== VisitScheduleType.now) {
    url += `/${providerId}`
  }
  return url
}

export const make = (params) => {
  const request = RequestSchema.cast(params)
  const pv = request.pendingVisit
  if (pv.scheduledType === VisitScheduleType.scheduled) {
    if (pv.scheduledAt) {
      pv.scheduledAt = moment(pv.scheduledAt).toISOString(false)
    }
  } else {
    delete pv.providerTimezone
    delete pv.providerName
    delete pv.providerId
  }
  // TODO replace this later... if needed
  delete pv.filters

  // Location needs to be null if locationCountry is not 'US'
  if (isLocationOutsideUS(pv.locationCountry)) {
    delete pv.location
  }

  if ('preferences' in pv && pv.preferences) {
    // Sift the values out that are empty before pushing
    pv.preferences = Object.keys(pv.preferences).reduce((acc, preference) => {
      if (!isEmpty(pv.preferences[preference])) {
        acc[preference] = pv.preferences[preference]
      }
      return acc
    }, {})
    if (isEmpty(pv.preferences)) delete pv.preferences
  } else {
    delete pv.preferences
  }
  return normalizeJsonToApi({
    pendingVisit: omit(pv, ['scheduledType', 'price']),
  })
}
export const execute = async (params) =>
  api.post(url(params), make(params), configBuilder(params)).catch(catchApiFailure)
