import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import configBuilder from '../config-builder'

const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  params: Yup.object().shape({
    filter: Yup.object().shape({
      messageType: Yup.string().oneOf(['visit', 'task']),
    }),
  }),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const url = () => `/v1/messages`
export const execute = async (params) => api.get(url(params), configBuilder(params)).catch(catchApiFailure)
