import { Formik, FormikConfig, FormikProps } from 'formik'
import FormRequiredLabel from '../components/form/RequiredFieldLabel'
import type { ComponentProps, ElementType } from 'react'
import type { Merge } from '@ui-components-3/ui/lib/types'

type FormikContainerProps<T extends ElementType, K> = Merge<ComponentProps<T>, Partial<FormikProps<K>>> &
  Pick<FormikConfig<K>, 'validateOnMount' | 'enableReinitialize' | 'onSubmit' | 'initialValues'> & {
    initialValues: K
    requiredFields?: ComponentProps<typeof FormRequiredLabel>['requiredFieldLabels']
    requiredLabelIgnoreFieldsLength?: boolean
    isRequiredLabelVisible?: boolean
    schema?: FormikConfig<K>['validationSchema']
  }

const withForm =
  <T extends ElementType>(Form: T) =>
  ({
    enableReinitialize,
    onSubmit,
    isRequiredLabelVisible,
    requiredLabelIgnoreFieldsLength,
    validateOnMount,
    requiredFields = [],
    ...props
  }: FormikContainerProps<T, any>) => (
    <>
      {isRequiredLabelVisible && (
        <FormRequiredLabel
          ignoreFieldsLength={!!requiredLabelIgnoreFieldsLength}
          requiredFieldLabels={requiredFields}
        />
      )}
      <Formik
        validateOnMount={validateOnMount}
        enableReinitialize={enableReinitialize}
        onSubmit={onSubmit}
        initialValues={props.initialValues}
        validationSchema={props.schema}
      >
        {(renderProps: any) => <Form {...props} {...renderProps} />}
      </Formik>
    </>
  )

export default withForm
