import Modal, { ModalRef } from '@ui-components-3/ui/lib/components/Modal'
import ExternalLink from 'components/common/ExternalLink'
import { forwardRef } from 'react'
import { TERMS_CONDITIONS_COMMUNITY_LINK } from 'util/constants'

const SelfConsentTermsModal = forwardRef<ModalRef, object>((props, ref) => {
  return (
    <Modal
      label="Informed Authorization & Consent to Therapy"
      size={'l'}
      ref={ref}
      footer={({ close }) => (
        <>
          <button type="button" className="btn btn-neutral" onClick={close}>
            Ok
          </button>
        </>
      )}
    >
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <h5 className="typography-body font-semibold text-neutral-800">
            IF YOU HAVE OR ARE HAVING A MEDICAL EMERGENCY, IMMEDIATELY CALL YOUR LOCAL EMERGENCY NOTIFICATION SYSTEM
            (911) OR GO TO YOUR LOCAL EMERGENCY ROOM.
          </h5>
          <h5 className="typography-body font-semibold text-neutral-800">Instructions</h5>
          <ol className="ml-5 list-decimal">
            <li className="typography-body text-neutral-800">Please read Section A, Introduction.</li>
            <li className="typography-body text-neutral-800">Complete Section B.</li>
          </ol>
        </div>
        <div className="flex flex-col gap-2">
          <h5 className="typography-body font-semibold text-neutral-800">Section A: Introduction</h5>
          <p className="typography-body text-neutral-800">
            Welcome to TimelyCare. Timely Telehealth, LLC, a Texas limited liability company (“TimelyCare”) provides
            access to remote Telehealth Consultations and exchange of information provided by healthcare Providers such
            as doctors, nurse practitioners, psychiatrists, psychologists, therapists, and/or counselors (collectively,
            the “Providers”). The purpose of this form is to collect your consent to participate in teletherapy, through
            electronic mediums such as phone, video, or asynchronous data exchange (the “Telehealth Consultations”),
            inform you of your rights and responsibilities, use of personal and medical information, and the risk and
            benefits of Telehealth Consultations. Please read this document carefully and select the checkbox below to
            indicate your informed consent.
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <h5 className="typography-body font-semibold text-neutral-800">What is Teletherapy?</h5>
          <p className="typography-body text-neutral-800">
            Teletherapy is the delivery of therapeutic services such as psychological counseling and support to members,
            allowing clinicians to provide a diagnosis, consultation and treatment using videoconference technology,
            telephone and/or computer. TimelyCare delivers teletherapy via Scheduled Counseling services.
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <h5 className="typography-body font-semibold text-neutral-800">
            In order for you to have an effective TimelyCare visit, please take note of the following:
          </h5>
          <ol className="ml-5 list-decimal">
            <li className="typography-body text-neutral-800">
              You are required by law to select the Country and State in which you are physically located at the time of
              your visit(s). Failure to accurately identify your location will result in cancellation of your visit(s).
            </li>
            <li className="typography-body text-neutral-800">Arrive for your visit on-time.</li>
            <li className="typography-body text-neutral-800">
              Use a reliable device (computer, tablet, or smartphone) with up-to-date software and a stable,
              password-protected internet connection.
            </li>
            <li className="typography-body text-neutral-800">
              Ensure you have a quiet, private space with adequate lighting for clear video communication.
            </li>
          </ol>
        </div>
      </div>
    </Modal>
  )
})

export default SelfConsentTermsModal
