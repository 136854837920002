import { createSelector } from 'reselect'

export const getMeta = (state) => state.view.meta
export const getRecords = (state) => state.view.records
export const getWindow = (state) => state.view.window
export const getAlert = (state) => state.view.alert
export const getNav = (state) => state.view.nav

export const isResetComplete = createSelector(getMeta, (meta = {}) => meta.isResetComplete)

export const getGroupConsentData = (groupId) => createSelector(getRecords, (records = {}) => records[groupId])

export const getProgramName = createSelector(getMeta, (meta = {}) => meta.programName)

export const getProgramLogoUrl = createSelector(getMeta, (meta = {}) => meta.logoUrl)
export const getWindowWidth = createSelector(getWindow, (window = {}) => window.width)

export const getPageTitle = createSelector(getNav, (nav = {}) => nav.title)
