import FaceFrownOpenIcon from '@ui-components-3/icons/lib/regular/FaceFrownOpen'

const NotFoundPage = () => {
  return (
    <div className="bg-primary-800 flex h-screen w-full flex-col items-center justify-center">
      <div className="m-auto max-w-[600px] p-4 text-center">
        <FaceFrownOpenIcon className="mx-auto h-auto w-[200px] max-w-full text-white" aria-hidden="true" />
        <h1 className="typography-h2 my-4 text-neutral-900">Oops</h1>
        <p className="typography-h4 pb-2 text-xl text-neutral-300">Sorry, this site does not exist.</p>
        <p className="typography-h4 text-xl text-neutral-300">Please check the url and try again</p>
      </div>
    </div>
  )
}

export default NotFoundPage
