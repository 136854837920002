import { createSlice } from '@reduxjs/toolkit'
import FamilyHistory from 'types/member/familyHistory'

export type FamilyHistoryState = {
  records: {
    [familyHistoryId: string]: FamilyHistory
  }
}

const defaultState: FamilyHistoryState = {
  records: {},
}

const getId = (history: FamilyHistory) => history.familyHistoryId

const slice = createSlice({
  name: 'familyHistory',
  initialState: defaultState,
  reducers: {
    putFamilyHistory: (state, action) => {
      const { history } = action.payload
      state.records[getId(history)] = history
    },
    putFamilyHistories: (state, action) => {
      const { histories } = action.payload
      histories.forEach((history: FamilyHistory) => {
        state.records[getId(history)] = history
      })
    },
    clearFamilyHistory: (state, action) => {
      const { historyId } = action.payload
      delete state.records[historyId]
    },
    clearFamilyHistories: (state) => {
      state.records = { ...defaultState.records }
    },
  },
})

const { actions, reducer } = slice
export const { putFamilyHistory, putFamilyHistories, clearFamilyHistory, clearFamilyHistories } = actions
export default reducer
