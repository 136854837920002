import * as Yup from 'yup'
import PhoneSchema from './PhoneSchema'
import { credentials, Specialties } from '../util/provider'

const MemberProviderSchema = Yup.object().shape({
  email: Yup.string().email(),
  name: Yup.string().min(4).required(),
  providerType: Yup.string().oneOf(['medical', 'therapy']).required(),
  phones: Yup.array().of(PhoneSchema).required(),
  specialty: Yup.string()
    .oneOf([...Specialties.medical, ...Specialties.therapy])
    .required(),
  suffix: Yup.array().of(Yup.string().oneOf(credentials)).required(),
  shareRecords: Yup.boolean().default(false),
})

export default MemberProviderSchema
