import Modal, { ModalRef } from '@ui-components-3/ui/lib/components/Modal'
import { forwardRef } from 'react'

export type SessionLimitReachedModalProps = {
  onConfirm: () => void
  serviceName: string
  isSuccess?: boolean
}

const SessionLimitReachedModal = forwardRef<ModalRef, SessionLimitReachedModalProps>((props, ref) => {
  const { onConfirm, serviceName, isSuccess = false } = props

  return (
    <Modal
      {...props}
      ref={ref}
      className="backdrop:backdrop-brightness-50"
      label={`Scheduled ${serviceName} Limit Reached`}
      onClose={onConfirm}
      footer={({ close }) => (
        <button type="button" className="btn btn-neutral" onClick={close}>
          View {isSuccess ? 'Sessions' : 'Visits'}
        </button>
      )}
    >
      <p className="typography-body-l font-medium text-neutral-800">
        You can only schedule a maximum of two {serviceName} {isSuccess ? 'sessions' : 'visits'} at a time.
      </p>
    </Modal>
  )
})
export default SessionLimitReachedModal
