// For member this is only used for cancelled visits
//   - no other visit status changes are allowed
import * as Yup from 'yup'
import configBuilder from '../config-builder'
import { normalizeJsonToApi } from '../../helpers'
import catchApiError from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'

const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  memberId: Yup.string().min(12, 'Member ID too short').required('Member ID required'),
  pendingVisitId: Yup.string().min(12, 'Pending Visit ID too short').required('Pending Visit ID required'),
  statusReason: Yup.string().required(),
  statusReasonCode: Yup.number().required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)

export const make = async (params) => {
  const cleaned = await RequestSchema.validate(params)
  const payload = {
    cancelledOrigin: 'web',
    statusReason: cleaned.statusReason,
    status_reason_code: cleaned.statusReasonCode,
  }
  return normalizeJsonToApi(payload)
}

export const url = ({ pendingVisitId, memberId }) => `/v1/${memberId}/visits/pending/${pendingVisitId}/cancel`

export const execute = async (params) =>
  api.put(url(params), await make(params), configBuilder(params)).catch(catchApiError)
