import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import configBuilder from '../config-builder'

const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  memberId: Yup.string().required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const url = ({ memberId }) => `/v1/${memberId}/personal/history`
export const execute = async (params) => api.get(url(params), configBuilder(params)).catch(catchApiFailure)
