import IllustrationLocationError from 'assets/images/success/illustration_location_error.svg'
import CrisisResources from 'components/visit-creation/CrisisResources'
import {
  VisitCreationForm,
  VisitCreationImage,
  VisitCreationScene,
} from 'components/visit-creation/VisitCreationWrappers/VisitCreationWrappers'
import { ReactNode } from 'react'

type Props = {
  renderFooter: () => ReactNode
}

const OutsideUS = ({ renderFooter }: Props) => {
  return (
    <VisitCreationScene>
      <VisitCreationForm>
        <div className="px-11">
          <h2 className="typography-h5 mb-4">You are currently in a location TimelyCare does not serve</h2>
          <p className="typography-body">If you need crisis support, please choose from the resources below: </p>
          <h3 className="typography-caption mb-2 mt-10 text-neutral-500">Resources</h3>
          <CrisisResources isSuccess />
        </div>
        {renderFooter?.()}
      </VisitCreationForm>
      <VisitCreationImage>
        <img
          className=" h-auto w-full object-contain sm:h-[calc(100vh-190px)]"
          src={IllustrationLocationError}
          alt=""
        />
      </VisitCreationImage>
    </VisitCreationScene>
  )
}

export default OutsideUS
