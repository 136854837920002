import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import { normalizeJsonToApi } from '../../helpers'
import { AddPostRequestSchema } from '../../../schemas/AddCommunityPostSchema'
import configBuilder from '../config-builder'
const api = ApiFactory.getApi(ApiTypes.member)

export const isValid = async (params) => await AddPostRequestSchema.isValid(params)
export const validate = async (params) => await AddPostRequestSchema.validate(params)
export const make = async (params) => {
  return normalizeJsonToApi(params.post)
}
export const url = () => '/v1/posts'
export const execute = async (params) =>
  api.post(url(), await make(params), configBuilder(params)).catch(catchApiFailure)
