import * as Yup from 'yup'
import configBuilder from '../config-builder'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  params: Yup.object().shape({
    filter: Yup.object(),
  }),
  headers: Yup.object()
    .shape({
      'x-form-token': Yup.string().notRequired(),
    })
    .notRequired(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => await RequestSchema.validate(params)
export const url = () => '/v1/forms'
export const execute = async (params) => api.get(url(), configBuilder(params)).catch(catchApiFailure)
