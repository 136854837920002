import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import { normalizeJsonToApi } from '../../helpers'
import { AddCommentRequestSchema } from '../../../schemas/AddCommunityPostSchema'
import configBuilder from '../config-builder'
const api = ApiFactory.getApi(ApiTypes.member)

export const isValid = async (params) => await AddCommentRequestSchema.isValid(params)
export const validate = async (params) => await AddCommentRequestSchema.validate(params)
export const make = async (params) => {
  return normalizeJsonToApi(params.comment)
}
export const url = ({ postId }) => `/v1/posts/${postId}/comments`
export const execute = async (params) =>
  api.post(url(params), await make(params), configBuilder(params)).catch(catchApiFailure)
