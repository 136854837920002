import * as Sentry from '@sentry/react'
import Config from 'config.ts'
import { browserTracingIntegration } from '@sentry/react'

export const initialize = () => {
  Sentry.init({
    dsn: Config.sentryDSN,
    integrations: [browserTracingIntegration()],
    environment: Config.environment,
    release: 'member-web@' + Config.appVersion,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: Config.isProduction ? 0.1 : 0.5,
    // TODO: n is not a function. (In 'n()', 'n' is undefined) error might be able to be removed once node libraries are updated
    ignoreErrors: [
      "TypeError: n is not a function. (In 'n()', 'n' is undefined)",
      "n is not a function. (In 'n()', 'n' is undefined)",
    ],
  })

  Sentry.setTag('app-version', Config.appVersion)
}

export const captureEvent = (error: Sentry.Event) => {
  Sentry.captureEvent(error)
}

export const captureException = (error: Error) => {
  Sentry.captureException(error)
}

export const captureMessage = (message: string) => {
  Sentry.captureMessage(message)
}

export const setUser = (user: Sentry.User | null) => {
  Sentry.setUser(user)
}
