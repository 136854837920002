import ExternalLink from 'components/common/ExternalLink'
import Markdown, { type MarkdownProps } from '@ui-components-3/ui/lib/components/Markdown'

const isExternalLink = (href?: string) => {
  return href && typeof href === 'string' && !href.match(/timelycare\.com/gi)
}

const renderers: MarkdownProps['renderers'] = {
  link: (props) => <ExternalLink {...props} showAlert={isExternalLink(props.href)} />,
}

type MarkdownViewProps = {
  content?: string
}

const MarkdownView = ({ content }: MarkdownViewProps) => {
  return <Markdown content={content} renderers={renderers} />
}

export default MarkdownView
