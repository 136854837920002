import TestIds from '../../../util/TestIds'

type VisitPriceProps = {
  price: string
  className?: string
  successVisit?: boolean
}

const VisitPrice = (props: VisitPriceProps) => {
  const { price, className, successVisit } = props
  if (!price || price === '0.00') return null

  return (
    <div className={className}>
      <div
        className="typography-body-xl mb-2 font-medium text-neutral-800"
        data-testid={TestIds.newVisit.label.paymentTitle}
      >
        The cost for this {successVisit ? 'session' : 'visit'} is:
      </div>
      <div className="typography-h3 text-neutral-800" data-testid={TestIds.newVisit.label.paymentDetail}>
        ${price}
      </div>
    </div>
  )
}

export default VisitPrice
