import { HTMLProps, useCallback, useState } from 'react'
import ExternalLinkDialog from '../ExternalLinkDialog'

type ExternalLinkProps = HTMLProps<HTMLAnchorElement> & {
  showAlert?: boolean
}

const ExternalLink = ({ children, href = '', showAlert = true, ...props }: ExternalLinkProps) => {
  const [isAlertVisible, setIsAlertVisible] = useState(false)
  const { onClick, onKeyDown } = props

  const handleClick = useCallback<ExternalLinkProps['onClick']>(
    (e) => {
      onClick?.(e)
      if (showAlert) {
        e.preventDefault()
        setIsAlertVisible(true)
      }
    },
    [onClick, showAlert],
  )

  const handleEnterPress = useCallback<ExternalLinkProps['onKeyDown']>(
    (e) => {
      onKeyDown?.(e)
      if (e.key === 'Enter' && showAlert) {
        e.preventDefault()
        setIsAlertVisible(true)
      }
    },
    [onKeyDown, showAlert],
  )

  return (
    <>
      <a rel="noreferrer" target="_blank" {...props} href={href} onClick={handleClick} onKeyDown={handleEnterPress}>
        {children}
      </a>
      {!!isAlertVisible && !!showAlert && <ExternalLinkDialog href={href} onClose={() => setIsAlertVisible(false)} />}
    </>
  )
}

export default ExternalLink
