import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import { normalizeJsonToApi } from 'api/helpers'
import { omit } from 'lodash'

const api = ApiFactory.getApi(ApiTypes.member)

export type PostVisitReviewParams = {
  // memberId: string
  visitId: string
  overallRating?: number
  overallComments?: string
  providerRating?: number
  providerComments?: string
  allowContactSupport?: boolean
  comments?: string
  withoutTcAnswer?: string
}

const RequestSchema = Yup.object().shape({
  visitId: Yup.string().label('Visit ID').required(),
  // memberId: Yup.string().label('Member ID').required(),
  overallRating: Yup.number().label('Rating').min(1, 'Rating must be at least 1').max(5, 'Rating must be 5 or lower'),

  providerRating: Yup.number().label('Rating').min(1, 'Rating must be at least 1').max(5, 'Rating must be 5 or lower'),

  allowContactSupport: Yup.bool(),
  providerComments: Yup.string(),
  overallComments: Yup.string(),
  comments: Yup.string(),
})

export const isValid = async (params: PostVisitReviewParams) => await RequestSchema.isValid(params)
export const validate = async (params: PostVisitReviewParams) => await RequestSchema.validate(params)
export const make = async (params: PostVisitReviewParams) => {
  const data = normalizeJsonToApi({ ...omit(params, 'visitId'), origin: 'sms' })
  return data
}
export const url = ({ visitId }: { visitId: string }) => `/v2/visits/${visitId}/reviews`
export const execute = async (params: PostVisitReviewParams) =>
  api.post(url(params), await make(params)).catch(catchApiFailure)
