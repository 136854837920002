import * as Yup from 'yup'
import configBuilder from '../config-builder'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import { normalizeJsonToApi } from 'api/helpers'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object({
  /* TODO: Remove the following parameters when configBuilder is typed
    START */
  params: Yup.object(),
  token: Yup.string(),
  /* END */
  authFlow: Yup.string().required(),
  authParameters: Yup.object({
    duplicateMemberId: Yup.string().required(),
  }).required(),
})

type Request = Yup.InferType<typeof RequestSchema>

export const isValid = async (params: Request) => await RequestSchema.isValid(params)
export const validate = async (params: Request) => RequestSchema.validate(params)
export const make = async ({ authFlow, authParameters }: Request) => normalizeJsonToApi({ authFlow, authParameters, origin: 'web' })

export const url = () => `/v1/auth-challenges/initiate`
export const execute = async (params: Request) =>
  api.post(url(), await make(params), configBuilder(params)).catch(catchApiFailure)
