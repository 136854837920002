import { setFetching } from '../slices/api'
import FetchTypes from '../../util/fetch-types'
import { handleRequestFailure, makeRequest } from '../../api/helpers'
import { clearPharmacies, clearSearchPharmacies, putPharmacies } from '../slices/pharmacies'

const mapToMember = (memberId) => (record) => ({ ...record.pharmacy, memberId })

export const fetchMemberPharmacies = (memberId) => async (dispatch) => {
  dispatch(
    setFetching({
      fetchType: FetchTypes.getMemberPharmacies,
      isFetching: true,
    }),
  )
  let success = false
  let result = null
  const params = { memberId }
  const handleSuccess = (data) => {
    dispatch(
      putPharmacies({
        pharmacies: data.map(mapToMember(memberId)),
      }),
    )
    success = true
    result = data
  }
  await makeRequest(FetchTypes.getMemberPharmacies, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(
    setFetching({
      fetchType: FetchTypes.getMemberPharmacies,
      isFetching: false,
    }),
  )
  return { success, data: result }
}

export const addMemberPharmacy = (memberId, pharmacyId) => async (dispatch) => {
  dispatch(
    setFetching({
      fetchType: FetchTypes.addMemberPharmacy,
      isFetching: true,
    }),
  )
  let success = false
  let result = null
  const params = { memberId, pharmacyId }
  const handleSuccess = (data) => {
    dispatch(
      putPharmacies({
        pharmacies: data.map(mapToMember(memberId)),
      }),
    )
    success = true
    result = data
  }
  await makeRequest(FetchTypes.addMemberPharmacy, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(
    setFetching({
      fetchType: FetchTypes.addMemberPharmacy,
      isFetching: false,
    }),
  )
  return { success, data: result }
}

export const removeMemberPharmacy = (memberId, pharmacyId) => async (dispatch) => {
  dispatch(
    setFetching({
      fetchType: FetchTypes.removeMemberPharmacy,
      isFetching: true,
    }),
  )
  let success = false
  let result = null
  const params = { memberId, pharmacyId }
  const handleSuccess = async (data) => {
    await dispatch(clearPharmacies())
    dispatch(
      putPharmacies({
        pharmacies: data.map(mapToMember(memberId)),
      }),
    )
    success = true
    result = data
  }
  await makeRequest(FetchTypes.removeMemberPharmacy, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(
    setFetching({
      fetchType: FetchTypes.removeMemberPharmacy,
      isFetching: false,
    }),
  )
  return { success, data: result }
}

export const fetchPharmacies =
  (filter = {}) =>
  async (dispatch) => {
    dispatch(
      setFetching({
        fetchType: FetchTypes.getPharmacies,
        isFetching: true,
      }),
    )
    let success = false
    let count = -1
    let pharmacies = []
    let mailOrderPharmacies = []

    dispatch(clearSearchPharmacies())

    const config = { params: { filter, page: 0, limit: 25 } }

    const reducedFilter = Object.keys(filter).reduce((acc, key) => {
      if (filter[key]) {
        if (Array.isArray(filter[key])) {
          if (filter[key].length > 0) acc[key] = filter[key]
        } else {
          acc[key] = filter[key]
        }
      }
      return acc
    }, {})

    const filterKeys = Object.keys(reducedFilter)

    const isSearchFilteredWithoutSpecialty =
      (!filterKeys.includes('specialty') || filter.specialty.length === 0) && filterKeys.length > 0

    const handleMailOrderSuccess = (data) => {
      if (Array.isArray(data)) {
        mailOrderPharmacies = data
      } else {
        mailOrderPharmacies = [data]
      }
      success = true
      count = count + mailOrderPharmacies.length
    }

    const handleSuccess = async (data) => {
      if (Array.isArray(data)) {
        pharmacies = data
      } else {
        pharmacies = [data]
      }

      if (isSearchFilteredWithoutSpecialty) {
        count = data.length
        const mailOrderConfig = { params: { filter: { specialty: 'mail_order' }, page: 0, limit: 20 } }
        await makeRequest(
          FetchTypes.getPharmacies,
          mailOrderConfig,
          handleMailOrderSuccess,
          handleRequestFailure(dispatch),
        )
      } else {
        success = true
        count = pharmacies.length
      }
    }

    await makeRequest(FetchTypes.getPharmacies, config, handleSuccess, handleRequestFailure(dispatch))

    dispatch(
      setFetching({
        fetchType: FetchTypes.getPharmacies,
        isFetching: false,
      }),
    )
    return { success, count, pharmacies, mailOrderPharmacies }
  }
