import { createSlice } from '@reduxjs/toolkit'
import { ConditionModel } from 'types/member/familyHistory'

export type ConditionState = {
  meta: object
  records: {
    [conditionId: string]: ConditionModel
  }
}

const defaultState = {
  meta: {},
  records: {
    // [conditionId]: Condition
  },
} satisfies ConditionState

const getId = (condition: ConditionModel) => condition.conditionId

const slice = createSlice({
  name: 'conditions',
  initialState: defaultState,
  reducers: {
    putCondition: (state, action) => {
      const { condition } = action.payload
      state.records[getId(condition)] = condition
    },
    putConditions: (state, action) => {
      const { conditions } = action.payload
      conditions.forEach((condition: ConditionModel) => {
        state.records[getId(condition)] = condition
      })
    },
    clearCondition: (state, action) => {
      const { conditionId } = action.payload
      delete state.records[conditionId]
    },
    clearConditions: (state) => {
      state.records = { ...defaultState.records }
    },
  },
})

const { actions, reducer } = slice
export const { putCondition, putConditions, clearCondition, clearConditions } = actions
export default reducer
