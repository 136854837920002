import { setFetching } from '../slices/api'
import FetchType from '../../util/fetch-types'
import { normalizeMedicalHistory } from '../../util/medical-wizard'
import { handleRequestFailure, makeRequest } from '../../api/helpers'
import { putConditions } from '../slices/conditions'
import { putMedicalHistory } from '../slices/medical-history'

export const fetchPossibleConditions = () => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.getConditions, isFetching: true }))
  const params = {}
  let success = false
  const handleSuccess = async (data) => {
    dispatch(putConditions({ conditions: data }))
    success = true
  }
  await makeRequest(FetchType.getConditions, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(setFetching({ fetchType: FetchType.getConditions, isFetching: false }))
  return { success }
}

export const getMedicalHistory = (memberId) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.getMedicalHistory, isFetching: true }))
  const params = { memberId }
  let success = false
  let result
  const handleSuccess = async (data) => {
    dispatch(putMedicalHistory({ history: data }))
    success = true
    result = data
  }
  await makeRequest(FetchType.getMedicalHistory, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(setFetching({ fetchType: FetchType.getMedicalHistory, isFetching: false }))
  return { success, result }
}

export const addMedicalHistory = (memberId, medicalHistory) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.addMedicalHistory, isFetching: true }))
  const params = { memberId, medicalHistory }
  let success = false
  let result = null
  const handleSuccess = async (data) => {
    dispatch(putMedicalHistory({ history: data }))
    success = true
    result = data
  }
  await makeRequest(FetchType.addMedicalHistory, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(setFetching({ fetchType: FetchType.addMedicalHistory, isFetching: false }))
  return { success, data: result }
}

export const updateMedicalConditions = (memberId, conditions, other) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.addMedicalConditions, isFetching: true }))
  const params = { memberId, conditions, other }
  let success = false
  let result = null
  const handleSuccess = async (data) => {
    dispatch(putMedicalHistory({ history: data }))
    success = true
    result = data
  }
  const handleFailure = async (error, response) => {
    if (response && response.status === 404) {
      const addResult = await dispatch(
        addMedicalHistory(memberId, {
          memberId,
          lifestyle: {},
          medications: [],
          conditionsCompleted: true,
          conditions,
          other,
        }),
      )
      success = addResult.success
      result = addResult.data
    } else {
      handleRequestFailure(dispatch)(error)
    }
  }
  await makeRequest(FetchType.addMedicalConditions, params, handleSuccess, handleFailure)
  dispatch(setFetching({ fetchType: FetchType.addMedicalConditions, isFetching: false }))
  return { success, data: result }
}

export const updateMedicalHistory = (memberId, medicalHistory) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.updateMedicalHistory, isFetching: true }))
  const params = { memberId, medicalHistory: normalizeMedicalHistory(medicalHistory) }
  let success = false
  let result = null
  const handleSuccess = async (data) => {
    dispatch(putMedicalHistory({ history: data }))
    success = true
    result = data
  }
  await makeRequest(FetchType.updateMedicalHistory, params, handleSuccess, async (error = {}, response) => {
    if (response && response.status === 404) {
      const addResult = await dispatch(addMedicalHistory(memberId, normalizeMedicalHistory(medicalHistory)))
      success = addResult.success
      result = addResult.data
    } else {
      handleRequestFailure(dispatch)(error)
    }
  })
  dispatch(setFetching({ fetchType: FetchType.updateMedicalHistory, isFetching: false }))
  return { success, data: result }
}
