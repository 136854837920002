import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RecommendedScreenVariantsType } from 'util/visit-types'

interface QuestionState {
  answers: Record<string, string>
  variant: RecommendedScreenVariantsType
}

const initialState: QuestionState = {
  answers: {
    answer0: '',
    answer1: '',
    answer2: '',
  },
  variant: 'result4',
}

const questionSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    setAnswer(state, action: PayloadAction<{ questionId: string; answer: string }>) {
      const { questionId, answer } = action.payload
      state.answers[questionId] = answer
    },
    setVariant(state, action: PayloadAction<RecommendedScreenVariantsType>) {
      state.variant = action.payload
    },
    resetAnswers(state) {
      state.answers = initialState.answers
      state.variant = initialState.variant
    },
  },
})

export const { setAnswer, setVariant, resetAnswers } = questionSlice.actions

export default questionSlice.reducer
