import * as Yup from 'yup'
import configBuilder from '../config-builder'
import catchApiFailure from '../catch-api-error'
import { ApiBlobFactory, ApiTypes } from '../../index'
const api = ApiBlobFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  memberId: Yup.string().label('Member ID').required(),
  transactionId: Yup.string().label('Transaction ID').required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => await RequestSchema.validate(params)
export const url = ({ memberId, transactionId }) =>
  `/v1/members/${memberId}/payments/transactions/${transactionId}/receipt`
export const execute = async (params) => api.get(url(params), configBuilder(params)).catch(catchApiFailure)
