import useAccountMerge, { MfaOptionType, useAccountMergeContext } from 'hooks/useAccountMerge'
import RadioCardsGroup from '@ui-components-3/ui/lib/components/RadioCardsGroup'
import { ModalRenderPropParams } from '@ui-components-3/ui/lib/components/Modal'
import Notice from '@ui-components-3/ui/lib/components/Notice'
import RequiredFieldLabel from 'components/form/RequiredFieldLabel'
import useId from '@ui-components-3/ui/lib/hooks/useId'
import { ChallengeResponse } from 'types/account-merge/AccountMergeChallenge'
import { GenerateMfaValues } from 'schemas/GenerateMfaSchema'
import { CHALLENGE_NAME_SCENE_MAP } from 'constants/accountMerge'
import PhoneIcon from '@ui-components-3/icons/lib/regular/Phone'
import EmailIcon from '@ui-components-3/icons/lib/regular/Envelope'
import { Controller } from 'react-hook-form'
import { toastMergeFailed } from '../AccountMerge'

const getFormId = (idPrefix: string) => idPrefix + 'form:select-mfa'

export const SelectMfaFooter = ({ idPrefix }: ModalRenderPropParams) => {
  const formId = getFormId(idPrefix)
  const context = useAccountMergeContext()
  const { handleCancel, selectMfaForm } = context
  const {
    formState: { isValid, isSubmitting, isDirty },
  } = selectMfaForm

  const { isSubmittingChallenge } = useAccountMerge()
  const isSubmitDisabled = !isValid || isSubmitting || !isDirty || isSubmittingChallenge

  return (
    <>
      <button
        type="button"
        onClick={() => handleCancel()}
        disabled={isSubmittingChallenge}
        className="btn btn-neutral-outlined self-end"
      >
        Cancel
      </button>
      <button type="submit" form={formId} disabled={isSubmitDisabled} className="btn btn-neutral self-end">
        Next
      </button>
    </>
  )
}

const OPTION_ICONS = {
  sms: PhoneIcon,
  email: EmailIcon,
} as const

const GetMfaOptionLabel = ({ mfaType, label }: MfaOptionType) => {
  const Icon = OPTION_ICONS[mfaType]
  return (
    <div className="gap-xs typography-body-l text-neutral flex items-center">
      <div className="bg-primary-100 flex h-10 w-10 items-center justify-center rounded-full">
        <Icon aria-hidden="true" className="fill-primary m-xxs h-auto w-5 shrink-0" />
      </div>
      {label}
    </div>
  )
}

export const SelectMfaContent = ({ idPrefix }: ModalRenderPropParams) => {
  const formId = getFormId(idPrefix)
  const {
    selectMfaForm,
    handleNextScene,
    selectedMemberId,
    handleCancel,
    setError,
    selectMfaDeliveryMethod,
    challenge,
  } = useAccountMergeContext() || {}
  const { handleSubmit, control } = selectMfaForm
  const { mfaOptions, handleFailedChallenge, submitChallenge } = useAccountMerge()
  const radioGroupLabelId = useId()

  const onSubmit = handleSubmit(async (values: GenerateMfaValues) => {
    const { deliveryMethod } = values
    const submittedOption: MfaOptionType = mfaOptions.find((o) => o.value === deliveryMethod)
    const mfaType = submittedOption.mfaType
    const payload = {
      mfaType,
      duplicateMemberId: selectedMemberId,
      phoneId: deliveryMethod,
    }
    if (mfaType === 'email') delete payload.phoneId
    // @ts-ignore
    const { success, data } = await submitChallenge(challenge, payload)
    if (success) {
      const { authenticationResult }: ChallengeResponse = data
      if (data?.challengeName) {
        const challenge = data.challengeName
        selectMfaDeliveryMethod(submittedOption)
        handleNextScene(CHALLENGE_NAME_SCENE_MAP[challenge], challenge, true)
      } else if (authenticationResult?.attemptsRemaining === 0) {
        handleCancel(true)
        toastMergeFailed()
      } else {
        handleFailedChallenge()
        setError('Failed to deliver MFA code, please try again.')
      }
    }
  })

  return (
    <form id={formId} onSubmit={onSubmit} className="gap-s pt-xs flex flex-col">
      <RequiredFieldLabel ignoreFieldsLength className="font-normal" />
      <p className="typography-body-l font-medium" id={radioGroupLabelId}>
        Where would you like your verification code sent?*
      </p>
      <Controller
        name="deliveryMethod"
        control={control}
        render={({ field }) => (
          <RadioCardsGroup
            {...field}
            aria-labelledby={radioGroupLabelId}
            options={mfaOptions}
            columns={1}
            getOptionLabel={GetMfaOptionLabel}
            getOptionValue={(o) => o.value}
          />
        )}
      />
      <Notice>
        <div className="typography-body">
          <p className="font-semibold">Merging</p>
          <p>This process is permanent and can’t be undone.</p>
        </div>
      </Notice>
    </form>
  )
}
