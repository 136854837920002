import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import Provider from 'types/provider/provider'

export type ProviderState = {
  meta: {
    lastFetchAt: null | string
    fetching: string[]
    fetches: {
      [providerId: string]: string | object
      errors: {
        [providerId: string]: number
      }
    }
  }
  records: {
    [providerId: string]: Provider
  }
}

const defaultState: ProviderState = {
  meta: {
    lastFetchAt: null,
    fetching: [],
    fetches: {
      // [providerId]: Date,
      errors: {
        // [providerId]: statusCode
      },
    },
  },
  records: {
    // [resource_id]: Resource
  },
}

const getId = (provider: Provider) => provider.providerId

const slice = createSlice({
  name: 'providers',
  initialState: defaultState,
  reducers: {
    putProvider: (state, action) => {
      const { provider } = action.payload
      const providerId = getId(provider)
      state.records[providerId] = provider
      if (state.meta.fetches.errors[providerId]) {
        delete state.meta.fetches.errors[providerId]
      }
    },
    putProviders: (state, action) => {
      const { providers } = action.payload
      providers.forEach((provider: Provider) => {
        const providerId = getId(provider)
        state.records[providerId] = provider
        state.meta.fetches[providerId] = moment().toISOString()
        if (state.meta.fetches.errors[providerId]) {
          delete state.meta.fetches.errors[providerId]
        }
      })
    },
    putProviderFetching: (state, action) => {
      const { providerId, isFetching } = action.payload
      if (isFetching) {
        if (!state.meta.fetching.includes(providerId)) {
          state.meta.fetching.push(providerId)
        }
      } else {
        const index = state.meta.fetching.indexOf(providerId)
        if (index >= 0) state.meta.fetching.splice(index, 1)
      }
    },
    putProviderLastFetch: (state, action) => {
      const { providerId, lastFetch } = action.payload
      state.meta.fetches[providerId] = lastFetch
    },
    putProviderFetchError: (state, action) => {
      const { providerId, statusCode } = action.payload
      state.meta.fetches.errors[providerId] = statusCode
    },
    clearProvider: (state, action) => {
      const { providerId } = action.payload
      delete state.records[providerId]
      if (state.meta.fetches.errors[providerId]) {
        delete state.meta.fetches.errors[providerId]
      }
      if (state.meta.fetches[providerId]) {
        delete state.meta.fetches[providerId]
      }
    },
    clearProviders: (state) => {
      state.meta.lastFetchAt = null
      state.meta.fetches = { ...defaultState.meta.fetches }
      state.records = { ...defaultState.records }
    },
  },
})

const { actions, reducer } = slice
export const {
  putProvider,
  putProviders,
  putProviderFetchError,
  putProviderLastFetch,
  putProviderFetching,
  clearProvider,
  clearProviders,
} = actions
export default reducer
