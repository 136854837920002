import * as Yup from 'yup'
import configBuilder from '../config-builder'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  params: Yup.object()
    .shape({
      filter: Yup.object().shape({
        clientId: Yup.string().required(),
        enabled: Yup.number().required(),
        groupId: Yup.string(),
      }),
      page: Yup.number(),
      limit: Yup.number(),
    })
    .required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const valiidate = async (params) => await RequestSchema.validate(params)
export const url = () => '/v1/visit-restrictions'
export const execute = async (params) => api.get(url(), configBuilder(params)).catch(catchApiFailure)
