import NewVisitLocationForm from 'forms/visit-creation/NewVisitLocationForm'
import NewVisitLocationSchema from 'schemas/NewVisitLocationSchema'
import { StepFormBaseProps } from '../types'

type SuccessOverviewProps = StepFormBaseProps & {
  props?: any
}

const SuccessLocation = ({ props, onSubmit, renderFooter, initialValues, pendingVisit }: SuccessOverviewProps) => {
  return (
    <div className="px-8">
      <NewVisitLocationForm
        {...props}
        onSubmit={onSubmit}
        schema={NewVisitLocationSchema}
        initialValues={initialValues}
        clearPayload={() => {}}
        isRequiredLabelVisible={false}
        pendingVisit={pendingVisit}
        fromSuccess
        validateOnMount
      >
        {renderFooter}
      </NewVisitLocationForm>
    </div>
  )
}

export default SuccessLocation
