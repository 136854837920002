import { createSlice } from '@reduxjs/toolkit'

const defaultState = {
  meta: {
    lastFetchAt: null,
  },
  records: {
    // [pharmacy_id]: Pharmacy
  },
  rxs: {
    //[rxId]: {}
  },
}

const getId = (pharmacy) => pharmacy.id

const slice = createSlice({
  name: 'pharmacies',
  initialState: defaultState,
  reducers: {
    putPharmacy: (state, action) => {
      const { pharmacy } = action.payload
      state.records[getId(pharmacy)] = pharmacy
    },
    putPharmacies: (state, action) => {
      const { pharmacies } = action.payload
      pharmacies.forEach((pharmacy) => {
        state.records[getId(pharmacy)] = pharmacy
      })
    },
    putPharmaciesLastFetch: (state, action) => {
      const { lastFetch } = action.payload
      state.meta.lastFetchAt = lastFetch
    },
    putRx: (state, action) => {
      const { rx } = action.payload
      state.rxs[rx.prescriptionId] = {
        ...rx,
        storeUpdatedAt: new Date(),
      }
    },
    clearRx: (state, action) => {
      const { prescriptionId } = action.payload
      delete state.rxs[prescriptionId]
    },
    clearPharmacy: (state, action) => {
      const { pharmacyId } = action.payload
      delete state.records[pharmacyId]
    },
    clearPharmacies: (state) => {
      state.records = { ...defaultState.records }
    },
    clearSearchPharmacies: (state) => {
      state.records = Object.values(state.records).reduce((acc, record) => {
        if (record.memberId) {
          acc[getId(record)] = record
        }
        return acc
      }, {})
    },
  },
})

const { actions, reducer } = slice
export const {
  putPharmacy,
  putPharmacies,
  putPharmaciesLastFetch,
  putRx,
  clearPharmacies,
  clearSearchPharmacies,
  clearRx,
  clearPharmacy,
} = actions
export default reducer
