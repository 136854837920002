import * as Yup from 'yup'

const NewVisitPharmacySchema = Yup.object().shape({
  pharmacy: Yup.object()
    .shape({
      externalId: Yup.string().required(),
      name: Yup.string().required(),
    })
    .required(),
})

export default NewVisitPharmacySchema
