import { createSlice } from '@reduxjs/toolkit'

const defaultState = {
  meta: {
    skipHistory: false,
  },
  records: {
    // [medical_history_id]: MedicalHistory
  },
}

const getId = (history) => history.medicalHistoryId

const slice = createSlice({
  name: 'medicalHistory',
  initialState: defaultState,
  reducers: {
    skipMedicalHistory: (state) => {
      state.meta.skipHistory = true
    },
    putMedicalHistory: (state, action) => {
      const { history } = action.payload
      state.records[getId(history)] = history
    },
    putMedicalHistories: (state, action) => {
      const { histories } = action.payload
      histories.forEach((history) => {
        state.records[getId(history)] = history
      })
    },
    clearMedicalHistory: (state, action) => {
      const { historyId } = action.payload
      delete state.records[historyId]
    },
    clearMedicalHistories: (state) => {
      state.records = { ...defaultState.records }
    },
  },
})

const { actions, reducer } = slice
export const {
  skipMedicalHistory,
  putMedicalHistories,
  putMedicalHistory,
  clearMedicalHistories,
  clearMedicalHistory,
} = actions
export default reducer
