import * as Yup from 'yup'
import ApiFactory, { ApiTypes } from '../../index'
import configBuilder from '../config-builder'
import catchApiError from '../catch-api-error'
import { normalizeJsonToApi } from '../../helpers'
import { intakeTypes } from 'util/visit-assessment'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object({
  pendingVisitId: Yup.string().required(),
  intake: Yup.object({
    intakeType: Yup.string().oneOf(intakeTypes).required(),
    form: Yup.object({
      formId: Yup.string().required(),
      answers: Yup.array().of(
        Yup.object({
          value: Yup.mixed(),
          fieldId: Yup.string(),
        }),
      ),
    }).required(),
  }).required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => await RequestSchema.validate(params)
export const make = async (params) => normalizeJsonToApi(params.intake)
export const url = ({ pendingVisitId }) => `/v1/visits/pending/${pendingVisitId}/intake`
export const execute = async (params) =>
  api.put(url(params), await make(params), configBuilder(params)).catch(catchApiError)
