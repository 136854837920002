import { createSelector } from 'reselect'
import { POST_STATUS } from '../../util/community'

export const getAllRecords = (state) => state.community.records
export const getAllMeta = (state) => state.community.meta
export const getPostDetails = (state) => state.community.postDetail
export const getReports = (state) => state.community.reports
export const getReactions = (state) => state.community.reactions
export const getNewPostIds = (state) => state.community.newPostIds
export const getNewCommentIds = (state) => state.community.newCommentIds
export const getAllFilter = (state) => state.community.meta.allFilter

export const getLatestTcVersion = createSelector(getAllMeta, (meta = {}) => meta.latestTc)
export const getLatestPostTcVersion = createSelector(getAllMeta, (meta = {}) => meta.latestPostTc)

export const getPost = (postId) => createSelector(getAllRecords, (records = {}) => records[postId])

export const getTotalPosts = createSelector(getAllMeta, (meta = {}) => meta.total)

export const getAllPosts = createSelector(getAllRecords, (records = {}) => {
  return Object.values(records).filter((post) => !post.isNew && !post.isReported)
})

export const getMyTotal = createSelector(getAllMeta, (meta = {}) => meta.myTotal)

export const getMyPostsByMostRecent = createSelector(getAllRecords, (records = {}) =>
  Object.values(records)
    .filter((post) => post.isAuthor)
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
)

export const getPublishedPosts = createSelector(getAllPosts, (records = []) => {
  return records.filter((post) => post.status === POST_STATUS.published)
})

export const getPublishedPostsByMostRecent = createSelector(getPublishedPosts, (records = []) => {
  return records.filter((post) => post.isAll).sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt))
})

export const getAllPostsByMostRecent = createSelector(getAllPosts, (records = []) => {
  return records.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
})

export const getAllNewPostIds = createSelector(getNewPostIds, (newPostIds = []) => newPostIds)

export const getNewCommentIdsByPost = (postId) =>
  createSelector(getNewCommentIds, (newCommentIds = {}) => newCommentIds[postId])

export const getAllNewCommentIds = (postId) =>
  createSelector(getNewCommentIdsByPost(postId), (newCommentIds = []) => newCommentIds)

export const getPostDetail = (postId) => createSelector(getPostDetails, (posts = {}) => posts[postId])

export const countNewComments = (postId) =>
  createSelector(getPostDetail(postId), (detail = {}) => Object.values(detail).filter((c) => c.isNew).length)

export const getComment = (postId, commentId) =>
  createSelector(getPostDetail(postId), (comments = {}) => comments[commentId])

export const getCommentsByOldest = (postId) =>
  createSelector(getPostDetail(postId), (comments = {}) => {
    return Object.values(comments)
      .filter((comment) => !comment.isNew && !comment.isReported)
      .sort((a, b) => new Date(a.publishedAt ?? a.createdAt) - new Date(b.publishedAt ?? b.createdAt))
  })

export const getCommentTotal = (postId) => createSelector(getPost(postId), (post = {}) => post?.counts?.comments)

export const getReportsArray = createSelector(getReports, (reports = {}) => Object.values(reports))

export const getPostReport = (postId) =>
  createSelector(getReports, (reports = {}) => Object.values(reports).find((report) => report.postId === postId))

export const getPostReactionByPostId = (postId) =>
  createSelector(getReactions, (reactions) => Object.values(reactions)?.find((r) => r.target.ref.postId === postId))

export const getRandomizedPosts = (state) => state.community.randomizedPosts
