import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
const api = ApiFactory.getApi(ApiTypes.member)
import qs from 'qs'
import { normalizeJsonToApi } from 'api/helpers'

const RequestSchema = Yup.object().shape({
  params: Yup.object()
    .shape({
      filter: Yup.object().shape({
        name: Yup.string().required(),
      }),
      currentPage: Yup.number().required(),
    })
    .required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const url = (params) => `/v2/medications?${qs.stringify(normalizeJsonToApi(params.params))}`
export const execute = async (params) => api.get(url(params), {}).catch(catchApiFailure)
