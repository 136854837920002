import { createSlice } from '@reduxjs/toolkit'

const defaultState = {
  meta: {
    lastFetchAt: null,
    removingImages: [],
  },
  records: {
    // [imageId]: Image
  },
}

const getId = (image) => image.imageId

const slice = createSlice({
  name: 'images',
  initialState: defaultState,
  reducers: {
    putImage: (state, action) => {
      const { image } = action.payload
      state.records[getId(image)] = image
    },
    putThumbImage: (state, action) => {
      const { imageId, data } = action.payload
      const image = state.records[imageId]
      if (image) {
        image.thumbImage = data
        state.records[imageId] = image
      }
    },
    putImages: (state, action) => {
      const { images } = action.payload
      images.forEach((image) => {
        const currentImage = state.records[getId(image)]
        if (currentImage) {
          state.records[getId(image)] = { ...image, ...currentImage }
        } else {
          state.records[getId(image)] = image
        }
      })
    },
    clearImage: (state, action) => {
      const { imageId } = action.payload
      delete state.records[imageId]
      const index = state.meta.removingImages.indexOf(imageId)
      if (index >= 0) {
        state.meta.removingImages.splice(index, 1)
      }
    },
    clearImages: (state) => {
      state.records = { ...defaultState.records }
    },
    deletingImage: (state, action) => {
      const { imageId } = action.payload
      if (!state.meta.removingImages.includes(imageId)) {
        state.meta.removingImages.push(imageId)
      }
    },
  },
})

const { actions, reducer } = slice
export const { putImage, putImages, clearImage, clearImages, deletingImage, putThumbImage } = actions
export default reducer
