import * as Yup from 'yup'
const AddressSchema = Yup.object().shape({
  street1: Yup.string()
    .label('Street 1')
    .trim('Street 1 should have no leading or trailing spaces')
    .min(2, 'Street 1 too Short')
    .max(35, 'Street 1 too Long')
    .required('Error: Street 1 is a required field that cannot be left blank'),
  street2: Yup.string()
    .label('Street 2')
    .trim('Street 2 should have no leading or trailing spaces')
    .max(35, 'Street 2 too Long')
    .nullable()
    .notRequired(),
  city: Yup.string()
    .label('City')
    .trim('City should have no leading or trailing spaces')
    .min(2, 'City is too Short')
    .max(35, 'City is too Long')
    .required('Error: City is a required field that cannot be left blank'),
  zip: Yup.string()
    .label('Zip Code')
    .matches(/\d{5}/, 'Zip Code must be 5 digits')
    .min(5, 'Zip Code is too Short')
    .max(5, 'Zip Code is too Long')
    .required('Error: Zip Code is a required field that cannot be left blank'),
  state: Yup.string()
    .label('State')
    .min(2, 'State is too Short')
    .max(2, 'State is too Long')
    .required('Error: State is a required field that cannot be left blank'),
})
export default AddressSchema
