import debounce from 'lodash/debounce'
import { Dispatch } from 'react'
import { readChatMessage } from 'redux/thunks/chat'
import type { VisitChatMessage } from 'types/visit-chat-message/visitChatMessage'

const VISIBILITY_TIMER = 1500
const VISIBILITY_THRESHOLD = 0.5

/*
    when marking message as read, all previous messages currently become read (backend "feature")    
    So only last message should be marked

    Currently message is considered read when
        * at least element height * VISIBILITY_THRESHOLD became visible
        * this element remained visible at least VISIBILITY_TIMER ms
*/

class VisibilityObserver {
  private dispatch: Dispatch<any>
  /* TODO: maybe persist it by viewedAt somehow? */
  private lastMarkedMessage: VisitChatMessage | null = null
  private messagesToMarkAsRead = new Map<string, VisitChatMessage>()
  private idToElement = new Map<VisitChatMessage['id'], HTMLElement>()
  private elementToMessage = new Map<HTMLElement, VisitChatMessage>()
  private io: IntersectionObserver | null = null
  private queue: [message: VisitChatMessage, element: HTMLElement][] = []

  private get messageToMarkAsRead() {
    let messageToMark: VisitChatMessage | null = null,
      ts = this.lastMarkedMessage ? +new Date(this.lastMarkedMessage.createdAt) : 0

    for (const message of this.messagesToMarkAsRead.values()) {
      const nTs = +new Date(message.createdAt)
      // TODO: filter my messages here
      if (nTs > ts) {
        messageToMark = message
        ts = nTs
      }
    }

    return messageToMark
  }

  private markLastMessageAsRead = debounce(
    async () => {
      const messageToMark = this.messageToMarkAsRead

      if (messageToMark) {
        const originalLastMarkedMessage = this.lastMarkedMessage
        try {
          this.lastMarkedMessage = messageToMark
          this.dispatch(readChatMessage(messageToMark))
        } catch (e) {
          this.lastMarkedMessage = originalLastMarkedMessage
          throw e
        }
      }
    },
    VISIBILITY_TIMER,
    { maxWait: 5000 },
  )

  private destroy() {
    this.io?.disconnect()
    this.markLastMessageAsRead.cancel()
    this.idToElement.clear()
    this.elementToMessage.clear()
    this.messagesToMarkAsRead.clear()
    this.queue = []
    this.io = null
  }

  constructor(dispatch: Dispatch<any>) {
    this.dispatch = dispatch
  }

  /**
   * Start/stop observing message visibility. Normally should be used inside callback ref
   *
   * @param message target message
   * @param element falsy value unobserves message, otherwise element is observed
   */
  observeMessage(message: VisitChatMessage, element: HTMLElement | null) {
    if (this.io) {
      if (element) {
        if (!this.elementToMessage.has(element)) {
          this.elementToMessage.set(element, message)
          this.idToElement.set('' + message.id, element)
          this.io.observe(element)
        }
      } else {
        const foundElement = this.idToElement.get('' + message.id)
        if (foundElement) {
          this.elementToMessage.delete(foundElement)
          this.idToElement.delete('' + message.id)
          this.io.unobserve(foundElement)
          this.messagesToMarkAsRead.delete('' + message.id)
        }
      }
    } else {
      if (element) {
        this.queue.push([message, element])
      } else {
        const idx = this.queue.findIndex(([msg]) => msg.id === message.id)
        if (idx !== -1) {
          this.queue.splice(idx, 1)
        }
      }
    }
  }

  /**
   * Initiate messages visibility observation
   *
   * @param rootElement scrollable messages container
   * @returns disposer function
   */
  start(rootElement: HTMLElement | null) {
    if (rootElement) {
      this.io = new IntersectionObserver(
        (entries) =>
          entries.forEach((entry) => {
            const message = this.elementToMessage.get(entry.target as HTMLElement)
            if (message) {
              if (entry.isIntersecting) {
                this.messagesToMarkAsRead.set('' + message.id, message)
                this.markLastMessageAsRead()
              } else {
                this.messagesToMarkAsRead.delete('' + message.id)
              }
            }
          }),
        {
          root: rootElement,
          threshold: VISIBILITY_THRESHOLD,
        },
      )

      this.queue.forEach(([message, element]) => this.observeMessage(message, element))
      this.queue = []

      return () => this.destroy()
    }
  }
}

export default VisibilityObserver
