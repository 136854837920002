import * as Yup from 'yup'
import { relationships } from '../util/family-history'

export const FamilyConditionSchema = Yup.object().shape({
  condition: Yup.string().required(),
  conditionId: Yup.string().required(),
  id: Yup.string(),
  name: Yup.string().required(),
  relationship: Yup.string().oneOf(relationships).required(),
})

// This is a 'slice' of the total FH record
// - basically filtered by relationship
export const FamilyHistoryInputSchema = Yup.object().shape({
  memberId: Yup.string().required(),
  relationship: Yup.string().label('Relationship').oneOf(relationships).required(),
  conditions: Yup.array().of(Yup.string()).required().min(1),
})

// Full history record, all relationships
const FamilyHistorySchema = Yup.object().shape({
  memberId: Yup.string().required(),
  conditions: Yup.array().of(FamilyConditionSchema).required(),
})

export default FamilyHistorySchema
