import VisitReasonForm from 'forms/visit-creation/VisitReasonForm'
import NewVisitReasonSchema from 'schemas/NewVisitReasonSchema'
import { StepFormBaseProps } from '../types'

type SuccessOverviewProps = StepFormBaseProps & {
  props?: any
}

const SuccessReasons = ({ props, onSubmit, renderFooter, initialValues, pendingVisit }: SuccessOverviewProps) => {
  return (
    <div className="p-8">
      <VisitReasonForm
        {...props}
        pendingVisit={pendingVisit}
        onSubmit={onSubmit}
        schema={NewVisitReasonSchema}
        initialValues={initialValues}
        isRequiredLabelVisible={true}
        requiredLabelIgnoreFieldsLength
      >
        {renderFooter}
      </VisitReasonForm>
    </div>
  )
}

export default SuccessReasons
