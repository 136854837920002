import classNames from '@ui-components-3/ui/lib/utils/classNames'
import { useMemo } from 'react'
import { isSuccess } from 'util/helpers'
import VisitTypes, {
  DisplayVisitType,
  iconForRecommendedServiceAndCare,
  iconForService,
  subtitleForService,
  timeFrameForServiceType,
} from 'util/visit-types'
import TimeFrameLabel from '../TimeFrameLabel/TimeFrameLabel'

type ServiceInfoProps = {
  type: string
  disabled?: boolean
  className?: string
  testId?: string
  confirmation?: boolean
  recommended?: boolean
  timeFrameVisible?: boolean
}

const ServiceInfo = ({
  type,
  className,
  testId,
  disabled,
  confirmation = false,
  recommended = false,
}: ServiceInfoProps) => {
  const Icon = recommended ? iconForRecommendedServiceAndCare(type) : iconForService(type)
  const title = DisplayVisitType[type]
  const subtitle = subtitleForService(type)
  const timeFrame = timeFrameForServiceType(type)
  const timeFrameVaries = VisitTypes.psychiatry === type

  const formattedTimeFrame = () => {
    if (timeFrameVaries || !timeFrame) return timeFrame

    return `${timeFrame} minute visit`
  }

  const successVisit = useMemo(() => {
    if (!confirmation) return ''
    return isSuccess(type) ? 'session' : 'visit'
  }, [type, confirmation])

  const colors = useMemo(() => {
    const iconWrapperColor = disabled ? 'bg-neutral-300' : recommended ? 'bg-primary-500' : 'bg-secondary-200'
    const iconColor = disabled ? 'fill-neutral-500' : recommended ? 'fill-white' : 'text-secondary-700'
    const titleColor = disabled ? 'text-neutral-500' : recommended ? 'text-neutral-800' : 'text-secondary-700'
    const subtitleColor = disabled ? 'text-neutral-500' : 'text-neutral-600'

    return {
      iconWrapper: iconWrapperColor,
      icon: iconColor,
      title: titleColor,
      subtitle: subtitleColor,
    }
  }, [disabled, recommended])

  return (
    <div className={classNames('flex items-start', className)}>
      {!!Icon && (
        <span
          className={classNames(
            'mr-3 flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full',
            colors.iconWrapper,
          )}
        >
          <Icon className={classNames('h-[24px] w-[24px]', colors.icon)} aria-hidden="true" />
        </span>
      )}
      <div data-testid={testId ?? null}>
        <div className={classNames('typography-body-l font-semibold', colors.title)}>
          {`${confirmation ? `You've selected a ` : ''}${title} ${successVisit}`}
        </div>
        {!!subtitle && (
          <div className={classNames('typography-body leading-[18px] tracking-[-0.02em]', colors.subtitle)}>
            {subtitle}
          </div>
        )}
        {!!timeFrame && <TimeFrameLabel timeFrame={formattedTimeFrame()} />}
      </div>
    </div>
  )
}

export default ServiceInfo
