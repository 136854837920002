import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
const api = ApiFactory.getApi(ApiTypes.provider)

const RequestSchema = Yup.object().shape({
  providerId: Yup.string().label('Provider ID').required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const url = ({ providerId }) => `/v1/providers/${providerId}`
export const execute = async (params) => api.get(url(params)).catch(catchApiFailure)
