import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { cloneDeep, assign } from 'lodash'
import PendingVisit from 'types/visit/pendingVisit'
import { Visit } from 'types/visit/visit'

type VisitMeta = {
  visitPage: null | number
  visitCount: number
  pageFetches: {
    [page: number]: string
  }
}

type VisitType = Visit & { page: number; interpreter: string; participants: string[] }

export type VisitState = {
  meta: {
    lastFetchAt: null | string
    [memberId: string]: VisitMeta | any
  }
  records: {
    [visit_id: string]: VisitType | any
    create: object
  }
  pending: {
    meta: {
      lastFetchAt: null
      [memberId: string]: VisitMeta | any
    }
    records: {
      [pending_visit_id: string]: PendingVisit
    }
  }
}

export const defaultVisitMeta: VisitMeta = {
  visitPage: null,
  visitCount: 0,
  pageFetches: {
    // [page]: Date
  },
}

const defaultState = {
  meta: {
    lastFetchAt: null,
    // [memberId] : defaultVisitMeta
  },
  records: {
    // [visit_id]: Visit,
    create: {},
  },
  pending: {
    meta: {
      lastFetchAt: null,
      // [memberId] : defaultVisitMeta
    },
    records: {
      // [pending_visit_id]: Pending Visit
    },
  },
} satisfies VisitState

const cleanVisitProviderName = (visit: VisitType) => {
  if (visit.providerName) {
    visit.providerName = visit.providerName.replace('undefined ', '').replace(', undefined', '')
  }
  return visit
}

const getVisitId = (visit: VisitType) => visit.visitId
const getPendingVisitId = (pendingVisit: PendingVisit) => pendingVisit.pendingVisitId

const slice = createSlice({
  name: 'visit',
  initialState: defaultState,
  reducers: {
    putNewVisit: (state, action) => {
      const { pendingVisit } = action.payload
      state.records.create = pendingVisit
    },
    putVisit: (state, action) => {
      const { visit } = action.payload
      state.records[getVisitId(visit)] = cleanVisitProviderName(cloneDeep(visit))
    },
    upsertVisit: (state, action) => {
      const { visit } = action.payload
      const id = getVisitId(visit)
      const cleanedVisit = cleanVisitProviderName(cloneDeep(visit))
      if (!state.records[id]) {
        state.records[id] = cleanedVisit
      } else {
        assign(state.records[id], cleanedVisit)
      }
    },
    putVisitParticipants: (state, action) => {
      const { visitId, participants } = action.payload
      if (state.records[visitId]) state.records[visitId].participants = participants
    },
    putVisitInterpreter: (state, action) => {
      const { visitId, interpreter, aslInterpreter } = action.payload
      if (state.records[visitId]) state.records[visitId] = { ...state.records[visitId], interpreter, aslInterpreter }
    },
    clearVisitInterpreter: (state, action) => {
      const { visitId, asl = false } = action.payload
      const key = asl ? 'aslInterpreter' : 'interpreter'

      if (state.records?.[visitId]?.[key]) {
        delete state.records[visitId][key]
      }
    },
    putVisits: (state, action) => {
      const { visits } = action.payload
      visits.forEach((visit: VisitType) => {
        state.records[getVisitId(visit)] = cleanVisitProviderName(cloneDeep(visit))
      })
    },
    putPendingVisit: (state, action) => {
      const { pendingVisit } = action.payload
      const id = getPendingVisitId(pendingVisit)
      state.pending.records[id] = pendingVisit
    },
    upsertPendingVisit: (state, action) => {
      const { pendingVisit } = action.payload
      const id = getPendingVisitId(pendingVisit)
      if (!state.pending.records[id]) {
        state.pending.records[id] = pendingVisit
      } else {
        assign(state.pending.records[id], pendingVisit)
      }
    },
    putPendingVisits: (state, action) => {
      const { pendingVisits } = action.payload
      pendingVisits.forEach((pendingVisit: PendingVisit) => {
        state.pending.records[getPendingVisitId(pendingVisit)] = pendingVisit
      })
    },
    putVisitCount: (state, action) => {
      const { memberId, count } = action.payload
      if (!state.meta[memberId]) state.meta[memberId] = cloneDeep(defaultVisitMeta)
      state.meta[memberId].visitCount = count
    },
    putVisitPageLoaded: (state, action) => {
      const { memberId, page, fetchedAt = moment().toISOString() } = action.payload
      if (!state.meta[memberId]) state.meta[memberId] = cloneDeep(defaultVisitMeta)
      state.meta[memberId].pageFetches[page] = fetchedAt
      state.meta[memberId].visitPage = page
    },
    putPendingVisitCount: (state, action) => {
      const { memberId, count } = action.payload
      if (!state.pending.meta[memberId]) state.pending.meta[memberId] = cloneDeep(defaultVisitMeta)
      state.pending.meta[memberId].visitCount = count
    },
    putPendingVisitPageLoaded: (state, action) => {
      const { memberId, page, fetchedAt = moment().toISOString() } = action.payload
      if (!state.pending.meta[memberId]) state.pending.meta[memberId] = cloneDeep(defaultVisitMeta)
      state.pending.meta[memberId].pageFetches[page] = fetchedAt
      state.pending.meta[memberId].visitPage = page
    },
    clearVisit: (state, action) => {
      const { visitId } = action.payload
      delete state.records[visitId]
    },
    clearVisits: (state) => {
      state.meta = { ...defaultState.meta }
      state.records = { ...defaultState.records }
    },
    clearPendingVisit: (state, action) => {
      const { pendingVisitId } = action.payload
      delete state.pending.records[pendingVisitId]
    },
    clearPendingVisits: (state) => {
      state.pending = { ...defaultState.pending }
    },
  },
})

const { actions, reducer } = slice
export const {
  putVisit,
  upsertVisit,
  putVisitParticipants,
  putVisitInterpreter,
  clearVisitInterpreter,
  putVisits,
  putPendingVisit,
  upsertPendingVisit,
  putPendingVisits,
  putVisitCount,
  putPendingVisitCount,
  putVisitPageLoaded,
  putPendingVisitPageLoaded,
  putNewVisit,
  clearVisits,
  clearVisit,
  clearPendingVisits,
  clearPendingVisit,
} = actions
export default reducer
