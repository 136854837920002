const MedicalVisitReasons = [
  'Allergies',
  'Back Pain',
  'Cough',
  'Eye Irritation',
  'Fever',
  'Muscle Ache',
  'Painful Urination',
  'Sinus Pain',
  'Skin Condition',
  'Sore Throat',
  'Upper Respiratory Infection',
  'Vomiting/Diarrhea',
] as const

export const TherapyVisitReasons = [
  'Anxiety',
  'Depression',
  'Substance Abuse',
  'Paranoia',
  'Abuse/Sexual Assault/Violence',
  'Trauma',
  'Stress',
  'Relationship Issues',
  'Social Services Needs',
  'Food/Body Image Issues',
  'Mood Issues',
] as const

export const HealthCoachVisitReasons = [
  'Nutrition / Healthy Eating',
  'Exercise / Movement',
  'Sleep Improvement',
  'Stress Management',
  'Time Management',
] as const

export const SuccessCoachVisitReasons = ['Academic Preparedness', 'Financial Wellness', 'Career Readiness'] as const

export default MedicalVisitReasons
