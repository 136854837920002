import {Persistence} from 'util/persistence'

export const localPersistence = new Persistence(localStorage)
export const sessionPersistence = new Persistence(sessionStorage)


export const getFromStorage = (key: string) => {
  return sessionPersistence.get(key) || localPersistence.get(key)
}

export const removeFromStorage = (key: string) => {
  localPersistence.remove(key)
  sessionPersistence.remove(key)
}