import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  visitId: Yup.string().label('Visit ID').required(),
  interpreterId: Yup.string().label('Interpreter ID').required(),
  asl: Yup.boolean().notRequired().nullable(),
})

type RequestParams = Yup.InferType<typeof RequestSchema>

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const url = ({ visitId, interpreterId, asl }: RequestParams) =>
  `/v2/visits/${visitId}/interpreters/${asl ? 'asl/' : ''}${interpreterId}`
export const execute = async (params) => api.put(url(params)).catch(catchApiFailure)
