import { createSelector } from 'reselect'
import { isNil, isEmpty } from 'lodash'

export const getRecords = (state) => state.medicalHistory.records
export const getMeta = (state) => state.medicalHistory.meta
export const getRecordArray = createSelector(getRecords, (records = {}) => Object.values(records))

export const getMedicalHistory = (medicalHistoryId) =>
  createSelector(getRecords, (records = {}) => records[medicalHistoryId])

export const getHistoryByMemberId = createSelector(getRecords, (records = {}) =>
  Object.values(records).reduce((acc, record) => {
    acc[record.memberId] = record
    return acc
  }, {}),
)

export const getMemberHistory = (memberId) =>
  createSelector(getRecords, (records = {}) => {
    return Object.values(records).find((record) => record.memberId === memberId)
  })

export const getMemberHistoryStatus = (memberId) =>
  createSelector(
    getMemberHistory(memberId),
    (history = {}) =>
      history && {
        completedGeneral: !isNil(history.heightFt) && !isNil(history.weightLbs),
        completedLifestyle:
          history.lifestyle &&
          history.lifestyle &&
          !isNil(history.lifestyle.smoke) &&
          !isNil(history.lifestyle.drink) &&
          !isNil(history.lifestyle.sexuallyActive),
        completedMedicalHistory: history.conditionsCompleted,
        completedFamilyHistory: history.familyHistoryCompleted,
        completedMedications: history.medicationsCompleted,
        completedAllergies: history.allergiesCompleted,
        completedPrimaryCare: history.primaryCareCompleted,
      },
  )

export const getSkipHistory = createSelector(getMeta, (meta = {}) => meta.skipHistory)

export const getMemberLifestyle = (memberId) =>
  createSelector(getMemberHistory(memberId), (history = {}) => history.lifestyle)

export const getMemberConditions = (memberId) =>
  createSelector(getMemberHistory(memberId), (history = {}) => history.conditions)

export const getMemberConditionsComplete = (memberId) =>
  createSelector(getMemberHistory(memberId), (history = {}) => history.conditionsCompleted)

export const getMemberOtherConditions = (memberId) =>
  createSelector(getMemberHistory(memberId), (history = {}) => history.other)

export const getMedication = (memberId, medicationId) =>
  createSelector(getMemberHistory(memberId), (history = {}) => {
    if (!medicationId || isEmpty(history)) return null
    return history.medications.find((med) => med.id === medicationId)
  })
