import { setFetching } from '../slices/api'
import FetchType from '../../util/fetch-types'
import { handleRequestFailure, makeRequest } from '../../api/helpers'
import { putReasons } from 'redux/slices/cancellation-reasons'

export const fetchCancellationReasons = () => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.getCancellationReasons, isFetching: true }))
  const params = {}
  let success = false
  const handleSuccess = async (data) => {
    dispatch(putReasons({ sessions: data }))
    success = true
  }
  await makeRequest(FetchType.getCancellationReasons, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(setFetching({ fetchType: FetchType.getCancellationReasons, isFetching: false }))
  return { success }
}
