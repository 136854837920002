import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'

const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  memberId: Yup.string().label('Member ID').required(),
  accessCode: Yup.string()
    .label('Access Code')
    .min(6, 'Access Code is too Short')
    .max(6, 'Access Code is too Long')
    .required(),
})

export const isValid = async (params) => {
  return await RequestSchema.isValid(params)
}
export const validate = async (params) => RequestSchema.validate(params)
export const make = async (params) => {
  return {
    access_code: params.accessCode,
  }
}
export const url = ({ memberId }) => `/v1/members/${memberId}/services`
export const execute = async (params) => api.post(url(params), await make(params)).catch(catchApiFailure)
