import FormikCheckbox from '@ui-components-3/compat/lib/components/FormikCheckbox'
import NewVisitModalitySchema from 'schemas/NewVisitModalitySchema'
import TestIds from 'util/TestIds'
import useId from '@ui-components-3/ui/lib/hooks/useId'
import { useRef } from 'react'
import Modal, { ModalRef } from '@ui-components-3/ui/lib/components/Modal'
import AslIcon from '@ui-components-3/icons/lib/solid/HandsAslInterpreting'

const AslInterpreterCheckbox = () => {
  const infoModalRef = useRef<ModalRef>(null)
  const id = useId()

  return (
    <>
      <label htmlFor={id} className="flex items-start gap-2.5 text-neutral-600">
        <div className="shrink-0">
          <FormikCheckbox
            id={id}
            data-testid={TestIds.newVisit.input.aslInterpreterNeeded}
            name="aslInterpreterNeeded"
            schema={NewVisitModalitySchema}
            noFormControl
          />
        </div>
        <span className="typography-body font-medium">
          I would like an American Sign Language (ASL) interpreter to join my call.{' '}
          <button
            type="button"
            className="btn-unsized btn-neutral-borderless !typography-body text-neutral-600 underline"
            onClick={() => infoModalRef.current?.open()}
          >
            Learn more
          </button>
        </span>
      </label>
      <Modal label="American Sign Language Assistance" ref={infoModalRef} footerVariant="right" size="l">
        <div className="typography-body sm:typography-body-xl flex flex-col gap-6 font-medium text-neutral-800">
          <p>By checking the box, you elect to have an ASL interpreter join your video visit to assist you. </p>
          <p className="flex items-center gap-4">
            <span className="bg-pantone-800 flex h-[48px] w-[48px] items-center justify-center rounded-full text-white sm:h-[60px] sm:w-[60px]">
              <AslIcon aria-hidden="true" className="h-6 w-6 sm:h-8 sm:w-8" />
            </span>
            American Sign Language
          </p>
        </div>
      </Modal>
    </>
  )
}

export default AslInterpreterCheckbox
