import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import configBuilder from '../config-builder'
import ApiFactory, { ApiTypes } from '../../index'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  params: Yup.object()
    .shape({
      filter: Yup.object().shape({
        visit_type: Yup.string().required(),
      }),
    })
    .required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const url = () => `/v1/visit-queues`
export const execute = async (params) => api.get(url(params), configBuilder(params)).catch(catchApiFailure)
