import { ChangeEvent, KeyboardEvent, useRef, useState } from 'react'
import { Field, FieldMetaProps } from 'formik'

import FormControl from '@ui-components-3/ui/lib/components/FormControl'
import Input from '@ui-components-3/ui/lib/components/Input'
import AddIcon from '@ui-components-3/icons/lib/solid/CirclePlus'
import TestIds from '../../../util/TestIds'
import Checkbox from '@ui-components-3/ui/lib/components/Checkbox'
import useId from '@ui-components-3/ui/lib/hooks/useId'
import classNames from '@ui-components-3/ui/lib/utils/classNames'

type ReasonCheckboxProps = {
  testId: string
  reason: string
  checked: boolean
  onChange: () => void
}

const ReasonCheckbox = ({ reason, testId, checked, onChange }: ReasonCheckboxProps) => {
  const checkboxId = useId()
  return (
    <li key={reason} className="flex items-center" data-testid={testId}>
      <Checkbox id={checkboxId} className="mr-2.5 shrink-0" checked={checked} onChange={onChange} />
      <label htmlFor={checkboxId} className="typography-body-l text-neutral-800">
        {reason}
      </label>
    </li>
  )
}

type VisitReasonListFieldProps = {
  name: string
  reasons: string[]
  value?: string
  disabled?: boolean
  reasonTitle: string
}

const VisitReasonListField = (props: VisitReasonListFieldProps) => {
  const otherInputRef = useRef<HTMLInputElement>(null)

  const { reasons, reasonTitle } = props

  const [otherReason, setOtherReason] = useState('')

  const handleOtherType = (event: ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) => {
    if (event.target.value.trim().length <= 30) {
      setOtherReason(event.target.value)
    }
  }

  return (
    <Field name={props.name} value={props.value} disabled={props.disabled}>
      {({
        field,
        form,
        meta,
      }: {
        field: { name: string; value: string }
        form: { setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void> }
        meta: FieldMetaProps<string>
      }) => {
        const { error } = meta
        const otherValues = (field.value || '').split(',').reduce((acc: string[], reason) => {
          const trimmed = reason.trim()
          if (!trimmed || reasons.includes(trimmed)) {
            return acc
          }
          acc.push(trimmed)
          return acc
        }, [])

        const handleToggle = (reason: string, isCurrentlyChecked: boolean) => {
          if (!field.value) {
            if (!isCurrentlyChecked) form.setFieldValue(field.name, reason)
          } else if (!isCurrentlyChecked) {
            // add to reasons
            form.setFieldValue(field.name, `${field.value}, ${reason}`)
          } else {
            // remove from reasons
            const midListRegex = new RegExp(`${reason}, `, 'i')
            const endOfListRegExp = new RegExp(`, ${reason}$`, 'i')
            const isMidList = midListRegex.test(field.value)
            if (isMidList) {
              form.setFieldValue(field.name, field.value.replace(midListRegex, ''))
            } else if (endOfListRegExp.test(field.value)) {
              form.setFieldValue(field.name, field.value.replace(endOfListRegExp, ''))
            } else {
              form.setFieldValue(field.name, field.value.replace(reason, ''))
            }
          }
        }

        const handleAddOther = () => {
          const trimmed = (otherReason || '').trim()
          if (trimmed.length > 2 && trimmed.length <= 30) {
            const regex = new RegExp(trimmed, 'i')
            if (!regex.test(field.value)) {
              if (!field.value) {
                form.setFieldValue(field.name, trimmed)
              } else {
                form.setFieldValue(field.name, `${field.value}, ${trimmed}`)
              }
              setOtherReason('')
            }
          }
        }

        const isOtherAddBtnDisabled = otherReason.length < 3

        const handleOtherInputKeydown = (e: KeyboardEvent<HTMLElement>) => {
          if (document.activeElement === otherInputRef.current && e.key === 'Enter' && e.shiftKey === false) {
            e.preventDefault()

            if (!isOtherAddBtnDisabled) {
              handleAddOther()
            }
          }
        }

        return (
          <div className="mb-5">
            <FormControl
              className="mb-4"
              helper={error ? 'Reason for visit cannot be left blank' : ''}
              helperVariant="error"
              consciouslyIgnoreLabel={true}
            >
              <fieldset aria-required="true">
                <legend
                  className="typography-h5 mb-xs gap-s inline-flex flex-col text-neutral-800"
                  data-testid={TestIds.newVisit.label.reasonTitle}
                >
                  {reasonTitle}*
                  <span className="typography-body font-semibold text-neutral-500">SELECT ONE OR MORE</span>
                </legend>
                <ul className="gid-cols-1 mb-2 grid gap-x-2 gap-y-4 sm:grid-cols-2">
                  {reasons.map((reason, index) => {
                    const regex = new RegExp(reason, 'i')
                    const isChecked = regex.test(field.value)
                    return (
                      <ReasonCheckbox
                        reason={reason}
                        checked={!!isChecked}
                        onChange={() => handleToggle(reason, isChecked)}
                        testId={TestIds.newVisit.input.reasonItem(index)}
                      />
                    )
                  })}
                  {otherValues.map((reason: string, index: number) => (
                    <ReasonCheckbox
                      reason={reason}
                      checked={true}
                      onChange={() => handleToggle(reason, true)}
                      testId={TestIds.newVisit.input.reasonItem(reasons.length + index)}
                    />
                  ))}
                </ul>
              </fieldset>
            </FormControl>
            <FormControl label="Other" className="w-full">
              <Input
                autoComplete="off"
                value={otherReason}
                onChange={handleOtherType}
                onKeyDown={handleOtherInputKeydown}
                ref={otherInputRef}
                data-testid={TestIds.newVisit.input.otherReason}
                suffix={
                  <button
                    className="btn-tiny btn-neutral-borderless disabled:bg-transparent"
                    onClick={handleAddOther}
                    disabled={isOtherAddBtnDisabled}
                    aria-label={'Add other reason'}
                    data-testid={TestIds.newVisit.button.otherReason}
                    type="button"
                  >
                    <AddIcon
                      className={classNames(
                        'h-5 w-5',
                        isOtherAddBtnDisabled ? 'text-neutral-300 opacity-50' : 'text-neutral-500',
                      )}
                      aria-hidden="true"
                    />
                  </button>
                }
              />
            </FormControl>
          </div>
        )
      }}
    </Field>
  )
}

export default VisitReasonListField
