import { createSlice } from '@reduxjs/toolkit'
import { Resource } from 'types/resource'

export type ResourceState = {
  meta: object
  records: {
    [resourceId: string]: Resource
  }
}

const defaultState = {
  meta: {},
  records: {
    // [resource_id]: Resource
  },
} satisfies ResourceState

const getId = (resource: Resource) => resource.resourceId

const slice = createSlice({
  name: 'resources',
  initialState: defaultState,
  reducers: {
    putResource: (state, action) => {
      const { resource } = action.payload
      state.records[getId(resource)] = resource
    },
    putResources: (state, action) => {
      const { resources } = action.payload
      resources.forEach((resource: Resource) => {
        state.records[getId(resource)] = resource
      })
    },
    clearResource: (state, action) => {
      const { resourceId } = action.payload
      delete state.records[resourceId]
    },
    clearResources: (state) => {
      state.records = { ...defaultState.records }
    },
  },
})

const { actions, reducer } = slice
export const { putResource, putResources, clearResource, clearResources } = actions
export default reducer
