type SMSSubTextProps = {
  className: string
}

const SMSSubText = ({ className, ...props }: SMSSubTextProps) => {
  return (
    <p className={className} {...props}>
      SMS messages will be used only for notifications. Mobile message and data rates may apply. Frequency may vary.
      Reply STOP to cancel.
    </p>
  )
}

export default SMSSubText
