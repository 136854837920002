import * as Yup from 'yup'
import PhoneSchema from './PhoneSchema'
import { EVisitModalities } from 'util/visit-modalities'

const NewVisitModalitySchema = Yup.object().shape({
  phone: PhoneSchema.required(),
  phoneId: Yup.string().notRequired().nullable().strip(),
  modality: Yup.string()
    .label('Visit Modality')
    .oneOf(Object.values(EVisitModalities || {}))
    .required(),
  aslInterpreterNeeded: Yup.boolean().notRequired().nullable().default(false),
})

export default NewVisitModalitySchema
