import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  dispensableDrugId: Yup.string().required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const url = ({ dispensableDrugId }) => `/v2/medications/${dispensableDrugId}`
export const execute = async (params) => api.get(url(params)).catch(catchApiFailure)
