import Pusher from 'pusher-js'
import PusherBatchAuthorizer from 'pusher-js-auth' // plugin for batching authorization
import Config from '../config'
import { getFromStorage } from 'util/storage'
import * as SessionKeys from './session-constants'

Pusher.logToConsole = !Config.isProduction

export const PusherStatus = {
  init: 'INIT',
  initialized: 'initialized',
  connecting: 'connecting',
  connected: 'connected',
  disconnected: 'disconnected',
  unavailable: 'unavailable',
  failed: 'failed',
}

class PusherClient {
  constructor() {
    this._isInitialized = false
    this._isConnected = false

    this.status = PusherStatus.init
    this.isBound = false
    this.pusher = null
    this.channels = new Map()
    this._bindHandler = null
    this._statusListener = null
  }

  isConnected() {
    return this._isConnected
  }

  isInitialized() {
    return this._isInitialized
  }

  async init(statusListener) {
    this._statusListener = statusListener
    if (this.isConnected) {
      await this.disconnect()
    }
    this.pusher = new Pusher(Config.pusherAppKey, {
      cluster: Config.pusherCluster,
      authEndpoint: Config.pusherAuthEndpoint,
      authorizer: PusherBatchAuthorizer,
      authDelay: 400,
      auth: () => ({
        headers: {
          Authorization: `Bearer ${getFromStorage(SessionKeys.TOKEN)}`,
        },
      }),
    })
    this.pusher?.connection?.bind?.('error', function (err) {
      console.error(err)
      if (err?.error?.data?.code === 4004) {
        console.log('Over limit!')
      }
    })
    this.pusher?.connection?.bind?.('state_change', this._handleStatusChange(this))
    this.pusher?.connection?.bind?.('disconnected', this._handleDisconnectionStatus(this))
    this.pusher?.connection?.bind?.('connected', this._handleConnectedStatus(this))
    console.log(this)
  }

  _handleDisconnectionStatus(self) {
    return function () {
      console.log('Pusher disconnected')
      self._statusListener = null
    }
  }

  _handleConnectedStatus(self) {
    return function () {
      console.log('Pusher connected')
      console.log(self)
      console.log('recycling subscriptions')
      self.recycleSubscriptions()
    }
  }

  _handleStatusChange(self) {
    return function (states) {
      self._isConnected = states.current === PusherStatus.connected
      self._isInitialized = ![PusherStatus.init, PusherStatus.unavailable, PusherStatus.failed].includes(states.current)
      self.status = states.current
      self._statusListener?.(self.status)
      console.log(`Pusher status changed from ${states.previous} to ${states.current}`)
    }
  }

  hasChannel(channelName) {
    return this.channels?.has(channelName)
  }

  hasActiveChannel(channelName) {
    return this.pusher?.channel(channelName)
  }

  channelList() {
    const list = []
    this.channels?.forEach((value, key) => list.push(key))
    return list
  }

  activeChannelList() {
    const list = []
    this.channels?.forEach((value, key) => {
      // only add to list if the channel is still healthy
      if (this.hasActiveChannel(key)) list.push(key)
    })
    return list
  }

  resubscribe() {
    this.channels?.forEach((value, key) => {
      this.channels.set(key, this.pusher?.subscribe(key))
    })
    if (this._bindHandler) {
      this.bind(this._bindHandler)
    }
  }

  recycleSubscriptions() {
    this.channels?.forEach((value, key) => {
      if (!this.hasActiveChannel(key)) {
        this.channels.set(key, this.pusher?.subscribe(key))
      }
    })
    if (this._bindHandler) {
      this.bind(this._bindHandler)
    }
  }

  subscribe(channelName) {
    console.log('Subscribing to ' + channelName)
    if (!this.hasChannel(channelName) || !this.hasActiveChannel(channelName)) {
      this.channels?.set(channelName, this.pusher?.subscribe(channelName))
    }
  }

  unsubscribe(channelName) {
    if (this.hasActiveChannel(channelName)) {
      this.pusher?.unsubscribe(channelName)
    }
    if (this.hasChannel(channelName)) {
      this.channels?.delete(channelName)
    }
  }

  bindStatus(handler) {
    this._statusListener = handler
  }

  unbindStatus() {
    this._statusListener = null
  }

  bind(handler) {
    if (this._bindHandler) {
      this.unbind(this._bindHandler)
    }
    this._bindHandler = handler
    this.channels?.forEach((value) => {
      value?.bind_global(handler)
      this.isBound = this.isBound || !!handler
    })
  }

  unbind(handler) {
    this.channels?.forEach((value) => {
      value?.unbind_global(handler)
    })
    this.isBound = false
    this._bindHandler = null
  }

  unsubscribeAll() {
    this.channels?.forEach((value, key) => {
      this.pusher?.unsubscribe(key)
    })
    this.channels = new Map()
  }

  async disconnect() {
    this.unbind()
    this.unsubscribeAll()
    await this.pusher?.disconnect?.()
  }

  updateToken() {
    if (this.pusher?.config?.auth?.headers?.Authorization) {
      this.pusher.config.auth.headers.Authorization = `Bearer ${getFromStorage(SessionKeys.TOKEN)}`
    }
  }
}

export default new PusherClient()
