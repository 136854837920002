import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import configBuilder from '../config-builder'
import { normalizeJsonToApi } from '../../helpers'
import PhoneSchema from '../../../schemas/PhoneSchema'
import AddressSchema from '../../../schemas/AddressSchema'
import { genderIdentities, gradSemesters, gradYears, accountClassifications } from '../../../util/member'

const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  memberId: Yup.string().required(),
  contact: Yup.object().shape({
    firstNamePreferred: Yup.string().label('Preferred First Name').notRequired().nullable(),
    gender: Yup.string().oneOf(['M', 'F', 'X']),
    genderIdentity: Yup.string().label('I identify as').oneOf(genderIdentities).notRequired().nullable(),
    genderSelfSelect: Yup.string().label('Self Selected Gender').when('genderIdentity', {
      is: 'Other/not listed',
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired().strip(),
    }),
    pronoun: Yup.string().notRequired().nullable(),
    preferredLanguage: Yup.object()
      .shape({
        name: Yup.string(),
        abbrev: Yup.string().min(2),
      })
      .notRequired(),
    ethnicity: Yup.array().of(Yup.string()).default([]).notRequired().nullable(),
    graduationSemester: Yup.string().oneOf(gradSemesters).notRequired().nullable(),
    graduationYear: Yup.string().label('Graduation Year').oneOf(gradYears).notRequired().nullable(),
    degreeClassification: Yup.string().label('Classification').oneOf(accountClassifications).notRequired().nullable(),
    phones: Yup.array().of(PhoneSchema),
    billingAddress: AddressSchema,
    mailingAddress: AddressSchema,
  }),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const make = async (params) => {
  const clean = RequestSchema.cast(params)
  if (!clean.contact.billingAddress.street2) {
    delete clean.contact.billingAddress.street2
  }
  if (!clean.contact.mailingAddress.street2) {
    delete clean.contact.mailingAddress.street2
  }
  if (!clean.contact.preferredLanguage?.name) {
    delete clean.contact.preferredLanguage
  }
  if (clean.contact.genderIdentity !== 'Other/not listed') {
    delete clean.contact.genderSelfSelect
  }
  return normalizeJsonToApi(clean.contact)
}
export const url = ({ memberId }) => `/v1/${memberId}/contact`
export const execute = async (params) =>
  api.put(url(params), await make(params), configBuilder(params)).catch(catchApiFailure)
