export const PusherTypes = {
  createPendingVisit: 'create-pending-visit',
  updatePendingVisit: 'update-pending-visit',
  videoCallWaiting: 'video-call-waiting',
  scheduleVisitRequested: 'scheduled-visit-requested',
  cancelScheduling: 'scheduled-visit-cancelled',
  acceptScheduling: 'scheduled-visit-accepted',
  rejectScheduling: 'scheduled-visit-rejected',
  visitClaimed: 'claim-visit',
  visitUnclaimed: 'unclaim-visit',
  visitStarted: 'start-visit',
  reviewVisit: 'review-visit',
  completeVisit: 'complete-visit',
  cancelVisit: 'cancel-visit',
  autoAssignVisit: 'auto-assign-visit',
  paymentFailure: 'payment-failed',
  createMessage: 'create-message',
  updateMessage: 'update-message',
  updateChatMessage: 'update-chat-message',
  createChatMessage: 'create-chat-message',
  deletePost: 'delete-post',
  updatePost: 'update-post',
  updatePostStatus: 'update-post-status',
  updateComponent: 'update-component',
  updateJourney: 'update-journey',
  createReaction: 'create-reaction',
  updateAccountMerge: 'update-account-merge',
}

export const PusherTopics = {
  member: (id) => `private-members-${id}`,
  visit: (id) => `private-visits-${id}`,
  pendingVisit: (id) => `private-pending-visits-${id}`,
  posts: () => 'private-posts',
  post: (id) => `private-posts-${id}`,
  memberPosts: (id) => `private-members-${id}-posts`,
  component: (id) => `private-components-${id}`,
  components: () => 'private-components',
  journey: (id) => `private-journeys-${id}`,
  journeys: () => 'private-journeys',
}
