import { ReactNode, useEffect, useMemo, SVGProps } from 'react'
import { isEmpty } from 'lodash'
import RadioCardsGroup from '@ui-components-3/ui/lib/components/RadioCardsGroup'
import { MinOption } from 'types/form/Form'
import { Controller, useForm } from 'react-hook-form'
import IllustrationServiceType from 'assets/images/ill_service_type.svg'
import HandHoldingMedicalIcon from '@ui-components-3/icons/lib/duotone/HandHoldingMedical'
import HeadSideBrainIcon from '@ui-components-3/icons/lib/duotone/HeadSideBrain'
import ShieldHeartIcon from '@ui-components-3/icons/lib/duotone/ShieldHeart'
import {
  VisitCreationForm,
  VisitCreationImage,
  VisitCreationScene,
} from 'components/visit-creation/VisitCreationWrappers/VisitCreationWrappers'
import useId from '@ui-components-3/ui/lib/hooks/useId'
import VisitTypes from 'util/visit-types'
import VisitScheduleType from 'util/visit-schedule-types'
import { useSelector } from 'react-redux'
import * as ServiceSelectors from 'redux/selectors/services'
import useScheduledVisitsLimit from 'hooks/useScheduledVisitsLimit'

const RADIO_CARD_ESTIMATED_WIDTH = 300

const CardIcons = {
  mentalHealth: ShieldHeartIcon,
  medicalAndHC: HandHoldingMedicalIcon,
  psychiatry: HeadSideBrainIcon,
}

export const newVisitRoutingEntryCardType = {
  mentalHealth: 'mentalHealth',
  medicalAndHC: 'medicalAndHC',
  psychiatry: VisitTypes.psychiatry,
}

type Option = MinOption & {
  disabled: boolean
  description: string
}

const getOptionCardLabel = (option: Option) => {
  const Icon: React.FC<SVGProps<SVGSVGElement>> = CardIcons[option.value]
  return (
    <div className="flex gap-2">
      <div className="h-10 w-10 p-1.5">
        <Icon aria-hidden={true} className="fill-primary-600 h-8 w-8" />
      </div>
      <div className="flex flex-col gap-1">
        <div className="typography-body-l font-semibold text-neutral-800">{option.label}</div>
        <span className="typography-body-xs text-neutral-500">{option.description}</span>
      </div>
    </div>
  )
}

const getOptionCardValue = (o: Option) => o.value
const isOptionCardDisabled = (option: Option) => option.disabled
const getOptionCardProps = (_: Option) => ({
  className: 'bg-white z-10 shadow-[0_8px_16px_0_rgba(27,99,162,0.08)] px-2 py-4',
})

type FormProps = {
  options: Option[]
  children: MemberSelectFormProps['children']
  onSubmit: MemberSelectFormProps['onSubmit']
  initalValues: { visitType: string; scheduledType: string }
  handleReturnToDashboard: () => void
}

const Form = ({ options, children, onSubmit, initalValues, handleReturnToDashboard }: FormProps) => {
  const { control, watch, handleSubmit, setValue } = useForm({
    defaultValues: initalValues,
  })

  const { setScheduledVisitType, LimitRescheduleDialog } = useScheduledVisitsLimit(handleReturnToDashboard)

  const selectedVisitType = watch('visitType')

  useEffect(() => {
    if (selectedVisitType === VisitTypes.psychiatry) {
      if (setScheduledVisitType(VisitTypes.psychiatry)) return
      setValue('scheduledType', VisitScheduleType.scheduled)
    }
  }, [selectedVisitType, setValue])

  const radioGroupLabelId = useId()

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2 id={radioGroupLabelId} className="typography-h5 mb-9 text-neutral-800">
        What kind of care are you looking for?
      </h2>
      <Controller
        control={control}
        name="visitType"
        render={({ field }) => (
          <RadioCardsGroup
            {...field}
            required={true}
            aria-labelledby={radioGroupLabelId}
            columns={1}
            options={options}
            getLabelProps={getOptionCardProps}
            getOptionLabel={getOptionCardLabel}
            getOptionValue={getOptionCardValue}
            isOptionDisabled={isOptionCardDisabled}
            columnWidth={RADIO_CARD_ESTIMATED_WIDTH}
            controlPlacement="rt"
          />
        )}
      />
      {children({ disabled: isEmpty(selectedVisitType) })}
      {LimitRescheduleDialog}
    </form>
  )
}

type MemberSelectFormProps = {
  children: (values: { disabled: boolean }) => ReactNode
  onSubmit: () => void
  handleReturnToDashboard: () => void
}

const NewVisitRoutingEntryForm = ({ children, onSubmit, ...props }: MemberSelectFormProps) => {
  const hasPsychiatryService = useSelector(ServiceSelectors.hasPsychiatryService)
  const hasMedicalAndHCService = useSelector(ServiceSelectors.hasMedicalAndHCService)
  const initalValues = {
    visitType: '',
    scheduledType: '',
  }

  const options = useMemo(() => {
    const baseOptions = [
      {
        label: 'Mental Health Care',
        value: 'mentalHealth',
        description: 'Services to treat your mental health and well-being',
        disabled: false,
      },
      {
        label: 'Medical Care and Health Coaching',
        value: 'medicalAndHC',
        description: 'Speak to a provider for common medical conditions and physical well-being',
        disabled: !hasMedicalAndHCService,
      },
      {
        label: 'Psychiatry',
        value: VisitTypes.psychiatry,
        description: 'Psychiatric care and medication support for mental health issues',
        disabled: !hasPsychiatryService,
      },
    ]
    return baseOptions.filter((opt) => !opt.disabled)
  }, [hasPsychiatryService, hasMedicalAndHCService])

  return (
    <VisitCreationScene>
      <VisitCreationForm>
        <Form {...props} options={options} onSubmit={onSubmit} initalValues={initalValues}>
          {children}
        </Form>
      </VisitCreationForm>
      <VisitCreationImage>
        <img className="h-full w-full" src={IllustrationServiceType} alt="" />
      </VisitCreationImage>
    </VisitCreationScene>
  )
}

export default NewVisitRoutingEntryForm
