import * as Yup from 'yup'
import configBuilder from '../config-builder'
import catchApiError from '../catch-api-error'
import { normalizeJsonToApi } from '../../helpers'
import ApiFactory, { ApiTypes } from '../../index'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object({
  memberId: Yup.string().required(),
  registration: Yup.object({
    device: Yup.object({
      make: Yup.string().required(),
      model: Yup.string().required(),
      modelVersion: Yup.string().required(),
      platform: Yup.string().required(),
      appVersion: Yup.string().required(),
      timezone: Yup.string().required(),
    }).required(),
    token: Yup.object({
      type: Yup.string().required(),
      data: Yup.string().required(),
    }).required(),
  }),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => await RequestSchema.validate(params)
export const make = async (params) => normalizeJsonToApi(params.registration)
export const url = ({ memberId }) => `/v1/${memberId}/notification/register`
export const execute = async (params) =>
  api.put(url(params), await make(params), configBuilder(params)).catch(catchApiError)
