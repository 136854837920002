import { ReactNode } from 'react'

type ProvideSelfConsentSceneProps = {
  children: ReactNode
}

export const ProvideSelfConsentScene = ({ children }: ProvideSelfConsentSceneProps) => {
  return <div className="flex flex-col items-start sm:gap-2">{children}</div>
}

type ProvideSelfConsentImageProps = {
  children: ReactNode
}

export const ProvideSelfConsentImage = ({ children }: ProvideSelfConsentImageProps) => {
  return (
    <div className="static bottom-0 right-0 flex items-start justify-start sm:top-0 sm:max-w-[345px] md:right-auto md:max-w-[460px] md:shrink-0 lg:max-w-[460px] xl:w-4/5 xl:max-w-[460px] 2xl:w-3/4 2xl:max-w-[460px]">
      {children}
    </div>
  )
}
