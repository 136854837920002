const Languages = {
  en: 'English',
  es: 'Spanish',
  zh: 'Chinese',
}

export const LanguageCodes = Object.keys(Languages)
export const LanguageArray = Object.values(Languages)
export const LanguageFilters = {
  en: 'English',
  es: 'Spanish',
  zh: 'Chinese',
}

export default Languages

export const getPrintableLanguages = (languageArray: Array<{ name: string }>) => {
  return languageArray?.reduce((acc, lang) => {
    if (!acc) return lang.name
    return `${acc}, ${lang.name}`
  }, '')
}

export const languageOptions = LanguageCodes.reduce((acc: Array<{ label: string; value: string }>, code) => {
  acc.push({ label: Languages[code as keyof typeof Languages], value: code })
  return acc
}, [])
