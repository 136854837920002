import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import configBuilder from '../config-builder'
const api = ApiFactory.getApi(ApiTypes.provider)

const RequestSchema = Yup.object().shape({
  providerId: Yup.string().label('Location').required(),
  params: Yup.object()
    .shape({
      filter: Yup.object()
        .shape({
          visitType: Yup.string().label('Visit Type').required(),
          memberId: Yup.string().label('Member ID').required(),
        })
        .required(),
    })
    .required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const url = ({ providerId }) => `/v1/providers/${providerId}/availability`
export const execute = async (params) => api.get(url(params), configBuilder(params)).catch(catchApiFailure)
