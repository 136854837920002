import { ReactNode, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import IllustrationQuestions from 'assets/images/ill_visit_questions.svg'
import {
  VisitCreationImage,
  VisitCreationScene,
} from 'components/visit-creation/VisitCreationWrappers/VisitCreationWrappers'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { ADD_PENDING_VISIT, createWithQuery } from 'routes/paths'
import { Steps } from 'pages/AddPendingVisitPage/AddPendingVisitPage'
import { selectAnswers } from 'redux/selectors/visit-questions'
import { useDispatch, useSelector } from 'react-redux'
import { setAnswer, setVariant } from 'redux/slices/visit-questions'
import { RecommendedScreenVariants, RecommendedScreenVariantsType } from 'util/visit-types'

export const RecommendedQuestions = [
  {
    title: 'How are your symptoms currently affecting your day-to-day activities?*',
    options: ['Some', 'A lot'],
  },
  {
    title: 'How soon were you hoping to get care?*',
    options: ['Within a week', 'ASAP'],
  },
  {
    title: 'How long have you been feeling this way?*',
    options: ['Less than 2 weeks', '2 weeks or more'],
  },
]

const FormSchema = Yup.object({
  answer0: Yup.string().required('Required'),
  answer1: Yup.string().required('Required'),
  answer2: Yup.string().required('Required'),
})

export type QuestionFormValues = Yup.InferType<typeof FormSchema>

type FormProps = {
  children: VisitQuestionsFormProps['children']
  onSubmit: VisitQuestionsFormProps['onSubmit']
}

const Form = ({ children, onSubmit }: FormProps) => {
  const dispatch = useDispatch()
  const answers: QuestionFormValues = useSelector(selectAnswers)
  const {
    watch,
    getValues,
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: answers,
    resolver: yupResolver(FormSchema),
  })
  const values = getValues()
  const watchedValues = watch()

  useEffect(() => {
    if (isValid) {
      Object.entries(values).forEach(([questionId, answer]) => {
        dispatch(setAnswer({ questionId, answer }))
      })
      let variant: RecommendedScreenVariantsType = RecommendedScreenVariants.result4
      if (values.answer0 === 'A lot') {
        variant = RecommendedScreenVariants.result4
      } else if (values.answer1 === 'ASAP' || values.answer2 === 'Less than 2 weeks') {
        variant = RecommendedScreenVariants.result5
      } else {
        variant = RecommendedScreenVariants.result7
      }
      dispatch(setVariant(variant))
    }
  }, [isValid, values, dispatch, watchedValues])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full flex-shrink-0 sm:pb-8 md:w-2/4">
      <p className="typography-body mb-9 mt-2 font-medium text-neutral-700">Questions with an asterisk are required</p>
      <h2 className="typography-h5 mb-9 text-neutral-800">Please answer a few questions</h2>
      <div className="flex max-w-[600px] flex-col gap-4 pb-10">
        {RecommendedQuestions.map((question, index) => (
          <Controller
            key={index}
            control={control}
            name={`answer${index}` as keyof QuestionFormValues}
            render={({ field }) => (
              <div className="w-full rounded-2xl bg-white px-8 py-6 shadow-[0px_8px_16px_0px_rgba(27,99,162,0.08)] md:min-w-[400px] md:max-w-[600px]">
                <p className="typography-body-l self-stretch font-semibold text-neutral-800">{question.title}</p>
                <div className="mt-4 grid grid-cols-2 gap-4">
                  {question.options.map((option, optionIndex) => {
                    const id = `answer${index}-${optionIndex}`
                    return (
                      <div key={optionIndex} className="rounded-md">
                        <input
                          {...field}
                          type="radio"
                          value={option}
                          id={id}
                          className="peer hidden"
                          checked={field.value === option}
                        />
                        <label
                          key={optionIndex}
                          htmlFor={id}
                          className="typography-body peer-checked:bg-primary-500 flex h-full cursor-pointer items-center justify-center rounded-md border border-neutral-700 p-2 text-center font-medium text-neutral-800 hover:bg-neutral-100 peer-checked:border-none peer-checked:text-white"
                        >
                          {option}
                        </label>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
          />
        ))}
        <Link
          to={createWithQuery(ADD_PENDING_VISIT, { step: Steps.visitType, throughStep: Steps.visitQuestions })}
          className="typography-body-m text-primary-600 mt-6 w-fit self-center font-medium underline"
          aria-label="Click here to navigate to the help center"
        >
          View all services
        </Link>
      </div>
      {children({ disabled: !isValid })}
    </form>
  )
}

type VisitQuestionsFormProps = {
  children: (values: { disabled: boolean }) => ReactNode
  onSubmit: () => void
}

const VisitQuestionsForm = ({ children, onSubmit }: VisitQuestionsFormProps) => {
  return (
    <VisitCreationScene>
      <Form onSubmit={onSubmit}>{children}</Form>
      <VisitCreationImage>
        <img className="hidden h-full w-full md:inline-block " src={IllustrationQuestions} alt="" />
      </VisitCreationImage>
    </VisitCreationScene>
  )
}

export default VisitQuestionsForm
