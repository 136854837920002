import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { LOGIN } from '../routes/paths'
import { useIdleTimer } from 'react-idle-timer'
import * as ApiThunks from '../redux/thunks/api'
import { useHistory } from 'react-router'
import { isEqual } from 'lodash'
import Modal from '@ui-components-3/ui/lib/components/Modal'

const defaultLogoutTimer = {
  timerOn: false,
  countdown: 60,
}

const withIdleAlert = (WrappedComponent) => {
  const Comp = function IdleAlert(props) {
    const history = useHistory()
    const dispatch = useDispatch()
    let timer = useRef()

    const [logoutTimer, setLogoutTimer] = useState(defaultLogoutTimer)

    const logout = useCallback(async () => {
      await dispatch(ApiThunks.logout())
      history.push(LOGIN)
    }, [dispatch, history])

    const stopLogoutTimer = useCallback(() => {
      setLogoutTimer(defaultLogoutTimer)
      if (timer.current) clearInterval(timer.current)
    }, [])

    const startLogoutTimer = useCallback(() => {
      timer.current = setInterval(() => {
        if (document.hidden) {
          stopLogoutTimer()
          logout()
        } else {
          setLogoutTimer((prev) => ({
            countdown: prev.countdown - 1,
            timerOn: true,
          }))
        }
      }, 1000)
    }, [logout, stopLogoutTimer])

    const handleOnIdle = useCallback(() => {
      startLogoutTimer()
    }, [startLogoutTimer])

    const handleOnAction = () => {
      if (!isEqual(logoutTimer, defaultLogoutTimer)) {
        stopLogoutTimer()
      }
    }

    useEffect(() => {
      if (logoutTimer.timerOn) {
        if (logoutTimer.countdown === 0) {
          stopLogoutTimer()
          logout()
        }
      }
    }, [logoutTimer, stopLogoutTimer, logout])

    useIdleTimer({
      timeout: 15 * 60 * 1000,
      onIdle: handleOnIdle,
      onAction: handleOnAction,
      debounce: 500,
      events: [
        'mousemove',
        'keydown',
        'wheel',
        'DOMMouseScroll',
        'mousewheel',
        'mousedown',
        'touchstart',
        'touchmove',
        'MSPointerDown',
        'MSPointerMove',
      ],
    })

    return (
      <>
        <WrappedComponent {...props} />
        {!!logoutTimer?.countdown && !!logoutTimer.timerOn && (
          <Modal
            label="Still there?"
            opened
            footer={({ close }) => (
              <button type="button" className="btn btn-neutral" onClick={close}>
                Continue
              </button>
            )}
            onClose={stopLogoutTimer}
          >
            <div className="typography-body-l text-neutral flex flex-col gap-2">
              <p>For your security, you are automatically logged out after 15 minutes of inactivity.</p>
              <p>
                Your session will expire in {logoutTimer.countdown} second{logoutTimer.countdown > 1 ? 's' : ''}.
              </p>
              <p>Press any key or click anywhere to continue.</p>
            </div>
          </Modal>
        )}
      </>
    )
  }

  Comp.propTypes = {
    location: PropTypes.object,
  }
  return Comp
}

export default withIdleAlert
