import * as Yup from 'yup'
import VisitTypes from '../util/visit-types'
import VisitScheduleType from '../util/visit-schedule-types'

const NewVisitTypeSchema = Yup.object().shape({
  visitType: Yup.string().label('Visit Type').oneOf(Object.values(VisitTypes).concat('medical_now')).required(),
  scheduledType: Yup.string().label('Schedule Type').oneOf(Object.values(VisitScheduleType)).required(),
  price: Yup.number(),
})

export default NewVisitTypeSchema
