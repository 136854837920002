import { RecommendedQuestions } from 'components/visit-creation/VisitQuestions/VisitQuestions'
import { createSelector } from 'reselect'

export const selectAnswers = (state) => state.visitQuestions.answers
export const selectVariant = (state) => state.visitQuestions.variant

export const selectSelectedOptions = createSelector(selectAnswers, (answers) => {
  return RecommendedQuestions.map((question, index) => {
    const answer = answers[`answer${index}`]
    const optionIndex = question.options.indexOf(answer)
    return optionIndex >= 0 ? (optionIndex + 1).toString() : ''
  })
})
