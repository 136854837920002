import { snakeCase, isEmpty, isNumber } from 'lodash'

const buildParams = (params = {}) => {
  const result = new URLSearchParams()
  const keys = Object.keys(params)

  if (keys.includes('page') || keys.includes('limit') || keys.includes('skip')) {
    result.append('page[limit]', params.limit)
    result.append('page[skip]', isNumber(params.skip) ? params.skip : String((params.page || 0) * params.limit))
  }

  if (keys.includes('include')) {
    const includeKeys = Object.keys(params.include)
    includeKeys.forEach((includeKey) => {
      const keys = Object.keys(params.include[includeKey])
      keys.forEach((key) => {
        result.append(`include[${includeKey}][${key}]`, params.include[includeKey][key])
      })
    })
  }

  keys.forEach((_key) => {
    const key = snakeCase(_key)
    if (key !== 'page' && key !== 'limit' && key !== 'include') {
      if (typeof params[key] === 'object') {
        Object.keys(params[key]).forEach((objectKey) => {
          let ok = objectKey
          if (ok.match(/\./)) {
            ok = ok
              .split('.')
              .map((part) => snakeCase(part))
              .join('.')
          } else {
            ok = snakeCase(objectKey)
          }
          let value = params[key][objectKey]
          if (objectKey === 'waitTime') {
            ok = 'created_at'
            value = value * -1
          }
          if (Array.isArray(value)) {
            if (!isEmpty(value)) {
              const finalKey = `${key}[${ok}]`
              value.forEach((val) => {
                result.append(finalKey, val)
              })
            }
          } else {
            result.append(`${key}[${ok}]`, value)
          }
        })
      } else {
        result.append(key, params[key])
      }
    }
  })
  return result
}

export default (params = { params: {}, token: undefined }) => {
  return {
    params: buildParams(params.params),
    headers: params.headers,
  }
}
