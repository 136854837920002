import * as Yup from 'yup'
import { LanguageSchema } from './NewVisitPreferencesSchema'

export const FiltersSchema = Yup.object().shape({
  ethnicity: Yup.array().of(Yup.string()).nullable(),
  race: Yup.array().of(Yup.string()).nullable(),
  gender: Yup.string().oneOf(['Male', 'Female', 'All', 'Another Gender Identity']).nullable(),
  languages: Yup.array().of(LanguageSchema).nullable(),
  specialty: Yup.array().of(Yup.string()).nullable(),
})

export const PreferencesSchema = Yup.object().shape({
  ethnicity: Yup.array().of(Yup.string()).nullable(),
  gender: Yup.string().oneOf(['M', 'F', 'X']).nullable(),
  'languages.abbrev': Yup.array().of(Yup.string()).nullable(),
  specialtyFocus: Yup.array().of(Yup.string()).nullable(),
})

const NewVisitProviderSchema = Yup.object().shape({
  providerId: Yup.string().required(),
  providerName: Yup.string().required(),
  providerTimezone: Yup.string().required(),
  filters: FiltersSchema.nullable(),
  preferences: PreferencesSchema.nullable(),
})

export default NewVisitProviderSchema
