import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import * as ApiSelectors from '../redux/selectors/api'
import * as ServiceSelectors from '../redux/selectors/services'
import FetchTypes from '../util/fetch-types'
import { isEmpty } from 'lodash'
import { objectIdPropTypeRequired } from '../proptypes/helpers/object-id'
import {
  addPaymentClient,
  addPaymentMethod,
  addTransaction,
  fetchPaymentMethods,
  removePaymentMethod,
} from '../redux/thunks/members'
import { getLoggedInMemberId } from '../redux/selectors/members'
import { serviceMap } from '../util/visit-types'
import Modal from '@ui-components-3/ui/lib/components/Modal'
import Loading from '@ui-components-3/ui/lib/components/Loading'

const withPaymentControl = (Child) => {
  const Comp = function PaymentControlComponent(props) {
    const dispatch = useDispatch()
    const memberId = useSelector(getLoggedInMemberId)

    const isRemoving = useSelector(ApiSelectors.isTypeFetching(FetchTypes.removePaymentMethod))
    const isFetchingMethods = useSelector(ApiSelectors.isTypeFetching(FetchTypes.getPaymentMethods))
    const isAddingMethod = useSelector(ApiSelectors.isTypeFetching(FetchTypes.addPaymentMethod))
    const isAddingClient = useSelector(ApiSelectors.isTypeFetching(FetchTypes.addPaymentClient))
    const services = useSelector(ServiceSelectors.getServicesByType)
    const [methodToRemove, setMethodToRemove] = useState(null)

    const handleFetchMethods = useCallback(async () => {
      if (isEmpty(memberId)) return null
      return dispatch(fetchPaymentMethods(memberId))
    }, [dispatch, memberId])

    const handleAddMethod = useCallback(
      async (source) => {
        return dispatch(addPaymentMethod(memberId, source))
      },
      [memberId],
    )

    const handleAddTransaction = useCallback(
      async (transaction) => {
        const serviceType = serviceMap[props?.pendingVisit?.scheduledType][props?.pendingVisit?.visitType]
        const selectedService = services[serviceType]

        return dispatch(addTransaction(memberId, transaction, selectedService.serviceId))
      },
      [memberId],
    )

    const handleAddPaymentClient = useCallback(async () => {
      return dispatch(addPaymentClient(memberId))
    }, [memberId])

    const handleRemoveMethod = useCallback(async () => {
      const result = await dispatch(removePaymentMethod(memberId, methodToRemove))
      setMethodToRemove(null)
      return result
    }, [dispatch, memberId, methodToRemove])

    const addErrorRole = useCallback(() => {
      const form = document.getElementById('card-form')
      const detail = form?.querySelector('span')
      if (detail) {
        detail?.setAttribute('role', 'alert')
      }
    }, [])

    const removeErrorRole = useCallback(() => {
      const form = document.getElementById('card-form')
      const detail = form?.querySelector('span')
      if (detail?.hasAttribute('role')) {
        detail?.removeAttribute('role')
      }
    }, [])

    const isPaymentsLoading = isRemoving || isFetchingMethods || isAddingMethod || isAddingClient

    return (
      <>
        <Child
          {...props}
          addPaymentMethod={handleAddMethod}
          removeMethod={setMethodToRemove}
          addTransaction={handleAddTransaction}
          fetchPaymentMethods={handleFetchMethods}
          addPaymentClient={handleAddPaymentClient}
          addErrorRole={addErrorRole}
          removeErrorRole={removeErrorRole}
        />
        {!!methodToRemove && (
          <Modal
            label="Remove payment method"
            opened
            onClose={() => setMethodToRemove(null)}
            footer={({ close }) => (
              <>
                <button className="btn btn-neutral-outlined" type="button" onClick={close}>
                  No
                </button>
                <button className="btn btn-neutral" type="button" onClick={handleRemoveMethod}>
                  {isRemoving ? <Loading shown /> : 'Yes, remove'}
                </button>
              </>
            )}
          >
            <p className="typography-body-l">Are you sure you want to remove this payment method?</p>
          </Modal>
        )}
        <Loading variant="big" shown={isPaymentsLoading} />
      </>
    )
  }
  Comp.propTypes = {
    memberId: objectIdPropTypeRequired,
    pendingVisit: PropTypes.object,
  }
  return Comp
}

export default withPaymentControl
