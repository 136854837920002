import * as Yup from 'yup'
import configBuilder from '../config-builder'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'

const api = ApiFactory.getApi(ApiTypes.member)

export const RequestSchema = Yup.object({
  // token: Yup.string().required(),
  memberId: Yup.string().required(),
  params: Yup.object({
    filter: Yup.object(),
  }).notRequired(),
})

type Request = Yup.InferType<typeof RequestSchema>

export const isValid = async (params: Request) => await RequestSchema.isValid(params)
export const validate = async (params: Request) => RequestSchema.validate(params)

export const url = ({ memberId }: Request) => `/v1/members/${memberId}/duplicates`
// TODO: remove once configBuilder is typed
//@ts-ignore
export const execute = async (params: Request) => api.get(url(params), configBuilder(params)).catch(catchApiFailure)
