import * as Yup from 'yup'
import configBuilder from '../config-builder'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'

type ParamsType = { visitType: string; answers: Array<string> }

const api = ApiFactory.getApi(ApiTypes.member)

export const RequestSchema = Yup.object().shape({
  visitType: Yup.string().required(),
  answers: Yup.array().of(Yup.string().required()).required(),
})
export const url = () => '/v1/care-routes'
export const isValid = async (params: ParamsType) => await RequestSchema.isValid(params)
export const validate = async (params: ParamsType) => RequestSchema.validate(params)
export const make = (params: ParamsType) => {
  const clean = RequestSchema.cast(params)
  return {
    form: {
      form_id: null,
      answers: clean.answers?.map((value, index) => ({
        value,
        field_id: null,
        ref: `section_${index + 1}_radio`,
      })),
    },
    service: clean.visitType,
  }
}
export const execute = async (params: ParamsType) =>
  api.post(url(), make(params), configBuilder()).catch(catchApiFailure)
