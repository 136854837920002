import { useCallback, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { ModalRef } from '@ui-components-3/ui/lib/components/Modal'
import * as VisitSelectors from 'redux/selectors/visits'
import { DisplayVisitType, TVisitType } from 'util/visit-types'
import SessionLimitReachedModal from 'components/dashboard/SessionLimitReachedModal/SessionLimitReachedModal'

const useScheduledVisitsLimit = (onDismiss: () => void) => {
  const rescheduleDialogRef = useRef<ModalRef>(null)
  const pendingScheduledVisits = useSelector(VisitSelectors.getScheduledVisitsByType)
  const [visitType, setVisitType] = useState('')

  const showRescheduleDialog = useCallback(() => {
    rescheduleDialogRef.current?.open()
  }, [])

  const setScheduledVisitType = useCallback(
    (visitType: TVisitType) => {
      if (pendingScheduledVisits[visitType] && pendingScheduledVisits[visitType].length >= 2) {
        setVisitType(visitType)
        showRescheduleDialog()
        return true
      }
      return false
    },
    [pendingScheduledVisits, showRescheduleDialog],
  )

  const LimitRescheduleDialog = useMemo(
    () => (
      <SessionLimitReachedModal
        serviceName={DisplayVisitType[visitType]}
        ref={rescheduleDialogRef}
        onConfirm={onDismiss}
      />
    ),
    [onDismiss, visitType],
  )

  return {
    setScheduledVisitType,
    rescheduleDialogRef,
    LimitRescheduleDialog,
  }
}
export default useScheduledVisitsLimit
