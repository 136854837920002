import AngleLeftIcon from '@ui-components-3/icons/lib/regular/AngleLeft'
import AngleRightIcon from '@ui-components-3/icons/lib/regular/AngleRight'
import CheckIcon from '@ui-components-3/icons/lib/regular/Check'
import Modal, { ModalRef } from '@ui-components-3/ui/lib/components/Modal'
import { useRef } from 'react'

interface Props {
  backAction?: () => void
  onCancel?: () => void
  disabled?: boolean
  isConfirm?: boolean
  leaveFlow?: boolean
}

const FooterBar = ({ backAction, onCancel, disabled, isConfirm, leaveFlow }: Props) => {
  const cancelDialogRef = useRef<ModalRef>()

  const SubmitIcon = isConfirm ? CheckIcon : AngleRightIcon

  return (
    <>
      <div className="fixed bottom-0 left-5 right-5 z-10 -mx-5 mt-auto flex h-[72px] items-center justify-between gap-3 border-t border-t-neutral-300 bg-white px-5 sm:min-h-[100px] sm:py-4 md:flex-row md:items-center lg:left-[290px] lg:right-[40px] lg:-mx-10">
        {!!onCancel && (
          <button
            className="btn btn-primary-borderless h-10 shrink-0 rounded-[10px] px-3 sm:h-14 sm:min-w-[120px] sm:rounded-2xl sm:px-4 md:mb-0 lg:min-w-[180px]"
            aria-label={'Cancel Visit'}
            onClick={() => cancelDialogRef.current?.open()}
            type="button"
          >
            Cancel
          </button>
        )}
        <div className="ml-auto flex items-center justify-end gap-3">
          {backAction && (
            <button
              type="button"
              onClick={backAction}
              className="btn btn-neutral-outlined h-10 flex-1 rounded-[10px] px-3 sm:h-14 sm:min-w-[120px] sm:rounded-2xl sm:px-4 md:mb-0 md:mr-8 lg:min-w-[180px]"
            >
              <AngleLeftIcon className="h-5 w-5" aria-hidden="true" />
              Back
            </button>
          )}
          {!leaveFlow ? (
            <button
              type="submit"
              className="btn btn-primary sm:px-s h-10 flex-1 rounded-[10px] px-3 sm:h-14 sm:min-w-[120px] sm:rounded-2xl md:mb-0 lg:min-w-[180px]"
              disabled={!!disabled}
            >
              {isConfirm ? 'Confirm' : 'Next'}
              <SubmitIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-primary h-10 min-w-[120px] flex-1 rounded-[10px] px-3 sm:h-14 sm:rounded-2xl md:mb-0 lg:min-w-[180px]"
              onClick={onCancel}
            >
              Done
            </button>
          )}
        </div>
      </div>
      {!!onCancel && (
        <Modal
          ref={cancelDialogRef}
          label="Cancel Coaching Session"
          footer={({ close }) => (
            <div className="gap-s flex w-full">
              <button type="button" className="btn btn-neutral-outlined flex-1" onClick={close}>
                No
              </button>
              <button type="button" className="btn btn-fuchsia-outlined flex-1" onClick={onCancel}>
                Cancel Session
              </button>
            </div>
          )}
        >
          <p className="typography-body-l my-xs">Are you sure you want to cancel Coaching Session?</p>
        </Modal>
      )}
    </>
  )
}

export default FooterBar
