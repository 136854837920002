import type { Dispatch } from 'redux'
import { PageKey } from '../../hooks/usePagination'
import FetchTypes from '../../util/fetch-types'
import { handleRequestFailure, makeRequest } from '../../api/helpers'
import { setFetching } from '../slices/api'
import { PaginatedResponse } from '../../types/api'
import {
  putExperimentalRandomizedComponentIds,
  putExperimentalRandomizedJourneyIds,
  putJourney,
  putJourneys,
  putSelfCareRandomizedContent,
} from '../slices/explore'
import Component, { ComponentReaction } from '../../types/explore/component'
import {
  clearReaction,
  putReaction,
  putCategories,
  putCategory,
  putCategoryComponents,
  putComponent,
  putComponents,
} from '../slices/explore'
import { Category } from '../../types/explore/category'
import { Journey } from '../../types/explore/journey'
import { ReactionResponse } from '../../types/reaction/reaction'
import { SelfCaresResponse } from 'types/explore/selfCares'
import { shuffle } from 'lodash'

export const getSelfCareComponents =
  ({ page, limit, sort, filter, isForCategory = false }: PageKey & { isForCategory?: boolean }) =>
  async (dispatch: Dispatch): Promise<{ success: boolean }> => {
    dispatch(setFetching({ fetchType: FetchTypes.getSccComponents, isFetching: true }))
    let success = false
    const params = { params: { limit, page, sort, filter } }
    const handleSuccess = (data: PaginatedResponse<Component[]>) => {
      success = true
      const {
        paginated,
        meta: { results },
      } = data

      // TODO: BIT-4654 Self-care randomization experiment - can be removed once experiment is complete
      const randomizedComponentIds = shuffle(paginated.map((comp) => comp.componentId))
      dispatch(putExperimentalRandomizedComponentIds(randomizedComponentIds))

      if (isForCategory) {
        dispatch(putCategoryComponents({ components: paginated, categoryId: filter?.categoryIds, total: results }))
      } else {
        dispatch(putComponents({ components: paginated, total: results }))
      }
    }
    await makeRequest(FetchTypes.getSccComponents, params, handleSuccess, handleRequestFailure(dispatch))

    dispatch(setFetching({ fetchType: FetchTypes.getSccComponents, isFetching: false }))
    return { success }
  }

export const getSelfCareComponent =
  (componentId: string) =>
  async (dispatch: Dispatch): Promise<{ success: boolean }> => {
    dispatch(setFetching({ fetchType: FetchTypes.getSccComponent, isFetching: true }))
    let success = false
    const params = { componentId }
    const handleSuccess = (data: Component) => {
      success = true
      dispatch(putComponent({ component: data }))
    }
    await makeRequest(FetchTypes.getSccComponent, params, handleSuccess, handleRequestFailure(dispatch))

    dispatch(setFetching({ fetchType: FetchTypes.getSccComponent, isFetching: false }))
    return { success }
  }

export const getSelfCareCategories =
  ({ page, limit, sort, filter }: PageKey) =>
  async (dispatch: Dispatch): Promise<{ success: boolean }> => {
    dispatch(setFetching({ fetchType: FetchTypes.getSccCategories, isFetching: true }))
    let success = false
    const include = {
      // TODO: change component_type to componentType and fix config-builder
      components: {
        fields: ['description', 'title', 'thumbnails', 'component_type', 'status', 'group_types'],
        size: 6,
      },
    }
    const params = { params: { limit, page, sort, filter, include } }
    const handleSuccess = (data: PaginatedResponse<Component[]>) => {
      success = true
      const {
        paginated,
        meta: { results },
      } = data
      dispatch(putCategories({ categories: paginated, total: results }))
    }
    await makeRequest(FetchTypes.getSccCategories, params, handleSuccess, handleRequestFailure(dispatch))

    dispatch(setFetching({ fetchType: FetchTypes.getSccCategories, isFetching: false }))
    return { success }
  }

export const getSelfCareCategory =
  (categoryId: string) =>
  async (dispatch: Dispatch): Promise<{ success: boolean }> => {
    dispatch(setFetching({ fetchType: FetchTypes.getSccCategory, isFetching: true }))
    let success = false
    const params = { categoryId }
    const handleSuccess = (data: Category) => {
      success = true
      dispatch(putCategory({ category: data }))
    }
    await makeRequest(FetchTypes.getSccCategory, params, handleSuccess, handleRequestFailure(dispatch))

    dispatch(setFetching({ fetchType: FetchTypes.getSccCategory, isFetching: false }))
    return { success }
  }

export const toggleComponentLike =
  (componentId: Component['componentId']) =>
  async (dispatch: Dispatch): Promise<{ success: boolean }> => {
    dispatch(setFetching({ fetchType: FetchTypes.toggleComponentLike, isFetching: true }))
    let success = false
    const params = { componentId, type: 'like' }
    const handleSuccess = (data: ComponentReaction) => {
      success = true
      if (data.componentId) {
        dispatch(putReaction({ reaction: data }))
      } else {
        dispatch(clearReaction(params))
      }
    }
    await makeRequest(FetchTypes.toggleComponentLike, params, handleSuccess, handleRequestFailure(dispatch))
    dispatch(setFetching({ fetchType: FetchTypes.toggleComponentLike, isFetching: false }))
    return { success }
  }

export const getSelfCareJourneys =
  ({ page, limit, sort, filter }: PageKey) =>
  async (dispatch: Dispatch): Promise<{ success: boolean }> => {
    dispatch(setFetching({ fetchType: FetchTypes.getSccJourneys, isFetching: true }))
    let success = false
    const params = { params: { limit, page, sort, filter } }
    const handleSuccess = (data: PaginatedResponse<Journey[]>) => {
      success = true
      const {
        paginated,
        meta: { results },
      } = data
      dispatch(putJourneys({ journeys: paginated, total: results }))

      // TODO: BIT-4654 Self-care randomization experiment - can be removed once experiment is complete
      const randomizedJourneyIds = shuffle(paginated.map((journey) => journey.journeyId))
      dispatch(putExperimentalRandomizedJourneyIds(randomizedJourneyIds))
    }
    await makeRequest(FetchTypes.getSccJourneys, params, handleSuccess, handleRequestFailure(dispatch))

    dispatch(setFetching({ fetchType: FetchTypes.getSccJourneys, isFetching: false }))
    return { success }
  }

export const getSelfCareRandomizedContent =
  ({ randomize }: { randomize: boolean }) =>
  async (dispatch: Dispatch): Promise<{ success: boolean }> => {
    dispatch(setFetching({ fetchType: FetchTypes.getSelfCares, isFetching: true }))
    let success = false
    // TODO: convert randomize_count to camelCase after adding support for it in config builder
    const params = { params: { randomize, randomize_count: '8' } }
    const handleSuccess = async (response: SelfCaresResponse) => {
      success = true
      if (response && response.randomized) {
        dispatch(putSelfCareRandomizedContent({ selfCareRandomizedContent: response.randomized }))
      } else {
        console.error('Unexpected response structure:', response)
      }
    }
    await makeRequest(FetchTypes.getSelfCares, params, handleSuccess, handleRequestFailure(dispatch))

    dispatch(setFetching({ fetchType: FetchTypes.getSelfCares, isFetching: false }))
    return { success }
  }

export const getSelfCareJourney =
  (journeyId: string) =>
  async (dispatch: Dispatch): Promise<{ success: boolean }> => {
    dispatch(setFetching({ fetchType: FetchTypes.getSccJourney, isFetching: true }))
    let success = false
    const params = { journeyId }
    const handleSuccess = (data: Journey) => {
      success = true
      dispatch(putJourney({ journey: data }))
    }
    await makeRequest(FetchTypes.getSccJourney, params, handleSuccess, handleRequestFailure(dispatch))

    dispatch(setFetching({ fetchType: FetchTypes.getSccJourney, isFetching: false }))
    return { success }
  }

export const addSelfCareJourneyComponentView =
  (componentId: string, journeyId: string) =>
  async (dispatch: Dispatch): Promise<{ success: boolean }> => {
    dispatch(setFetching({ fetchType: FetchTypes.addSccJourneyComponentView, isFetching: true }))
    let success = false
    const params = { journeyId, componentId }
    const handleSuccess = (data: ReactionResponse) => {
      success = true
    }
    await makeRequest(FetchTypes.addSccJourneyComponentView, params, handleSuccess, handleRequestFailure(dispatch))

    dispatch(setFetching({ fetchType: FetchTypes.addSccJourneyComponentView, isFetching: false }))
    return { success }
  }
