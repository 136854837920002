import { isEmpty } from 'lodash'
import { createSelector } from 'reselect'
import VisitStatus from '../../util/visit-status'
import VisitScheduleType from '../../util/visit-schedule-types'

export const getRecords = (state) => state.visit.records
export const getMeta = (state) => state.visit.meta
export const getPendingRecords = (state) => state.visit.pending.records
export const getPendingMeta = (state) => state.visit.pending.meta
export const getRecordArray = createSelector(getRecords, (records = {}) => Object.values(records))
export const getPendingRecordArray = createSelector(getPendingRecords, (records = {}) => Object.values(records))

export const getMemberVisitMeta = (memberId) => createSelector(getMeta, (meta = {}) => meta[memberId])
export const getMemberPendingVisitMeta = (memberId) => createSelector(getPendingMeta, (meta = {}) => meta[memberId])

export const getVisit = (visitId) => createSelector(getRecords, (records = {}) => records[visitId])

export const getVisitByPendingId = (pendingVisitId) =>
  createSelector(getRecords, (records = {}) => {
    return Object.values(records).find((visit) => visit?.pendingVisitId === pendingVisitId)
  })

export const getMemberVisits = (memberId) =>
  createSelector(getRecords, (records = {}) => {
    return Object.values(records).filter((rec) => !!rec?.visitId && rec.memberId === memberId)
  })

export const getAllVisits = createSelector(getRecords, (records = {}) => {
  const visits = Object.values(records).filter((rec) => !!rec?.visitId)
  visits.sort((a, b) => {
    if (a.createdAt > b.createdAt) return -1
    return 1
  })
  return visits
})
export const getVisitProviderIds = createSelector(getAllVisits, (records = []) =>
  records?.map(({ providerId }) => providerId),
)
export const getAllCompletedPendingVisits = createSelector(getPendingRecords, (records = {}) => {
  const completedPendingVisits = Object.values(records).filter((rec) => rec.status === 'completed')
  return completedPendingVisits
})

export const getAllCompletedPendingVisitIds = createSelector(getAllCompletedPendingVisits, (pendingVisits = []) => {
  const pendingVisitIds = pendingVisits.map((visit) => visit.pendingVisitId)
  return pendingVisitIds
})

export const getVisitsWithPendingVisitIds = (pendingVisitIds) =>
  createSelector(getRecords, (records = {}) => {
    const pendingVisitMap = pendingVisitIds.reduce((acc, pendingVisitId) => {
      acc[pendingVisitId] = true
      return acc
    }, {})
    const visits = Object.values(records).filter((rec) => pendingVisitMap[rec.pendingVisitId])
    return visits
  })

export const getVisitPage = (memberId) => createSelector(getMemberVisitMeta(memberId), (meta = {}) => meta.visitPage)

export const getVisitRecordCount = (memberId) =>
  createSelector(getMemberVisits(memberId), (array = []) => array.length - 1)

export const getPendingVisitRecordCount = (memberId) =>
  createSelector(getMemberVisits(memberId), (array = []) => array.length)

export const getVisitPageLastFetch = (memberId, page) =>
  createSelector(getMemberVisitMeta(memberId), (meta = {}) => !!meta.pageFetches && meta.pageFetches[page])

export const getVisitCount = (memberId) => createSelector(getMemberVisitMeta(memberId), (meta = {}) => meta.visitCount)

export const getAllVisitCount = createSelector(getMeta, (meta = {}) => {
  const regex = new RegExp(/^([0-9a-fA-F]{24})$/) // mongo id
  const memberIds = Object.keys(meta).filter((key) => !!key.match(regex))
  return memberIds.reduce((acc, id) => acc + meta[id].visitCount, 0)
})

export const getPendingVisitPage = (memberId) =>
  createSelector(getMemberPendingVisitMeta(memberId), (meta = {}) => meta.visitPage)

export const getPendingVisitPageLastFetch = (memberId, page) =>
  createSelector(getMemberPendingVisitMeta(memberId), (meta = {}) => !!meta.pageFetches && meta.pageFetches[page])

export const getPendingVisitCount = (memberId) =>
  createSelector(getMemberPendingVisitMeta(memberId), (meta = {}) => meta.visitCount)

export const getAllPendingVisitCount = createSelector(getPendingMeta, (meta = {}) => {
  const regex = new RegExp(/^([0-9a-fA-F]{24})$/) // mongo id
  const memberIds = Object.keys(meta).filter((key) => !!key.match(regex))
  return memberIds.reduce((acc, id) => acc + meta[id].visitCount, 0)
})

export const getMemberVisitCount = (memberId) =>
  createSelector(getMemberVisits(memberId), (visits = []) => visits.length)

export const getPendingVisit = (pendingVisitId) =>
  createSelector(getPendingRecords, (records = {}) => records[pendingVisitId])

export const getMemberPendingVisits = (memberId) =>
  createSelector(getPendingRecords, (records = {}) => Object.values(records).filter((rec) => rec.memberId === memberId))

export const getMemberLastPendingVisit = (memberId) =>
  createSelector(getPendingRecords, (records = {}) =>
    Object.values(records)
      .filter((rec) => rec.memberId === memberId && rec.completedAt)
      .reduce((a, b) => (a.completedAt > b.completedAt ? a : b), {}),
  )
const incompleteStatuses = [
  VisitStatus.pending,
  VisitStatus.inProgress,
  VisitStatus.cancelled,
  VisitStatus.pendingConfirmation,
]

export const getMemberIncompletePendingVisitMap = (memberId) =>
  createSelector(getMemberPendingVisits(memberId), (pendingVisits = []) =>
    pendingVisits.reduce((acc, pv) => {
      if (incompleteStatuses.includes(pv.status)) {
        acc[pv.pendingVisitId] = pv
      }
      return acc
    }, {}),
  )

export const getMemberScheduledVisits = (memberId) =>
  createSelector(getMemberPendingVisits(memberId), (visits = []) =>
    visits.filter((visit) => visit.scheduledType === 'scheduled' && visit.status === 'pending'),
  )

export const getScheduledVisits = createSelector(getPendingRecordArray, (visits = []) => {
  const filtered = visits.filter((visit) => visit.scheduledType === 'scheduled' && visit.status === 'pending')
  filtered.sort((a, b) => (a.scheduledAt > b.scheduledAt ? 1 : -1))
  return filtered
})

export const getScheduledVisitsByType = createSelector(getScheduledVisits, (visits = {}) => {
  const pendingByType = visits.reduce((acc, visit) => {
    if (!acc[visit.visitType]) {
      acc[visit.visitType] = [visit]
    } else {
      acc[visit.visitType].push(visit)
    }
    return acc
  }, {})
  return pendingByType
})

export const getMemberImmediateVisits = (memberId) =>
  createSelector(getMemberPendingVisits(memberId), (visits = []) =>
    visits.filter((visit) => visit.scheduledType === 'now' && visit.status === 'pending'),
  )

export const getMemberJoinableVisits = (memberId) =>
  createSelector(getMemberImmediateVisits(memberId), (visits = []) => visits.filter((visit) => !!visit.providerId))

export const getInProgressVisit = createSelector(getPendingRecordArray, (visits = []) => {
  const inProgressVisits = visits.filter((visit) => visit.status === 'in-progress')
  if (!!inProgressVisits && inProgressVisits.length > 0) {
    const [first] = inProgressVisits
    return first
  }
  return null
})

export const getActiveVisit = createSelector(getPendingRecordArray, (visits = []) => {
  const isInProgress = (status) => status === VisitStatus.inProgress
  const isPending = (status) => status === VisitStatus.pending
  const isNowVisit = (scheduledType) => scheduledType === VisitScheduleType.now
  const { inProgress, pending } = visits.reduce(
    (acc, visit) => {
      if (isInProgress(visit.status)) {
        acc.inProgress.push(visit)
      } else if (isPending(visit.status) && isNowVisit(visit.scheduledType)) {
        acc.pending.push(visit)
      }
      return acc
    },
    { inProgress: [], pending: [] },
  )
  if (!isEmpty(inProgress)) {
    inProgress.sort((a, b) => (a.scheduledAt > b.scheduledAt ? -1 : 1))
    const [first] = inProgress
    return first
  }
  if (!isEmpty(pending)) {
    pending.sort((a, b) => (a.scheduledAt > b.scheduledAt ? -1 : 1))
    const [first] = pending
    return first
  }
  return null
})

export const getMemberActiveVisit = (memberId) =>
  createSelector(getMemberPendingVisits(memberId), (visits = []) => {
    const inProgressVisits = visits.filter((visit) => visit.status === 'in-progress')
    if (!!inProgressVisits && inProgressVisits.length > 0) {
      const [first] = inProgressVisits
      return first
    }
    return null
  })

export const getMemberUnfilledRx = (memberId) =>
  createSelector(getMemberVisits(memberId), (visits = []) => {
    return visits.reduce((acc, visit) => {
      if (!isEmpty(visit.rxs)) {
        return [...acc, ...visit.rxs.filter((rx) => rx.status === 'submitted')]
      }
      return acc
    }, [])
  })

export const getCreateVisit = createSelector(getRecords, (records = {}) => records.create)
