import * as Yup from 'yup'
import configBuilder from '../config-builder'
import ApiFactory, { ApiTypes } from '../../index'
import catchApiFailure from '../catch-api-error'

const api = ApiFactory.getApi(ApiTypes.provider)

const RequestSchema = Yup.object().shape({
  params: Yup.object()
    .shape({
      filter: Yup.object().shape({
        externalId: Yup.string(),
        'notifications.snooze': Yup.string(),
        'licenses.state': Yup.string(),
        'licenses.type': Yup.string(),
      }),
      sort: Yup.object(),
      page: Yup.number().required(),
      limit: Yup.number().required(),
    })
    .required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const url = () => '/v2/providers'
export const execute = async (params) => api.get(url(), configBuilder(params)).catch(catchApiFailure)
