import Modal, { ModalRef } from '@ui-components-3/ui/lib/components/Modal'
import ExternalLink from 'components/common/ExternalLink'
import { forwardRef, useMemo } from 'react'
import { TERMS_CONDITIONS_LINK } from 'util/constants'

interface PaymentDialogProps {
  sessionOrVisit?: string
}

const PaymentInfoDialog = forwardRef<ModalRef, PaymentDialogProps>((_props, ref) => {
  const { sessionOrVisit = 'visit' } = _props

  return (
    <Modal label="Payment Required" size="m" ref={ref}>
      <p>
        Please note that payment is due at the time of service and TimelyCare does not bill insurance companies or third
        party payors for services rendered. You may request a copy of a superbill following a {sessionOrVisit} for which
        you may then submit to your insurance company or third party payor for reimbursement. Please note that services
        rendered by TimelyCare may not be covered by insurance. If you plan to utilize insurance benefits for additional
        TimelyCare {sessionOrVisit}s, we suggest that you contact your insurance company or third party payor prior to
        scheduling a Telehealth Consultation. Please review the TimelyCare{' '}
        <ExternalLink className="text-link" href={TERMS_CONDITIONS_LINK}>
          Terms & Conditions
        </ExternalLink>{' '}
        for more information.
      </p>
    </Modal>
  )
})

export default PaymentInfoDialog
