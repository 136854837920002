import withForm from 'hoc/withForm'
import FormikCheckbox from '@ui-components-3/compat/lib/components/FormikCheckbox'
import FormikFormattedTextField from '@ui-components-3/compat/lib/components/FormikFormattedTextField'
import FormikRadioGroup from '@ui-components-3/compat/lib/components/FormikRadioGroup'

import { cleanPhoneNumberInput, phoneInputFormat } from 'util/formatters'
import TestIds from 'util/TestIds'
import SMSSubText from 'components/common/SMSSubText'
import { Formik, FormikHelpers } from 'formik'
import PhoneSchema, { PhoneFormValues } from 'schemas/PhoneSchema'
import { forwardRef } from 'react'
import Modal, { ModalRef } from '@ui-components-3/ui/lib/components/Modal'

const options = [
  { label: 'Mobile', value: 'mobile' },
  { label: 'Home', value: 'home' },
  { label: 'Office', value: 'office' },
]

const initialValues = {
  number: '',
  type: 'mobile',
  acceptSms: true,
  preferred: true,
}

type PhoneFormDialogProps = {
  onSubmit: (values: PhoneFormValues, form: FormikHelpers<PhoneFormValues>) => void
}

const PhoneFormDialog = forwardRef<ModalRef, PhoneFormDialogProps>(({ onSubmit }, ref) => {
  return (
    <Formik initialValues={initialValues} validationSchema={PhoneSchema} onSubmit={onSubmit}>
      {(renderProps) => {
        const { dirty, isValid, handleSubmit, resetForm, isSubmitting } = renderProps

        return (
          <Modal
            ref={ref}
            label="New Phone Number"
            onClose={() => resetForm()}
            footer={({ idPrefix, close }) => (
              <>
                <button
                  className="btn btn-neutral-outlined"
                  data-testid={TestIds.phone.button.cancel}
                  onClick={close}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  data-testid={TestIds.phone.button.save}
                  disabled={!dirty || !isValid || isSubmitting}
                  type="submit"
                  form={`${idPrefix}form`}
                >
                  Save Number
                </button>
              </>
            )}
          >
            {({ idPrefix }) => (
              <form
                onSubmit={handleSubmit}
                className="flex flex-col"
                data-testid={TestIds.newVisit.view.phoneForm}
                id={`${idPrefix}form`}
              >
                <p className="typography-body mb-s font-medium text-neutral-700">*Phone number is a required field</p>
                <FormikFormattedTextField
                  fullWidth
                  data-testid={TestIds.newVisit.input.phoneForm}
                  formatText={phoneInputFormat}
                  unformatText={cleanPhoneNumberInput}
                  autoComplete="tel"
                  label={'Phone Number*'}
                  name={'number'}
                  className="mb-s"
                />
                <FormikRadioGroup
                  label={'Type'}
                  name={'type'}
                  options={options}
                  data-testid={TestIds.newVisit.input.phoneType}
                  className="mb-s"
                />
                <FormikCheckbox
                  name={'acceptSms'}
                  data-testid={TestIds.newVisit.input.acceptSms}
                  label={'Allow SMS messaging to this number'}
                />
                <SMSSubText
                  className="typography-body-l mt-2.5 text-neutral-500"
                  data-testid={TestIds.newVisit.label.smsExplanation}
                />
              </form>
            )}
          </Modal>
        )
      }}
    </Formik>
  )
})

export default PhoneFormDialog
