import capitalize from 'lodash/capitalize'
import type { ConditionType } from 'types/medical/form'

export const printableConditionName = (condition: { name: string; condition: string }) => {
  if (condition.name === 'Other') {
    if (condition.condition.toLowerCase() !== 'other') {
      return `${condition.name} ${capitalize(condition.condition)}`
    }
  }
  return condition.name
}

type MinimalCondition = {
  /** refers to parent category */
  conditionId: string
  /** refers to parent category or subtype */
  condition: string
}

export const getUniqueConditionPrimitiveKeyRaw = (
  conditionId: MinimalCondition['conditionId'],
  condition: MinimalCondition['condition'],
) => `${conditionId}/${condition}`

export const getUniqueConditionPrimitiveKey = <T extends MinimalCondition>(condition: T) =>
  getUniqueConditionPrimitiveKeyRaw(condition.conditionId, condition.condition)

export const mapConditionsToPrimitiveValues = <T extends MinimalCondition[]>(conditions: T | undefined) =>
  conditions?.map((c) => getUniqueConditionPrimitiveKey(c)) || []

export const possibleConditionsToMap = <T extends ConditionType[], K>(pc: T, extra: K) => {
  const result = new Map<string, T[0] & K>()

  for (const c of pc) {
    const key = getUniqueConditionPrimitiveKey(c)
    if (result.has(key)) {
      console.error('Condition not unique: ', { key, condition: c, conditions: pc })
    }
    result.set(key, { ...extra, condition: c.condition, conditionId: c.conditionId, name: c.name })
    if ('types' in c && Array.isArray(c.types)) {
      for (const t of c.types) {
        const key = getUniqueConditionPrimitiveKeyRaw(c.conditionId, t.key)
        if (result.has(key)) {
          console.error('Condition not unique: ', { key, condition: t.key, conditions: pc })
        }
        result.set(key, { ...extra, condition: t.key, conditionId: c.conditionId, name: c.name })
      }
    }
  }

  return result
}

export const mapConditionsBack = <T extends ConditionType[], K>(
  possibleConditions: T,
  extra: K,
  conditions: ReturnType<typeof getUniqueConditionPrimitiveKey>[] | undefined,
) => {
  const m = possibleConditionsToMap(possibleConditions, extra)
  return (
    conditions?.flatMap((v) => {
      const result = m.get(v)
      if (result) {
        return [result]
      }

      if (process.env.NODE_ENV !== 'production') {
        console.error('Condition not found: ', { v, m })
      }

      return []
    }) || []
  )
}
