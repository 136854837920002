import React, { ReactNode } from 'react'
import { ContactSupportImage, ContactSupportScene } from '../ContactSupportWrapper/ContactSupportWrappers'
import Notice from '@ui-components-3/ui/lib/components/Notice'
import ContactSupportImg from 'assets/images/contact_support_image.svg'
import Phone from '@ui-components-3/icons/lib/regular/Phone'
import { SUPPORT_NUMBER } from 'util/support'
import { useHistory } from 'react-router'
import { DASHBOARD } from 'routes/paths'

const ContactSupportStep = ({ children }: { children: (values: { disabled: boolean }) => ReactNode }) => {
  const history = useHistory()
  const phoneNumber = '(833)-4-TIMELY'
  return (
    <ContactSupportScene>
      <p className="typography-h5">We need some more information to continue scheduling this visit</p>
      <p className="typography-body mb-4">Please call our customer support team for assistance.</p>
      <ContactSupportImage>
        <img className="h-auto w-full object-contain" src={ContactSupportImg} alt="" />
      </ContactSupportImage>
      <div className="mb-8 md:max-w-[560px] lg:max-w-[560px]">
        <Notice variant="info">
          {
            'Before enabling certain services, our team needs additional information in order to comply with local regulations.'
          }
        </Notice>
      </div>
      <a
        onClick={() => {
          history.push(DASHBOARD)
        }}
        href={`tel:${SUPPORT_NUMBER}`}
        className="btn btn-primary mb-8 flex h-12 w-full max-w-[560px] items-center justify-center rounded-xl px-4 py-3 text-base sm:h-14 sm:max-w-[560px] sm:rounded-2xl md:max-w-[560px] lg:max-w-[560px]"
      >
        <Phone aria-hidden="true" className="mr-2 h-[21px] w-[21px]" />
        {`Call Customer Support - ${phoneNumber}`}
      </a>
      {children({ disabled: true })}
    </ContactSupportScene>
  )
}

export default ContactSupportStep
