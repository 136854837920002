import { useMemo } from 'react'
import { isEmpty } from 'lodash'
import { capEveryWord, getPharmacySpecialties } from '../../../util/pharmacies'
import PrescriptionIcon from '@ui-components-3/icons/lib/regular/Prescription'
import { phoneFormat } from '../../../util/formatters'
import TestIds from '../../../util/TestIds'
import LocationIcon from '@ui-components-3/icons/lib/solid/LocationDot'
import { PharmacyModel } from 'types/visit/pendingVisit'
import ExternalLink from 'components/common/ExternalLink'

type PharmacySearchListItemProps = {
  pharmacy: PharmacyModel
  'data-testid'?: string
  index?: number
  onAddClick: () => Promise<any>
}

const PharmacySearchListItem = (props: PharmacySearchListItemProps) => {
  const { pharmacy, index } = props

  const streetAddress = useMemo(() => {
    if (!pharmacy) return ''
    const { address1, address2 } = pharmacy
    let streetAddress = address1
    if (address2) streetAddress += ` ${address2}`
    return capEveryWord(streetAddress)
  }, [pharmacy])

  const city = useMemo(() => {
    if (!pharmacy) return ''
    return capEveryWord(pharmacy.city)
  }, [pharmacy])

  const mapUrl = useMemo(() => {
    if (!pharmacy || !pharmacy.point || isEmpty(pharmacy.point.coordinates)) {
      return null
    }
    const [lng, lat] = pharmacy.point.coordinates
    return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}&label=${pharmacy.storeName}`
  }, [pharmacy])

  const specialties = useMemo(() => {
    return getPharmacySpecialties(pharmacy).split(', ')
  }, [pharmacy])

  return (
    <div className="my-4">
      <div className="mb-4 flex" data-testid={props['data-testid']}>
        <span data-testid={TestIds.newVisit.label.pharmacyItem.avatar(index)} className="mr-4">
          <PrescriptionIcon className="text-primary-600 h-10 w-10" aria-hidden="true" />
        </span>
        <div className="flex-1">
          <h2 className="typography-h5 text-neutral-800" data-testid={TestIds.newVisit.label.pharmacyItem.name(index)}>
            {pharmacy?.storeName}
          </h2>
          {!!specialties?.length && (
            <p
              className="mb-2 flex flex-wrap items-center"
              data-testid={TestIds.newVisit.label.pharmacyItem.specialty(index)}
            >
              {specialties.map((specialty, i) => (
                <span
                  key={String(i)}
                  className="py-0.2 typography-body bg-pantone-300 text-pantone-800 mb-1 mr-1 inline-block rounded-[18px] px-2.5 font-semibold"
                >
                  {specialty}
                </span>
              ))}
            </p>
          )}
          <p className="typography-body-l text-neutral-600">
            <div data-testid={TestIds.newVisit.label.pharmacyItem.street(index)}>{streetAddress}</div>
            <div data-testid={TestIds.newVisit.label.pharmacyItem.city(index)}>
              {city}, {pharmacy?.state} {pharmacy?.zip}
            </div>
            <div data-testid={TestIds.newVisit.label.pharmacyItem.phone(index)}>{phoneFormat(pharmacy?.phone)}</div>
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        <ExternalLink
          aria-disabled={!mapUrl}
          className="btn-small btn-primary-outlined"
          href={mapUrl}
          data-testid={TestIds.newVisit.button.viewMap(index)}
        >
          <LocationIcon className="mr-2 h-[24px] w-[18px]" aria-hidden="true" />
          View Map
        </ExternalLink>
        <button
          className="btn-small btn-primary"
          onClick={props.onAddClick}
          data-testid={TestIds.newVisit.button.addPharmacy(index)}
          type="button"
        >
          Add Pharmacy
        </button>
      </div>
    </div>
  )
}

export default PharmacySearchListItem
