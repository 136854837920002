import { Suspense, lazy } from 'react'
import { Switch } from 'react-router-dom'
import routeTree from '../index'
import RouteBranch from 'routes/RouteBranch'
import ScrollToTop from 'core/ScrollToTop'
import withPusher from 'hoc/withPusher'
import A11yMessage from 'components/common/A11yMessage'
import Loading from '@ui-components-3/ui/lib/components/Loading'

const ConnectedAlert = lazy(() => import('core/ConnectedAlert'))
const PushService = lazy(() => import('core/PushService'))

const Root = (pusherProps) => {
  return (
    <>
      <A11yMessage />
      <ScrollToTop />
      <Switch>
        {routeTree.map(({ path, ...route }) => (
          <RouteBranch key={path} path={path} {...pusherProps} {...route} />
        ))}
      </Switch>
      <Suspense fallback={<Loading variant="big" />}>
        <PushService />
        <ConnectedAlert />
      </Suspense>
    </>
  )
}

export default withPusher(Root)
