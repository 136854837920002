import { createSlice } from '@reduxjs/toolkit'
import { Category } from '../../types/explore/category'
import Component from '../../types/explore/component'
import { Journey } from '../../types/explore/journey'
import { SelfCareContent } from 'types/explore/selfCares'

export type ExploreState = {
  meta: {
    totalComponents: number
    totalCategories: number
    totalCategoryComponents: number
    totalJourneys: number
  }
  categoryComponents: {
    [categoryId: string]: {
      [componentId: string]: Component
    }
  }
  components: {
    [componentId: string]: Component
  }
  categories: {
    [categoryId: string]: Category
  }
  journeys: {
    [journeyId: string]: Journey
  }
  selfCareRandomizedContent: SelfCareContent[]
  lastJourneyComponentViewed: {
    [journeyId: string]: Component['componentId']
  }
  // TODO: BIT-4654 Self-care randomization experiment - can be removed once experiment is complete
  experimentalRandomizedJourneyIds: string[]
  experimentalRandomizedComponentIds: string[]
}

const defaultState: ExploreState = {
  meta: {
    totalComponents: 0,
    totalCategories: 0,
    totalCategoryComponents: 0,
    totalJourneys: 0,
  },
  categoryComponents: {
    /*
      [category_id]: {
        [component_id]: component
      }
    */
  },
  components: {
    // [component_id]: component
  },
  categories: {
    // [category_id]: category
  },
  journeys: {
    // [journey_id]: journey
  },
  selfCareRandomizedContent: [],
  lastJourneyComponentViewed: {
    // [journey_id]: component_id
  },
  // TODO: BIT-4654 Self-care randomization experiment - can be removed once experiment is complete
  experimentalRandomizedJourneyIds: [],
  experimentalRandomizedComponentIds: [],
}

const getComponentId = (component: Component) => component.componentId
const getCategoryId = (category: Category) => category.categoryId
const getJourneyId = (journey: Journey) => journey.journeyId

const slice = createSlice({
  name: 'explore',
  initialState: defaultState,
  reducers: {
    putComponent: (state, action) => {
      const { component } = action.payload
      const id = getComponentId(component)
      state.components[id] = component
    },
    putComponents: (state, action) => {
      const { components, total } = action.payload
      components.forEach((comp: Component) => {
        const id = getComponentId(comp)
        state.components[id] = comp
      })
      state.meta.totalComponents = total
    },
    clearComponent: (state, action) => {
      const { componentId } = action.payload
      delete state.components[componentId]
    },
    clearComponents: (state) => {
      state.components = { ...defaultState.components }
    },
    putCategory: (state, action) => {
      const { category } = action.payload
      const id = getCategoryId(category)
      state.categories[id] = category
    },
    putCategories: (state, action) => {
      const { categories, total } = action.payload
      categories.forEach((category: Category) => {
        const id = getCategoryId(category)
        state.categories[id] = category
      })
      state.meta.totalCategories = total
    },
    putCategoryComponents: (state, action) => {
      const { components, total, categoryId } = action.payload
      state.categoryComponents[categoryId] = { ...state.categoryComponents[categoryId] }
      components.forEach((comp: Component) => {
        const id = getComponentId(comp)
        state.categoryComponents[categoryId][id] = comp
      })
      state.meta.totalCategoryComponents = total
    },
    putReaction: (state, action) => {
      const { reaction } = action.payload
      const reactionsCopy = state.components[reaction.componentId].reactions.map((reaction) => ({ ...reaction }))
      reactionsCopy.push(reaction)
      state.components[reaction.componentId].reactions = reactionsCopy
    },
    clearReaction: (state, action) => {
      const { componentId, type } = action.payload
      if (state.components[componentId].reactions.length > 0) {
        const newReactions = state.components[componentId].reactions.filter((r: any) => r.type !== type)
        if (newReactions) {
          state.components[componentId].reactions = newReactions
        }
      }
    },
    clearCategory: (state, action) => {
      const { categoryId } = action.payload
      delete state.categories[categoryId]
    },
    clearCategories: (state) => {
      state.categories = { ...defaultState.categories }
    },
    putJourney: (state, action) => {
      const { journey } = action.payload
      const id = getJourneyId(journey)
      state.journeys[id] = journey
    },
    putJourneys: (state, action) => {
      const { journeys, total } = action.payload
      journeys.forEach((journey: Journey) => {
        const id = getJourneyId(journey)
        state.journeys[id] = journey
      })
      state.meta.totalJourneys = total
    },
    putSelfCareRandomizedContent: (state, action) => {
      state.selfCareRandomizedContent = action.payload.selfCareRandomizedContent
    },
    clearSelfCareRandomizedContent: (state) => {
      state.selfCareRandomizedContent = []
    },
    clearJourney: (state, action) => {
      const { journeyId } = action.payload
      delete state.journeys[journeyId]
    },
    clearJourneys: (state) => {
      state.journeys = { ...defaultState.journeys }
    },
    putLastJourneyComponentViewed: (state, action) => {
      const { componentId, journeyId } = action.payload
      state.lastJourneyComponentViewed[journeyId] = componentId
    },
    clearLastJourneyComponentViewed: (state, action) => {
      const { journeyId } = action.payload
      delete state.lastJourneyComponentViewed[journeyId]
    },
    // TODO: BIT-4654 Self-care randomization experiment - can be removed once experiment is complete
    putExperimentalRandomizedJourneyIds: (state, action) => {
      state.experimentalRandomizedJourneyIds = [
        ...new Set([...state.experimentalRandomizedJourneyIds, ...action.payload]),
      ]
    },
    clearExperimentalRandomizedJourneyIds: (state) => {
      state.experimentalRandomizedJourneyIds = defaultState.experimentalRandomizedJourneyIds
    },
    putExperimentalRandomizedComponentIds: (state, action) => {
      const newIds = action.payload
      state.experimentalRandomizedComponentIds = [...new Set([...state.experimentalRandomizedComponentIds, ...newIds])]
    },
    clearExperimentalRandomizedComponentIds: (state) => {
      state.experimentalRandomizedComponentIds = defaultState.experimentalRandomizedComponentIds
    },
  },
})

const { actions, reducer } = slice
export const {
  putComponent,
  putComponents,
  clearComponent,
  clearComponents,
  putCategories,
  putCategory,
  putReaction,
  clearCategory,
  clearCategories,
  putCategoryComponents,
  clearReaction,
  putJourney,
  putJourneys,
  putSelfCareRandomizedContent,
  clearSelfCareRandomizedContent,
  clearJourney,
  clearJourneys,
  putLastJourneyComponentViewed,
  clearLastJourneyComponentViewed,
  putExperimentalRandomizedJourneyIds,
  clearExperimentalRandomizedJourneyIds,
  putExperimentalRandomizedComponentIds,
  clearExperimentalRandomizedComponentIds,
} = actions
export default reducer
