import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
const api = ApiFactory.getApi(ApiTypes.provider)

const RequestSchema = Yup.object().shape({
  prescriptionId: Yup.string().label('Prescription ID').required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const url = ({ prescriptionId }) => `/v1/prescriptions/${prescriptionId}`
export const execute = async (params) => api.get(url(params)).catch(catchApiFailure)
