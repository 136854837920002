import Notice from '@ui-components-3/ui/lib/components/Notice'

type VisitConfirmationLocationProps = {
  displayLocation?: string
}

const VisitConfirmationLocation = ({ displayLocation }: VisitConfirmationLocationProps) => {
  if (!displayLocation) {
    return null
  }

  return (
    <>
      <div className="mb-4">
        <p className="typography-body-l mb-1 font-semibold text-neutral-800">Your Location</p>
        <p className="typography-body-l text-neutral-800">{displayLocation}</p>
      </div>
      <Notice variant="warning" className="mb-4">
        <p className="typography-body-l text-neutral-800">
          If the location for this visit is incorrect, please go back and reschedule.
        </p>
      </Notice>
    </>
  )
}

export default VisitConfirmationLocation
