import { ReactNode, useCallback, useEffect, useState } from 'react'

import withForm from '../../../hoc/withForm'
import { useSelector } from 'react-redux'
import * as PharmacySelectors from '../../../redux/selectors/pharmacies'
import isEmpty from 'lodash/isEmpty'
import PlusIcon from '@ui-components-3/icons/lib/duotone/CirclePlus'
import RadioCardsGroup from '@ui-components-3/ui/lib/components/RadioCardsGroup'
import TestIds from '../../../util/TestIds'
import PharmacyListItem from '../../../components/visit-creation/PharmacyListItem'
import classNames from '@ui-components-3/ui/lib/utils/classNames'
import InfoCard from '../../../components/common/InfoCard'
import PharmacySearchDialog from '../../../components/visit-creation/PharmacySearchDialog'
import useId from '@ui-components-3/ui/lib/hooks/useId'
import { usePharmacyControl } from 'hooks/usePharmacyControl'
import Loading from '@ui-components-3/ui/lib/components/Loading'
import Modal from '@ui-components-3/ui/lib/components/Modal'
import { FormikProps } from 'formik'
import PendingVisit from 'types/visit/pendingVisit'

const PHARMACY_CARD_WIDTH = 320

const stringifyId = (pharmacyId?: number) => (!pharmacyId ? null : `${pharmacyId}`)

type PharmacySelectionFormProps = FormikProps<any> & {
  memberId: string
  pendingVisit: PendingVisit
  children: (props: { disabled: boolean }) => ReactNode
}

const PharmacySelectionForm = ({
  isValid,
  handleSubmit,
  setFieldValue,
  pendingVisit,
  values,
  children,
}: PharmacySelectionFormProps) => {
  const [isPharmacySearchVisible, setIsPharmacySearchVisible] = useState(false)
  const openSearchDialog = useCallback(() => setIsPharmacySearchVisible(true), [setIsPharmacySearchVisible])
  const closeSearchDialog = useCallback(() => setIsPharmacySearchVisible(false), [setIsPharmacySearchVisible])

  const [removePharmacyId, setRemovePharmacyId] = useState(null)

  const toggleRemovePharmacyDialog = useCallback(
    (pharmacyId) => () => {
      setRemovePharmacyId(pharmacyId || null)
    },
    [],
  )

  const { memberId, visitType } = pendingVisit

  const { handleRemovePharmacy, handleFetchMemberPharmacies, isPharmacyLoading } = usePharmacyControl(memberId)

  const memberPharmacies = useSelector(PharmacySelectors.getMemberPharmacies(memberId))

  useEffect(() => {
    if (!isPharmacySearchVisible) {
      handleFetchMemberPharmacies()
    }
  }, [memberId, isPharmacySearchVisible, handleFetchMemberPharmacies])

  const handlePharmacyClick = (pharmacyId) => {
    const pharmacy = memberPharmacies?.find((pharmacy) => stringifyId(pharmacy?.id) === pharmacyId)
    if (pharmacy && values.pharmacy.externalId !== pharmacyId) {
      setFieldValue('pharmacy', {
        externalId: pharmacyId,
        name: pharmacy.storeName,
      })
    }
  }

  const handlePharmacyAdded = (pharmacy) => {
    handlePharmacyClick(pharmacy)
    setIsPharmacySearchVisible(false)
  }

  const confirmRemovePharmacy = (pharmacyId) => async (e) => {
    e?.preventDefault()
    e?.stopPropagation()
    toggleRemovePharmacyDialog(null)()

    // @ts-ignore
    const { success } = await handleRemovePharmacy(pharmacyId)

    if (success && values.pharmacy?.externalId === stringifyId(pharmacyId)) {
      setFieldValue('pharmacy', {
        externalId: '',
        name: '',
      })
    }
  }

  const radioGroupLabelId = useId()

  /* TODO: remove indexed testId */

  return (
    <>
      <form onSubmit={handleSubmit} className="flex w-full flex-1 flex-col">
        <div className="mb-6">
          <h2
            className="typography-body-xl mb-4 font-medium text-neutral-800"
            data-testid={TestIds.newVisit.label.pharmacyTitle}
            id={radioGroupLabelId}
          >
            Select or add a pharmacy in case a prescription is needed
          </h2>
          <p
            className="typography-body-l mb-4 font-medium text-neutral-600"
            data-testid={TestIds.newVisit.label.pharmacySubtitle}
          >
            Required for a {visitType} visit
          </p>
          <InfoCard
            className="mb-6 max-w-[550px]"
            title="Disclaimer"
            description="We cannot prescribe controlled substances such as stimulants (Ritalin, Adderall, Vyvanse, Concerta), sedatives and tranquilizers. This type of prescription requires seeing a licensed prescriber in person."
          />
          <RadioCardsGroup
            columnWidth={PHARMACY_CARD_WIDTH}
            options={memberPharmacies}
            value={values.pharmacy.externalId}
            aria-labelledby={radioGroupLabelId}
            controlPlacement="rt"
            name="pharmacy"
            onChange={(e) => handlePharmacyClick(e.target.value)}
            getOptionValue={(pharmacy) => stringifyId(pharmacy.id)}
            getOptionLabel={(pharmacy) => (
              <PharmacyListItem
                data-testid={TestIds.newVisit.input.pharmacy(memberPharmacies.indexOf(pharmacy))}
                onRemoveClick={toggleRemovePharmacyDialog(pharmacy.id)}
                pharmacy={pharmacy}
              />
            )}
          />

          <button
            className={classNames('btn-small mt-m', isEmpty(memberPharmacies) ? 'btn-primary' : 'btn-primary-outlined')}
            onClick={openSearchDialog}
            data-testid={TestIds.newVisit.input.pharmacy('new')}
            type="button"
          >
            Add Pharmacy
            <PlusIcon
              className={classNames('h-[24px] w-[24px]', isEmpty(memberPharmacies) ? 'text-white' : 'text-primary-800')}
              aria-hidden="true"
            />
          </button>
        </div>
        {children?.({ disabled: !isValid || !values.pharmacy?.externalId })}
      </form>
      {!!isPharmacySearchVisible && (
        <PharmacySearchDialog
          opened
          memberId={memberId}
          onPharmacyAdded={handlePharmacyAdded}
          onClose={closeSearchDialog}
        />
      )}
      {!!removePharmacyId && (
        <Modal
          label="Remove Pharmacy"
          opened
          onClose={toggleRemovePharmacyDialog(null)}
          footer={({ close }) => (
            <>
              <button type="button" className="btn btn-neutral-outlined" onClick={close}>
                No
              </button>
              <button type="button" className="btn btn-neutral" onClick={confirmRemovePharmacy(removePharmacyId)}>
                Yes, Remove Pharmacy
              </button>
            </>
          )}
        >
          <p className="typography-body-l">Are you sure you want to remove this pharmacy?</p>
        </Modal>
      )}
      <Loading variant="big" shown={!!isPharmacyLoading} />
    </>
  )
}

export default withForm(PharmacySelectionForm)
