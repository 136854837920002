import { useContext, createContext, Dispatch, SetStateAction, useMemo, useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UseFormReturn } from 'react-hook-form'
import { AccountMergeScene, CHALLENGE_NAME_SCENE_MAP } from 'constants/accountMerge'
import { PasswordConfirmPasswordValues } from 'schemas/PasswordConfirmPasswordSchema'
import { VerifyIdentityValues } from 'schemas/accountMerge/VerifyIdentitySchema'
import { GenerateMfaValues } from 'schemas/GenerateMfaSchema'
import { getDuplicateAccount, getLoggedInMemberDuplicates, getLoggedInMemberId } from 'redux/selectors/members'
import { clearAccountMergeToken } from 'redux/slices/auth'
import {
  postAccountMergeInitialize,
  fetchMemberDuplicates,
  postAccountMergeChallenge,
  postAccountMergeSaveForLater,
} from 'redux/thunks/members'
import type { ChallengeName } from 'types/account-merge/AccountMergeChallenge'
import { toast } from 'util/toast'
import { DuplicateMember } from 'types/account-merge/memberDuplicate'
import { VerifyMfaValues } from 'schemas/VerifyMfaSchema'
import { getAccountMergeSessionToken } from 'redux/selectors/auth'
import jwtDecode from 'jwt-decode'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { VerifyIdentitySchema } from 'schemas/accountMerge/VerifyIdentitySchema'
import { GenerateMfaSchema } from 'schemas/GenerateMfaSchema'
import { VerifyMfaSchema } from 'schemas/VerifyMfaSchema'
import { PasswordConfirmPasswordSchema } from 'schemas/PasswordConfirmPasswordSchema'
import { getMessageDetails } from 'redux/selectors/messages'
import { Message } from 'types/message/message'
import { isAnyTypeFetching, isTypeFetching } from 'redux/selectors/api'
import FetchTypes from 'util/fetch-types'

export type MfaOptionType = {
  mfaType: string
  value: string
  label: string
}

export type AccountMergeContextType = {
  handleCancel: (closeWithoutConfirm?: boolean) => void
  resetForms: () => void
  verifyIdentityForm: UseFormReturn<VerifyIdentityValues>
  verifyPasswordForm: UseFormReturn<PasswordConfirmPasswordValues>
  selectMfaForm: UseFormReturn<GenerateMfaValues>
  verifyMfaForm: UseFormReturn<VerifyMfaValues>
  handleNextScene: (scene: string, challenge?: string, clearError?: boolean) => void
  selectedMemberId: string
  selectDuplicate: (id: string) => void
  selectMfaDeliveryMethod: (deliveryMethod: MfaOptionType) => void
  mfaDeliveryMethod: MfaOptionType
  challenge?: ChallengeName
  setChallenge: Dispatch<SetStateAction<ChallengeName | undefined>>
  setError: Dispatch<SetStateAction<string>>
  clearSessionToken: () => void
  duplicateAccounts: DuplicateMember[]
  restart: () => void
  task?: Message
}

export const AccountMergeContext = createContext<AccountMergeContextType | null>(null)

export const useAccountMergeContext = () => useContext<AccountMergeContextType>(AccountMergeContext)

export const useAccountMergeFlow = ({ taskId }: { taskId?: string }) => {
  const dispatch = useDispatch()
  const [selectedMemberId, setSelectedMemberId] = useState('')
  const [scene, setScene] = useState<AccountMergeScene>(() => (taskId ? 'terms' : 'entry'))
  const [challenge, setChallenge] = useState<ChallengeName>()
  const [mfaDeliveryMethod, setMfaDeliveryMethod] = useState<MfaOptionType>({ mfaType: '', label: '', value: '' })
  const [error, setError] = useState('')
  const loggedInMemberId = useSelector(getLoggedInMemberId)

  const sessionToken = useSelector(getAccountMergeSessionToken)
  const duplicateAccounts = useSelector(getLoggedInMemberDuplicates)
  const task = useSelector(getMessageDetails(taskId))

  const selectDuplicate = useCallback((memberId: string) => setSelectedMemberId(memberId), [])

  const selectMfaDeliveryMethod = useCallback(
    (deliveryMethod: MfaOptionType) => setMfaDeliveryMethod(deliveryMethod),
    [],
  )

  const handleNextScene = useCallback((scene: AccountMergeScene, challenge?: ChallengeName, clearError = true) => {
    setScene(scene)
    if (clearError) setError('')
    if (challenge) setChallenge(challenge)
  }, [])

  const verifyPasswordForm = useForm({
    mode: 'onChange',
    defaultValues: { password: '', confirmPassword: '' },
    resolver: yupResolver(PasswordConfirmPasswordSchema),
  })

  const verifyIdentityForm = useForm({
    mode: 'onChange',
    defaultValues: { firstName: '', lastName: '', dob: new Date().getTime() },
    resolver: yupResolver(VerifyIdentitySchema),
  })

  const selectMfaForm = useForm({
    mode: 'onChange',
    defaultValues: { deliveryMethod: '' },
    resolver: yupResolver(GenerateMfaSchema),
  })

  const verifyMfaForm = useForm({
    mode: 'onChange',
    defaultValues: { mfaCode: '' },
    resolver: yupResolver(VerifyMfaSchema),
  })

  const resetForms = useCallback(() => {
    verifyPasswordForm.reset()
    verifyIdentityForm.reset()
    verifyMfaForm.reset()
    selectMfaForm.reset()
  }, [verifyPasswordForm, verifyIdentityForm, verifyMfaForm, selectMfaForm])

  const sessionTokenExpiration = useMemo(() => {
    if (sessionToken) {
      const exp = jwtDecode<{ exp: number }>(sessionToken)?.exp
      return exp * 1000 - Date.now()
    }
    return undefined
  }, [sessionToken])

  const clearSessionToken = useCallback(() => {
    dispatch(clearAccountMergeToken())
  }, [dispatch])

  const restart = useCallback(() => {
    const startScene = taskId ? 'terms' : 'entry'
    setScene(startScene)
    setError('')
    resetForms()
    clearSessionToken()
  }, [clearSessionToken, resetForms, taskId])

  useEffect(() => {
    if (sessionTokenExpiration) {
      const timeout = setTimeout(() => {
        restart()
        setError('Authentication challenge session has expired')
        toast.error(<p className="m-xxs text-neutral leading-[18px]">Authentication challenge session has expired</p>)
      }, sessionTokenExpiration)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [sessionTokenExpiration, restart])

  useEffect(() => {
    if (!selectedMemberId) {
      if (task?.meta?.duplicateMember?.id) {
        selectDuplicate(task.meta.duplicateMember.id)
      } else if (duplicateAccounts.length === 1) {
        selectDuplicate(duplicateAccounts[0]?.duplicateMemberId)
      }
    }
  }, [duplicateAccounts, selectDuplicate, selectedMemberId, task])

  return {
    task,
    scene,
    error,
    restart,
    setError,
    challenge,
    resetForms,
    setChallenge,
    selectMfaForm,
    verifyMfaForm,
    selectDuplicate,
    handleNextScene,
    selectedMemberId,
    mfaDeliveryMethod,
    duplicateAccounts,
    clearSessionToken,
    verifyPasswordForm,
    verifyIdentityForm,
    selectMfaDeliveryMethod,
  }
}

const useAccountMerge = () => {
  const dispatch = useDispatch()
  const { selectedMemberId, handleNextScene, clearSessionToken } = useAccountMergeContext() || {}

  const loggedInMemberId = useSelector(getLoggedInMemberId)
  const isSavingForLater = useSelector(isTypeFetching(FetchTypes.postAccountMergeSaveForLater))
  const isSubmittingChallenge = useSelector(
    isAnyTypeFetching([FetchTypes.postAccountMergeInit, FetchTypes.postAccountMergeChallenge]),
  )
  const selectedDuplicateAccount: DuplicateMember = useSelector(getDuplicateAccount(selectedMemberId))

  const mfaOptions = useMemo(
    () =>
      Object.entries(selectedDuplicateAccount?.member || {})?.flatMap(([k, v]) =>
        Array.isArray(v)
          ? v.map(({ number, phoneId }) => ({ label: number, value: phoneId, mfaType: 'sms' }))
          : { label: v, value: v, mfaType: 'email' },
      ) || [],
    [selectedDuplicateAccount],
  )

  const refetchDuplicates = async (query?: { taskId?: string }) =>
    dispatch(fetchMemberDuplicates(loggedInMemberId, query))
  const accountMergeInit = async () =>
    // TODO: remove hard coded auth flow
    dispatch(postAccountMergeInitialize(selectedMemberId, selectedDuplicateAccount?.requiredAuthFlow))
  const submitChallenge = async (challenge: ChallengeName, response: Record<string, string>) =>
    dispatch(postAccountMergeChallenge(challenge, response))
  const saveForLater = async () => dispatch(postAccountMergeSaveForLater())

  const handleFailedChallenge = async () => {
    // @ts-ignore
    const { success, data, errorMsg } = await accountMergeInit()
    if (success) {
      const challenge = data.challengeName
      handleNextScene(CHALLENGE_NAME_SCENE_MAP[challenge], challenge, false)
    } else {
      toast.error(<p className="m-xxs text-neutral leading-[18px]">{errorMsg}</p>)
    }
  }

  const resendMfaCode = async (selectMfaResponse: Record<string, string>) => {
    clearSessionToken()
    // @ts-ignore
    const { success, errorMsg } = await accountMergeInit()
    if (success) {
      // @ts-ignore
      const { success: selectMfaSuccess, data: selectMfaData } = await submitChallenge(
        'mfa_select_type',
        selectMfaResponse,
      )
      if (selectMfaSuccess) {
        const challenge = selectMfaData.challengeName
        handleNextScene(CHALLENGE_NAME_SCENE_MAP[challenge], challenge, false)
        return true
      }
    }
    toast.error(<p className="m-xxs text-neutral leading-[18px]">{errorMsg}</p>)
    return false
  }

  const handleSaveForLater = async () => {
    // @ts-ignore
    const { success } = await saveForLater()
    if (success) {
      toast.success('Task saved. Visit the message center to complete your account merge.')
    } else {
      toast.error('Failed to save account merge for later. Please contact support.')
    }
  }

  return {
    refetchDuplicates,
    selectedDuplicateAccount,
    accountMergeInit,
    submitChallenge,
    handleFailedChallenge,
    mfaOptions,
    resendMfaCode,
    handleSaveForLater,
    isSavingForLater,
    isSubmittingChallenge,
  }
}

export default useAccountMerge
