import { useCallback } from 'react'
import SchedulingForm from 'forms/visit-creation/SchedulingForm'
import { StepFormBaseProps } from '../types'
import { useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'
import { clearAvailability } from 'redux/slices/scheduling'
import { fetchProviderAvailability } from 'redux/thunks/providers'
import NewVisitSchedulingSchema from 'schemas/NewVisitSchedulingSchema'

type SuccessOverviewProps = StepFormBaseProps & {
  props?: any
}

const SuccessScheduling = ({ props, onSubmit, renderFooter, initialValues, pendingVisit }: SuccessOverviewProps) => {
  const dispatch = useDispatch()

  const handleFetchProviderAvailability = useCallback(async () => {
    if (isEmpty(pendingVisit) || isEmpty(pendingVisit.providerId)) return null
    dispatch(clearAvailability())
    return dispatch(fetchProviderAvailability(pendingVisit.memberId, pendingVisit.providerId, pendingVisit.visitType))
  }, [dispatch, pendingVisit])

  return (
    <div className="p-8">
      <SchedulingForm
        {...props}
        pendingVisit={pendingVisit}
        onSubmit={onSubmit}
        initialValues={initialValues}
        schema={NewVisitSchedulingSchema}
        isRequiredLabelVisible={true}
        requiredLabelIgnoreFieldsLength
        fetchProviderAvailability={handleFetchProviderAvailability}
      >
        {renderFooter}
      </SchedulingForm>
    </div>
  )
}

export default SuccessScheduling
