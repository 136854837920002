import Provider from 'types/provider/provider'
import ProviderInfoDetailSection from '../ProviderInfoDetailSection'
import { getProviderOptionalProfileValue } from 'util/provider'
import Accordion from '@ui-components-3/ui/lib/components/Accordion'
import { ReactNode, useCallback, useMemo } from 'react'
import ProviderInfoBulletList from '../ProviderInfoBulletList'
import MarkdownView from 'components/Explore/MarkdownView'

type AccordionItem = {
  key: string
  label: string
  content: ReactNode
}

const renderAccordionItem = (i: AccordionItem) => (
  <div className="typography-body-l -m-4 bg-neutral-100 px-6 pb-6 text-neutral-800">{i.content}</div>
)

const renderAccordionLabel = (i: AccordionItem) => (
  <p className="typography-body-l font-medium text-neutral-600">{i.label}</p>
)

const getAccordionKey = (i: AccordionItem) => i.key

const therapyProviderTypes = ['therapy-all', 'therapy-scheduled', 'therapy-now']

const filterNoneValue = (values?: string[]) => values?.filter((i) => i.toLowerCase() !== 'none') || []

type ProviderInfoProps = {
  provider: Provider
  isSuccess?: boolean
}

const ProviderInfo = ({ provider, isSuccess }: ProviderInfoProps) => {
  const { profile, type } = provider || {}

  const {
    genderIdentity,
    languages,
    raceEthnicity,
    aboutMe,
    diagnosticExpertise,
    communityExpertise,
    bestPartSuccessCoach,
    collegeSuccessTips,
    religiousExpertise,
    almaMater,
  } = profile || {}

  const showDiagnosticExpertiseSection = useMemo(() => therapyProviderTypes.includes(type), [type])
  const showCommunityExpertiseGeneral = useMemo(() => [...therapyProviderTypes, 'hc'].includes(type), [type])
  const showReligiousExpertise = useMemo(() => [...therapyProviderTypes, 'psychiatry'].includes(type), [type])
  const showCommunityExpertiseSection = showCommunityExpertiseGeneral || showReligiousExpertise

  const HideIsSuccess = useCallback((value: boolean) => (isSuccess ? true : value), [isSuccess])

  const filteredDiagnosticExpertise = useMemo(
    () => (showDiagnosticExpertiseSection ? filterNoneValue(diagnosticExpertise) : []),
    [diagnosticExpertise, showDiagnosticExpertiseSection],
  )

  const filteredCommunityExpertise = useMemo(
    () => (showCommunityExpertiseGeneral ? filterNoneValue(communityExpertise) : []),
    [communityExpertise, showCommunityExpertiseGeneral],
  )

  const filteredReligiousExpertise = useMemo(
    () => (showReligiousExpertise ? filterNoneValue(religiousExpertise) : []),
    [religiousExpertise, showReligiousExpertise],
  )

  const accordionItems = useMemo<AccordionItem[]>(
    () =>
      [
        {
          key: 'aboutMe',
          label: 'About Me',
          content: (
            <>
              <p className="typography-body text-primary-800 font-semibold">OVERVIEW</p>
              {aboutMe ? (
                <div className="pt-2">
                  <MarkdownView content={aboutMe} />
                </div>
              ) : (
                <p>-</p>
              )}
            </>
          ),
        },
        {
          key: 'diagnosticExpertise',
          label: 'Diagnostic Expertise',
          content: <ProviderInfoBulletList items={filteredDiagnosticExpertise} />,
          hidden: HideIsSuccess(!showDiagnosticExpertiseSection),
        },
        {
          key: 'communityExpertise',
          label: 'Community Expertise',
          content: (
            <div className="flex flex-col gap-4">
              {!!showCommunityExpertiseGeneral && (
                <div>
                  <p className="typography-body text-primary-800 font-semibold">GENERAL</p>
                  <ProviderInfoBulletList items={filteredCommunityExpertise} />
                </div>
              )}
              {!!showReligiousExpertise && (
                <div>
                  <p className="typography-body text-primary-800 font-semibold">RELIGIOUS & SPIRITUAL</p>
                  <ProviderInfoBulletList items={filteredReligiousExpertise} />
                </div>
              )}
            </div>
          ),
          hidden: HideIsSuccess(!showCommunityExpertiseSection),
        },
        {
          key: 'myEducation',
          label: 'My Education',
          content: (
            <>
              <p className="typography-body text-primary-800 font-semibold">
                {'College / University Attended'.toUpperCase()}
              </p>
              <p>{getProviderOptionalProfileValue(almaMater)}</p>
            </>
          ),
          hidden: !isSuccess,
        },
        {
          key: 'bestPartOfSuccessCoaching',
          label: 'Best Part of Success Coaching',
          content: (
            <>
              <p>{getProviderOptionalProfileValue(bestPartSuccessCoach)}</p>
            </>
          ),
          hidden: !isSuccess,
        },
        {
          key: 'tipsForSuccess',
          label: 'Tips for Success',
          content: (
            <>
              <p>{getProviderOptionalProfileValue(collegeSuccessTips)}</p>
            </>
          ),
          hidden: !isSuccess,
        },
      ].filter((i) => !i.hidden),
    [
      aboutMe,
      filteredDiagnosticExpertise,
      HideIsSuccess,
      showDiagnosticExpertiseSection,
      showCommunityExpertiseGeneral,
      filteredCommunityExpertise,
      showReligiousExpertise,
      filteredReligiousExpertise,
      showCommunityExpertiseSection,
      almaMater,
      isSuccess,
      bestPartSuccessCoach,
      collegeSuccessTips,
    ],
  )

  if (!provider) {
    return null
  }

  return (
    <>
      <ProviderInfoDetailSection title={'Gender identity'} subtitle={getProviderOptionalProfileValue(genderIdentity)} />
      <ProviderInfoDetailSection
        title={'Languages'}
        subtitle={getProviderOptionalProfileValue(languages?.join(', '))}
      />
      <ProviderInfoDetailSection title={'Race & Ethnicity'} subtitle={getProviderOptionalProfileValue(raceEthnicity)} />
      <Accordion
        id="provider-profile-accordion"
        itemSize="l"
        items={accordionItems}
        renderContent={renderAccordionItem}
        renderLabel={renderAccordionLabel}
        getKey={getAccordionKey}
      />
    </>
  )
}

export default ProviderInfo
