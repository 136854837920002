import FormikCheckbox from '@ui-components-3/compat/lib/components/FormikCheckbox'
import FormikRadioGroup from '@ui-components-3/compat/lib/components/FormikRadioGroup'
import TestIds from '../../../util/TestIds'
import PropTypes from 'prop-types'
import keys from 'lodash/keys'
import capitalize from 'lodash/capitalize'
import withForm from '../../../hoc/withForm'
import Modal from '@ui-components-3/ui/lib/components/Modal'
import { useCallback, useEffect, useImperativeHandle, useMemo } from 'react'
import AnimatedAccordion from 'components/AnimatedAccordion'
const genderOptions = [
  { value: 'M', label: 'Male' },
  { value: 'F', label: 'Female' },
  { value: 'X', label: 'Another Gender Identity' },
]

const radioButtonBox = '[&_li:nth-child(1)>div>div>div>div>div]'
const radioButtonBoxClasses = `${radioButtonBox}:border-b-[1px] ${radioButtonBox}:border-neutral-300 ${radioButtonBox}:py-2`
const modalClasses =
  'h-[80%] w-[90%] md:w-[552px] lg:w-[710px] backdrop:backdrop-blur [&_div:nth-child(2)]:flex-1 transform transition-transform duration-300 ease-in-out'
const renderAccordionItem = (i) => <div className="typography-body-l -m-4 px-6 pb-6 text-neutral-800">{i.content}</div>
const getAccordionKey = (i) => i.key
const NonTherapyProviderFilters = (props) => {
  const {
    values,
    onCancel,
    dirty,
    isValid,
    handleSubmit,
    schema,
    isVisible,
    resetForm,
    onUpdateValuesCount,
    filterRef,
    hideFilterOption,
  } = props

  // Update the count of `true` values and call `onUpdateValuesCount`
  useEffect(() => {
    const countTrueValues = (obj) => Object.values(obj).filter(Boolean).length

    const count = Object.keys(values).reduce((acc, key) => {
      return acc + countTrueValues(values[key])
    }, 0)

    onUpdateValuesCount(count)
  }, [values, onUpdateValuesCount])

  useImperativeHandle(filterRef, () => ({
    resetForm,
  }))

  const renderAccordionContent = useCallback(
    (key) => {
      switch (key) {
        case 'gender': {
          return (
            <FormikRadioGroup
              data-testid={TestIds.newVisit.input.genderFilter}
              schema={schema}
              name={'gender'}
              options={genderOptions}
              noFormControl
              columns={1}
            />
          )
        }
        case 'ethnicity': {
          return (
            <div className="w-full py-2">
              <FormikCheckbox
                name={'ethnicity.ofColor'}
                label={'Only Providers Of Color'}
                schema={schema}
                data-testid={TestIds.newVisit.input.ethnicityFilter}
              />
            </div>
          )
        }
        default:
          return <p className="typography-body text-primary-800 font-semibold">not found</p>
      }
    },
    [schema],
  )
  const accordionItems = useMemo(() => {
    return keys(values)
      .map((key) => ({
        key,
        label: capitalize(key),
        content: renderAccordionContent(key),
      }))
      .filter((i) => !i.hidden)
      .filter(({ key }) => !hideFilterOption(capitalize(key)))
  }, [hideFilterOption, renderAccordionContent, values])
  return (
    <Modal
      label="Filter"
      opened={isVisible}
      className={`${modalClasses} ${isVisible ? 'translate-y-0' : 'translate-y-full'}`}
      onClose={onCancel}
      footer={({ idPrefix }) => (
        <>
          <button
            className="btn btn-neutral-outlined"
            data-testid={TestIds.newVisit.button.cancelFilter}
            onClick={resetForm}
            type="button"
          >
            Clear All
          </button>
          <button
            data-testid={TestIds.newVisit.button.applyFilter}
            disabled={!dirty || !isValid}
            className="btn btn-neutral"
            type="submit"
            form={`${idPrefix}form`}
          >
            Apply
          </button>
        </>
      )}
    >
      {({ idPrefix }) => (
        <form onSubmit={handleSubmit} id={`${idPrefix}form`}>
          <AnimatedAccordion
            id="provider-profile-accordion"
            itemSize="l"
            allowMultipleOpen={true}
            items={accordionItems}
            renderContent={renderAccordionItem}
            renderLabel={(i) => {
              const total = Object.values(values[i.key]).filter(Boolean).length
              return (
                <p className="typography-body-l text-primary-600 font-semibold">
                  {i.label} {!!total && `(${total})`}
                </p>
              )
            }}
            getKey={getAccordionKey}
            className={`[&_label]:text-sm [&_label]:font-normal [&_label]:text-neutral-600 ${radioButtonBoxClasses} [&_li>button+div>div]:px-0`}
          />
        </form>
      )}
    </Modal>
  )
}

NonTherapyProviderFilters.propTypes = {
  initialValues: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  enableReinitialize: PropTypes.bool,
  dirty: PropTypes.bool,
  isValid: PropTypes.bool,
  isLoading: PropTypes.bool,
  pendingVisit: PropTypes.object,
  filterRef: PropTypes.object.isRequired,
}

export default withForm(NonTherapyProviderFilters)
