export const mfaOptionsMap = {
  sms: 'sms',
  email: 'email',
}

export const mfaOptions = Object.values(mfaOptionsMap)

export const buildMfaChoice = (option: string) => {
  if (option === 'email') {
    return {
      deliveryMethod: 'email',
    }
  } else {
    return {
      deliveryMethod: 'sms',
      phoneId: option,
    }
  }
}
