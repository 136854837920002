import classNames from '@ui-components-3/ui/lib/utils/classNames'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import { ReactNode } from 'react'
import { isBeforeVisitTypeSelection } from 'pages/AddPendingVisitPage/AddPendingVisitPage'

type AddVisitPageLayoutProps = {
  children: ReactNode
  step: number
}

const AddVisitPageLayout = ({ step, children }: AddVisitPageLayoutProps) => {
  useDocumentTitle(`Create visit step ${step}`)
  return (
    <div
      className={classNames('flex h-full flex-col px-5 pb-0 lg:px-10', !isBeforeVisitTypeSelection(step) && 'pt-10')}
    >
      {children}
    </div>
  )
}

export default AddVisitPageLayout
