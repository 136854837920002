import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import { normalizeJsonToApi } from '../../helpers'
import configBuilder from '../config-builder'
import ApiFactory, { ApiTypes } from '../../index'
import { REACTION_MAP } from 'util/community'

const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object({
  target: Yup.object({
    ref: Yup.object({
      postId: Yup.string().required(),
    }),
    type: Yup.string().oneOf(['post']).required(),
  }),
  type: Yup.string().oneOf(Object.values(REACTION_MAP)).required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => await RequestSchema.validate(params)
export const make = async (params) => {
  return normalizeJsonToApi(params)
}
export const url = () => '/v1/reactions'
export const execute = async (params) =>
  api.post(url(), await make(params), configBuilder(params)).catch(catchApiFailure)
