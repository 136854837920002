import Modal from '@ui-components-3/ui/lib/components/Modal'
import { Formik, useFormikContext } from 'formik'
import { usePharmacyControl } from 'hooks/usePharmacyControl'
import { PharmacyModel } from 'types/visit/pendingVisit'
import { isNumber, isEmpty } from 'lodash'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { getPreferredAddress } from 'util/member'
import { useSelector } from 'react-redux'
import { getMember } from 'redux/selectors/members'
import SearchIcon from '@ui-components-3/icons/lib/regular/MagnifyingGlass'
import AngleLeftIcon from '@ui-components-3/icons/lib/solid/AngleLeft'
import TestIds from 'util/TestIds'
import classNames from '@ui-components-3/ui/lib/utils/classNames'
import PharmacySearchListItem from '../PharmacySearchListItem'
import PharmacySearchFormContent from '../PharmacySearchFormContent'

const buildInitialValues = (member) => {
  const address = getPreferredAddress(member)
  return {
    storeName: '',
    city: address?.city ?? '',
    state: address?.state ?? '',
    zip: '',
    distance: '',
    specialty: ['retail'],
    lat: null,
    lng: null,
  }
}

const transformSearchValues = (values) => {
  return Object.keys(values).reduce((acc, key) => {
    if (isNumber(values[key]) || !isEmpty(values[key])) {
      acc[key] = values[key]
    }
    return acc
  }, {})
}

type DialogFooterProps = {
  idPrefix: string
  close: () => void
  resultsCount?: number
  isLoading?: boolean
}

const DialogFooter = ({ close, idPrefix, resultsCount, isLoading }: DialogFooterProps) => {
  const { values } = useFormikContext()

  const hasSomeFilter = useMemo(() => {
    return Object.values(values).reduce((acc, value) => {
      return acc || (!!value && !isEmpty(value))
    }, false)
  }, [values])

  return (
    <>
      <button type="button" className="btn btn-primary-outlined" onClick={close}>
        Close
      </button>
      {!resultsCount && (
        <button
          type="submit"
          className="btn btn-primary"
          data-testid={TestIds.newVisit.button.searchPharmacy}
          disabled={!hasSomeFilter || !!isLoading}
          form={`${idPrefix}form`}
          key="submit"
        >
          <SearchIcon className="h-5 w-5" aria-hidden="true" />
          Search Pharmacy
        </button>
      )}
    </>
  )
}

type PharmacySearchDialogProps = {
  memberId: string
  opened: boolean
  onClose: () => void
  onPharmacyAdded: (pharmacy: PharmacyModel) => void
}

const PharmacySearchDialog = ({ memberId, opened, onClose }: PharmacySearchDialogProps) => {
  const [error, setError] = useState('')
  const [searchResults, setSearchResults] = useState<PharmacyModel[]>([])
  const member = useSelector(getMember(memberId))

  const { handleFetchPharmacies, handleAddPharmacy, isPharmacyLoading } = usePharmacyControl(memberId)

  const handleFormSubmit = useCallback(
    async (values, form) => {
      const filter = transformSearchValues(values)
      // @ts-ignore
      const { success, pharmacies, mailOrderPharmacies } = await handleFetchPharmacies(filter)
      const results = [...pharmacies, ...mailOrderPharmacies]
      if (!success || !results.length) {
        setError('No pharmacies found')
      }

      setSearchResults(results)
      form.resetForm({ ...form, values })
    },
    [handleFetchPharmacies],
  )

  const onPharmacyAddClick = useCallback(
    async (pharmacy: PharmacyModel) => {
      // @ts-ignore
      const { success } = await handleAddPharmacy(pharmacy)
      if (!success) {
        setError('Failed to add pharmacy')
      } else {
        onClose()
      }
    },
    [handleAddPharmacy, onClose],
  )

  const resultsCount = searchResults?.length

  const initialValues = useMemo(() => buildInitialValues(member), [member])

  return (
    <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
      <>
        <Modal
          opened={opened}
          label="Add a Pharmacy"
          onClose={onClose}
          size="custom"
          className="w-[600px] max-w-full"
          footer={({ close, idPrefix }) => (
            <DialogFooter close={close} idPrefix={idPrefix} resultsCount={resultsCount} isLoading={isPharmacyLoading} />
          )}
        >
          {({ idPrefix }) => (
            <>
              {!!resultsCount && (
                <div className="typography-body-l sticky top-0 z-10 flex w-full items-center bg-white pb-3 font-normal text-neutral-600">
                  <button
                    className="btn-small btn-neutral-borderless text-primary-600 h-6 px-0"
                    type="button"
                    onClick={() => setSearchResults([])}
                  >
                    <AngleLeftIcon className="h-6 w-6" aria-hidden="true" />
                    Back
                  </button>
                  <span className="mx-3 h-6 w-px bg-neutral-300" />
                  Found: {resultsCount} Pharmacies
                </div>
              )}
              <PharmacySearchFormContent
                formId={`${idPrefix}form`}
                className={classNames(!!resultsCount && 'hidden')}
              />
              {!!resultsCount && (
                <div className="px-4 pb-4 md:px-6">
                  {searchResults.map((pharmacy, i) => {
                    return (
                      <Fragment key={pharmacy.id}>
                        <hr />
                        <PharmacySearchListItem
                          pharmacy={pharmacy}
                          index={i}
                          onAddClick={() => onPharmacyAddClick(pharmacy)}
                          data-testid={TestIds.newVisit.input.pharmacySearch(i)}
                        />
                      </Fragment>
                    )
                  })}
                </div>
              )}
            </>
          )}
        </Modal>
        {!!error && (
          <Modal label="Error" opened>
            <p className="typography-body-l">{error}</p>
          </Modal>
        )}
      </>
    </Formik>
  )
}

export default PharmacySearchDialog
