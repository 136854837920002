import { ReactNode, useEffect, useMemo, useState } from 'react'
import { isEmpty } from 'lodash'
import Dash from '@ui-components-3/icons/lib/solid/Dash'
import RadioCardsGroup from '@ui-components-3/ui/lib/components/RadioCardsGroup'
import TestIds from 'util/TestIds'
import * as MemberSelectors from 'redux/selectors/members'
import { useSelector } from 'react-redux'
import { MinOption } from 'types/form/Form'
import { Controller, useForm } from 'react-hook-form'
import MedicalHistoryVisitValidation from 'schemas/MedicalHistoryVisitValidation'
import * as MedicalHistorySelectors from 'redux/selectors/medical-history'
import { getPrintableFullName, getAge, fullGender } from 'util/member'
import StudentInBedWithPhoneLink from 'assets/images/student_in_bed_with_phone.svg'
import { yupResolver } from '@hookform/resolvers/yup'
import { MemberSelectFormValues, MemberSelectSchema } from 'schemas/MemberSelectSchema'
import PendingVisit from 'types/visit/pendingVisit'
import {
  VisitCreationForm,
  VisitCreationImage,
  VisitCreationScene,
} from 'components/visit-creation/VisitCreationWrappers/VisitCreationWrappers'
import useId from '@ui-components-3/ui/lib/hooks/useId'

const RADIO_CARD_ESTIMATED_WIDTH = 300

type Option = MinOption & {
  age: string
  disabled: boolean
  gender: string
  name: string
}

const getOptionCardLabel = (option: Option) => (
  <div>
    <div className="typography-body-l mb-2.5 font-semibold text-neutral-700">{option.name}</div>
    <div className="flex items-center">
      <span className="typography-body font-semibold text-neutral-500">{option.age}</span>
      <Dash aria-hidden="true" className="text-primary-700 mx-0.5 h-[24px] w-[24px] opacity-40" />
      <span className="typography-body font-semibold text-neutral-700">{option.gender}</span>
    </div>
  </div>
)

const getOptionCardValue = (o: Option) => o.value
const isOptionCardDisabled = (option: Option) => option.disabled
const getOptionCardProps = (_: Option) => ({ className: 'bg-white z-10' })

type FormProps = {
  memberOptions: Option[]
  children: MemberSelectFormProps['children']
  onSubmit: MemberSelectFormProps['onSubmit']
  initalValues: MemberSelectFormValues
}

const Form = ({ memberOptions, children, onSubmit, initalValues }: FormProps) => {
  const {
    formState: { isValid },
    control,
    watch,
    setValue,
    handleSubmit,
  } = useForm<MemberSelectFormValues>({
    resolver: yupResolver(MemberSelectSchema),
    defaultValues: initalValues,
  })

  const selectedMemberId = watch('memberId')
  const memberRecords = useSelector(MemberSelectors.getMembers)

  const radioGroupLabelId = useId()

  useEffect(() => {
    if (selectedMemberId) {
      const member = memberRecords.find((memberRecord) => memberRecord.memberId === selectedMemberId)
      setValue('memberFirstName', member?.firstName)
      setValue('memberLastName', member?.lastName)
      setValue('memberDob', member?.dob)
    }
  }, [memberRecords, selectedMemberId, setValue])

  return (
    <form
      // TODO Change testid
      data-testid={TestIds.newVisit.view.memberSelectForm}
      onSubmit={handleSubmit(onSubmit)}
    >
      <p className="typography-body mb-10 mt-2 font-medium text-neutral-700">
        All fields marked with asterisks are required*
      </p>
      <h2 id={radioGroupLabelId} className="typography-h5 mb-4 text-neutral-800">
        Who needs to be seen?*
      </h2>
      <Controller
        control={control}
        name="memberId"
        render={({ field }) => (
          <RadioCardsGroup
            {...field}
            required={true}
            aria-labelledby={radioGroupLabelId}
            columns={1}
            options={memberOptions}
            getLabelProps={getOptionCardProps}
            getOptionLabel={getOptionCardLabel}
            getOptionValue={getOptionCardValue}
            isOptionDisabled={isOptionCardDisabled}
            columnWidth={RADIO_CARD_ESTIMATED_WIDTH}
          />
        )}
      />

      {children({ disabled: !isValid })}
    </form>
  )
}

const getInintalMemberId = ({ pendingVisit, memberOptions }) => {
  if (pendingVisit?.memberId) {
    return pendingVisit?.memberId
  }
  if (memberOptions && memberOptions?.length === 1) {
    return memberOptions[0].value
  }
  return ''
}

type MemberSelectFormProps = {
  children: (values: { disabled: boolean }) => ReactNode
  onSubmit: () => void
  pendingVisit: PendingVisit
}

const MemberSelectForm = ({ children, onSubmit, pendingVisit }: MemberSelectFormProps) => {
  const [memberOptions, setMemberOptions] = useState<Option[]>([])

  const memberRecords = useSelector(MemberSelectors.getMembers)
  const historyMap = useSelector(MedicalHistorySelectors.getHistoryByMemberId)
  const userMemberId = useSelector(MemberSelectors.getLoggedInMemberId)
  const userMember = useSelector(MemberSelectors.getMember(userMemberId))

  useEffect(() => {
    if (isEmpty(memberRecords) || isEmpty(historyMap)) {
      setMemberOptions([])
    } else if (userMember && userMemberId) {
      const options = memberRecords.reduce(
        (acc, member) => {
          const { memberId } = member
          if (memberId !== userMemberId) {
            acc.push({
              value: memberId,
              name: `${getPrintableFullName(member)} (${member.relationType})`,
              age: getAge(member, ' mos old', ' yr old'),
              gender: fullGender(member.gender),
              disabled: !MedicalHistoryVisitValidation.isValidSync(historyMap[memberId]),
            })
          }
          return acc
        },
        [
          {
            value: userMemberId,
            name: `${getPrintableFullName(userMember)} (Me)`,
            age: getAge(userMember, ' mos old', ' yr old'),
            gender: fullGender(userMember.gender),
            disabled: !MedicalHistoryVisitValidation.isValidSync(historyMap[userMemberId]),
          },
        ],
      )
      setMemberOptions(options)
    }
  }, [memberRecords, historyMap, userMember, userMemberId])

  const initalValues = useMemo(() => {
    return {
      memberDob: pendingVisit?.memberDob || '',
      memberFirstName: pendingVisit?.memberFirstName || '',
      memberId: getInintalMemberId({ pendingVisit, memberOptions }),
      memberLastName: pendingVisit?.memberLastName || '',
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    } satisfies MemberSelectFormValues
  }, [pendingVisit, memberOptions])

  return (
    <VisitCreationScene>
      <VisitCreationForm>
        {memberOptions.length > 0 && (
          <Form memberOptions={memberOptions} onSubmit={onSubmit} initalValues={initalValues}>
            {children}
          </Form>
        )}
      </VisitCreationForm>
      <VisitCreationImage>
        <img className="h-full w-full" src={StudentInBedWithPhoneLink} alt="" />
      </VisitCreationImage>
    </VisitCreationScene>
  )
}

export default MemberSelectForm
