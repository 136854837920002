import useMemberGroupType from 'hooks/useMemberGroupType'
import { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLoggedInMember, getMemberAge } from 'redux/selectors/members'
import {
  getClient,
  getGroup,
  getServiceByType,
  getServiceLimitResetMap,
  getServiceTypes,
} from 'redux/selectors/services'
import { getMemberLastPendingVisit } from 'redux/selectors/visits'
import { fetchClientVisitCount, fetchGroup } from 'redux/thunks/clients'

const PendoIdentify = () => {
  const member = useSelector(getLoggedInMember)
  const age = useSelector(getMemberAge(member?.memberId))
  const client = useSelector(getClient(member?.clientId))
  const group = useSelector(getGroup(member?.groupId))
  const serviceTypes = useSelector(getServiceTypes)
  const lastPendingVisit = useSelector(getMemberLastPendingVisit(member?.memberId))
  const serviceLimitResetMap = useSelector(getServiceLimitResetMap)
  const scheduledTherapyService = useSelector(getServiceByType('therapy', 'scheduled'))
  const { memberGroupType } = useMemberGroupType()
  const dispatch = useDispatch()

  const [scheduledTherapyVisitsRemaining, setScheduledTherapyVisitsRemaining] = useState<number | undefined>(undefined)
  const initializedRef = useRef(false)
  const accountIdRef = useRef<string | null>(null)
  const visitorIdRef = useRef<string | null>(null)

  const handleFetchGroup = useCallback(
    (groupId: string) => {
      if (groupId) dispatch(fetchGroup(groupId))
    },
    [dispatch],
  )

  useEffect(() => {
    if (member?.groupId) {
      handleFetchGroup(member.groupId)
    }
  }, [handleFetchGroup, member?.groupId])

  const fetchVisitCount = useCallback(async () => {
    // @ts-ignore
    const { count } = await dispatch(
      fetchClientVisitCount(member.clientId, { visitType: 'therapy', scheduledType: 'scheduled' }),
    )
    if (count && scheduledTherapyService) {
      setScheduledTherapyVisitsRemaining(scheduledTherapyService?.limitMember - count)
    }
  }, [dispatch, member?.clientId, scheduledTherapyService?.limitMember])

  useEffect(() => {
    if (member?.clientId) fetchVisitCount()
  }, [fetchVisitCount, member?.clientId])

  const attributes = useMemo(
    () => ({
      visitor: {
        id: member?.memberId,
        groupType: memberGroupType,
        email: member?.email,
        sex: member?.gender,
        age,
        origin: 'web',
        registeredDate: member?.registeredAt,
        gender: member?.genderIdentity || undefined,
        ethnicity: member?.ethnicity || undefined,
        lastVisitDate: lastPendingVisit.completedAt || undefined,
        lastLogin: new Date().toISOString(),
        graduationSemester: member?.graduationSemester || undefined,
        graduationYear: member?.graduationYear || undefined,
        ...serviceLimitResetMap,
        scheduledTherapyVisitsRemaining: scheduledTherapyVisitsRemaining,
      },
      account: {
        id: member?.groupId,
        clientName: client?.name || undefined,
        clientId: member?.clientId || undefined,
        groupName: group?.name,
        services: serviceTypes || undefined,
        environment: process.env.REACT_APP_ENVIRONMENT,
      },
    }),
    [
      age,
      client,
      group,
      lastPendingVisit.completedAt,
      member,
      scheduledTherapyVisitsRemaining,
      serviceLimitResetMap,
      serviceTypes,
      memberGroupType,
    ],
  )

  const minimalAttributesReady = !!attributes.visitor.id && !!attributes.account.id

  useEffect(() => {
    if (window.pendo) {
      if (minimalAttributesReady) {
        if (initializedRef.current) {
          if (accountIdRef.current !== attributes.account.id || visitorIdRef.current !== attributes.visitor.id) {
            window.pendo.identify(attributes)
          } else {
            window.pendo.updateOptions(attributes)
          }
        } else {
          window.pendo?.initialize(attributes)
          initializedRef.current = true
        }
        accountIdRef.current = attributes.account.id
        visitorIdRef.current = attributes.visitor.id
      }
    }
  }, [attributes, minimalAttributesReady, initializedRef, accountIdRef, visitorIdRef])

  return null
}

export default PendoIdentify
