import FormikCheckbox from '@ui-components-3/compat/lib/components/FormikCheckbox'
import FormikRadioGroup from '@ui-components-3/compat/lib/components/FormikRadioGroup'
import TestIds from '../../../util/TestIds'
import PropTypes from 'prop-types'
import keys from 'lodash/keys'
import capitalize from 'lodash/capitalize'
import withForm from '../../../hoc/withForm'
import { LanguageFilters } from '../../../util/languages'
import Modal from '@ui-components-3/ui/lib/components/Modal'
import { useCallback, useEffect, useImperativeHandle, useMemo } from 'react'
import AnimatedAccordion from 'components/AnimatedAccordion'

const radioButtonBox = '[&_li>div>div>div>div>div]'

const radioRowClasses =
  '[&_li:not(:first-child)>div>div>div>div>div:first-of-type]:border-t-[1px] [&_li:not(:first-child)>div>div>div>div>div:first-of-type]:pt-2 [&_li>div>div>div>div>div]:border-b-[1px] [&_li>div>div>div>div>div]:pb-2'
const radioButtonBoxClasses = `${radioButtonBox}:border-neutral-300 ${radioButtonBox}:py-6 [&_label]:py-1`
const modalClasses =
  'h-[80%] w-[90%] md:w-[552px] lg:w-[710px] backdrop:backdrop-blur [&_div:nth-child(2)]:flex-1 transform transition-transform duration-300 ease-in-out'
const renderAccordionItem = (i) => (
  <div className="typography-body-l -m-4 px-6 pb-6 pt-2 text-neutral-800">{i.content}</div>
)
const getAccordionKey = (i) => i.key
const TherapyProviderFilters = (props) => {
  const {
    values,
    onCancel,
    dirty,
    isValid,
    handleSubmit,
    schema,
    isVisible,
    resetForm,
    onUpdateValuesCount,
    filterRef,
    hideFilterOption,
    availableExpertise,
    availableGenders,
    availableEthnicity,
    defaultFilters,
    selectedCount,
  } = props

  // Update the count of `true` values and call `onUpdateValuesCount`
  useEffect(() => {
    const hasGenderFilter = values['gender']?.length > 0 ? 1 : 0
    const expertiseFilterNumb = Object.values(values['specialtyFocus'])?.filter(Boolean)?.length
    const raceFilterNumb = values['race']?.length > 0 ? 1 : 0
    onUpdateValuesCount(expertiseFilterNumb + raceFilterNumb + hasGenderFilter)
  }, [values])

  useImperativeHandle(filterRef, () => ({
    resetForm,
  }))

  const renderAccordionContent = useCallback(
    (key) => {
      switch (key) {
        case 'languages': {
          return keys(values.languages).map((language, idx) => {
            const value = values.languages[language]
            return (
              <div
                className={`w-full border-b-[1px] ${idx === 0 && 'border-t-[1px]'} border-neutral-300 py-2`}
                key={language}
              >
                <FormikCheckbox
                  data-testid={TestIds.newVisit.input.languageFilter(language)}
                  name={`languages.${language}`}
                  label={LanguageFilters[language]}
                  value={value}
                  schema={schema}
                />
              </div>
            )
          })
        }
        case 'race': {
          return (
            <FormikRadioGroup
              data-testid={TestIds.newVisit.input.ethnicityFilter}
              schema={schema}
              name={key}
              options={availableEthnicity}
              noFormControl
              columns={1}
            />
          )
        }
        case 'specialtyFocus': {
          return availableExpertise.map((focus, idx) => (
            <div className={`w-full ${idx === 0 && 'border-t-[1px]'} pt-2`} key={focus.label}>
              <FormikCheckbox
                data-testid={TestIds.newVisit.input.specialtyFilter(focus.label)}
                name={`specialtyFocus.${focus.label}`}
                label={focus.label}
                schema={schema}
              />
            </div>
          ))
        }
        case 'gender': {
          return (
            <FormikRadioGroup
              data-testid={TestIds.newVisit.input.genderFilter}
              schema={schema}
              name={key}
              options={availableGenders}
              noFormControl
              columns={1}
            />
          )
        }
        default:
          return <p className="typography-body text-primary-800 font-semibold">not found</p>
      }
    },
    [schema, values],
  )

  const accordionItems = useMemo(() => {
    return keys(values)
      .map((key) => ({
        key,
        label: capitalize(key === 'specialtyFocus' ? 'expertise' : key),
        content: renderAccordionContent(key),
      }))
      .filter((i) => !i.hidden)
      .filter(({ key }) => !hideFilterOption(capitalize(key === 'specialtyFocus' ? 'expertise' : key)))
  }, [hideFilterOption, renderAccordionContent, values])

  return (
    <Modal
      label="Filter"
      opened={isVisible}
      className={`${modalClasses} ${isVisible ? 'translate-y-0' : 'translate-y-full'}`}
      onClose={onCancel}
      footer={({ idPrefix }) => (
        <>
          <button
            className="btn btn-neutral-outlined"
            data-testid={TestIds.newVisit.button.cancelFilter}
            type="button"
            disabled={!dirty && selectedCount === 0}
            onClick={() => {
              resetForm({ values: defaultFilters })
            }}
          >
            Clear All
          </button>
          <button
            data-testid={TestIds.newVisit.button.applyFilter}
            disabled={!dirty || !isValid}
            className="btn btn-neutral"
            type="submit"
            form={`${idPrefix}form`}
          >
            Apply
          </button>
        </>
      )}
    >
      {({ idPrefix }) => (
        <form onSubmit={handleSubmit} id={`${idPrefix}form`}>
          <AnimatedAccordion
            id="provider-profile-accordion"
            itemSize="l"
            items={accordionItems}
            allowMultipleOpen={true}
            renderContent={renderAccordionItem}
            renderLabel={(i) => {
              let total
              if (i.key === 'gender' || i.key === 'race') {
                total = values[i.key].length > 0 ? 1 : null
              } else {
                total = Object.values(values[i.key]).filter(Boolean).length
              }
              return (
                <p className="typography-body-l text-primary-600 font-semibold">
                  {i.label} {!!total && `(${total})`}
                </p>
              )
            }}
            getKey={getAccordionKey}
            className={`[&_label]:text-sm [&_label]:font-normal [&_label]:text-neutral-600  ${radioButtonBoxClasses} ${radioRowClasses} [&_li>button+div>div]:px-0`}
          />
        </form>
      )}
    </Modal>
  )
}

TherapyProviderFilters.propTypes = {
  initialValues: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  enableReinitialize: PropTypes.bool,
  dirty: PropTypes.bool,
  isValid: PropTypes.bool,
  isLoading: PropTypes.bool,
  pendingVisit: PropTypes.object,
  filterRef: PropTypes.object.isRequired,
  availableExpertise: PropTypes.array,
  availableGenders: PropTypes.array,
  availableEthnicity: PropTypes.array,
}

export default withForm(TherapyProviderFilters)
