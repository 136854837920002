import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import { normalizeJsonToApi } from '../../helpers'

const api = ApiFactory.getApi(ApiTypes.member)

/* TODO: maybe include surveyId here? */
const RequestSchema = Yup.object().shape({
  pendingVisitId: Yup.string().required(),
  form: Yup.object().shape({
    formId: Yup.string().required(),
    answers: Yup.array()
      .of(
        Yup.object().shape({
          fieldId: Yup.string().required(),
          value: Yup.mixed().nullable(),
        }),
      )
      .required(),
  }),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const make = async (params) => {
  const { token, surveyId, ...paramsWithoutToken } = params
  const request = RequestSchema.cast(paramsWithoutToken)
  return normalizeJsonToApi(request)
}

export const makeHeaders = (params) => {
  if (params.token) {
    return { 'x-form-token': `Bearer ${params.token}` }
  }
}
export const url = (params) => `/v2/surveys/${params.surveyId}`
export const execute = async (params) =>
  api.put(url(params), await make(params), { headers: makeHeaders(params) }).catch(catchApiFailure)
