import { createSlice } from '@reduxjs/toolkit'

export type PusherState = {
  meta: {
    status: string
  }
  records: {
    [topic: string]: string
  }
}

const defaultState: PusherState = {
  meta: {
    status: '',
  },
  records: {
    // [topic]: topic
  },
}

const slice = createSlice({
  name: 'pusher',
  initialState: defaultState,
  reducers: {
    putStatus: (state, action) => {
      const { status } = action.payload
      state.meta.status = status
    },
    putTopic: (state, action) => {
      const { topic } = action.payload
      state.records[topic] = topic
    },
    putTopics: (state, action) => {
      const { topics } = action.payload
      topics.forEach((topic: string) => {
        state.records[topic] = topic
      })
    },
    clearTopic: (state, action) => {
      const { topic } = action.payload
      delete state.records[topic]
    },
    clearTopics: (state) => {
      state.records = { ...defaultState.records }
    },
  },
})

const { actions, reducer } = slice
export const { putStatus, putTopic, putTopics, clearTopic, clearTopics } = actions
export default reducer
