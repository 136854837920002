import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import configBuilder from '../config-builder'
import { normalizeJsonToApi } from '../../helpers'

const api = ApiFactory.getApi(ApiTypes.auth)

const RequestSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .label('Password')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/g,
      'Password must have 10 characters, and must contain 1 capital letter,' +
        ' 1 lowercase letter, 1 special character, and 1 number',
    )
    .required(),
  password: Yup.string()
    .label('Password')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/g,
      'Password must have 10 characters, and must contain 1 capital letter,' +
        ' 1 lowercase letter, 1 special character, and 1 number',
    )
    .required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const make = async (params) => {
  return normalizeJsonToApi(params)
}
export const url = () => '/v1/password/update'
export const execute = async (params) =>
  api.put(url(params), await make(params), configBuilder(params)).catch(catchApiFailure)
