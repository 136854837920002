import { useMemo, useEffect, RefObject, useRef, Dispatch, Fragment } from 'react'
import groupBy from 'lodash/groupBy'
import keys from 'lodash/keys'
import moment from 'moment-timezone'
import NewMessageIcon from '@ui-components-3/icons/lib/regular/Message'
import classNames from '@ui-components-3/ui/lib/utils/classNames'
import { handleFeedback } from 'util/video'
import { useHistory } from 'react-router'
import Provider from 'types/provider/provider'
import Member from 'types/member/member'
import { VisitChatMessage } from 'types/visit-chat-message/visitChatMessage'
import VisibilityObserver from './VisibilityObserver'
import PendingVisit from 'types/visit/pendingVisit'
import TestIds from 'util/TestIds'
import { useDispatch } from 'react-redux'

export type VisitChatMessagesProps = {
  messages: VisitChatMessage[]
  chatId: string
  member: Member
  provider: Provider
  pendingVisit?: PendingVisit
  pendingVisitId?: string
}

const VIEWED_AT_FORMATTER = new Intl.DateTimeFormat('us', {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
})

const useVisibilityObserver = (msgListRef: RefObject<HTMLElement>, dispatch: Dispatch<any>) => {
  const model = (useRef<VisibilityObserver>().current ||= new VisibilityObserver(dispatch))

  /* eslint-disable-next-line */
  useEffect(() => model.start(msgListRef.current), [])

  return model
}

export const isMessageRead = (message: VisitChatMessage, memberId: Member['memberId']) =>
  !!message.viewedAt || message.senderId === memberId

const VisitChatMessages = ({
  messages,
  chatId,
  member,
  provider,
  pendingVisit,
  pendingVisitId,
}: VisitChatMessagesProps) => {
  const messagesEndRef = useRef<HTMLDivElement>(null)
  const scrollableRef = useRef<HTMLDivElement>(null)
  const history = useHistory()
  const dispatch = useDispatch()
  const namesById = useMemo(() => {
    const names: Record<string, Record<string, string>> = {}
    if (member) {
      names[member.memberId] = {
        name: 'You',
        initials: 'tm',
      }
    }
    if (provider) {
      names[provider.providerId] = {
        name: `${provider.firstName} ${provider.lastName}`,
        initials: provider.lastName
          ? `${provider.firstName.charAt(0)}${provider.lastName.charAt(0)}`
          : provider.firstName.charAt(0),
      }
    }
    return names
  }, [member, provider])

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ block: 'end', inline: 'nearest' })
  }, [messages])

  const visibilityObserver = useVisibilityObserver(scrollableRef, dispatch)

  const transformedMessages = useMemo(
    () =>
      groupBy(
        messages?.map((message, i, arr) => {
          return {
            ...message,
            _id: message.id,
            text: message.message,
            shouldDrawUnreadSeparatorBefore:
              i > 0 && !isMessageRead(message, member.memberId) && isMessageRead(arr[i - 1], member.memberId),
            createdOn: moment(message.createdAt).format('YYYY-MM-DD'),
            transformedCreatedAt: moment(message.createdAt).format('h:mm a'),
            user: {
              _id: message.senderId,
              name: namesById[message.senderId].name,
              initials: namesById[message.senderId].initials,
            },
          }
        }),
        'createdOn',
      ),
    [messages, member],
  )

  return (
    <div
      className="flex min-h-0 grow flex-col items-center overflow-auto bg-neutral-100 p-3"
      data-testid={TestIds.visitChat.visitChatMessagesList.view}
      ref={scrollableRef}
    >
      {keys(transformedMessages).map((date, i) => {
        const messageList = transformedMessages[date]
        return (
          <div
            aria-live="polite"
            className="gap-s sm:gap-m flex w-full max-w-3xl grow flex-col justify-end py-3"
            key={i}
          >
            <div className="typography-body-s	p-3 text-center">{moment(date).format('MMM Do, YYYY')}</div>
            {messageList?.map((message) => {
              const memberMessage = message.user._id === member.memberId
              const providerMessage = message.user._id === provider.providerId

              return (
                <Fragment key={message.id}>
                  {message.shouldDrawUnreadSeparatorBefore ? (
                    <div className="mb-s gap-xs p-xs flex items-center justify-center border-b border-neutral-800 font-semibold text-neutral-700">
                      <NewMessageIcon aria-hidden="true" />
                      NEW MESSAGE
                    </div>
                  ) : null}
                  <div
                    ref={(el) => visibilityObserver.observeMessage(message, el)}
                    className={classNames(
                      'border-primary-200 px-s relative w-full max-w-3xl rounded-2xl border py-[11px]',
                      providerMessage && 'text-primary-800 self-start bg-white',
                      memberMessage && 'self-end bg-neutral-500 text-white',
                    )}
                    data-testid={TestIds.visitChat.visitChatMessagesList.message(message.id)}
                  >
                    <p className="typography-body-l overflow-hidden overflow-ellipsis">
                      {message.user.name}: <span className="whitespace-pre-line">{message.text}</span>
                      <div className="typography-small text-right">{message.transformedCreatedAt}</div>
                    </p>
                    <span
                      className={classNames(
                        'absolute -bottom-2',
                        memberMessage && 'right-2 rotate-90',
                        providerMessage && 'left-2 -rotate-90',
                      )}
                    >
                      <span
                        className={classNames(
                          'relative h-0 w-0 border-l-[16px] border-r-[16px] border-t-[16px] border-transparent border-t-neutral-500',
                          providerMessage &&
                            'border-t-primary-200 before:absolute before:-left-[14px] before:-top-[15.5px] before:h-0 before:w-0 before:border-l-[16px] before:border-r-[16px] before:border-t-[16px] before:border-transparent before:border-t-white before:content-[""]',
                        )}
                      />
                    </span>
                  </div>
                  {memberMessage && message.viewedAt ? (
                    <div className="typography-small text-right text-neutral-500">
                      <span className="font-bold">Read</span>{' '}
                      {VIEWED_AT_FORMATTER.format(new Date(message.viewedAt)).toLowerCase()}
                    </div>
                  ) : null}
                </Fragment>
              )
            })}
            {pendingVisit?.status === 'in-review' ? (
              <div className="px-s text-primary-800 max-w-md self-center py-[11px]">
                <p className="typography-body-l italic">
                  The call was ended.{' '}
                  <button
                    className="text-primary-500 font-semibold italic"
                    onClick={() => handleFeedback(pendingVisit.memberId, pendingVisitId, history)}
                  >
                    Click here
                  </button>{' '}
                  to exit.
                </p>
              </div>
            ) : null}
          </div>
        )
      })}
      <div ref={messagesEndRef} />
    </div>
  )
}

export default VisitChatMessages
