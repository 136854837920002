import axios from 'axios'
import Config from '../config'

export const ApiTypes = {
  member: 'member',
  provider: 'provider',
  auth: 'auth',
  consent: 'consent',
}

Object.freeze(ApiTypes)

const getBaseUrlForApi = (apiType) => {
  switch (apiType) {
    case ApiTypes.auth:
      return Config.authUrl
    case ApiTypes.member:
      return Config.memberUrl
    case ApiTypes.provider:
      return Config.providerUrl
    case ApiTypes.consent:
      return Config.consentUrl
    default:
      return Config.proxyUrl
  }
}

const createApi = (apiType) => {
  return axios.create({
    baseURL: getBaseUrlForApi(apiType),
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

const createMockApi = (mockUrl) => {
  return axios.create({
    baseURL: mockUrl,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

const createApiBlob = (apiType) => {
  return axios.create({
    baseURL: getBaseUrlForApi(apiType),
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  })
}

class ApiFactoryClass {
  constructor() {
    this._apiMap = {
      [ApiTypes.auth]: createApi(ApiTypes.auth),
      [ApiTypes.member]: createApi(ApiTypes.member),
      [ApiTypes.provider]: createApi(ApiTypes.provider),
      [ApiTypes.consent]: createApi(ApiTypes.consent),
    }
  }

  getApi(apiType, mockUrl = '') {
    return !mockUrl || Config.environment !== 'local' ? this._apiMap[apiType] : createMockApi(mockUrl)
  }
}

const ApiFactory = new ApiFactoryClass()
Object.freeze(ApiFactory)

class ApiBlobFactoryClass {
  constructor() {
    this._apiMap = {
      [ApiTypes.member]: createApiBlob(ApiTypes.member),
    }
  }

  getApi(apiType) {
    return this._apiMap[apiType]
  }
}

export const ApiBlobFactory = new ApiBlobFactoryClass()
Object.freeze(ApiBlobFactory)

export default ApiFactory
