import React from 'react'

const AccessibilityDocumentContainer = () => {
  return (
    <div className="p-4">
      <h1 className="typography-h3 mb-6 font-medium">TimelyCare Accessibility Documentation</h1>
      <span className="typography-body-l block font-medium">
        We are dedicated to ensuring an accessible user experience in our TimelyCare mobile and web applications, with
        strict compliance to the Web Content Accessibility Guidelines (WCAG) 2.1 standards.
        <br />
        <br />
        Our development team meticulously integrates key HTML accessibility features to define page structure, making it
        easier for screen readers to navigate content. We employ ARIA (Accessible Rich Internet Applications) attributes
        to enhance the accessibility of dynamic content and complex user interface controls developed with HTML,
        JavaScript, and related technologies. These attributes include roles, states, and properties that define the
        behavior and purpose of elements for assistive technologies.
        <br />
        <br />
        Interactive elements in our applications are equipped with detailed accessibility labels, roles, and hints. This
        ensures that users with screen readers or other assistive technologies can understand the function and context
        of each element. Forms are designed with associated tags for every input field for improved screen reader
        compatibility.
        <br />
        <br />
        We also ensure our applications respond to your device's accessibility preferences, such as high contrast or
        reduced motion settings. Our color schemes and font sizes are chosen to enhance readability and meet WCAG
        standards for color contrast and text scaling.
        <br />
        <br />
        Our Mobile application utilizes accessibility to enhance the mobile user experience, this includes utilizing
        Accessibility Labels, Accessibility Roles, Touchable Elements, Dynamic Font Sizes, Color Contrast, and providing
        Screen Reader Compatibility via VoiceOver (iOS) and TalkBack (Android), and AccessibilityStates.
        <br />
        <br />
        Our adherence to WCAG standards is validated through extensive audits, and we are committed to continuous
        improvement. By doing so, we strive to make our services approachable and navigable for every user, regardless
        of their abilities or disabilities.
        <br />
        <br />
      </span>
    </div>
  )
}

export default AccessibilityDocumentContainer
