import useAccountMerge, { useAccountMergeContext } from 'hooks/useAccountMerge'

export const EntryFooter = () => {
  const { handleNextScene, selectedMemberId } = useAccountMergeContext()
  const { handleSaveForLater, isSavingForLater } = useAccountMerge()
  return (
    <>
      <button
        type="button"
        className="btn btn-neutral-outlined"
        disabled={isSavingForLater}
        onClick={handleSaveForLater}
      >
        Save for Later
      </button>
      <button
        type="button"
        className="btn btn-neutral self-end"
        disabled={isSavingForLater}
        onClick={() => handleNextScene(selectedMemberId ? 'terms' : 'selectAccount')}
      >
        Start Merge
      </button>
    </>
  )
}

export const EntryContent = () => {
  const { duplicateAccounts } = useAccountMergeContext()
  const multipleDupes = duplicateAccounts.length > 1
  return (
    <div className="typography-body-l gap-m flex flex-col">
      <p>
        {multipleDupes
          ? 'You have multiple accounts with different emails. In order to transfer your records, you must merge these accounts.'
          : 'We found another email associated with your account. Verify your identity in order to access your records and health information. This will be a two-step verification process.'}
      </p>
      {multipleDupes ? (
        <p>
          If you have more than two accounts, you’ll need to repeat this process until all accounts have been merged.
        </p>
      ) : null}
    </div>
  )
}
