import { configureStore, combineReducers } from '@reduxjs/toolkit'
import View from './slices/view'
import Api from './slices/api'
import Auth from './slices/auth'
import Allergies from './slices/allergies'
import Chats from './slices/chats'
import Conditions from './slices/conditions'
import FamilyHistory from './slices/family-history'
import Images from './slices/images'
import MedicalHistory from './slices/medical-history'
import Members from './slices/members'
import Messages from './slices/messages'
import Pusher from './slices/pusher'
import Payments from './slices/payments'
import PaymentsHistory from './slices/payments-history'
import Pharmacies from './slices/pharmacies'
import Providers from './slices/providers'
import Resources from './slices/resources'
import Scheduling from './slices/scheduling'
import Services from './slices/services'
import Success from './slices/success'
import Support from './slices/support'
import Visits from './slices/visits'
import Community from './slices/community'
import Explore from './slices/explore'
import CancellationReason from './slices/cancellation-reasons'
import visitQuestions from './slices/visit-questions'
import providerFilters from './slices/providerFilters'

const reducer = {
  api: Api,
  auth: Auth,
  allergy: Allergies,
  chats: Chats,
  community: Community,
  condition: Conditions,
  explore: Explore,
  familyHistory: FamilyHistory,
  images: Images,
  medicalHistory: MedicalHistory,
  member: Members,
  message: Messages,
  pusher: Pusher,
  payments: Payments,
  paymentsHistory: PaymentsHistory,
  pharmacies: Pharmacies,
  providers: Providers,
  resources: Resources,
  scheduling: Scheduling,
  services: Services,
  successAvailability: Success,
  support: Support,
  view: View,
  visit: Visits,
  cancellationReason: CancellationReason,
  visitQuestions: visitQuestions,
  filters: providerFilters,
} as const

const rootReducer = combineReducers(reducer)

export const store = configureStore({
  reducer: reducer,
})

export const setupStore = (preloadedState: any) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  })
}

export type RootState = ReturnType<typeof store.getState>
export type GetState = () => RootState
