import { ReactNode } from 'react'

type GetCareCardProps = {
  children: ReactNode[]
}

const GetCareCard = ({ children }: GetCareCardProps) => {
  return (
    <div className="relative mx-auto w-full max-w-[450px] rounded-2xl border-transparent bg-white p-8 shadow-[0px_8px_16px_0px_rgba(27,99,162,.08)]">
      <div className="flex flex-col gap-6 text-center">{children}</div>
    </div>
  )
}

type WrapperProps = {
  children: ReactNode
}

const Wrapper = ({ children }: WrapperProps) => (
  <div className="flex w-full flex-col px-5 py-8 sm:flex-row sm:items-start md:px-10">{children}</div>
)

type TitleProps = {
  children: ReactNode
  dataTestId: string
}

const Title = ({ children, dataTestId }: TitleProps) => {
  return (
    <h2 data-testid={dataTestId} className="typography-h4 text-neutral-800">
      {children}
    </h2>
  )
}

type ImageProps = {
  src: string
  alt: string
}

const Image = ({ src, alt }: ImageProps) => {
  return <img src={src} alt={alt} className="h-[185px] self-center" />
}

type DescriptionProps = {
  children: ReactNode
}

const Description = ({ children }: DescriptionProps) => {
  return <p className="typography-body-l text-neutral-600">{children}</p>
}

GetCareCard.Wrapper = Wrapper
GetCareCard.Title = Title
GetCareCard.Image = Image
GetCareCard.Description = Description

export default GetCareCard
