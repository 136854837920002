import { createSelector } from 'reselect'
import FetchTypes from '../../util/fetch-types'

const FETCHES_WITHOUT_LOADING = {
  [FetchTypes.addChatMessage]: FetchTypes.addChatMessage,
  [FetchTypes.getVisitChat]: FetchTypes.getVisitChat,
  [FetchTypes.location]: FetchTypes.location,
}

export const isFetching = (state) => state.api.fetching.length > 0

export const isError = (state) => state.api.meta.isError
export const getErrors = (state) => state.api.meta.errors
export const getSource = (state) => state.api.source
export const getCampaign = (state) => state.api.campaign

export const getFetching = (state) => state.api.fetching
export const isMenuHidden = (state) => state.api.isMenuHidden

export const getMfa = (state) => state.api.mfa

export const isLoading = createSelector(getFetching, (fetching) => {
  if (!fetching || fetching.length === 0) return false
  return fetching.some((fetchType) => !FETCHES_WITHOUT_LOADING[fetchType])
})

export const isTypeFetching = (fetchType) => createSelector(getFetching, (fetching) => fetching.includes(fetchType))

export const isAnyTypeFetching = (fetchTypes = []) =>
  createSelector(getFetching, (fetching) => fetchTypes.some((fetchType) => fetching?.includes(fetchType)))

export const getMfaOptions = createSelector(getMfa, (mfa) => {
  return Object.keys(mfa.choices).reduce((acc, key) => {
    if (key === 'email' && !!mfa.choices[key]) {
      acc.push({
        label: mfa.choices[key],
        value: 'email',
      })
    } else if (key === 'phones' && !!mfa.choices[key].length) {
      mfa.choices[key].forEach((phone) => {
        acc.push({
          label: phone.phoneNumber,
          value: phone.phoneId,
        })
      })
    }
    return acc
  }, [])
})
