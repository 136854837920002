import { ModalRenderPropParams } from '@ui-components-3/ui/lib/components/Modal'
import useAccountMerge, { useAccountMergeContext } from 'hooks/useAccountMerge'
import RequiredFieldLabel from 'components/form/RequiredFieldLabel'
import { VerifyMfaValues } from 'schemas/VerifyMfaSchema'
import { CHALLENGE_NAME_SCENE_MAP } from 'constants/accountMerge'
import { ChallengeResponse } from 'types/account-merge/AccountMergeChallenge'
import FormControl from '@ui-components-3/ui/lib/components/FormControl'
import MaskInput from '@ui-components-3/ui/lib/components/MaskInput'
import { Controller } from 'react-hook-form'

const getFormId = (idPrefix: string) => idPrefix + 'form:verify-mfa'

export const VerifyMfaFooter = ({ idPrefix }: ModalRenderPropParams) => {
  const formId = getFormId(idPrefix)
  const context = useAccountMergeContext()
  const { handleCancel, verifyMfaForm } = context
  const {
    formState: { isValid, isSubmitting, isDirty },
  } = verifyMfaForm

  const { isSubmittingChallenge } = useAccountMerge()
  const isSubmitDisabled = !isValid || isSubmitting || !isDirty || isSubmittingChallenge

  return (
    <>
      <button
        type="button"
        onClick={() => handleCancel()}
        disabled={isSubmittingChallenge}
        className="btn btn-neutral-outlined self-end"
      >
        Cancel
      </button>
      <button type="submit" form={formId} disabled={isSubmitDisabled} className="btn btn-neutral self-end">
        Next
      </button>
    </>
  )
}

export const VerifyMfaContent = ({ idPrefix }: ModalRenderPropParams) => {
  const formId = getFormId(idPrefix)
  const {
    verifyMfaForm,
    handleNextScene,
    selectedMemberId,
    handleCancel,
    setError,
    mfaDeliveryMethod,
    challenge,
    resetForms,
  } = useAccountMergeContext()
  const { handleFailedChallenge, submitChallenge, mfaOptions, resendMfaCode } = useAccountMerge()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = verifyMfaForm

  const onSubmit = handleSubmit(async (values: VerifyMfaValues) => {
    const { mfaCode } = values
    // @ts-ignore
    const { success, data } = await submitChallenge(challenge, { mfaCode, duplicateMemberId: selectedMemberId })
    if (success) {
      const { authenticationResult }: ChallengeResponse = data
      if (data?.challengeName) {
        const challenge = data.challengeName
        handleNextScene(CHALLENGE_NAME_SCENE_MAP[challenge], challenge, true)
      } else if (authenticationResult?.attemptsRemaining === 0) {
        handleCancel(true)
      } else {
        await handleFailedChallenge()
        resetForms()
        setError('Incorrect code, try again')
      }
    }
  })

  const handleResendCode = async () => {
    const method = mfaOptions.find((o) => o.value === mfaDeliveryMethod.value)
    if (method) {
      const mfaType = method.mfaType
      const payload = {
        mfaType,
        duplicateMemberId: selectedMemberId,
        phoneId: method.value,
      }
      if (mfaType === 'email') delete payload.phoneId
      const success = await resendMfaCode(payload)
      if (!success) {
        setError('Failed to resend MFA code, please try again.')
      }
    }
  }

  return (
    <form id={formId} onSubmit={onSubmit} className="gap-s pt-xs flex flex-col">
      <RequiredFieldLabel ignoreFieldsLength className="font-normal" />
      {mfaDeliveryMethod ? (
        <p className="typography-body-l font-medium">Enter the 6 digit code we sent to {mfaDeliveryMethod.label}</p>
      ) : null}
      <Controller
        control={control}
        name="mfaCode"
        render={({ field: { ref, ...field } }) => (
          <FormControl
            label="Verification Code*"
            helperVariant="error"
            helper={errors.mfaCode ? errors.mfaCode.message : undefined}
          >
            <MaskInput autoComplete="off" mask="_-_-_-_-_-_" transform {...field} />
          </FormControl>
        )}
      />
      <button
        type="button"
        onClick={handleResendCode}
        className="btn-unsized btn-primary-borderless mb-s self-start font-semibold underline"
      >
        Resend Code
      </button>
    </form>
  )
}
