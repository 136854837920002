import * as Yup from 'yup'
import configBuilder from '../config-builder'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import PaymentClientSchema from '../../../schemas/PaymentClientSchema'
import { normalizeJsonToApi } from '../../helpers'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  paymentClient: PaymentClientSchema.label('Payment Client').required(),
  memberId: Yup.string().label('Member ID').required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => await RequestSchema.validate(params)
export const url = ({ memberId }) => `/v1/${memberId}/payments/members`
export const make = ({ paymentClient }) => normalizeJsonToApi(paymentClient)
export const execute = async (params) =>
  api.post(url(params), make(params), configBuilder(params)).catch(catchApiFailure)
