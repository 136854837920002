import { forwardRef } from 'react'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import Modal, { ModalRef } from '@ui-components-3/ui/lib/components/Modal'
import { getLoggedInMemberId } from 'redux/selectors/members'
import { DASHBOARD, MEDICAL_OVERVIEW, createMedicalPath } from 'routes/paths'
import { Link } from 'react-router-dom'

export const ModalContent = {
  account: {
    content: 'To access, you must first update your medical information.',
    getRoute: ({ memberId }) => createMedicalPath(MEDICAL_OVERVIEW, memberId),
    ctaLabel: 'Update Information',
  },
  dashboard: {
    content: 'You currently have an active visit with a provider.',
    getRoute: () => DASHBOARD,
    ctaLabel: 'Got It',
  },
}

type AddPendingVisitRedirectModalProps = {
  queryValue: string
}

const AddPendingVisitRedirectModal = forwardRef<ModalRef, AddPendingVisitRedirectModalProps>(({ queryValue }, ref) => {
  const history = useHistory()
  const memberId = useSelector(getLoggedInMemberId)
  const redirectRoute = ModalContent[queryValue].getRoute({ memberId })
  return (
    <Modal
      ref={ref}
      label="Provider Care Unavailable"
      className="backdrop:backdrop-blur"
      onClose={() => history.push(redirectRoute)}
      footer={
        <Link to={redirectRoute} className="btn btn-neutral w-full">
          {ModalContent[queryValue].ctaLabel}
        </Link>
      }
    >
      <p className="pb-s pt-xs text-neutral">{ModalContent[queryValue].content}</p>
    </Modal>
  )
})

export default AddPendingVisitRedirectModal
