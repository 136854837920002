import { createSlice } from '@reduxjs/toolkit'
import { Filter } from 'hooks/usePagination'
import { Comment } from 'types/community/comment'
import { Post } from 'types/community/post'
import { PostReport } from 'types/community/reports'
import { ReactionResponse } from 'types/reaction/reaction'

export type CommunityState = {
  meta: {
    total: number
    myTotal: number
    savedTotal: number
    allFilter: Filter
    latestTc: string | null
    latestPostTc: string | null
  }
  records: {
    [postId: string]: Post
  }
  postDetail: {
    [postId: string]: {
      [commmentId: string]: Comment
    }
  }
  reports: {
    [reportId: string]: PostReport
  }
  reactions: {
    [reactionId: string]: ReactionResponse
  }
  newPostIds: string[]
  newCommentIds: {
    [postId: string]: string[]
  }
  randomizedPosts: Post[] | null
}

export const defaultState: CommunityState = {
  meta: {
    total: 0,
    myTotal: 0,
    savedTotal: 0,
    allFilter: { status: 'published', postType: 'post' },
    latestTc: null,
    latestPostTc: null,
  },
  records: {
    // [post_id]: post
  },
  postDetail: {
    // [post_id]: {
    //   [comment_id]: comment
    // }
  },
  reports: {
    // [report_id]: report
  },
  reactions: {
    // [reaction_id]: ReactionResoponse
  },
  newPostIds: [],
  newCommentIds: {
    // [post_id]: string[]
  },
  randomizedPosts: null,
}

const getId = (post: Post | Comment) => post.postId
const getReportId = (report: PostReport) => report.postReportId
const getBooleanTypeForFetchType = (postFetchType: string) => {
  switch (postFetchType) {
    case 'my':
      return 'isMy'
    case 'saved':
      return 'isSaved'
    case 'all':
    default:
      return 'isAll'
  }
}
const getTotalTypeForFetchType = (postFetchType: string) => {
  switch (postFetchType) {
    case 'my':
      return 'myTotal'
    case 'saved':
      return 'savedTotal'
    case 'all':
    default:
      return 'total'
  }
}

const findAssociatedRecord = (store: CommunityState['reports'] | CommunityState['reactions'], postId: string) =>
  Object.values(store).find((r) => r.postId === postId)

const slice = createSlice({
  name: 'community',
  initialState: defaultState,
  reducers: {
    putTcVersion: (state, action) => {
      const { version } = action.payload
      state.meta.latestTc = version
    },
    putPostAgreementVersion: (state, action) => {
      const { version } = action.payload
      state.meta.latestPostTc = version
    },
    putPost: (state, action) => {
      const { post, postFetchType } = action.payload
      const postId = getId(post)
      const existing = state.records[postId]
      const postUpdate = { ...post }
      if (existing) {
        // make sure to pass along true booleans
        if (existing.isMy) {
          postUpdate.isMy = true
        }
        if (existing.isAll) {
          postUpdate.isAll = true
        }
      }
      state.records[postId] = postUpdate
      if (postFetchType) {
        state.meta[getTotalTypeForFetchType(postFetchType)]++
      }
    },
    putPostCounts: (state, action) => {
      const { postId, counts } = action.payload
      if (state.records[postId]) {
        state.records[postId].counts = counts
      }
    },
    putCommentCounts: (state, action) => {
      const { parentPostId, postId, counts } = action.payload
      const postDetail = state.postDetail[parentPostId]
      if (postDetail?.[postId]) {
        postDetail[postId].counts = counts
      }
    },
    putPosts: (state, action) => {
      const { posts, total, postFetchType } = action.payload
      const booleanType = getBooleanTypeForFetchType(postFetchType)
      posts.forEach((post: Post) => {
        const postUpdate = { ...post, [booleanType]: true }
        const postId = getId(post)
        const existing = state.records[postId]
        if (existing) {
          if (existing.isMy) {
            postUpdate.isMy = true
          }
          if (existing.isAll) {
            postUpdate.isAll = true
          }
        }
        const existingReport = findAssociatedRecord(state.reports, postId)
        if (existingReport) {
          postUpdate.isReported = true
        }
        state.records[postId] = postUpdate
      })
      state.meta[getTotalTypeForFetchType(postFetchType)] = total
    },
    clearPost: (state, action) => {
      const { postId } = action.payload
      delete state.records[postId]
    },
    clearPosts: (state) => {
      state.records = { ...defaultState.records }
    },
    putAllFilter: (state, action) => {
      const { filter } = action.payload
      state.meta.allFilter = filter
    },
    clearAllFilter: (state) => {
      state.meta.allFilter = { status: 'published', postType: 'post' }
    },
    putNewPost: (state, action) => {
      const { post } = action.payload
      if (post.isAuthor && !state.records[getId(post)]) {
        state.meta.myTotal++
      }
      state.records[getId(post)] = { ...post, isNew: true }
    },
    putPostComment: (state, action) => {
      const { postId, comment } = action.payload
      if (!state.postDetail[postId]) {
        state.postDetail[postId] = {}
      }
      state.postDetail[postId][getId(comment)] = comment
    },
    putPostComments: (state, action) => {
      const { postId, comments } = action.payload
      if (!state.postDetail[postId]) {
        state.postDetail[postId] = {}
      }
      comments.forEach((comment: Comment) => {
        if (state.reports) {
          findAssociatedRecord(state.reports, getId(comment))
          const existingReport = findAssociatedRecord(state.reports, getId(comment))
          if (existingReport) {
            comment.isReported = true
          }
        }
        state.postDetail[postId][getId(comment)] = comment
      })
    },
    putPostNewComments: (state, action) => {
      const { postId, comments } = action.payload
      if (!state.postDetail[postId]) {
        state.postDetail[postId] = {}
      }
      comments.forEach((comment: Comment) => {
        state.postDetail[postId][getId(comment)] = { ...comment, isNew: true }
      })
    },
    putMoveNewComments: (state, action) => {
      const { postId } = action.payload
      Object.values(state.postDetail[postId] ?? {})
        .filter((p) => p.isNew)
        .forEach((comment) => {
          state.postDetail[postId][getId(comment)].isNew = false
        })
    },
    clearPostComment: (state, action) => {
      const { postId, commentId } = action.payload
      if (state.postDetail[postId]?.[commentId]) {
        delete state.postDetail[postId][commentId]
      }
    },
    clearPostComments: (state, action) => {
      const { postId } = action.payload
      state.postDetail[postId] = {}
    },
    clearPostDetail: (state, action) => {
      const { postId } = action.payload
      delete state.postDetail[postId]
    },
    putReport: (state, action) => {
      const { report } = action.payload
      state.reports[getReportId(report)] = report
      // Don't flag isReported on putReport, action only called when a report is created and this will be used to display the report view
    },
    putReports: (state, action) => {
      const { reports }: { reports: PostReport[] } = action.payload
      Object.values(reports).forEach((report) => {
        const reportId = getReportId(report)
        state.reports[reportId] = report
        const postId = report.postId
        if (state.records[postId]) {
          const existingRecord = state.records[postId]
          state.records[postId] = { ...existingRecord, isReported: true }
        }
      })
    },
    clearReport: (state, action) => {
      const { postReportId } = action.payload
      delete state.reports[postReportId]
    },
    clearReports: (state) => {
      state.reports = { ...defaultState.reports }
    },
    putReaction: (state, action) => {
      const { reaction } = action.payload
      state.reactions[reaction.reactionId] = reaction
    },
    putReactions: (state, action) => {
      const { reactions }: { reactions: ReactionResponse[] } = action.payload
      reactions.forEach((reaction) => {
        state.reactions[reaction.reactionId] = reaction
      })
    },
    clearReaction: (state, action) => {
      const { postId } = action.payload
      const reaction = Object.values(state.reactions).find((r) => r.target.ref.postId === postId)
      if (reaction) {
        delete state.reactions[reaction.reactionId]
      }
    },
    clearReactions: (state) => {
      state.reactions = { ...defaultState.reactions }
    },
    putNewPostId: (state, action) => {
      const { postId } = action.payload
      if (!state.newPostIds.includes(postId)) {
        state.newPostIds.push(postId)
      }
    },
    clearNewPostIds: (state) => {
      state.newPostIds = []
    },
    putNewCommentId: (state, action) => {
      const { parentPostId, postId } = action.payload
      if (!state.newCommentIds[parentPostId]) {
        state.newCommentIds[parentPostId] = []
      }
      state.newCommentIds[parentPostId].push(postId)
    },
    putRandomizedPosts: (state, action) => {
      state.randomizedPosts = action.payload?.length ? action.payload : []
    },
    clearNewCommentIds: (state, action) => {
      const { postId } = action.payload
      state.newCommentIds[postId] = []
    },
    clearCommunity: (state) => {
      state.meta = { ...defaultState.meta }
      state.records = { ...defaultState.records }
      state.postDetail = { ...defaultState.postDetail }
      state.reports = { ...defaultState.reports }
      state.reactions = { ...defaultState.reactions }
      state.newPostIds = { ...defaultState.newPostIds }
      state.newCommentIds = { ...defaultState.newCommentIds }
      state.randomizedPosts = defaultState.randomizedPosts
    },
  },
})

const { actions, reducer } = slice
export const {
  putPost,
  putPostCounts,
  putPosts,
  clearPost,
  clearPosts,
  clearPostComment,
  clearPostComments,
  clearPostDetail,
  putTcVersion,
  putPostAgreementVersion,
  putNewPost,
  putPostComment,
  putPostComments,
  putPostNewComments,
  putMoveNewComments,
  putReport,
  putReports,
  clearReport,
  clearReports,
  putReaction,
  putReactions,
  clearReaction,
  clearReactions,
  putNewPostId,
  clearNewPostIds,
  putNewCommentId,
  clearNewCommentIds,
  clearCommunity,
  putAllFilter,
  clearAllFilter,
  putCommentCounts,
  putRandomizedPosts,
} = actions
export default reducer
