import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'

const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  memberId: Yup.string().label('Member ID').required(),
  visitId: Yup.string().label('Visit ID').required(),
  rating: Yup.number()
    .label('Rating')
    .min(1, 'Rating must be at least 1')
    .max(5, 'Rating must be 5 or lower')
    .required(),
  comments: Yup.string(),
})

export const isValid = async (params) => {
  return await RequestSchema.isValid(params)
}
export const validate = async (params) => RequestSchema.validate(params)
export const make = async (params) => {
  const data = {
    member_id: params.memberId,
    rating: params.rating,
  }
  if (params.comments) {
    data.comments = params.comments
  }
  return data
}
export const url = ({ visitId }) => `/v1/visits/${visitId}/reviews`
export const execute = async (params) => api.post(url(params), await make(params)).catch(catchApiFailure)
