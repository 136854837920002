import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import { ChatSchema } from '../../../schemas/ChatSchema'
import { normalizeJsonToApi } from '../../helpers'

const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  chat: ChatSchema,
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const make = async (params) => {
  const payload = await RequestSchema.validate(params)
  const data = normalizeJsonToApi(payload.chat, null, 'id')
  return data
}
export const url = () => '/v1/chats'
export const execute = async (params) => api.post(url(), await make(params)).catch(catchApiFailure)
