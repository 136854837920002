import withForm from 'hoc/withForm'
import TestIds from 'util/TestIds'
import { AnyObjectSchema } from 'yup'
import PendingVisit from 'types/visit/pendingVisit'
import FormikRadioGroup from '@ui-components-3/compat/lib/components/FormikRadioGroup'
import WomanOnSofaLink from 'assets/images/woman_on_sofa.svg'
import {
  VisitCreationForm,
  VisitCreationImage,
  VisitCreationScene,
} from 'components/visit-creation/VisitCreationWrappers/VisitCreationWrappers'
import Notice from '@ui-components-3/ui/lib/components/Notice'

const locationOptions = [
  {
    value: 'false',
    label: 'I am in the United States',
  },
  { value: 'true', label: 'I am traveling or living outside of the United States' },
]

type initialValues = {
  location: string
  locationCountry: string
  memberDob: string
  memberFirstName: string
  memberId: string
  memberLastName: string
  price: number
  reasonForVisit: string
  scheduledType: string
  timezone: string
  visitType: string
}

type NewVisitLocationFormProps = {
  initialValues: initialValues
  schema: AnyObjectSchema
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  handleSubmit: () => void
  values: initialValues
  errors: object
  enableReinitialize: boolean
  dirty: boolean
  isValid: boolean
  isLoading: boolean
  children: (values: { disabled: boolean }) => JSX.Element
  positionError: any
  clearPayload: () => void
  pendingScheduledVisits: { [key: string]: string[] }
  userMemberId: string
  pendingVisit: PendingVisit
  fromSuccess?: boolean
}

export const NewVisitLocationForm = (props: NewVisitLocationFormProps) => {
  const { handleSubmit, isValid, schema, fromSuccess } = props

  return (
    <VisitCreationScene>
      <VisitCreationForm>
        <form onSubmit={handleSubmit} data-testid={TestIds.newVisit.view.locationForm}>
          <div className="relative z-10">
            <p className="typography-body mb-10 mt-2 font-medium text-neutral-700">
              All fields marked with asterisks are required*
            </p>
            <h2 className="typography-h5 mb-8 text-neutral-800">What is your current location?*</h2>
            <FormikRadioGroup
              required={true}
              name="isOutsideUS"
              schema={schema}
              options={locationOptions}
              columns={1}
              label="Location"
            />
            <Notice variant="info" className="mt-8">
              <p className="typography-body-l mt-1 text-neutral-800">
                {!fromSuccess
                  ? 'Provider services are dependent on your location at the time of your visit. You may need to reschedule if your location will be different.'
                  : 'Service is dependent on your location at the time of your session.'}
              </p>
            </Notice>
          </div>
          {props.children({ disabled: !isValid })}
        </form>
      </VisitCreationForm>
      <VisitCreationImage>
        <img className="h-auto w-full object-contain" src={WomanOnSofaLink} alt="" />
      </VisitCreationImage>
    </VisitCreationScene>
  )
}

export default withForm(NewVisitLocationForm)
