import * as Yup from 'yup'
import { normalizeJsonToApi } from '../../helpers'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import configBuilder from '../config-builder'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object({
  mfaCode: Yup.string().required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => await RequestSchema.validate(params)
export const make = async (params) => normalizeJsonToApi(params)
export const url = () => '/v1/me/verify-mfa'
export const execute = async (params) =>
  api.post(url(), await make(params), configBuilder(params)).catch(catchApiFailure)
