import { withLDProvider } from 'launchdarkly-react-client-sdk'
import Config from '../../config'
import { ReactNode } from 'react'

type FlagProviderProps = JSX.IntrinsicAttributes & {
  children?: ReactNode
}
const FlagProvider = ({ children }: FlagProviderProps) => {
  return children
}

export default withLDProvider<FlagProviderProps>({ clientSideID: Config.launchDarklyKey ?? '' })(FlagProvider)
