import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getLoggedInMemberGroupType, getDigitalOnlyStatus } from 'redux/selectors/members'
import { GROUP_TYPES } from 'types/client/group'

export default () => {
  const memberGroupType = useSelector(getLoggedInMemberGroupType)
  const isDigitalOnly = useSelector(getDigitalOnlyStatus)
  const isFacultyStaff = useMemo(() => {
    return memberGroupType === GROUP_TYPES.facultyStaff
  }, [memberGroupType])
  const isStudent = useMemo(() => memberGroupType === GROUP_TYPES.student, [memberGroupType])

  return {
    memberGroupType,
    isFacultyStaff,
    isDigitalOnly,
    isStudent,
  }
}
