import * as Yup from 'yup'
import ApiFactory, { ApiTypes } from '../../index'
import catchApiFailure from '../catch-api-error'

const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  chatId: Yup.string().label('Chat ID').required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const url = ({ chatId }) => `/v1/chats/${chatId}`
export const execute = async (params) => api.get(url(params)).catch(catchApiFailure)
