import { createVisitPath, DASHBOARD, VISIT_FEEDBACK } from '../routes/paths'
import includes from 'lodash/includes'
import { VISIT_RATINGS } from './session-constants'
import { toast } from './toast'
import { localPersistence } from 'util/storage'

export const showFeedback = (setAlert, body, alertClasses) => {
  setAlert({
    type: 'custom',
    visible: true,
    body: body,
    onYes: () => {},
    onNo: () => {},
    yesContent: '',
    noContent: '',
    customNoBtnClass: alertClasses.noLinkStyle,
    customYesBtnClass: alertClasses.yesLinkStyle,
  })
}

export const handleFeedback = (memberId, pendingVisitId, history) => {
  if (!localPersistence.get(VISIT_RATINGS)) {
    localPersistence.set(VISIT_RATINGS, JSON.stringify([]))
  }
  const visitRatings = JSON.parse(localPersistence.get(VISIT_RATINGS))
  if (!includes(visitRatings, pendingVisitId)) {
    visitRatings.push(pendingVisitId)
    localPersistence.set(VISIT_RATINGS, JSON.stringify(visitRatings))
    history.push(createVisitPath(VISIT_FEEDBACK, pendingVisitId, memberId))
  } else {
    history.push(DASHBOARD)
  }
}

export const defaultEndCallTimer = {
  timerOn: false,
  countdown: 30,
}

export const defaultDisconnectionTimer = {
  timerOn: false,
  countdown: 600,
}

export const startTimer = (setTimerFn) => {
  return setInterval(() => {
    setTimerFn((prev) => ({
      countdown: prev.countdown - 1,
      timerOn: true,
    }))
  }, 1000)
}

export const handleVideoError = (error, pendingVisitId) => {
  console.error(error)
  const Msg = () => (
    <div className="m-2 flex flex-col items-start gap-2">
      The video could not be connected.
      <button
        className="btn-unsized text-neutral text-left font-semibold underline"
        onClick={() => window.location?.reload()}
        type="button"
      >
        Try Again
      </button>
    </div>
  )
  toast.error(<Msg />, {
    toastId: `video-${pendingVisitId}`,
    closeButton: false,
    autoClose: 10000,
    hideProgressBar: false,
  })
}
