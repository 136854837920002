import { createSlice } from '@reduxjs/toolkit'
import { omit } from 'lodash'

const defaultState = {
  meta: {
    isFetching: false,
    isLoginSuccess: false,
    isError: false,
    errors: {
      // [fetchType]: "error message"
    },
  },
  fetching: [], // array of fetch types currently being fetched
  mfa: {
    mfaRequired: false,
    choices: {
      email: '',
      phones: [],
    },
  },
  isMenuHidden: false,
  source: undefined,
  campaign: undefined,
}

const slice = createSlice({
  name: 'api',
  initialState: defaultState,
  reducers: {
    setFetching: (state, action) => {
      const { isFetching, fetchType } = action.payload
      if (isFetching) {
        state.fetching.push(fetchType)
      } else {
        const index = state.fetching.indexOf(fetchType)
        if (index >= 0) {
          state.fetching.splice(index, 1)
        }
      }
      state.meta.isFetching = isFetching
    },
    putMfaOptions: (state, action) => {
      const { mfaChoices } = action.payload
      const { email = '', phones = [] } = mfaChoices
      state.mfa.choices.email = email
      state.mfa.choices.phones = phones
      state.mfa.mfaRequired = true
    },
    clearMfa: (state) => {
      state.mfa.mfaRequired = false
      state.mfa.choices.email = ''
      state.mfa.choices.phones = []
    },
    setError: (state, action) => {
      const { error, fetchType } = action.payload
      state.meta.errors = {
        ...state.meta.errors,
        [fetchType]: error,
      }
      state.meta.isError = true
    },
    clearError: (state, action) => {
      state.meta.errors = omit(state.meta.errors, [action.payload.fetchType])
      state.meta.isError = Object.keys(state.meta.errors).length > 0
    },
    setIsMenuHidden: (state, action) => {
      state.isMenuHidden = action.payload
    },
    setSource: (state, action) => {
      state.source = action.payload
    },
    clearSource: (state) => {
      state.source = undefined
    },
    setCampaign: (state, action) => {
      state.campaign = action.payload
    },
    clearCampaign: (state) => {
      state.campaign = undefined
    },
  },
})

const { actions, reducer } = slice
export const {
  setFetching,
  putMfaOptions,
  setError,
  clearError,
  setIsMenuHidden,
  clearMfa,
  setSource,
  clearSource,
  setCampaign,
  clearCampaign,
} = actions
export default reducer
