import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import * as APM from 'util/apm'
import App from './App'
import Config from './config'

import './css/tailwind.css'
import 'material-react-toastify/dist/ReactToastify.css'
import './css/toast-overrides.css'
import 'react-loading-skeleton/dist/skeleton.css'

APM.initialize()

if (Config.isProduction) {
  console.log = () => {}
  console.error = () => {}
}

createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <App />
  </StrictMode>,
)
