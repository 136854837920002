import * as Yup from 'yup'

export const ParticipantSchema = Yup.object().shape({
  id: Yup.string().label('Participant ID').required(),
  userType: Yup.string().label('User Type').oneOf(['member', 'provider'], 'Invalid User Type').required(),
})

export const ChatSchema = Yup.object().shape({
  pendingVisitId: Yup.string().label('Pending Visit ID').required(),
  participants: Yup.array().label('Participants').of(ParticipantSchema).required(),
})
