import { FC, SVGProps } from 'react'

type TypeProtocol = 'medical' | 'therapy' | 'psychiatry' | 'hc'

type CareProtocol = {
  type: TypeProtocol
  protocol: string
}

export const GROUP_TYPES = {
  student: 'Student',
  facultyStaff: 'FacultyStaff',
  k12FacultyStaff: 'K12FacultyStaff',
} as const

export const COMMUNITY_GROUP_TYPES = {
  student: 'Student',
  facultyStaff: 'FacultyStaff',
} as const

export const GROUP_TYPES_TO_COMMUNITY_GROUP_TYPE_MAP = {
  [GROUP_TYPES.student]: COMMUNITY_GROUP_TYPES.student,
  [GROUP_TYPES.facultyStaff]: COMMUNITY_GROUP_TYPES.facultyStaff,
  [GROUP_TYPES.k12FacultyStaff]: COMMUNITY_GROUP_TYPES.facultyStaff,
} as const

export type GroupTypeDisplay = {
  name: string
  backgroundColor: string
  textColor: string
  icon: FC<SVGProps<SVGElement>>
}

export type GroupType = (typeof GROUP_TYPES)[keyof typeof GROUP_TYPES]
export type CommunityGroupType = (typeof COMMUNITY_GROUP_TYPES)[keyof typeof COMMUNITY_GROUP_TYPES]
export type GroupTypeToCommunityGroupTypeMap = Record<GroupType, CommunityGroupType>

export type Group = {
  groupId: string
  name: string
  groupFee: number
  accessCode: null | string
  passwordExpiration: number
  passwordLength: number
  services: [] | any[]
  mfaEnabled: boolean
  affiliations: [] | any[]
  dependentsEnabled: boolean
  canPreviewComponents: null
  client: string
  careProtocols: CareProtocol[]
  groupType: GroupType
}

export const STUDENT_CATEGORIES: readonly string[] = [
  'Anxiety',
  'Coursework',
  'Depression',
  'Family & Friends',
  'Finances',
  'General',
  'Goals',
  'Graduation',
  'Gratitude',
  'Job Market',
  'LGBTQ+',
  'Relationships',
  'Self-Care',
  'Stress',
  'Student Athletics',
  'Student Life',
  'Work',
]

export const FACULTY_STAFF_CATEGORIES: readonly string[] = [
  'Anxiety',
  'Burnout',
  'Career',
  'Caregiving',
  'Depression',
  'Finances',
  'General',
  'Goals',
  'Gratitude',
  'LGBTQ+',
  'Personal Relationships',
  'Self-Care',
  'Social Support',
  'Stress',
  'Work Relationships',
]

export const K12_FACULTY_STAFF_CATEGORIES: readonly string[] = [
  'Anxiety',
  'Burnout',
  'Career',
  'Caregiving',
  'Depression',
  'Finances',
  'General',
  'Goals',
  'Gratitude',
  'LGBTQ+',
  'Personal Relationships',
  'Self-Care',
  'Social Support',
  'Stress',
  'Work Relationships',
]

export const ALL_CATEGORIES = [
  ...new Set([...STUDENT_CATEGORIES, ...FACULTY_STAFF_CATEGORIES, ...K12_FACULTY_STAFF_CATEGORIES]),
] as const

type AllCategoryValues = (typeof ALL_CATEGORIES)[number]

export type CategoryOption = {
  label: AllCategoryValues
  value: AllCategoryValues
}

export const PACKAGE_OPTIONS = {
  ALL: null,
  DIGITAL_ONLY: true,
  CLINICAL_ONLY: false,
} as const
