import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import configBuilder from '../config-builder'
import MedicalHistorySchema from '../../../schemas/MedicalHistorySchema'
import { normalizeJsonToApi } from '../../helpers'

const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  memberId: Yup.string().required(),
  medicalHistory: MedicalHistorySchema.required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const make = async (params) => {
  const medicalHistory = MedicalHistorySchema.cast(params.medicalHistory)
  // TODO - fix types of normalized JSON
  const payload: any = normalizeJsonToApi({ medicalHistory })
  if (!payload.medical_history.lifestyle) payload.medical_history.lifestyle = {}
  return payload
}
export const url = ({ memberId }) => `/v2/${memberId}/personal/history`
export const execute = async (params) =>
  api.put(url(params), await make(params), configBuilder(params)).catch(catchApiFailure)
