import { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getPageTitle } from 'redux/selectors/view'

const A11yMessage = () => {
  const titleRef = useRef<HTMLSpanElement>(null)
  const pageTitle = useSelector(getPageTitle)
  useEffect(() => {
    titleRef.current?.focus()
  }, [pageTitle])

  return (
    <div>
      <span ref={titleRef} tabIndex={-1} className="sr-only" role="status" aria-live="assertive" aria-atomic="true">
        {`Navigated to ${pageTitle} page.`}
      </span>
    </div>
  )
}

export default A11yMessage
