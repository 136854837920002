import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { isFlaggedOn } from '.'

const useFeatureFlagToggle = (flagName: string) => {
  const flags = useFlags()

  return isFlaggedOn(flagName, flags)
}

export default useFeatureFlagToggle
