import moment from 'moment'
import FetchType from '../../util/fetch-types'
import * as SessionKeys from '../../util/session-constants'
import * as LocalKeys from '../../util/local-constants'
import { handleRequestFailure, makeRequest } from '../../api/helpers'
import { clearCampaign, clearMfa, clearSource, putMfaOptions, setFetching, setIsMenuHidden } from '../slices/api'
import Scenes from '../../util/Scenes'
import { extractMemberData, prepareDashboard, fetchMe } from './members'
import { putAlert } from '../slices/view'
import { phoneFormat } from '../../util/form'
import { SUPPORT_NUMBER } from '../../util/support'
import { clearAcknowledgments, clearMembers, putLoggedInMember, putMember } from '../slices/members'
import { clearClients, clearNeeds, clearServices } from '../slices/services'
import { clearPharmacies } from '../slices/pharmacies'
import { clearTopics } from '../slices/pusher'
import { clearAllergies } from '../slices/allergies'
import { clearChats } from '../slices/chats'
import { clearPendingVisits, clearVisits } from '../slices/visits'
import { clearConditions } from '../slices/conditions'
import { clearMedicalHistories } from '../slices/medical-history'
import { clearFamilyHistories } from '../slices/family-history'
import { clearImages } from '../slices/images'
import { clearResources } from '../slices/resources'
import { clearProviders } from '../slices/providers'
import { clearPaymentMethods } from '../slices/payments'
import PusherClient from '../../util/pusher'
import { fetchMemberNeeds } from './clients'
import * as APM from 'util/apm'
import { clearMessages } from 'redux/slices/messages'
import { clearCommunity } from 'redux/slices/community'
import {
  clearCategories,
  clearComponents,
  clearExperimentalRandomizedComponentIds,
  clearExperimentalRandomizedJourneyIds,
  clearJourneys,
  clearSelfCareRandomizedContent,
} from 'redux/slices/explore'
import { getSource } from 'redux/selectors/api'
import { removeFromStorage, sessionPersistence } from 'util/storage'
import { insertAuthValuesIntoSessionStorage } from 'util/auth'

export const fetchAuthChallenge = (email) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.authChallenge, isFetching: true }))
  let success = false
  let result = null
  const params = { email }
  const handleSuccess = (data) => {
    success = true
    result = data
  }
  await makeRequest(FetchType.authChallenge, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(setFetching({ fetchType: FetchType.authChallenge, isFetching: false }))
  return { success, data: result }
}

// TODO: Make dob optional once this file is converted to typescript
export const lookupMemberRecord =
  ({ email, dob }) =>
  async (dispatch) => {
    dispatch(setFetching({ fetchType: FetchType.lookupMember, isFetching: true }))
    let success = false
    let result = null
    const params = { email, dob }
    const handleSuccess = async (data) => {
      if (data && data.member && data.member.status === 'ineligible') {
        dispatch(
          putAlert({
            type: 'error',
            body:
              "Sorry, but we're unable to verify your eligibility at this time. " +
              'If you need assistance, please contact Customer Support at ' +
              phoneFormat(SUPPORT_NUMBER),
            title: 'Unable to Verify Eligibility',
            success: false,
          }),
        )
        success = false
      } else {
        await dispatch(putMember({ member: data }))
        success = true
        result = data
      }
    }
    await makeRequest(FetchType.lookupMember, params, handleSuccess, handleRequestFailure(dispatch))
    dispatch(setFetching({ fetchType: FetchType.lookupMember, isFetching: false }))
    return { success, data: result }
  }

export const validatePhoneNumber = (phone) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.validatePhoneNumber, isFetching: true }))
  let success = false
  let result = null
  const previouslyValidated = JSON.parse(await sessionPersistence.get(SessionKeys.VALIDATED_NUMBERS)) || {}
  if (phone in previouslyValidated) {
    // already attempted validation - return previously fetched validation result;
    success = previouslyValidated[phone]
    dispatch(setFetching({ fetchType: FetchType.validatePhoneNumber, isFetching: false }))
    return { success, data: { valid: success, phone } }
  }
  const params = { phone }
  const handleSuccess = async ({ valid }) => {
    previouslyValidated[phone] = valid
    sessionPersistence.set(SessionKeys.VALIDATED_NUMBERS, JSON.stringify(previouslyValidated))
    success = valid
    result = { valid, phone }
  }
  await makeRequest(FetchType.validatePhoneNumber, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(setFetching({ fetchType: FetchType.validatePhoneNumber, isFetching: false }))
  return { success, data: result }
}

export const logout = () => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.logout, isFetching: true }))
  const refreshToken = sessionPersistence.get(SessionKeys.REFRESH_TOKEN)
  const params = { refreshToken }
  let success = false
  const handleSuccess = async () => {
    success = true
  }
  await makeRequest(FetchType.logout, params, handleSuccess, handleRequestFailure(dispatch))

  try {
    await PusherClient.disconnect()
    removeFromStorage(SessionKeys.MEMBER_ID)
    removeFromStorage(SessionKeys.MEMBER_IS_SSO)

    removeFromStorage(SessionKeys.TOKEN)
    removeFromStorage(SessionKeys.REFRESH_TOKEN)
    removeFromStorage(SessionKeys.TOKEN_EXPIRES_AT)

    removeFromStorage(SessionKeys.MEMBER_ID)

    removeFromStorage(SessionKeys.TOKEN)
    removeFromStorage(SessionKeys.REFRESH_TOKEN)
    removeFromStorage(SessionKeys.TOKEN_EXPIRES_AT)

    removeFromStorage(SessionKeys.VISIT_RATINGS)

    removeFromStorage(LocalKeys.CODE_VERIFIER)

    dispatch(clearTopics())
    dispatch(clearMembers())
    dispatch(clearVisits())
    dispatch(clearPendingVisits())
    dispatch(clearPaymentMethods())
    dispatch(clearChats())
    dispatch(clearClients())
    dispatch(clearServices())
    dispatch(clearNeeds())
    dispatch(clearPharmacies())
    dispatch(clearAllergies())
    dispatch(clearConditions())
    dispatch(clearMedicalHistories())
    dispatch(clearFamilyHistories())
    dispatch(clearImages())
    dispatch(clearResources())
    dispatch(clearProviders())
    dispatch(putLoggedInMember({ memberId: null }))
    dispatch(clearMessages())
    dispatch(clearCommunity())
    dispatch(clearSelfCareRandomizedContent())
    dispatch(clearComponents())
    dispatch(clearJourneys())
    dispatch(clearCategories())
    dispatch(clearSource())
    dispatch(clearCampaign())
    dispatch(clearAcknowledgments())
    // TODO: BIT-4654 Self-care randomization experiment - can be removed once experiment is complete
    dispatch(clearExperimentalRandomizedJourneyIds())
    dispatch(clearExperimentalRandomizedComponentIds())
    // history.push(paths.LOGIN);
    APM.setUser(null)
  } catch (e) {
    console.error(e)
    APM.captureException(e)
  }
  dispatch(setFetching({ fetchType: FetchType.logout, isFetching: false }))
  return { success }
}

export const forgotPassword = (email, userType) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.forgotPassword, isFetching: true }))
  const params = { email, userType }
  let success = false
  const handleSuccess = async () => {
    success = true
  }
  await makeRequest(FetchType.forgotPassword, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(setFetching({ fetchType: FetchType.forgotPassword, isFetching: false }))
  return { success }
}

export const generateMfa = (mfaChoice) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.generateMfa, isFetching: true }))
  let success = false
  const params = { mfaChoice }
  const handleSuccess = () => {
    success = true
  }
  await makeRequest(FetchType.generateMfa, params, handleSuccess, handleRequestFailure(dispatch))

  dispatch(setFetching({ fetchType: FetchType.generateMfa, isFetching: false }))
  return { success }
}

export const verifyMfa = (mfaCode) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.verifyMfa, isFetching: true }))
  let success = false
  let nextScene = Scenes.login.dashboard
  let memberId = null
  const params = { mfaCode }
  const handleSuccess = async (data) => {
    await insertAuthValuesIntoSessionStorage(data)
    if (data.passwordExpired) nextScene = Scenes.login.expiredPassword
    if (nextScene === Scenes.login.dashboard && data.member) {
      await dispatch(extractMemberData(data))
      await dispatch(prepareDashboard(data.memberId, data.dependentData))
      await dispatch(fetchMemberNeeds())
      dispatch(clearMfa())
    }
    memberId = data.memberId
    success = true
  }
  await makeRequest(FetchType.verifyMfa, params, handleSuccess, handleRequestFailure(dispatch))

  dispatch(setFetching({ fetchType: FetchType.verifyMfa, isFetching: false }))
  return { success, nextScene, memberId }
}

export const updatePassword = (oldPassword, password) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.updatePassword, isFetching: true }))
  const params = { oldPassword, password }
  let success = false
  const handleSuccess = async () => {
    success = true
  }
  await makeRequest(FetchType.updatePassword, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(setFetching({ fetchType: FetchType.updatePassword, isFetching: false }))
  return { success }
}

export const resetPassword = (resetToken, password) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.resetPassword, isFetching: true }))
  const params = { resetToken, password }
  let success = false
  const handleSuccess = async () => {
    success = true
  }
  await makeRequest(FetchType.resetPassword, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(setFetching({ fetchType: FetchType.resetPassword, isFetching: false }))
  return { success }
}

export const verifyAccessCode = (accessCode) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.verifyAccessCode, isFetching: true }))
  const params = { accessCode }
  let success = false
  const handleSuccess = async () => {
    success = true
  }
  await makeRequest(FetchType.verifyAccessCode, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(setFetching({ fetchType: FetchType.verifyAccessCode, isFetching: false }))
  return { success }
}

export const attemptLogin = (email, password) => async (dispatch, getState) => {
  // Source is derived from params to track user origin
  const source = getSource(getState())
  dispatch(setFetching({ fetchType: FetchType.login, isFetching: true }))
  let success = false
  let nextScene = Scenes.login.dashboard
  const handleSuccess = async (data) => {
    await insertAuthValuesIntoSessionStorage(data)
    if (data.passwordExpired) nextScene = Scenes.login.expiredPassword
    if (data.mfaRequired) {
      nextScene = Scenes.login.generateMfa
      await dispatch(putMfaOptions({ mfaChoices: data.mfaChoices }))
    } else if (nextScene === Scenes.login.dashboard && data.member) {
      await dispatch(extractMemberData(data))
      await dispatch(prepareDashboard(data.memberId, data.dependentData))
      await dispatch(fetchMemberNeeds())
    }
    success = true
  }
  await makeRequest(FetchType.login, { email, password, source }, handleSuccess, handleRequestFailure(dispatch))
  dispatch(setFetching({ fetchType: FetchType.login, isFetching: false }))
  return { success, nextScene }
}

export const determineRequestNeediness =
  ({ cacheTtlInMinutes, fetchNext, lastFetchSelector, existingSelector }) =>
  async (dispatch, getState) => {
    const state = getState()
    const lastFetch = lastFetchSelector(state)
    const existing = !existingSelector || existingSelector(state)
    const lastAcceptableUpdate = moment().subtract(cacheTtlInMinutes, 'minutes')
    if (!existing || !lastFetch || lastAcceptableUpdate.isAfter(lastFetch)) {
      dispatch(fetchNext)
    }
  }

export const setMenuHiddenVisibility = (hideMenu) => async (dispatch) => {
  dispatch(setIsMenuHidden(hideMenu))
}

export const postOAuthToken =
  ({ code, codeVerifier }) =>
  async (dispatch) => {
    dispatch(setFetching({ fetchType: FetchType.postOAuthToken, isFetching: true }))
    let success = false
    const params = { code, codeVerifier }
    let result = null
    const handleSuccess = (data) => {
      result = data
      success = true
    }
    await makeRequest(FetchType.postOAuthToken, params, handleSuccess, handleRequestFailure(dispatch))
    dispatch(setFetching({ fetchType: FetchType.postOAuthToken, isFetching: false }))
    return { success, data: result }
  }

export const unsubscribeEmail = (uniqueId) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.unsubscribeEmail, isFetching: true }))
  let success = false
  const params = { uniqueId }
  const handleSuccess = () => {
    success = true
  }
  await makeRequest(FetchType.unsubscribeEmail, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(setFetching({ fetchType: FetchType.unsubscribeEmail, isFetching: false }))
  return { success }
}

export const registerForPushNotifications = (token, memberId, device) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.updatePushRegistration, isFetching: true }))
  let success = false
  const params = {
    memberId,
    registration: {
      device,
      token: {
        type: 'webfcm',
        data: token,
      },
    },
  }
  const handleSuccess = () => {
    success = true
  }
  const handleFailure = () => {
    console.log('Failed to register for push notifications.')
  }
  await makeRequest(FetchType.updatePushRegistration, params, handleSuccess, handleFailure)
  dispatch(setFetching({ fetchType: FetchType.updatePushRegistration, isFetching: false }))
  return { success }
}
