import { createSlice } from '@reduxjs/toolkit'
import { Allergy } from 'types/member/medicalHistory'

export type AllergyState = {
  meta: object
  records: {
    [allergyId: string]: Allergy
  }
}

const defaultState = {
  meta: {},
  records: {
    // [allergyId]: Allergy
  },
} satisfies AllergyState

const getId = (allergy: Allergy) => allergy.allergyId

const slice = createSlice({
  name: 'allergy',
  initialState: defaultState,
  reducers: {
    putAllergies: (state, action) => {
      const { allergies } = action.payload
      allergies.forEach((allergy: Allergy) => (state.records[getId(allergy)] = allergy))
    },
    putAllergy: (state, action) => {
      const { allergy } = action.payload
      state.records[getId(allergy)] = allergy
    },
    clearAllergies: (state) => {
      state.records = { ...defaultState.records }
    },
    clearAllergy: (state, action) => {
      const { allergyId } = action.payload
      delete state.records[allergyId]
    },
  },
})

const { actions, reducer } = slice
export const { putAllergies, putAllergy, clearAllergies, clearAllergy } = actions
export default reducer
