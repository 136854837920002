import classNames from '@ui-components-3/ui/lib/utils/classNames'
import { Link } from 'react-router-dom'
import { ACCOUNT_SERVICES, HELP_CENTER } from 'routes/paths'
import CirclePlus from '@ui-components-3/icons/lib/duotone/CirclePlus'

type EmptyServicesStateProps = {
  className?: string
}

const EmptyServicesState = ({ className }: EmptyServicesStateProps) => {
  return (
    <div className={classNames('flex flex-col items-start gap-y-4', className)}>
      <h2 className="typography-h5 mb-4 text-neutral-800">It appears that no services have been added yet.</h2>
      <p className="typography-body text-neutral-800">
        If you believe this is an error or need assistance, please reach out to our support team in the{' '}
        <Link to={HELP_CENTER} className="text-link-primary">
          Help Center
        </Link>
        .
      </p>
      <Link to={ACCOUNT_SERVICES} className="btn-small btn-primary-outlined mt-6">
        Add Services
        <CirclePlus aria-hidden="true" />
      </Link>
    </div>
  )
}

export default EmptyServicesState
