class AccordionState {
  private activeKeys = new Set<string>()
  private _subscriptions = new Set<() => void>()

  private _run() {
    this._subscriptions.forEach((s) => s())
  }

  setActiveKey(key: string, allowMultipleOpen: boolean) {
    if (allowMultipleOpen) {
      if (this.activeKeys.has(key)) {
        this.activeKeys.delete(key)
      } else {
        this.activeKeys.add(key)
      }
    } else {
      this.activeKeys.clear()
      this.activeKeys.add(key)
    }
    this._run()
  }

  isActiveKey(key: string) {
    return this.activeKeys.has(key)
  }

  on = (fn: () => void) => {
    this._subscriptions.add(fn)
    return () => this._subscriptions.delete(fn)
  }
}

export default AccordionState
