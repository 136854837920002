import * as Yup from 'yup'
import configBuilder from '../config-builder'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import { normalizeJsonToApi } from '../../helpers'
import omit from 'lodash/omit'

const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  firstName: Yup.string().when('accessCode', {
    is: (code) => !!code,
    then: Yup.string().trim().required(),
    otherwise: Yup.string().strip().notRequired().nullable(),
  }),
  lastName: Yup.string().when('accessCode', {
    is: (code) => !!code,
    then: Yup.string().trim().required(),
    otherwise: Yup.string().strip().notRequired().nullable(),
  }),
  firstNamePreferred: Yup.string().when('accessCode', {
    is: (code) => !!code,
    then: Yup.string().notRequired(),
    otherwise: Yup.string().strip().notRequired().nullable(),
  }),
  dob: Yup.string().when('accessCode', {
    is: (code) => !!code,
    then: Yup.string()
      .matches(/^(\d{4}-\d{2}-\d{2})$/)
      .required(),
    otherwise: Yup.string()
      .matches(/^(\d{4}-\d{2}-\d{2})$/)
      .notRequired()
      .nullable(),
  }),
  graduationSemester: Yup.string().when('accessCode', {
    is: (code) => !!code,
    then: Yup.string().notRequired(),
    otherwise: Yup.string().strip().notRequired().nullable(),
  }),
  graduationYear: Yup.string().when('accessCode', {
    is: (code) => !!code,
    then: Yup.string().notRequired(),
    otherwise: Yup.string().strip().notRequired().nullable(),
  }),
  email: Yup.string().email().required(),
  accessCode: Yup.string().nullable().notRequired(),
  groupId: Yup.string().notRequired().nullable(),
  userId: Yup.string().notRequired().nullable(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const make = async (params) => {
  const fieldsToRemove = []

  if (!params.firstNamePreferred?.length) fieldsToRemove.push('firstNamePreferred')
  if (!params.graduationSemester) fieldsToRemove.push('graduationSemester')
  if (!params.graduationYear) fieldsToRemove.push('graduationYear')

  const cleanedParams = omit(params, fieldsToRemove)
  const cleaned = await RequestSchema.validate(cleanedParams)

  return normalizeJsonToApi(cleaned, undefined)
}
export const url = () => '/v3/lookup'
export const execute = async (params) =>
  api.post(url(params), await make(params), configBuilder(params)).catch(catchApiFailure)
