import { useFlags } from 'launchdarkly-react-client-sdk'
import { isFlaggedOn } from '.'
import { useSelector } from 'react-redux'
import { getHasLDIdentified } from 'redux/selectors/auth'

type FeatureFlagResult = {
  flagValue: boolean
  isReady: boolean
  // WARNING: Any use of FF values that require LD identify to have been called (in AuthRoute) must wait for hasLDIdentified to be true (most obvious when a browser refresh occurs)
  hasLDIdentified?: boolean
}

const useFeatureFlagDetailsToggle = (flagName: string): FeatureFlagResult => {
  const flags = useFlags()

  const flagValue = isFlaggedOn(flagName, flags)
  const isReady = flags?.[flagName] !== undefined
  const hasLDIdentified = useSelector(getHasLDIdentified)

  return { flagValue, isReady, hasLDIdentified }
}

export default useFeatureFlagDetailsToggle
