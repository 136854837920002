import { useMemo } from 'react'

import { capEveryWord, getPharmacySpecialties } from '../../../util/pharmacies'
import LocationIcon from '@ui-components-3/icons/lib/light/LocationDot'
import DeleteIcon from '@ui-components-3/icons/lib/duotone/Trash'

import TestIds from '../../../util/TestIds'
import { isEmpty } from 'lodash'
import { phoneFormat } from '../../../util/formatters'
import { PharmacyModel } from 'types/visit/pendingVisit'
import ExternalLink from 'components/common/ExternalLink'

const onMapLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
  e.stopPropagation()
}

type PharmacyListItemProps = {
  pharmacy: PharmacyModel
  onRemoveClick: () => void
  'data-testid'?: string
}

const PharmacyListItem = (props: PharmacyListItemProps) => {
  const { pharmacy } = props

  const streetAddress = useMemo(() => {
    if (!pharmacy) return ''
    const { address1, address2 } = pharmacy
    let streetAddress = address1
    if (address2) streetAddress += ` ${address2}`
    return capEveryWord(streetAddress)
  }, [pharmacy])

  const city = useMemo(() => {
    if (!pharmacy) return ''
    return capEveryWord(pharmacy.city)
  }, [pharmacy])

  const mapUrl = useMemo(() => {
    if (!pharmacy || !pharmacy.point || isEmpty(pharmacy.point.coordinates)) {
      return null
    }
    const [lng, lat] = pharmacy.point.coordinates
    return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}&label=${pharmacy.storeName}`
  }, [pharmacy])

  const specialty = useMemo(() => {
    return getPharmacySpecialties(pharmacy)
  }, [pharmacy])

  return (
    <div className="flex-1">
      {!!pharmacy?.storeName && (
        <h3 className="typography-body-l mb-3 font-semibold text-neutral-700">
          {pharmacy.storeName}
          <br />
          {specialty}
        </h3>
      )}
      <p className="typography-body mb-2 text-neutral-600">
        {streetAddress} <br />
        {city}, {pharmacy?.state} {pharmacy?.zip}
      </p>
      <p className="typography-body mb-7 font-semibold text-neutral-700">{phoneFormat(pharmacy?.phone)}</p>
      <div className="flex items-center justify-between">
        <ExternalLink
          className="btn-small btn-primary-outlined bg-white"
          href={mapUrl}
          aria-disabled={!mapUrl}
          data-testid={TestIds.newVisit.button.locate}
          onClick={onMapLinkClick}
        >
          <LocationIcon className="mr-2 h-[24px] w-[18px]" aria-hidden="true" />
          Find On Map
        </ExternalLink>
        <button
          className="btn btn-neutral-borderless text-primary-700 mr-[-56px]"
          onClick={props.onRemoveClick}
          aria-label={'Delete pharmacy'}
          type="button"
        >
          <DeleteIcon className="h-[24px] w-[24px]" aria-hidden="true" />
        </button>
      </div>
    </div>
  )
}

export default PharmacyListItem
