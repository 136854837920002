import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import { normalizeJsonToApi } from 'api/helpers'

const api = ApiFactory.getApi(ApiTypes.consent)

const RequestSchema = Yup.object().shape({
  preApprovalId: Yup.string().required(),
})

export type Request = Yup.InferType<typeof RequestSchema>

export const isValid = async (params: Request) => {
  return await RequestSchema.isValid(params)
}
export const validate = async (params: Request) => RequestSchema.validate(params)


export const make = async (params: Request) => normalizeJsonToApi({
  self_consent_pre_approval_id: params.preApprovalId,
})


export const url = () => `/v1/consents/self/confirmations`
export const execute = async (params: Request) => api.post(url(), await make(params)).catch(catchApiFailure)
