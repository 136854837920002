import { AddressUpdateMember } from 'types/account/form'
import Member, { Phone } from 'types/member/member'
import { Language } from 'types/provider/provider'

export const cleanPhoneNumberInput = (number: string) => ('' + number).replace(/\D/g, '').substring(0, 10)

export const cleanNumberInput = (number: string) => ('' + number).replace(/\D/g, '')
export const phoneFormat = (number: string) => {
  const clean = cleanNumberInput(number)
  const match = clean.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`
  }
}

export const phoneInputFormat = (number: string) => {
  const clean = cleanNumberInput(number)
  const area = clean.substring(0, 3)
  const exchange = clean.substring(3, 6)
  const remain = clean.substring(6, 10)

  if (clean.length > 6) return `(${area}) ${exchange}-${remain}`
  if (clean.length > 3) return `(${area}) ${exchange}`
  if (clean.length) return `(${area}`
}

export const accessCodeInputFormat = (code: string) => {
  if (!code) return ''
  const chars = code.split('').map((char) => char.toUpperCase())
  return chars.join('-')
}

export const cleanAccessCodeInput = (code: string) => {
  if (!code) return
  const chars = code.split('-').map((char) => char.toUpperCase())
  return chars.join('')
}

export const stringArrayToSeries = (words: string[]) =>
  words.length < 3 ? words.join(' and ') : `${words.slice(0, -1).join(', ')}and ${words.at(-1)}`

type Payload = {
  email: string
  gender: string
  memberId: string
  phones: Phone[]
  mailingAddress: Partial<AddressUpdateMember>
  billingAddress: Partial<AddressUpdateMember>
  firstName?: string
  lastName?: string
  firstNamePreferred?: string
  degreeClassification?: string
  graduationSemester?: string
  graduationYear?: string
  dob?: string
  ethnicity?: string[]
  genderIdentity?: string
  genderSelfSelect?: string
  pronoun?: string
  preferredLanguage?: Language
}

export const newMemberToPostPayload = (member: Member & { address: AddressUpdateMember; phone: Phone }) => {
  const address = {
    ...(member.address || {}),
  }
  if (!address.street2) {
    delete address.street2
  }
  const payload: Payload = {
    email: member.email,
    gender: member.gender,
    memberId: member.memberId,
    phones: [{ ...member.phone, preferred: true }],
    mailingAddress: {
      ...address,
      country: 'US',
    },
    billingAddress: {
      ...address,
      country: 'US',
    },
  }
  if (member.firstName) {
    payload.firstName = member.firstName
  }
  if (member.lastName) {
    payload.lastName = member.lastName
  }
  if (member.firstNamePreferred) {
    payload.firstNamePreferred = member.firstNamePreferred
  }
  if (member.degreeClassification) {
    payload.degreeClassification = member.degreeClassification
  }
  if (member.graduationSemester) {
    payload.graduationSemester = member.graduationSemester
  }
  if (member.graduationYear) {
    payload.graduationYear = member.graduationYear
  }
  if (member.dob) {
    payload.dob = member.dob
  }
  if (member.ethnicity) {
    payload.ethnicity = member.ethnicity
  }
  if (member.genderIdentity) {
    payload.genderIdentity = member.genderIdentity
  }
  if (member.genderSelfSelect) {
    payload.genderSelfSelect = member.genderSelfSelect
  }
  if (member.pronoun) {
    payload.pronoun = member.pronoun
  }
  if (!!member.preferredLanguage && !!member.preferredLanguage.name) {
    payload.preferredLanguage = member.preferredLanguage
  }
  return payload
}
