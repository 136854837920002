import { createSelector } from 'reselect'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'

export const getMeta = (state) => state.member.meta
export const getRecords = (state) => state.member.records
export const getDuplicates = (state) => state.member.duplicates
export const getNames = (state) => state.member.names
export const getAcknowledgments = (state) => state.member.acknowledgments

export const getProviders = (state) => state.member.providers
export const getDependentsRecords = (state) => state.member.dependents.records
export const getFetches = createSelector(getMeta, (meta = {}) => meta.fetches)

export const getRecordArray = createSelector(getRecords, (records = {}) => Object.values(records))

export const getMembers = createSelector(getRecordArray, (records = []) => {
  return records.filter((member) => !!member.memberId)
})

export const getMemberIds = createSelector(getMembers, (records) => records.map((member) => member.memberId))

export const getLoggedInMemberId = createSelector(getMeta, (meta = {}) => meta.loggedInMemberId)

export const getLoggedInMemberHasUnsavedDuplicates = createSelector(
  getLoggedInMemberId,
  getDuplicates,
  (loggedInMemberId, duplicates = {}) =>
    !!Object.values(duplicates?.[loggedInMemberId] || {}).filter((x) => !x.taskId).length,
)

export const getLoggedInMemberDuplicates = createSelector(
  getLoggedInMemberId,
  getDuplicates,
  (loggedInMemberId, duplicates = {}) => {
    return Object.values(duplicates?.[loggedInMemberId] || {}) || []
  },
)

export const getDuplicateAccount = (dupeId) =>
  createSelector(getLoggedInMemberDuplicates, (dupes = []) => dupes.find((d) => d.duplicateMemberId === dupeId))

export const getLoggedInMember = createSelector(
  getMeta,
  getRecords,
  (meta = {}, records = {}) => records[meta?.loggedInMemberId],
)

export const getLoggedInMemberGroupType = createSelector(getMeta, (meta) => meta && meta.loggedInMemberGroup?.groupType)
export const getDigitalOnlyStatus = createSelector(getMeta, (meta) => meta && meta.loggedInMemberGroup?.digitalOnly)

export const getMember = (memberId) => createSelector(getRecords, (records = {}) => records[memberId])

export const getMemberAge = (memberId) =>
  createSelector(getMember(memberId), (member = {}) =>
    !member.dob ? 0 : Math.abs(moment(member.dob, 'YYYY-MM-DD').diff(new Date(), 'years')),
  )

export const getHasConsentFiled = (memberId) =>
  createSelector(
    getMember(memberId),
    (member = {}) => (!member.consentFilename ? false : member.consentFilename), // Return/need filename?
  )

export const getMemberProviders = (memberId) => createSelector(getProviders, (providers = {}) => providers[memberId])

export const getMemberProvider = (memberId, providerId) =>
  createSelector(getMemberProviders(memberId), (memberProviders = {}) => {
    return memberProviders[providerId]
  })

export const getMemberProviderArray = (memberId) =>
  createSelector(getProviders, (providers = {}) => providers[memberId] && Object.values(providers[memberId]))

export const getNewMember = createSelector(getRecords, (records = {}) => records.create)

export const getMemberBio = (memberId) =>
  createSelector(
    getMember(memberId),
    (member = {}) =>
      !isEmpty(member) && {
        email: member.email,
        firstName: member.firstName,
        lastName: member.lastName,
        firstNamePreferred: member.firstNamePreferred,
        name: `${member.firstName} ${member.lastName}`,
        dateOfBirth: !!member.dob && moment(member.dob, 'YYYY-MM-DD').format('MMM DD, YYYY'),
        gender: member.gender,
        genderIdentity: member.genderIdentity === 'Other/not listed' ? member.genderSelfSelect : member.genderIdentity,
        graduationSemester: member.graduationSemester,
        graduationYear: member.graduationYear,
        createdAt: member.createdAt,
        updatedAt: member.updatedAt,
      },
  )

export const getLookupId = createSelector(getMeta, (meta = {}) => meta.lookupId)

export const getMemberDependents = (memberId) =>
  createSelector(getDependentsRecords, (records = {}) => {
    return Object.values(records).filter((rec) => rec.primaryMemberId === memberId)
  })

export const getMemberDependent = (dependentId) =>
  createSelector(getDependentsRecords, (records = {}) => {
    return records[dependentId]
  })

export const getMemberDependentIds = (memberId) =>
  createSelector(getMemberDependents(memberId), (dependents) => dependents.map((dependent) => dependent.memberId))

export const getMemberDependentCount = (memberId) =>
  createSelector(getMemberDependents(memberId), (dependents) => (!dependents ? 0 : dependents.length))

export const getMemberLastFetch = (memberId) => createSelector(getFetches, (fetches = {}) => fetches[memberId])

export const getMemberName = (memberId) => createSelector(getNames, (names = {}) => names[memberId])

export const getAgreedCommunityTCVersion = createSelector(
  getLoggedInMember,
  (member = {}) => member?.termsConditions?.find?.((term) => term.type === 'tc-community-rules')?.version,
)

export const getTcAppAgreementDate = createSelector(
  getLoggedInMember,
  (member = {}) => member?.termsConditions?.find?.((term) => term.type === 'tc-app')?.lastAcceptedAt,
)

export const getCommunityPostAgreementVersion = createSelector(
  getLoggedInMember,
  (member = {}) => member?.termsConditions?.find?.((term) => term.type === 'tc-post-agreement')?.version,
)

export const getNewServiceAcknowledgment = createSelector(
  getAcknowledgments,
  (acknowledgments = {}) => acknowledgments?.modalNewService?.showPrompt === true,
)
