import * as Yup from 'yup'
import { LanguageArray, LanguageCodes } from '../util/languages'

export const LanguageSchema = Yup.object().shape({
  name: Yup.string().oneOf(LanguageArray).required(),
  abbrev: Yup.string().oneOf(LanguageCodes).required(),
})

const NewVisitPreferencesSchema = Yup.object().shape({
  preferences: Yup.object().shape({
    gender: Yup.string(),
    ethnicity: Yup.array().of(Yup.string()),
    specialty: Yup.array().of(Yup.string()),
    languages: Yup.array().of(Yup.string()),
  }),
})

export default NewVisitPreferencesSchema
