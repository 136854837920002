import { isEmpty } from 'lodash'

import * as MemberSelectors from '../selectors/members'
import { putTopic } from '../slices/pusher'
import { PusherTypes, PusherTopics } from '../../util/pusher-topics'
import { fetchPendingVisit, fetchPendingVisits, fetchVisits } from './visits'
import { getChat } from './chat'

import { getPendingVisit, getVisitByPendingId } from '../selectors/visits'
import { putAlert, clearAlert } from '../slices/view'
import { fetchProviderIfNeeded } from './providers'
import { COMMUNITY, createVisitPath, DASHBOARD, EXPLORE, VISIT_CHAT_VIEW } from '../../routes/paths'
import { clearPendingVisits, clearVisits } from '../slices/visits'
import VisitStatus, { alertVisitCompletion, alertVisitUnavailability } from '../../util/visit-status'
import {
  clearPostComment,
  clearPost,
  putPost,
  putPostComments,
  clearPostDetail,
  putPostCounts,
  putNewPostId,
  putNewCommentId,
  putReaction,
  putCommentCounts,
} from '../slices/community'
import { getComment, getPost } from '../selectors/community'
import { getSelfCareComponent, getSelfCareJourney } from './explore'
import { clearComponent, clearJourney } from '../slices/explore'
import { EVisitModalities, getVisitPathByModality } from 'util/visit-modalities'
import { checkIsVisitAssessmentComplete, isAssessmentAvailableForVisitType } from 'util/visit-assessment'
import { putMessageDetails, putNewMessage } from 'redux/slices/messages'
import { readChatMessages } from 'redux/slices/chats'
import { fetchUnreadMessageCounts } from './messages'
import { toast } from 'util/toast'
import soundUrl from 'assets/sounds/chat_notification.wav'
import { isSuccess } from 'util/helpers'
import { GROUP_TYPES_TO_COMMUNITY_GROUP_TYPE_MAP } from 'types/client/group'
import { toastMergeFailed } from 'components/dashboard/AccountMerge/AccountMerge'

const newMessageAudio = new Audio(soundUrl)

export const parsePusherMessage = (message, history, flags) => async (dispatch, getState) => {
  const loggedInMemberId = MemberSelectors.getLoggedInMemberId(getState())
  const memberId = message?.memberId || loggedInMemberId
  const state = getState()
  const memberGroupType = MemberSelectors.getLoggedInMemberGroupType(state)
  const isMemberGroupTypeIncluded = (groupTypes) => Array.isArray(groupTypes) && groupTypes.includes(memberGroupType)

  console.log('MESSAGE RECEIVED:', message.type)
  switch (message.type) {
    case 'pusher:subscription_error': {
      console.log(message)
      break
    }
    case PusherTypes.createPendingVisit: {
      dispatch(fetchPendingVisits({ memberId }))
      break
    }
    case PusherTypes.reviewVisit: {
      if (message.meta?.pendingVisitId) {
        await dispatch(fetchPendingVisit(message.meta?.pendingVisitId, memberId))
        const pendingVisit = await getPendingVisit(message.meta.pendingVisitId)(getState())
        alertVisitCompletion(pendingVisit)
      }
      break
    }
    case PusherTypes.updatePendingVisit: {
      dispatch(fetchPendingVisits({ memberId }))
      break
    }
    case PusherTypes.videoCallWaiting: {
      break
    }
    case PusherTypes.completeVisit: {
      dispatch(fetchPendingVisits({ memberId }))
      dispatch(fetchVisits({ memberId }))
      if (message.meta?.pendingVisitId) {
        await dispatch(fetchPendingVisit(message.meta?.pendingVisitId, memberId))
        const pendingVisit = await getPendingVisit(message.meta.pendingVisitId)(getState())
        alertVisitCompletion(pendingVisit)
      }
      break
    }
    case PusherTypes.createChatMessage: {
      if (!isEmpty(message.meta)) {
        if (message.meta.chatId) {
          dispatch(getChat(message.meta.chatId))
        }
        if (message.meta.pendingVisitId) {
          const pendingVisit = await getPendingVisit(message.meta.pendingVisitId)(getState())
          if (!!pendingVisit && !pendingVisit.chatId) {
            await dispatch(fetchPendingVisit(message.meta.pendingVisitId, pendingVisit.memberId))
          }
          if (!history.location.pathname.includes(`${memberId}/visits/${message.meta.pendingVisitId}`)) {
            const visitPath = getVisitPathByModality(pendingVisit.modality)
            toast.success(<p className="typography-body p-2">Your provider just sent you a chat.</p>, {
              onClick: () => history.push(createVisitPath(visitPath, message.meta.pendingVisitId, memberId)),
              onOpen: newMessageAudio.play(),
              role: 'alert',
            })
          }
        }
      }
      break
    }
    case PusherTypes.scheduleVisitRequested:
    case PusherTypes.acceptScheduling:
    case PusherTypes.rejectScheduling: {
      dispatch(fetchPendingVisits({ memberId }))
      break
    }
    case PusherTypes.cancelScheduling: {
      dispatch(fetchPendingVisits({ memberId }))
      break
    }
    case PusherTypes.paymentFailure: {
      dispatch(
        putAlert({
          isAlert: true,
          type: 'error',
          body:
            'Unfortunately the visit payment attempt was unsuccessful' +
            ' and your consult has now been cancelled. Please create' +
            ' a new visit and use a different card',
          title: 'Payment Error',
          success: false,
        }),
      )
      dispatch(fetchPendingVisits({ memberId }))
      dispatch(fetchVisits({ memberId }))
      break
    }
    case PusherTypes.autoAssignVisit:
    case PusherTypes.visitClaimed: {
      dispatch(fetchPendingVisit(message.meta?.pendingVisitId, memberId))
      break
    }
    case PusherTypes.visitStarted: {
      const { data } = await dispatch(fetchPendingVisit(message.meta?.pendingVisitId, memberId))
      const state = getState()
      if (!!data && [EVisitModalities.video, EVisitModalities.chat].includes(data.modality)) {
        const successVisit = isSuccess(data.visitType)
        const member = MemberSelectors.getMember(memberId)(state)
        const isMemberOptedOut = member?.measurementIntakeOpt?.optedOut
        const shouldTakeAssessmentSurvey =
          !isMemberOptedOut &&
          !!data?.shouldScore &&
          isAssessmentAvailableForVisitType(data) &&
          !checkIsVisitAssessmentComplete(data)
        dispatch(fetchProviderIfNeeded(data.providerId))
        const visitPath = getVisitPathByModality(data.modality)
        if (visitPath) {
          dispatch(
            putAlert({
              visible: true,
              isSimple: true,
              type: undefined,
              body: !shouldTakeAssessmentSurvey
                ? `Your ${successVisit ? 'session' : 'visit'} has begun, please join now`
                : "Before joining the visit, you'll need to take a short health survey.",
              title: !shouldTakeAssessmentSurvey ? `Join ${successVisit ? 'Session' : 'Visit'}` : 'Take Intake',
              confirmRoute: !shouldTakeAssessmentSurvey
                ? createVisitPath(visitPath, data?.pendingVisitId, data?.memberId)
                : { pathname: DASHBOARD, state: { shouldOpenAssessment: true } },
              yesContent: !shouldTakeAssessmentSurvey
                ? `YES, JOIN ${successVisit ? 'SESSION' : 'VISIT'}`
                : 'TAKE INTAKE',
              noContent: 'NO',
            }),
          )
        }
      }
      break
    }
    case PusherTypes.visitUnclaimed:
    case PusherTypes.cancelVisit: {
      dispatch(clearPendingVisits())
      dispatch(fetchPendingVisits({ memberId }))
      dispatch(clearVisits())
      dispatch(fetchVisits({ memberId }))
      if (message?.type === PusherTypes.visitUnclaimed) {
        alertVisitUnavailability({
          redirectToHome: true,
          history,
          status: VisitStatus.pending,
        })
      } else {
        await dispatch(fetchPendingVisit(message.meta?.pendingVisitId, memberId))
        const pendingVisit = await getPendingVisit(message.meta.pendingVisitId)(getState())
        const isChatVisit =
          history.location.pathname !== createVisitPath(VISIT_CHAT_VIEW, message.meta.pendingVisitId, memberId)
        alertVisitUnavailability({
          redirectToHome: isChatVisit,
          history,
          status: VisitStatus.cancelled,
          pendingVisit,
        })
      }
      dispatch(clearAlert())
      break
    }
    case PusherTypes.updatePost:
    case PusherTypes.updatePostStatus: {
      const {
        status,
        postId,
        parentPostId,
        postType,
        counts,
        authorId,
        reactions,
        groupType: communityGroupType,
      } = message.meta
      const state = getState()
      const memberGroupType = MemberSelectors.getLoggedInMemberGroupType(state)

      if (GROUP_TYPES_TO_COMMUNITY_GROUP_TYPE_MAP[memberGroupType] !== communityGroupType) {
        break
      }

      const isComment = postType === 'comment'

      const selector = isComment ? getComment(parentPostId, postId) : getPost(postId)

      const createClearAction = () =>
        isComment
          ? clearPostComment({
              postId: parentPostId,
              commentId: postId,
            })
          : clearPost({ postId })

      const createPutAction = (existing) =>
        isComment
          ? putPostComments({
              postId: parentPostId,
              comments: [
                {
                  ...existing,
                  status,
                  counts,
                  reactions,
                },
              ],
            })
          : putPost({
              post: {
                ...existing,
                status,
                counts,
                reactions,
              },
            })

      const createNewPutAction = () =>
        isComment ? putNewCommentId({ postId, parentPostId }) : putNewPostId({ postId })

      const existing = selector(getState())

      if (status === 'denied') {
        if (existing) {
          if (!existing.isAuthor) {
            dispatch(createClearAction())
            if (history.location.pathname.includes('community/') && !isComment) {
              if (history.location.pathname.includes(`community/${postId}`)) {
                toast.error(<p style={{ lineHeight: '18px', margin: '5px' }}>This post is no longer available</p>)
                history.push(COMMUNITY)
              }
            }
          } else {
            dispatch(createPutAction(existing))
          }
        }
      } else if (status === 'published') {
        if (existing && message.type === PusherTypes.updatePost) {
          dispatch(createPutAction(existing))
        } else if (!existing && message.type !== PusherTypes.updatePostStatus) {
          return
        } else if (authorId !== loggedInMemberId) {
          dispatch(createNewPutAction())
        }
        if (isComment && parentPostId) {
          dispatch(putCommentCounts({ parentPostId, postId, counts: message.meta?.counts }))
        } else {
          dispatch(putPostCounts({ postId, counts: message.meta?.counts }))
        }
      }

      break
    }
    case PusherTypes.deletePost: {
      const { postId, parentPostId, postType, authorId } = message.meta
      const isComment = postType === 'comment'
      const isMyPost = memberId === authorId
      if (history.location.pathname.includes(`community/${postId}`) && !isComment) {
        if (!isMyPost) {
          toast.error(<p style={{ lineHeight: '18px', margin: '5px' }}>This post is no longer available</p>)
        }
        history.push(COMMUNITY)
      }
      if (isComment) {
        dispatch(
          clearPostComment({
            postId: parentPostId,
            commentId: postId,
          }),
        )
      } else {
        dispatch(clearPost({ postId }))
        dispatch(clearPostDetail({ postId }))
      }
      break
    }
    case PusherTypes.updateComponent: {
      const { componentId, status, groupTypes } = message.meta

      if (status === 'published' && isMemberGroupTypeIncluded(groupTypes)) {
        dispatch(getSelfCareComponent(componentId))
      }
      if (status === 'draft' || status === 'archived') {
        if (history.location.pathname.includes(`/${componentId}`)) {
          history.push(EXPLORE)
          toast.error(<p style={{ lineHeight: '18px', margin: '5px' }}>This content is no longer available</p>)
        }
        const existing = getState().explore.components[componentId]
        if (existing) {
          dispatch(clearComponent({ componentId }))
        }
      }
      break
    }
    case PusherTypes.updateJourney: {
      const { journeyId, status, groupTypes } = message.meta

      if (status === 'published' && isMemberGroupTypeIncluded(groupTypes)) {
        dispatch(getSelfCareJourney(journeyId))
      }
      if (status === 'draft' || status === 'archived') {
        if (history.location.pathname.includes(`/${journeyId}`)) {
          history.push(EXPLORE)
          toast.error(<p style={{ lineHeight: '18px', margin: '5px' }}>This content is no longer available</p>)
        }
        const existing = getState().explore.journeys[journeyId]
        if (existing) {
          dispatch(clearJourney({ journeyId }))
        }
      }
      break
    }
    case PusherTypes.createReaction: {
      if (
        'postId' in message.meta.target.ref &&
        message.meta.type !== 'view' &&
        message.memberId === loggedInMemberId
      ) {
        dispatch(putReaction({ reaction: message.meta }))
      }
      if ('journeyId' in message.meta.target.ref) {
        const { journeyId } = message?.meta?.target?.ref || {}
        if (journeyId && message?.memberId === loggedInMemberId) {
          dispatch(getSelfCareJourney(journeyId))
        }
      }
      break
    }
    case PusherTypes.createMessage: {
      const _message = message?.meta?.dataValues || {}
      if (_message?.id || _message?.messageId) {
        dispatch(putNewMessage({ ..._message, messageId: _message?.messageId || _message?.id }))
      }

      dispatch(fetchUnreadMessageCounts())
      break
    }
    case PusherTypes.updateMessage: {
      const _message = message?.meta?.dataValues || {}
      if (_message?.id || _message?.messageId) {
        dispatch(putMessageDetails({ ..._message, messageId: _message?.messageId || _message?.id }))
      }
      dispatch(fetchUnreadMessageCounts())
      break
    }
    case PusherTypes.updateChatMessage: {
      const messages = message.meta.updatedChatMessages
      dispatch(readChatMessages({ messages }))
      break
    }
    case PusherTypes.updateAccountMerge: {
      if (message.meta.migrationStatus === 'complete') {
        toast.success(
          <p style={{ lineHeight: '18px', margin: '5px' }}>Account merge complete. Your account has been updated.</p>,
        )
      }
      if (message.meta?.migrationStatus === 'failed') {
        toastMergeFailed()
      }
      break
    }
    default:
      break
  }
}

export const subscribeToMemberTopic = () => async (dispatch, getState) => {
  const memberId = MemberSelectors.getLoggedInMemberId(getState())
  if (memberId) dispatch(putTopic({ topic: PusherTopics.member(memberId) }))
}

export const subscribeToPendingVisitTopic = (pendingVisitId) => async (dispatch) => {
  if (pendingVisitId) {
    dispatch(putTopic({ topic: PusherTopics.pendingVisit(pendingVisitId) }))
  }
}
