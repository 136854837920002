import BanIcon from '@ui-components-3/icons/lib/regular/Ban'
import CircleDotIcon from '@ui-components-3/icons/lib/regular/CircleDot'
import WarningIcon from '@ui-components-3/icons/lib/solid/TriangleExclamation'
import Notice from '@ui-components-3/ui/lib/components/Notice'
import classNames from '@ui-components-3/ui/lib/utils/classNames'
import SuccessOverviewIllustration from 'assets/images/success/illustration_overview.svg'
import moment from 'moment'
import { useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import { hasOnDemandSuccessCoachingService, hasScheduledSuccessCoachingService } from 'redux/selectors/services'
import { getActiveVisit } from 'redux/selectors/visits'

type AvailabilityInfo = {
  times: string
  online: boolean
}

interface SuccessEntryProps {
  moveToNext: any
  availability?: AvailabilityInfo
}

const SuccessEntry = ({ moveToNext, availability }: SuccessEntryProps) => {
  const activeVisit = useSelector(getActiveVisit)
  const hasScheduledSuccess = useSelector(hasScheduledSuccessCoachingService)
  const hasOnDemandSuccess = useSelector(hasOnDemandSuccessCoachingService)

  const StatusIcon = activeVisit ? WarningIcon : availability?.online ? CircleDotIcon : BanIcon

  const statusClassName =
    activeVisit && availability?.online
      ? 'bg-pantone-300 text-pantone-800'
      : availability?.online
      ? 'bg-[#F1FFFB] text-[#00855E]'
      : 'bg-fuchsia-100 text-fuchsia-700'

  const statusText = useMemo(() => {
    if (activeVisit && availability?.online) {
      return 'Active Visit In Progress'
    } else if (availability?.online) {
      return 'Currently Online'
    } else {
      return 'Currently Offline'
    }
  }, [activeVisit, availability?.online])

  const timesText = availability.online
    ? `${availability?.times} (${moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z')})`
    : 'Currently Offline'

  return (
    <div className="flex-1 overflow-x-hidden bg-[#F9FBFD] px-6 py-6 text-neutral-800 md:px-11 md:py-10">
      <div className="mx-auto grid max-w-[956px] grid-cols-1 gap-x-6 md:grid-cols-2">
        <div className="flex flex-col gap-y-4 sm:gap-y-6">
          {hasOnDemandSuccess && (
            <div className="rounded-2xl bg-white p-8 shadow-[0px_8px_16px_0px_rgba(27,99,162,0.08)] md:p-10">
              <div className="flex justify-end">
                {availability ? (
                  <div
                    className={classNames(
                      'typography-small mb-2 inline-flex items-center gap-1 rounded-[50px] px-3 py-1 font-semibold',
                      statusClassName,
                    )}
                  >
                    <span className="flex h-4 w-4 items-center justify-center p-0.5">
                      <StatusIcon className="h-full w-full" aria-hidden="true" />
                    </span>
                    {statusText}
                  </div>
                ) : (
                  <Skeleton width={140} className="h-6" containerClassName="block leading-none mt-[10px]" />
                )}
              </div>
              <h2 className="typography-body-xl md:typography-body-xxl mb-4 font-semibold">On-Demand Hours</h2>
              <div className="grid grid-cols-2 gap-2">
                <div className="typography-body md:typography-body-l font-semibold">Weekdays</div>
                {availability ? (
                  <div className="typography-body md:typography-body-l">{timesText}</div>
                ) : (
                  <Skeleton width={185} className="h-4" containerClassName="block leading-none " />
                )}
                <div className="typography-body md:typography-body-l font-semibold">Weekends</div>
                {availability ? (
                  <div className="typography-body md:typography-body-l">Unavailable</div>
                ) : (
                  <Skeleton width={185} className="h-4" containerClassName="block leading-none" />
                )}
              </div>
              {!!activeVisit && availability?.online && (
                <Notice variant="warning" className="mb-2 mt-10">
                  <div className="text-neutral-800">
                    <p className="typography-body mb-1 font-semibold">Active Visit in Progress</p>
                    <p className="typography-small">
                      {`There is currently an active visit in progress. To connect with a Success Coach, either complete or
                    cancel your current active visit${hasScheduledSuccess ? ' or schedule a session' : ''}. `}
                    </p>
                  </div>
                </Notice>
              )}
              {!availability.online && (
                <Notice variant="info" className="mb-2 mt-4">
                  <div className="text-neutral-800">
                    <p className="typography-body mb-1 font-semibold">Currently Offline</p>
                    <p className="typography-small">
                      {`Our on-demand success coaches are only available during on-demand hours. Please come back during
                    available hours${hasScheduledSuccess ? ' or schedule a session' : ''}. `}
                    </p>
                  </div>
                </Notice>
              )}
              {!activeVisit && availability?.online ? (
                <button className="btn btn-primary mt-16 w-full" type="button" onClick={moveToNext}>
                  Connect Now
                </button>
              ) : (
                !availability && (
                  <Skeleton width={185} className="h-full" containerClassName="block leading-none h-16 mt-16" />
                )
              )}
            </div>
          )}
          {hasScheduledSuccess && (
            <div className="rounded-2xl border bg-white p-8 md:p-10">
              <h2 className="typography-body-xl md:typography-body-xxl mb-4 font-semibold">Scheduled Coaching</h2>
              <p className="typography-body-l font-normal">Set up a time in advance that works for you.</p>
              <button
                className="btn btn-neutral-outlined mt-6 w-full"
                type="button"
                onClick={() => moveToNext({ isScheduled: true })}
              >
                Schedule a Session
              </button>
            </div>
          )}
          <div className="typography-small md:typography-body flex flex-col gap-y-4 p-10 pt-0">
            <h3 className="typography-body-l md:typography-body-xl font-semibold">What to Expect</h3>
            <p>{`Our coaches are available to connect anytime during on-demand hours${
              hasScheduledSuccess ? ' or with a scheduled appointment' : ''
            }. `}</p>
            <p>
              Before you speak with a coach, you’ll choose your focus areas and if you’d like a phone or video call.
            </p>
            <p>Your session will last about 30 minutes.</p>
          </div>
        </div>
        <div className="hidden h-full w-full items-center md:flex">
          <div className="h-[480px] w-full min-w-[466px]">
            <img className="h-full w-full" src={SuccessOverviewIllustration} alt="" aria-hidden="true" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuccessEntry
