import distanceFn from './distance'

const Locations = {
  AU: import('./locations/AU.json'),
  CA: import('./locations/CA.json'),
  US: import('./locations/US.json'),
}

/**
 * iterates over locations to find closest location to given input lat-long values
 * @param  {Number} latitude  latitude value
 * @param  {Number} longitude longitude value
 * @param  {String} countryCode Country Code. Should exist in /locations/{countryCode}.json
 * @return {Object}           city data in an object
 */
async function dirtyReverseGeocode(latitude, longitude, countryCode) {
  let minDistance = Infinity
  let city = {}

  // start with inout values
  const start = { latitude, longitude }

  // iterate through all locations
  const otherCountryOrigin = await Locations[countryCode.toUpperCase()]

  if (otherCountryOrigin) {
    try {
      for (const location of otherCountryOrigin.default) {
        const distance = distanceFn(start, location)
        if (distance < minDistance) {
          city = location
          minDistance = distance
        }
      }
    } catch (e) {
      return undefined
    }

    // add distance to city object
    city.distance = minDistance

    // return city object
    return city
  }
  return undefined
}

export default dirtyReverseGeocode
