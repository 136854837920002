import classNames from '@ui-components-3/ui/lib/utils/classNames'
import { Link } from 'react-router-dom'
import { HELP_CENTER } from 'routes/paths'
import { stringArrayToSeries } from 'util/formatters'

type DisabledServicesSubTextProps = {
  services: string[]
  testid: string
  className?: string
}

const DisabledServicesSubText = ({ services, testid, className }: DisabledServicesSubTextProps) => (
  <div className={classNames('w-auto py-4', className)} data-testid={testid}>
    <p className="typography-small max-w-lg text-neutral-500">
      Your access to {stringArrayToSeries(services)} services has been disabled. If you have any questions please
      contact us{' '}
      <Link
        to={HELP_CENTER}
        className="font-semibold text-neutral-800 hover:underline focus:underline"
        aria-label="Click here to navigate to the help center"
      >
        here
      </Link>
      .
    </p>
  </div>
)

export default DisabledServicesSubText
