import { setFetching } from '../slices/api'
import FetchType from '../../util/fetch-types'
import { handleRequestFailure, makeRequest } from '../../api/helpers'
import { putRx } from '../slices/pharmacies'
import { putMessageDetails, putMessageViewedAt, putMessages, putUnreadMessageCounts } from 'redux/slices/messages'

export const fetchMessages =
  ({ page, limit, sort, messageType }) =>
  async (dispatch) => {
    dispatch(setFetching({ fetchType: FetchType.getMessages, isFetching: true }))
    let success = false
    let result = null
    const params = { params: { limit, page, filter: { messageType }, sort } }

    const handleSuccess = (data) => {
      success = true
      result = data
      dispatch(putMessages({ messages: data.paginated, total: data.meta.results, messageType }))
    }
    await makeRequest(FetchType.getMessages, params, handleSuccess, handleRequestFailure(dispatch))

    dispatch(setFetching({ fetchType: FetchType.getMessages, isFetching: false }))
    return { success, data: result }
  }

export const fetchMessageDetails = (messageId) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.getMessage, isFetching: true }))
  let success = false
  let result = null
  const params = { messageId }
  const handleSuccess = (data) => {
    success = true
    result = data
    dispatch(putMessageDetails(data))
  }
  await makeRequest(FetchType.getMessage, params, handleSuccess, handleRequestFailure(dispatch))

  dispatch(setFetching({ fetchType: FetchType.getMessage, isFetching: false }))
  return { success, data: result }
}

export const fetchRx = (prescriptionId) => async (dispatch) => {
  let success = false
  let result = null
  dispatch(
    setFetching({
      fetchType: FetchType.getPrescription,
      isFetching: true,
    }),
  )
  const params = { prescriptionId }
  const handleSuccess = (data) => {
    success = true
    dispatch(putRx({ rx: data }))
    result = data
  }
  await makeRequest(FetchType.getPrescription, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(
    setFetching({
      fetchType: FetchType.getPrescription,
      isFetching: false,
    }),
  )
  return { success, data: result }
}

export const viewMessage = (messageId) => async (dispatch, getState) => {
  dispatch(setFetching({ fetchType: FetchType.putMessageViewedAt, isFetching: true }))
  let success = false
  let result = null
  const params = { messageId, viewedAt: new Date().toISOString() }
  const handleSuccess = (data) => {
    success = true
    result = data
    dispatch(putMessageViewedAt(params))
    dispatch(fetchUnreadMessageCounts())
  }
  await makeRequest(FetchType.putMessageViewedAt, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(setFetching({ fetchType: FetchType.putMessageViewedAt, isFetching: false }))
  return { success, data: result }
}

export const updateMessageStatus =
  ({ messageId, status }) =>
  async (dispatch) => {
    dispatch(setFetching({ fetchType: FetchType.putMessageStatus, isFetching: true }))
    let success = false
    let result = null
    const params = { messageId, status }
    const handleSuccess = (data) => {
      success = true
      result = data
      dispatch(putMessageDetails(data))
    }
    await makeRequest(FetchType.putMessageStatus, params, handleSuccess, handleRequestFailure(dispatch))
    dispatch(setFetching({ fetchType: FetchType.putMessageStatus, isFetching: false }))
    return { success, data: result }
  }

export const fetchUnreadMessageCounts = () => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.getUnreadMessageCounts, isFetching: true }))
  let success = false
  let result = null
  const params = {}
  const handleSuccess = (data) => {
    success = true
    result = data
    dispatch(putUnreadMessageCounts(data))
  }
  await makeRequest(FetchType.getUnreadMessageCounts, params, handleSuccess, handleRequestFailure(dispatch))

  dispatch(setFetching({ fetchType: FetchType.getUnreadMessageCounts, isFetching: false }))
  return { success, data: result }
}
