import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  visitId: Yup.string().label('Visit ID').required(),
  asl: Yup.boolean().notRequired().nullable(),
})

type RequestParams = Yup.InferType<typeof RequestSchema>

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const url = ({ visitId, asl }: RequestParams) => `/v2/visits/${visitId}/interpreters${asl ? '/asl' : ''}`
export const execute = async (params) => api.post(url(params)).catch(catchApiFailure)
