import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { parsePusherMessage, subscribeToMemberTopic } from '../redux/thunks/pusher'
import { normalizeJsonFromApi } from '../api/helpers'
import { getTopics } from '../redux/selectors/pusher'
import { getLoggedInMemberId } from '../redux/selectors/members'
import PusherClient, { PusherStatus } from '../util/pusher'
import * as APM from 'util/apm'
import { useHistory } from 'react-router'

const reduceTopicArrayToMap = (acc, topic) => {
  acc[topic] = topic
  return acc
}

const withPusher = (Child) => {
  return function PusherContainer(props) {
    const dispatch = useDispatch()
    const history = useHistory()
    const [status, setStatus] = useState(PusherStatus.init)
    const flags = useFlags()
    const topics = useSelector(getTopics)
    const memberId = useSelector(getLoggedInMemberId)

    const closeConnection = () => PusherClient.disconnect()

    const eventListener = useCallback(
      (type, data) => {
        if (type === 'pusher:subscription_succeeded') {
          console.log('Subscribed to channel successfully')
          return
        }
        try {
          const msg = { ...data, type }
          // console.log({ msg });
          if (msg.type) {
            dispatch(parsePusherMessage(normalizeJsonFromApi(msg), history, flags))
          }
        } catch (e) {
          console.error(e)
          APM.captureException(e)
        }
      },
      [history, flags, dispatch],
    )

    const statusListener = useCallback((status) => {
      setStatus(status)
    }, [])

    const connect = async () => {
      if (!PusherClient.isInitialized() || !PusherClient._statusListener) {
        await PusherClient.init(statusListener)
      }
    }

    const unsubscribeTopics = (topics) => {
      if (topics.length > 0) {
        topics.forEach((topic) => PusherClient.unsubscribe(topic))
      }
    }

    useEffect(() => () => void closeConnection(), [])

    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line
      useEffect(() => {
        console.log({ status }, new Date())
      }, [status])
    }

    useEffect(() => {
      if (memberId) {
        dispatch(subscribeToMemberTopic())
      }
    }, [memberId])

    useEffect(() => {
      if (!PusherClient.isConnected()) return

      // Sync redux expectation to Pusher singleton reality
      const expectedTopicsLookup = topics.reduce(reduceTopicArrayToMap, {})
      const unsubList = []
      PusherClient.activeChannelList().forEach((topic) => {
        if (!expectedTopicsLookup[topic]) unsubList.push(topic)
      })
      unsubscribeTopics(unsubList)
      topics.forEach((topic) => {
        PusherClient.subscribe(topic)
      })
      PusherClient.bind(eventListener)
    }, [status, topics])

    useEffect(() => {
      const isDisconnectingOrDisconnected = status === PusherStatus.disconnected
      if (!memberId && !isDisconnectingOrDisconnected) {
        closeConnection()
      }
      if (memberId) {
        if (status === PusherStatus.disconnected || status === PusherStatus.init) {
          connect()
        }
      }
    }, [memberId, status])

    const pusherProps = {
      status,
      close: closeConnection,
      connect,
    } as const

    return <Child {...props} {...pusherProps} />
  }
}

export default withPusher
