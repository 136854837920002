import { createSelector } from 'reselect'
import VisitTypes, { medicalAndHCServices, serviceMap } from '../../util/visit-types'
import { camelCase } from 'lodash'
import { isOnDemandSuccess, isScheduledSuccess, isSuccess } from 'util/helpers'
import { UTILITY_TYPES } from 'types/member/member'

export const getRecords = (state) => state.services.records
export const getClients = (state) => state.services.clients
export const getSurveys = (state) => state.services.surveys
export const getGroups = (state) => state.services.groups
export const getNeeds = (state) => state.services.needs
export const getRestrictions = (state) => state.services.restrictions

export const getServices = createSelector(getRecords, (records = {}) => Object.values(records))
export const getGroupHasClinicalService = createSelector(getServices, (services) => {
  return services.some((service) => service.utilityType === UTILITY_TYPES.clinical)
})

const visitServices = [
  VisitTypes.medical,
  VisitTypes.medicalNow,
  VisitTypes.healthCoach,
  VisitTypes.therapy,
  VisitTypes.therapyNow,
  VisitTypes.psychiatry,
]
export const getVisitServices = createSelector(getRecords, (records = {}) =>
  Object.values(records).filter((rec) => visitServices.includes(rec.serviceType)),
)

export const getServicesByType = createSelector(getRecords, (records) =>
  Object.values(records).reduce((acc, rec) => {
    acc[rec.serviceType] = rec
    return acc
  }, {}),
)

export const getServiceByType = (visitType, scheduledType) =>
  createSelector(getRecords, (records = {}) => {
    if (visitType && scheduledType) {
      const type = serviceMap[scheduledType][visitType]
      return Object.values(records).find((rec) => rec.serviceType === type)
    }
  })

export const getServiceRestrictions = (visitType) =>
  createSelector(getRestrictions, (restrictions) => restrictions?.[visitType] ?? [])

export const hasNeedsService = createSelector(getServicesByType, (servicesByType = {}) => !!servicesByType.needs)
export const hasPsychiatryService = createSelector(
  getServices,
  (services) => !!services.find((s) => s.serviceType === VisitTypes.psychiatry),
)
export const hasMedicalAndHCService = createSelector(getServices, (services) => {
  return services.some((s) => medicalAndHCServices.includes(s.serviceType))
})

export const getServiceTypes = createSelector(getServices, (services) =>
  (services || []).map((service) => service.serviceType),
)

export const getServiceLimitResetMap = createSelector(getServices, (services) =>
  (services || []).reduce((acc, service) => {
    acc[camelCase(`${service.serviceType}LimitReset`)] = service.limitAnniversaryAt
    return acc
  }, {}),
)

export const getClient = (clientId) => createSelector(getClients, (clients = {}) => clients[clientId])

export const getGroup = (groupId) => createSelector(getGroups, (groups = {}) => groups[groupId])

export const getService = (serviceId) => createSelector(getRecords, (records = {}) => records[serviceId])

export const getSurvey = (surveyId) => createSelector(getSurveys, (surveys = {}) => surveys[surveyId])

export const getSurveyList = createSelector(getSurveys, (surveys = {}) => Object.values(surveys))

export const getNeedsList = createSelector(getNeeds, (needs = {}) => Object.values(needs))

export const hasActiveNeed = createSelector(getNeedsList, (list) => !!list?.length)

export const getNeed = (needId) => createSelector(getNeeds, (needs = {}) => needs[needId])

export const hasSuccessCoachingService = createSelector(
  getServices,
  (services) => !!services?.find((s) => isSuccess(s.serviceType)),
)

export const hasScheduledSuccessCoachingService = createSelector(
  getServices,
  (services) => !!services?.find((s) => isScheduledSuccess(s.serviceType)),
)

export const hasOnDemandSuccessCoachingService = createSelector(
  getServices,
  (services) => !!services?.find((s) => isOnDemandSuccess(s.serviceType)),
)
