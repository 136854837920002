import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  memberId: Yup.string().label('Member ID').required(),
  visitId: Yup.string().label('Visit ID').required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const url = ({ memberId, visitId }) => `/v2/${memberId}/visits/${visitId}/video/token`
export const execute = async (params) => api.get(url(params)).catch(catchApiFailure)
