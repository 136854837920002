import * as Yup from 'yup'
import { normalizeJsonToApi } from '../../helpers'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import { mfaOptions, mfaOptionsMap } from '../../../util/mfa'
import configBuilder from '../config-builder'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object({
  mfaChoice: Yup.object({
    deliveryMethod: Yup.string().oneOf(mfaOptions).required(),
    phoneId: Yup.string().when('deliveryMethod', {
      is: (method) => method === mfaOptionsMap.sms,
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired(),
    }),
  }),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => await RequestSchema.validate(params)
export const make = async (params) => normalizeJsonToApi(params.mfaChoice)
export const url = () => '/v1/me/generate-mfa'
export const execute = async (params) =>
  api.post(url(), await make(params), configBuilder(params)).catch(catchApiFailure)
