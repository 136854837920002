import { useMemo } from 'react'
import { getAvatarUrl, getProviderInitials } from 'util/provider'
import Provider from 'types/provider/provider'
import { useProviderFullNameAndDetails } from 'hooks/useProviderFullNameAndDetails'

type ProviderHeaderProps = {
  provider?: Provider
  'data-testid'?: string
  infoTestId?: string
  isSuccess?: boolean
}

const ProviderHeader = (props: ProviderHeaderProps) => {
  const { provider, isSuccess } = props

  const { fullNameAndCredentials, details } = useProviderFullNameAndDetails(provider, isSuccess)

  const providerAvatarUrl = useMemo(() => {
    return getAvatarUrl(provider)
  }, [provider])

  const initials = useMemo(() => getProviderInitials(provider), [provider])

  return (
    <div className="flex items-start" data-testid={props['data-testid']}>
      <div className="bg-secondary-700 mr-3 flex h-12 w-12 shrink-0 items-center justify-center overflow-hidden rounded-xl">
        {providerAvatarUrl ? (
          <img className="h-full w-full object-cover" src={providerAvatarUrl} alt="" />
        ) : (
          <span className="typography-body-xl text-secondary-200 text-center font-semibold">{initials}</span>
        )}
      </div>
      <div data-testid={props.infoTestId} className="typography-body-xl">
        <div className="font-semibold text-neutral-800">{fullNameAndCredentials}</div>
        <div className="text-neutral-500">{details}</div>
      </div>
    </div>
  )
}

export default ProviderHeader
