export const cleanPhoneNumberInput = (number: string) => ('' + number).replace(/\D/g, '').substring(0, 10)

export const cleanNumberInput = (number: string) => ('' + number).replace(/\D/g, '')
export const phoneFormat = (number: string) => {
  const clean = cleanNumberInput(number)
  const match = clean.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`
  }
}

export const phoneInputFormat = (number: string) => {
  const clean = cleanNumberInput(number)
  const area = clean.substring(0, 3)
  const exchange = clean.substring(3, 6)
  const remain = clean.substring(6, 10)

  if (clean.length > 6) return `${area}-${exchange}-${remain}`
  if (clean.length > 3) return `${area}-${exchange}`
  if (clean.length) return `${area}`
}

export const getRequiredAriaLabel = (label: string) => `${label} is a required field`

export const createSimpleSelectOption = <T = string>(
  value: any,
  labelFormatter?: (value: T) => string,
  valueFormatter?: (value: T) => string,
): { label: string; value: any } => {
  const label = labelFormatter ? labelFormatter : (value: any) => `${value}`
  const formattedValue = valueFormatter ? valueFormatter(value) : value
  return { label: label(value), value: formattedValue }
}

export const createSimpleSelectOptions = <T = string>(
  values: T[],
  labelFormatter?: (value: T) => string,
  valueFormatter?: (value: T) => string,
): { label: string; value: any }[] => {
  return values.map((value) => createSimpleSelectOption(value, labelFormatter, valueFormatter))
}

export const getLabelFromOptions = (options: { label: string; value: string }[], value: string) => {
  const option = options.find((option) => option.value === value)
  return option ? option.label : ''
}
