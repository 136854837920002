import { ComponentPropsWithoutRef } from 'react'
import LikeIcon from '@ui-components-3/icons/lib/solid/ThumbsUp'
import LoveIcon from '@ui-components-3/icons/lib/solid/Heart'
import SupportIcon from '@ui-components-3/icons/lib/solid/HandHoldingHeart'
import CelebrateIcon from '@ui-components-3/icons/lib/solid/PartyHorn'
import CongratulateIcon from '@ui-components-3/icons/lib/solid/HandsClapping'
import Avatar1Url from 'assets/images/community/avatars/avatar-image-1.svg'
import Avatar2Url from 'assets/images/community/avatars/avatar-image-2.svg'
import Avatar3Url from 'assets/images/community/avatars/avatar-image-3.svg'
import Avatar4Url from 'assets/images/community/avatars/avatar-image-4.svg'
import Avatar5Url from 'assets/images/community/avatars/avatar-image-5.svg'
import Avatar6Url from 'assets/images/community/avatars/avatar-image-6.svg'
import Avatar7Url from 'assets/images/community/avatars/avatar-image-7.svg'
import Avatar8Url from 'assets/images/community/avatars/avatar-image-8.svg'
import Avatar9Url from 'assets/images/community/avatars/avatar-image-9.svg'
import {
  CategoryOption,
  FACULTY_STAFF_CATEGORIES,
  GROUP_TYPES,
  K12_FACULTY_STAFF_CATEGORIES,
  STUDENT_CATEGORIES,
} from 'types/client/group'

export const COMMUNTIY_ICONS_BY_REACTION = {
  like: LikeIcon,
  love: LoveIcon,
  support: SupportIcon,
  celebrate: CelebrateIcon,
  congratulate: CongratulateIcon,
}

export const COMMUNITY_ICON_CLASSNAMES_BY_REACTION = {
  like: 'text-primary-500',
  love: 'text-fuchsia-800',
  support: 'text-primary-600',
  celebrate: 'text-pantone-700',
  congratulate: 'text-secondary-700',
} as const

export const COMMUNITY_ICON_BG_CLASSNAMES_BY_REACTION = {
  like: 'bg-primary-500',
  love: 'bg-fuchsia-800',
  support: 'bg-primary-600',
  celebrate: 'bg-pantone-700',
  congratulate: 'bg-secondary-700',
} as const

export const POST_STATUS = {
  pending: 'pending',
  published: 'published',
  inReview: 'in-review',
  denied: 'denied',
}

export const CommunityAuthorAvatars = {
  Avatar1: (props) => <img alt="" {...props} src={Avatar1Url} />,
  Avatar2: (props) => <img alt="" {...props} src={Avatar2Url} />,
  Avatar3: (props) => <img alt="" {...props} src={Avatar3Url} />,
  Avatar4: (props) => <img alt="" {...props} src={Avatar4Url} />,
  Avatar5: (props) => <img alt="" {...props} src={Avatar5Url} />,
  Avatar6: (props) => <img alt="" {...props} src={Avatar6Url} />,
  Avatar7: (props) => <img alt="" {...props} src={Avatar7Url} />,
  Avatar8: (props) => <img alt="" {...props} src={Avatar8Url} />,
  Avatar9: (props) => <img alt="" {...props} src={Avatar9Url} />,
} as const satisfies Record<`Avatar${number}`, (props: ComponentPropsWithoutRef<'img'>) => JSX.Element>

export type PostStatusValues = keyof typeof POST_STATUS

export const REACTION_MAP = {
  like: 'like',
  love: 'love',
  support: 'support',
  celebrate: 'celebrate',
  congratulate: 'congratulate',
} as const

export const adminReplyColors = {
  alias: '#1B63A2',
  background: '#C2D8EC',
  border: '#1B63A2',
}

export const tabMap = {
  Discussions: 0,
  'My Activity': 1,
  // Saved: 2,
}

export const communityTabs = Object.keys(tabMap)

export const getCategoryOptions = (groupType = GROUP_TYPES.student): CategoryOption[] => {
  const groupTypeCategories = {
    [GROUP_TYPES.student]: STUDENT_CATEGORIES,
    [GROUP_TYPES.facultyStaff]: FACULTY_STAFF_CATEGORIES,
    [GROUP_TYPES.k12FacultyStaff]: K12_FACULTY_STAFF_CATEGORIES,
  }

  const selectedCategories = groupTypeCategories[groupType] ?? STUDENT_CATEGORIES

  return selectedCategories.map((category) => ({ label: category, value: category }))
}

export const reportReasons = [
  'Hate speech',
  'Bullying or harassment',
  'I just don’t like it',
  'Suicide or self-injury',
  'Other',
]

export const buildAuthorInitials = (fn: string, ln: string) => {
  return `${fn.slice(0, 1)}${ln.slice(0, 1)}`
}

export const buildInitialsForDisplay = (name: string) => {
  if (name.length > 2) {
    const fnLn = name.split(' ')
    return buildAuthorInitials(fnLn[0], fnLn[1])
  } else return name
}

export const getPostStatusStyles = ({ status, isSelected }: { status: PostStatusValues; isSelected: boolean }) => {
  switch (status) {
    case POST_STATUS.inReview:
      return isSelected ? 'bg-pantone-100 border-primary-600' : 'border-pantone-600 bg-pantone-100'
    case POST_STATUS.denied:
      return isSelected ? 'bg-neutral-200 border-primary-600' : 'border-fuchsia-800 bg-neutral-200'
    default:
      return isSelected ? 'bg-primary-100 border-primary-600' : 'bg-white border-neutral-300'
  }
}
