interface Persister {
  setItem: (key: string, value: string) => void
  getItem: (key: string) => string | undefined
  removeItem: (key: string) => void
  clear: () => void
}

export class Persistence {
  persister: Persister

  constructor(persister: Persister) {
    console.log('making Persistence')
    this.persister = persister
  }

  set(key: string, value: string | undefined) {
    this.persister.setItem(key, value)
  }

  get(key: string) {
    return this.persister.getItem(key)
  }
  clear() {
    this.persister.clear()
  }
  remove(key: string) {
    this.persister.removeItem(key)
  }
}