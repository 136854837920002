import * as Yup from 'yup'
import { stateValues } from '../util/states'
import VisitTypes from '../util/visit-types'
import VisitScheduleType from '../util/visit-schedule-types'
import { SlimPhoneSchema } from './PhoneSchema'
import { EVisitModalities } from '../util/visit-modalities'
import lastIndexOf from 'lodash/lastIndexOf'
import { LanguageSchema } from './NewVisitPreferencesSchema'

Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a) => a) {
  return this.test('unique', message, function (list) {
    const hasError = list.length !== new Set(list.map(mapper)).size
    if (hasError) {
      const countMap = list.reduce((acc, time) => {
        if (time) {
          if (acc[time]) {
            acc[time] = acc[time] + 1
          } else {
            acc[time] = 1
          }
        }
        return acc
      }, {})

      const duplicate = Object.keys(countMap).find((key) => countMap[key] > 1)

      const index = lastIndexOf(list, duplicate)

      return this.createError({
        path: `${this.path}[${index}]`,
        message,
      })
    } else {
      return true
    }
  })
})

const NewVisitSchema = Yup.object().shape({
  // step 1
  memberId: Yup.string().label('Patient').required(),
  location: Yup.string()
    .label('Location')
    .when('locationCountry', {
      is: 'US',
      then: (schema) => schema.oneOf(stateValues).required(),
      otherwise: (schema) => schema.notRequired(),
    }),
  locationCountry: Yup.string().default('US').required(),
  visitType: Yup.string().label('Visit Type').oneOf(Object.values(VisitTypes)).required(),
  scheduledType: Yup.string().label('Schedule Type').oneOf(Object.values(VisitScheduleType)).required(),
  //step 2
  reasonForVisit: Yup.string().label('Reason for Visit').required(),
  // step 3
  preferences: Yup.object()
    .shape({
      gender: Yup.string().oneOf(['M', 'F', 'X', 'U', '', null], 'Gender must be a valid gender').label('Gender'),
      ethnicity: Yup.array().of(Yup.string()).label('Ethnicity'),
      specialty: Yup.array().of(Yup.string()).label('Specialty'),
      languages: Yup.array().of(LanguageSchema).label('Languages'),
    })
    .notRequired()
    .nullable(),
  // step 4
  phone: SlimPhoneSchema.required(),
  modality: Yup.string()
    .label('Visit Modality')
    .oneOf(Object.values(EVisitModalities || {}))
    .required(),
  aslInterpreterNeeded: Yup.boolean().notRequired().nullable().default(false),
  // step 5
  providerId: Yup.string().label('Provider ID').when('scheduledType', {
    is: VisitScheduleType.scheduled,
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired().strip(),
  }),
  providerName: Yup.string().when('scheduledType', {
    is: VisitScheduleType.scheduled,
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired().strip(),
  }),
  providerTimezone: Yup.string().when('scheduledType', {
    is: VisitScheduleType.scheduled,
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired().strip(),
  }),
  // step 6
  memberFirstName: Yup.string().label('Member first name').required(),
  memberLastName: Yup.string().label('Member last name').required(),
  memberDob: Yup.string().label('Member Date of Birth').required(),
  timezone: Yup.string().label('Timezone').required(),
  scheduledAt: Yup.date().label('Scheduled Date').when('scheduledType', {
    is: VisitScheduleType.scheduled,
    then: Yup.date().required(),
    otherwise: Yup.date().notRequired().nullable().strip(),
  }),
  // step 7
  pharmacy: Yup.object().when('visitType', {
    is: (visitType) => visitType === VisitTypes.medical || visitType === VisitTypes.psychiatry,
    then: Yup.object()
      .shape({
        externalId: Yup.string().required(),
        name: Yup.string().required(),
      })
      .required(),
    otherwise: Yup.object().notRequired().strip(),
  }),
  // step 8
  paymentTransactionId: Yup.string().when('price', {
    is: (price) => !!price && price > 0,
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired().strip(),
  }),
  price: Yup.number(),
})

export default NewVisitSchema
