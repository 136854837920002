import { setFetching } from '../slices/api'
import FetchType from '../../util/fetch-types'
import { makeRequest, handleRequestFailure } from '../../api/helpers'
import * as MemberSelectors from '../selectors/members'
import * as ProviderSelectors from '../selectors/providers'
import { cloneDeep } from 'lodash'
import * as ChatSelectors from '../selectors/chats'
import * as VisitSelectors from '../selectors/visits'
import { printError } from '../../util/logging'
import { fetchMember } from './members'
import { fetchProvider } from './providers'
import * as ChatActions from '../slices/chats'
import * as VisitActions from '../slices/visits'
import * as ViewActions from '../slices/view'
import * as APM from 'util/apm'
import { decrypt, encrypt, joinParticipantKeys } from 'util/encryption'

export const createVisitChat = (pendingVisitId, memberId, providerId) => async (dispatch) => {
  dispatch(
    setFetching({
      fetchType: FetchType.addVisitChat,
      isFetching: true,
    }),
  )
  let success = false
  const params = {
    chat: {
      pendingVisitId,
      participants: [
        { userType: 'member', id: memberId },
        { userType: 'provider', id: providerId },
      ],
    },
  }
  const handleSuccess = async (data) => {
    success = true
    dispatch(VisitActions.putPendingVisit({ pendingVisit: data }))
    await dispatch(getChat(data.chatId))
  }
  const handleError = async (error) => {
    if (!error) return
    if (typeof error === 'object') {
      const { response, errors } = error
      if (response) {
        const { data } = response
        if (!!data && !!data.errors && data.errors.length > 0) {
          const [firstError] = data.errors
          printError(firstError)
          // makeAlert('Error', !!firstError.detail && firstError.detail || firstError);
          dispatch(ViewActions.putError(firstError))
          return
        }
      } else if (errors) {
        if (Array.isArray(errors)) {
          const [firstError] = errors
          printError(firstError)
          // makeAlert('Error', !!firstError.detail && firstError.detail || firstError);
          dispatch(ViewActions.putError(firstError))
          return
        } else {
          printError(errors)
        }
      }
    }
    // makeAlert('Error', error);
  }

  await makeRequest(FetchType.addVisitChat, params, handleSuccess, handleError)
  dispatch(
    setFetching({
      fetchType: FetchType.addVisitChat,
      isFetching: false,
    }),
  )
  return success
}

export const getChat = (chatId) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.getVisitChat, isFetching: true }))
  let success = false
  const params = { chatId }
  const handleSuccess = async (data) => {
    await dispatch(ChatActions.putChat({ chat: data }))
    dispatch(decryptChat(data.chatId || data.id))
    success = true
  }
  await makeRequest(FetchType.getVisitChat, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(setFetching({ fetchType: FetchType.getVisitChat, isFetching: false }))
  return success
}

export const getPendingVisitChat = (pendingVisitId) => async (dispatch, getState) => {
  dispatch(setFetching({ fetchType: FetchType.getVisitChat, isFetching: true }))
  let success = false
  const pendingVisit = VisitSelectors.getPendingVisit(pendingVisitId)(getState())
  if (!!pendingVisit && !!pendingVisit.chatId) {
    success = await dispatch(getChat(pendingVisit.chatId))
  }
  dispatch(setFetching({ fetchType: FetchType.getVisitChat, isFetching: false }))
  return success
}

export const readChatMessage = (chatMessage) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.readChatMessage, isFetching: true }))
  let success = false
  const handleSuccess = () => {
    success = true
  }
  dispatch(ChatActions.readChatMessage({ message: chatMessage }))
  await makeRequest(
    FetchType.readChatMessage,
    { messageId: chatMessage.id },
    handleSuccess,
    handleRequestFailure(dispatch),
  )

  dispatch(setFetching({ fetchType: FetchType.readChatMessage, isFetching: false }))
  return success
}

const fetchChatParticipants = (chatId) => async (dispatch, getState) => {
  const state = getState()
  let chat = ChatSelectors.getChat(chatId)(state)
  if (!chat) {
    const success = await dispatch(getChat(chatId))
    if (success) chat = ChatSelectors.getChat(chatId)(getState())
    if (!success || !chat) {
      console.log('NO CHAT FOUND')
      return false
    }
  }

  for (let participant of chat.participants) {
    switch (participant.userType) {
      case 'member': {
        const member = MemberSelectors.getMember(participant.id)(state)
        if (!member || !member.publicKey) {
          const success = await dispatch(fetchMember(participant.id))
          if (!success) {
            return false
          }
        }
        break
      }
      case 'provider': {
        const provider = ProviderSelectors.getProvider(participant.id)(state)
        if (!provider || !provider.publicKey) {
          const success = await dispatch(fetchProvider(participant.id))
          if (!success) {
            return false
          }
        }
        break
      }
      default:
        return
    }
  }
  return true
}

const formulateEncryptionKey = (chatId) => async (dispatch, getState) => {
  const fetchSuccess = await dispatch(fetchChatParticipants(chatId))
  if (!fetchSuccess) return

  const state = getState()
  const chat = ChatSelectors.getChat(chatId)(state)

  const participantKeys = []

  for (let participant of chat.participants) {
    switch (participant.userType) {
      case 'member': {
        const member = MemberSelectors.getMember(participant.id)(state)
        if (!member || !member.publicKey) return
        participantKeys.push(member.publicKey)
        break
      }
      case 'provider': {
        const provider = ProviderSelectors.getProvider(participant.id)(state)
        if (!provider || !provider.publicKey) return
        participantKeys.push(provider.publicKey)
        break
      }
      default:
        return
    }
  }

  return joinParticipantKeys(participantKeys)
}

export const decryptChat = (chatId) => async (dispatch, getState) => {
  dispatch(setFetching({ fetchType: FetchType.decryptMessage, isFetching: true }))
  try {
    const key = await dispatch(formulateEncryptionKey(chatId))

    if (!key) throw new Error('Insufficient data for decryption')
    const state = getState()
    const chat = cloneDeep(ChatSelectors.getChat(chatId)(state))

    if (!!chat && !!chat.messages && chat.messages.some((message) => !message.decrypted)) {
      for (let message of chat.messages) {
        message.raw = message.message
        message.message = await decrypt(message.message, key)
        message.decrypted = true
      }
      // chat.decrypted = true;
      dispatch(ChatActions.putChat({ chat: chat }))
    } else {
      printError('Chat was already decrypted')
    }
  } catch (e) {
    printError('Error decrypting chat ' + chatId)
    printError(e)
    APM.captureException(e)
  }
  dispatch(setFetching({ fetchType: FetchType.decryptMessage, isFetching: false }))
}

export const addChatMessage = (chatId, message) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.addChatMessage, isFetching: true }))
  let success = false
  try {
    const key = await dispatch(formulateEncryptionKey(chatId))
    if (!key) throw new Error('Insufficient data for encryption')

    const params = {
      chatId,
      message: {
        ...message,
        message: encrypt(message.message, key),
      },
    }
    const handleSuccess = () => {
      success = true
    }
    await makeRequest(FetchType.addChatMessage, params, handleSuccess, handleRequestFailure(dispatch))
  } catch (e) {
    printError(e)
  }
  dispatch(setFetching({ fetchType: FetchType.addChatMessage, isFetching: false }))
  return success
}
