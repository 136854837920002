import Modal from '@ui-components-3/ui/lib/components/Modal'
import { DOMAttributes, ReactEventHandler, useCallback } from 'react'

type ExternalLinkDialogProps = {
  href?: string
  onClose: () => void
}

const ExternalLinkDialog = ({ href, onClose }: ExternalLinkDialogProps) => {
  const onConfirmEnterPress = useCallback<DOMAttributes<HTMLAnchorElement>['onKeyDown']>(
    (e) => {
      if (e.key === 'Enter') {
        setTimeout(() => {
          onClose?.()
        }, 50)
      }
    },
    [onClose],
  )

  const handleClose = useCallback<ReactEventHandler<HTMLDialogElement>>(
    (e) => {
      e.stopPropagation()
      onClose?.()
    },
    [onClose],
  )

  if (!href) {
    return null
  }

  return (
    <Modal
      portal
      opened
      label="Leave TimelyCare?"
      onClose={handleClose}
      footer={({ close }) => (
        <div className="flex justify-end gap-4">
          <button type="button" className="btn btn-neutral-outlined" onClick={close}>
            Cancel
          </button>
          <a
            href={href}
            rel="noreferrer"
            target="_blank"
            className="btn btn-neutral"
            onClick={close}
            onKeyDown={onConfirmEnterPress}
          >
            Ok
          </a>
        </div>
      )}
    >
      <p className="typography-body-l text-left font-normal text-neutral-600">
        You’re about to leave TimelyCare and be redirected to an external website.
      </p>
    </Modal>
  )
}

export default ExternalLinkDialog
