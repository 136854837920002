import { createSelector } from 'reselect'

export const getMeta = (state) => state.pharmacies.meta
export const getRecords = (state) => state.pharmacies.records
export const getRxs = (state) => state.pharmacies.rxs

export const getPharmacies = createSelector(getRecords, (records = {}) => Object.values(records))

export const getNonMemberPharmacies = createSelector(getRecords, (records = {}) =>
  Object.values(records).filter((pharmacy) => !pharmacy.memberId),
)

export const getPharmacy = (pharmacyId) => createSelector(getRecords, (records = {}) => records[pharmacyId])

export const getMemberPharmacies = (memberId) =>
  createSelector(getPharmacies, (pharmacies = []) => pharmacies.filter((pharmacy) => memberId === pharmacy.memberId))

export const getRx = (prescriptionId) =>
  createSelector(getRxs, (rxs = {}) => {
    return rxs[prescriptionId]
  })

export const getLastFetchAt = createSelector(getMeta, (meta = {}) => meta.lastFetchAt)
