import { EVisitModalities, VisitModalityIconsLight, VisitModalityLabels } from 'util/visit-modalities'
import classNames from '@ui-components-3/ui/lib/utils/classNames'
import { capitalize } from 'lodash'

type VisitModalityProps = {
  modality: EVisitModalities
  className?: string
}

const VisitModality = ({ modality, className }: VisitModalityProps) => {
  const Icon = VisitModalityIconsLight[modality]
  const label = VisitModalityLabels[modality] || capitalize(modality)

  return (
    <div className={classNames('flex items-center', className)}>
      {!!Icon && (
        <span className="bg-secondary-200 text-secondary-700 mr-3 flex h-[48px] w-[48px] shrink-0 items-center justify-center rounded-full">
          <Icon className="h-[50%] w-[50%]" aria-hidden="true" />
        </span>
      )}
      <span className="typography-body-l text-secondary-700 font-semibold">{label}</span>
    </div>
  )
}

export default VisitModality
