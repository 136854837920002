import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'

const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  accessCode: Yup.string().label('Access Code').required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const url = ({ accessCode }) => `/v1/groups/${accessCode}/verify`
export const execute = async (params) => api.get(url(params)).catch(catchApiFailure)
