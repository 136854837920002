import CryptoJS from 'crypto-js'

export const encrypt = (text: string, key: string) => {
  return CryptoJS.AES.encrypt(text, key).toString()
}

export const decrypt = (cipher: string, key: string) => {
  const bytes = CryptoJS.AES.decrypt(cipher, key)
  return bytes.toString(CryptoJS.enc.Utf8)
}

export const joinKeys = (providerKey: string, memberKey: string) => {
  return providerKey + memberKey
}

export const joinParticipantKeys = (keys = []) => {
  return keys.join('')
}

export const base64URLEncode = (buffer: Uint8Array) => {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(buffer)))
    .replace(/\+/g, '-') // Replace '+' with '-'
    .replace(/\//g, '_') // Replace '/' with '_'
    .replace(/=+$/, '') // Remove '=' padding
}

export const generateRandomUint8Array = (length: number = 32) => {
  const array = new Uint8Array(length)
  return crypto.getRandomValues(array)
}

export const sha256 = async (input: string) => {
  const hashBuffer = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(input))
  return new Uint8Array(hashBuffer) // Convert ArrayBuffer to Uint8Array
}
