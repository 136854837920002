import * as Yup from 'yup'
import { normalizeJsonToApi } from '../../helpers'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import { getWindowBrowser } from '../../../util/origin'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string()
    .label('Password')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/g,
      'Password must have 10 characters, and must contain 1 capital letter, 1 lowercase letter, 1 special character, and 1 number',
    )
    .required(),
})

export const isValid = async (params) => {
  return await RequestSchema.isValid(params)
}
export const validate = async (params) => RequestSchema.validate(params)
export const make = async (params) => {
  const { source, ...restParams } = params
  return normalizeJsonToApi({
    ...restParams,
    user_type: 'member',
    origin: 'web',
    originMeta: {
      browser: getWindowBrowser(),
      userAgent: window.navigator.userAgent,
      language: window.navigator.language,
      platform: window.navigator.platform,
      vendor: window.navigator.vendor,
      // capture screen size? probably not necessary
      windowHeight: window.screen.height,
      windowWidth: window.screen.width,
      source,
    },
  })
}
export const url = () => '/v1/me/login'
export const execute = async (params) => api.post(url(params), await make(params)).catch(catchApiFailure)
