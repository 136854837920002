import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import configBuilder from '../config-builder'
import { normalizeJsonToApi } from '../../helpers'
import MemberProviderSchema from '../../../schemas/MemberProviderSchema'

const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  memberId: Yup.string().required(),
  providerId: Yup.string().required(),
  provider: MemberProviderSchema.required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const make = async (params) => {
  return normalizeJsonToApi({ provider: params.provider })
}
export const url = ({ memberId, providerId }) => `/v1/${memberId}/providers/${providerId}`
export const execute = async (params) =>
  api.put(url(params), await make(params), configBuilder(params)).catch(catchApiFailure)
