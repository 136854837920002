import { ElementType } from 'react'
import AuthRoute from '../AuthRoute'
import Member from 'types/member/member'

// TODO: Fix type for route
type RouteBranchProps = {
  path: string
  component?: ElementType
  routes?: any[]
  exact?: boolean
  isPrivate?: boolean
  style?: object
  member?: Member
  memberId?: string
}

const RouteBranch = ({ path, component: Comp, routes, isPrivate, exact, ...extras }: RouteBranchProps) => {
  return (
    <AuthRoute
      style={{ flex: 1, display: 'flex' }}
      path={path}
      isPrivate={isPrivate}
      exact={!!exact}
      render={(props) => <Comp {...extras} {...props} routes={routes} />}
    />
  )
}

export default RouteBranch
