import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import { ChatMessageSchema } from '../../../schemas/ChatMessageSchema'
import { normalizeJsonToApi } from '../../helpers'

const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  chatId: Yup.string().label('Chat ID').required(),
  message: ChatMessageSchema,
})

export const isValid = async (params) => {
  return await RequestSchema.isValid(params)
}
export const validate = async (params) => RequestSchema.validate(params)
export const make = async (params) => {
  const payload = await RequestSchema.validate(params)
  return normalizeJsonToApi(payload.message)
}
export const url = ({ chatId }) => `/v1/chats/${chatId}/messages`
export const execute = async (params) => api.post(url(params), await make(params)).catch(catchApiFailure)
