export function objectIdPropType(props, propName, componentName) {
  if (props[propName] && !/^([0-9a-fA-F]{24})$/.test(props[propName])) {
    return new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed.`)
  }
}

export function objectIdPropTypeRequired(props, propName, componentName) {
  if (!props[propName]) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed - No value assigned`,
    )
  }
  if (!/^([0-9a-fA-F]{24})$/.test(props[propName])) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`: \`${props[propName]}\`. Validation failed.`,
    )
  }
}
