export const MEMBER_ID = 'TIMELY_MEMBER_ID'
export const USER_ID = 'TIMELY_MEMBER_USER_ID'
export const MEMBER_IS_SSO = 'TIMELY_MEMBER_IS_SSO'
// export const IMAGE_URL = 'TIMELY_IMAGE_URL';
// export const NAME = 'TIMELY_NAME';

export const TOKEN = 'TIMELY_MEMBER_APP_TOKEN'
export const REFRESH_TOKEN = 'TIMELY_MEMBER_APP_REFRESH_TOKEN'
export const TOKEN_CREATED_AT = 'TIMELY_MEMBER_TOKEN_CREATED_AT'
export const TOKEN_EXPIRES_AT = 'TIMELY_MEMBER_TOKEN_EXPIRES_AT'

export const PROGRAM_NAME = 'TIMELY_MEMBER_PROGRAM_NAME'
export const PROGRAM_LOGO_URL = 'TIMELY_MEMBER_PROGRAM_LOGO_URL'
export const VISIT_RATINGS = 'VISIT_RATINGS'
export const VALIDATED_NUMBERS = 'VALIDATED_NUMBERS'

export const ARCHIVED_VISIT = (visitId: string) => 'TIMELY_MEMBER_ARCHIVED_VISIT=' + visitId
