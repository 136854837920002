import { createSlice } from '@reduxjs/toolkit'

const defaultState = {
  meta: {},
  records: [
    // ticket id
  ],
  faqs: {
    // [faqId]: FAQ
  },
}

const getFaqId = (faq) => faq.faqId

const slice = createSlice({
  name: 'support',
  initialState: defaultState,
  reducers: {
    putSupportTicket: (state, action) => {
      const { ticketId } = action.payload
      state.records.push(ticketId)
    },
    clearSupportTicket: (state, action) => {
      const { ticketId } = action.payload
      const index = state.records.indexOf(ticketId)
      if (index >= 0) {
        state.records.splice(index, 1)
      }
    },
    clearSupportTickets: (state) => {
      state.records = defaultState.records
    },
    putFaqQuestions: (state, action) => {
      const { questions } = action.payload
      questions.forEach((question) => {
        state.faqs[getFaqId(question)] = question
      })
    },
    clearFaqQuestions: (state) => {
      state.faqs = { ...defaultState.faqs }
    },
  },
})

const { actions, reducer } = slice

export const { putSupportTicket, clearSupportTicket, clearSupportTickets, putFaqQuestions, clearFaqQuestions } = actions
export default reducer
