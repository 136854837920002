import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'

const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  phone: Yup.string()
    .label('Phone Number')
    .matches(/^(\d{10})$/, 'Phone number must have 10 digits')
    .required(),
})

export const isValid = async (params) => {
  return await RequestSchema.isValid(params)
}
export const validate = async (params) => RequestSchema.validate(params)
export const make = async (params) => {
  return params
}
export const url = () => '/v1/validate/phone'
export const execute = async (params) => api.post(url(), await make(params)).catch(catchApiFailure)
