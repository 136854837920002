import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PendingVisit from '../../types/visit/pendingVisit'
import * as VisitSelectors from '../../redux/selectors/visits'
import * as ProviderSelectors from '../../redux/selectors/providers'
import { useDispatch, useSelector } from 'react-redux'
import { putNewVisit } from 'redux/slices/visits'
import SuccessEntry from './steps/Entry'
import SuccessLocation from './steps/Location'
import SuccessOutsideUS from './steps/OutsideUS'
import SuccessReasons from './steps/Reasons'
import SuccessCallType from './steps/CallType'
import SuccessSelectCoach from './steps/SelectCoach'
import SuccessScheduling from './steps/Scheduling'
import SuccessConfirmation from './steps/Confirm'
import SuccessPayment from './steps/Payment'
import * as MemberSelectors from 'redux/selectors/members'
import { EVisitModalities } from 'util/visit-modalities'
import NewVisitSchema from 'schemas/NewVisitSchema'
import { toast } from 'util/toast'
import { createPendingVisit } from '../../redux/thunks/visits'
import VisitScheduleType from 'util/visit-schedule-types'
import { Redirect, useHistory } from 'react-router'
import { DASHBOARD } from '../../routes/paths'
import classNames from '@ui-components-3/ui/lib/utils/classNames'
import { getSuccessAvailability } from '../../redux/selectors/success'
import isEmpty from 'lodash/isEmpty'
import { fetchSuccessAvailability } from '../../redux/thunks/success'
import FooterBar from './components/FooterBar'
import { FormikValues } from 'formik'
import { getServiceByType, hasSuccessCoachingService } from 'redux/selectors/services'
import { fetchClientVisitCount } from 'redux/thunks/clients'
import MemberType from 'types/member/member'
import VisitTypes, { DisplayVisitType } from 'util/visit-types'
import { ModalRef } from '@ui-components-3/ui/lib/components/Modal'
import SessionLimitReachedModal from 'components/dashboard/SessionLimitReachedModal/SessionLimitReachedModal'
import * as ApiSelectors from '../../redux/selectors/api'
import FetchTypes from 'util/fetch-types'
import Loading from '@ui-components-3/ui/lib/components/Loading'
import { trackClickEvent } from 'util/analytics'

enum SuccessSteps {
  entry = 'entry',
  location = 'location',
  outsideUS = 'outsideUS',
  reason = 'reason',
  callType = 'callType',
  selectCoach = 'selectCoach',
  scheduling = 'scheduling',
  payment = 'payment',
  confirm = 'confirm',
}

const SUCCESS_INITIAL_VALUES = (pendingVisit: PendingVisit) => {
  return {
    [SuccessSteps.location]: {
      isOutsideUS: pendingVisit.locationCountry ? String(pendingVisit.locationCountry !== 'US') : '',
    },
    [SuccessSteps.reason]: {
      reasonForVisit: pendingVisit.reasonForVisit || '',
    },
    [SuccessSteps.callType]: {
      modality: pendingVisit.modality || EVisitModalities.video,
      phone: pendingVisit.phone || null,
      phoneId: pendingVisit.phone ? pendingVisit.phone.id : '',
      aslInterpreterNeeded: pendingVisit.aslInterpreterNeeded ?? false,
    },
    [SuccessSteps.selectCoach]: {
      providerId: pendingVisit.providerId || '',
      providerName: pendingVisit.providerName || undefined,
      preferences: pendingVisit.preferences || null,
      providerTimezone: pendingVisit.providerTimezone || '',
    },
    [SuccessSteps.scheduling]: {
      timezone: pendingVisit.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
      scheduledAt: pendingVisit.scheduledAt || null,
    },
    [SuccessSteps.payment]: {
      paymentTransactionId: pendingVisit.paymentTransactionId || '',
    },
    [SuccessSteps.confirm]: {
      ...pendingVisit,
    },
  }
}

const SuccessPage = () => {
  const [currentStep, setCurrentStep] = useState<SuccessSteps>(SuccessSteps.entry)
  const dispatch = useDispatch()
  const pendingVisit: PendingVisit = useSelector(VisitSelectors.getCreateVisit)
  const member: MemberType = useSelector(MemberSelectors.getLoggedInMember)
  const history = useHistory()
  const availability = useSelector(getSuccessAvailability)
  const [isScheduled, setIsScheduled] = useState(false)
  const [isOverLimit, setIsOverLimit] = useState(false)
  const reachedModalRef = useRef<ModalRef | null>(null)
  const isMeFetching = useSelector(ApiSelectors.isTypeFetching(FetchTypes.getMe))
  const isFetchingRestrictions = useSelector(ApiSelectors.isTypeFetching(FetchTypes.getVisitRestrictions))
  const isCreatingVisit = useSelector(ApiSelectors.isTypeFetching(FetchTypes.addPendingVisit))
  const isCreatingScheduling = useSelector(ApiSelectors.isTypeFetching(FetchTypes.addSchedulingRequest))
  const isUpdatingContact = useSelector(ApiSelectors.isTypeFetching(FetchTypes.updateMemberContact))
  const isFetchingProviders = useSelector(ApiSelectors.isTypeFetching(FetchTypes.getAvailableProviders))
  const isFetchingProviderAvailability = useSelector(ApiSelectors.isTypeFetching(FetchTypes.getProviderAvailability))
  const isLoading =
    isMeFetching ||
    isFetchingRestrictions ||
    isCreatingVisit ||
    isCreatingScheduling ||
    isUpdatingContact ||
    isFetchingProviders ||
    isFetchingProviderAvailability

  const isSuccessServiceEnabled = useSelector(hasSuccessCoachingService)

  const visitProviderId = useMemo(() => {
    if (!pendingVisit) return null
    return pendingVisit.providerId
  }, [pendingVisit])

  const provider = useSelector(ProviderSelectors.getProvider(visitProviderId))
  const memberPendingScheduledVisits = useSelector(VisitSelectors.getScheduledVisitsByType)
  const selectedService = useSelector(getServiceByType(pendingVisit?.visitType, pendingVisit?.scheduledType))

  useEffect(() => {
    if (isEmpty(availability) && isSuccessServiceEnabled) {
      dispatch(fetchSuccessAvailability())
    }
  }, [availability, dispatch, isSuccessServiceEnabled])

  const initialValues = useMemo(() => {
    return SUCCESS_INITIAL_VALUES(pendingVisit)[currentStep]
  }, [pendingVisit, currentStep])

  const handleUpdatePayload = useCallback(
    async (payload: any) => {
      const updatedVisit = {
        ...pendingVisit,
        ...payload,
      }
      dispatch(putNewVisit({ pendingVisit: updatedVisit }))
    },
    [dispatch, pendingVisit],
  )

  const handleCreatePendingVisit = useCallback(
    async (payload: any) => {
      return dispatch(createPendingVisit(payload))
    },
    [dispatch],
  )

  const handleCheckOverLimit = useCallback(async () => {
    let isOverLimitInner = false
    if (selectedService?.limitMember && selectedService?.overLimitFee) {
      // @ts-ignore
      const { success, count } = await dispatch(
        fetchClientVisitCount(member?.clientId, { visitType: selectedService.serviceType }),
      )
      if (success && count >= selectedService.limitMember) isOverLimitInner = true
    }
    return isOverLimitInner
  }, [dispatch, member?.clientId, selectedService])

  const handleStepSubmit = useCallback(
    async (values: FormikValues) => {
      try {
        if (currentStep === SuccessSteps.entry) {
          const { isScheduled } = values
          const hasTwoOrMoreSuccessVisits = memberPendingScheduledVisits[VisitTypes.success]?.length >= 2

          if (hasTwoOrMoreSuccessVisits && isScheduled) {
            reachedModalRef.current?.open()
            return
          }
          const newValues = {
            memberDob: member.dob,
            memberFirstName: member.firstName,
            memberId: member.memberId,
            memberLastName: member.lastName,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          }
          await handleUpdatePayload(newValues)
          setIsScheduled(values.isScheduled)
          setCurrentStep(SuccessSteps.location)
        } else if (currentStep === SuccessSteps.location) {
          const isOutsideUS = values.isOutsideUS === 'true'
          const newValues = {
            location: isOutsideUS ? '' : member.addresses[0].state,
            locationCountry: isOutsideUS ? '' : member.addresses[0].country,
            visitType: VisitTypes.success,
            scheduledType: isScheduled ? VisitScheduleType.scheduled : VisitScheduleType.now,
            reasonForVisit: '',
            modality: '',
            phone: null,
          }
          await handleUpdatePayload(newValues)
          setCurrentStep(isOutsideUS ? SuccessSteps.outsideUS : SuccessSteps.reason)
        } else if (currentStep === SuccessSteps.reason) {
          const newValues = {
            reasonForVisit: values.reasonForVisit,
            modality: '',
            phone: null,
          }
          await handleUpdatePayload(newValues)
          values.reasonForVisit && setCurrentStep(SuccessSteps.callType)
        } else if (currentStep === SuccessSteps.callType) {
          const newValues = {
            modality: values.modality,
            phone: values.phone,
            aslInterpreterNeeded: !!values.aslInterpreterNeeded,
          }
          if (!values.modality) return
          if (isScheduled) {
            await handleUpdatePayload(newValues)
            setCurrentStep(SuccessSteps.selectCoach)
            return
          }
          const isOverLimitInner = await handleCheckOverLimit()
          await handleUpdatePayload({
            ...newValues,
            price: isOverLimitInner ? selectedService.overLimitFee : selectedService.serviceFee || 0,
          })
          setIsOverLimit(isOverLimitInner)
          setCurrentStep(isOverLimitInner || selectedService.serviceFee ? SuccessSteps.payment : SuccessSteps.confirm)
        } else if (currentStep === SuccessSteps.selectCoach) {
          const newValues = {
            providerId: values.providerId,
            providerName: values.providerName,
            preferences: values.preferences,
            providerTimezone: values.providerTimezone,
          }
          await handleUpdatePayload(newValues)
          setCurrentStep(SuccessSteps.scheduling)
        } else if (currentStep === SuccessSteps.scheduling) {
          const newValues = {
            scheduledAt: values.scheduledAt,
            price: selectedService.serviceFee,
          }

          const isOverLimitInner = await handleCheckOverLimit()
          if (isOverLimitInner) {
            await handleUpdatePayload({
              ...newValues,
              price: selectedService.overLimitFee,
            })
            setIsOverLimit(isOverLimitInner)
            setCurrentStep(SuccessSteps.payment)
            return
          }

          await handleUpdatePayload(newValues)

          const nextStep = selectedService.serviceFee ? SuccessSteps.payment : SuccessSteps.confirm
          setCurrentStep(nextStep)
        } else if (currentStep === SuccessSteps.payment) {
          const newValues = {
            paymentTransactionId: values.paymentTransactionId,
          }
          await handleUpdatePayload(newValues)
          setCurrentStep(SuccessSteps.confirm)
        } else if (currentStep === SuccessSteps.confirm) {
          const payload = NewVisitSchema.cast(pendingVisit)
          // @ts-ignore
          const promise: any = handleCreatePendingVisit(payload)
          const { success } = await promise
          if (success) {
            trackClickEvent(`success_coach_session_confirmed-${pendingVisit?.scheduledType}`, {
              visitType: pendingVisit?.visitType,
              scheduleType: pendingVisit?.scheduledType,
              device: 'web',
            })
            dispatch(putNewVisit({ pendingVisit: {} }))
            toast.success(
              <p
                role="alert"
                style={{ lineHeight: '18px', margin: '5px' }}
              >{`Session has been created successfully`}</p>,
            )
            history.push(DASHBOARD)
          } else {
            console.error('error adding visit')
          }
        }
      } catch (e) {
        console.error(e)
      }
    },
    [
      currentStep,
      dispatch,
      handleCheckOverLimit,
      handleCreatePendingVisit,
      handleUpdatePayload,
      history,
      isScheduled,
      member,
      memberPendingScheduledVisits,
      pendingVisit,
      selectedService,
    ],
  )

  const handlePreviousStep = useCallback(() => {
    if (currentStep === SuccessSteps.location) {
      setCurrentStep(SuccessSteps.entry)
    } else if ([SuccessSteps.reason, SuccessSteps.outsideUS].includes(currentStep)) {
      setCurrentStep(SuccessSteps.location)
    } else if (currentStep === SuccessSteps.callType) {
      setCurrentStep(SuccessSteps.reason)
    } else if (currentStep === SuccessSteps.selectCoach) {
      setCurrentStep(SuccessSteps.callType)
    } else if (currentStep === SuccessSteps.scheduling) {
      setCurrentStep(SuccessSteps.selectCoach)
    } else if (currentStep === SuccessSteps.payment) {
      isScheduled ? setCurrentStep(SuccessSteps.scheduling) : setCurrentStep(SuccessSteps.callType)
    } else if (currentStep === SuccessSteps.confirm) {
      isScheduled ? setCurrentStep(SuccessSteps.scheduling) : setCurrentStep(SuccessSteps.callType)
    }
  }, [currentStep, isScheduled])

  const hasFooterBar = currentStep !== SuccessSteps.entry

  const cancelFlow = useCallback(() => {
    history.push(DASHBOARD)
    dispatch(putNewVisit({ pendingVisit: {} }))
  }, [dispatch, history])

  const renderFooter = useCallback(
    ({ disabled = false } = {}) => {
      switch (currentStep) {
        case SuccessSteps.location: {
          return <FooterBar onCancel={cancelFlow} disabled={disabled} />
        }
        case SuccessSteps.outsideUS:
          return <FooterBar onCancel={cancelFlow} backAction={handlePreviousStep} leaveFlow />
        case SuccessSteps.reason:
        case SuccessSteps.callType:
        case SuccessSteps.selectCoach:
        case SuccessSteps.scheduling:
        case SuccessSteps.confirm: {
          return (
            <FooterBar
              backAction={handlePreviousStep}
              onCancel={cancelFlow}
              disabled={disabled}
              isConfirm={currentStep === SuccessSteps.confirm}
            />
          )
        }
        default: {
          return null
        }
      }
    },
    [cancelFlow, currentStep, handlePreviousStep],
  )

  const renderStep = useCallback(() => {
    const formProps = { renderFooter, onSubmit: handleStepSubmit, initialValues, pendingVisit, provider }

    switch (currentStep) {
      case SuccessSteps.location:
        return <SuccessLocation {...formProps} />
      case SuccessSteps.outsideUS:
        return <SuccessOutsideUS renderFooter={renderFooter} />
      case SuccessSteps.reason:
        return <SuccessReasons {...formProps} />
      case SuccessSteps.callType:
        return <SuccessCallType {...formProps} />
      case SuccessSteps.selectCoach:
        return <SuccessSelectCoach {...formProps} />
      case SuccessSteps.scheduling:
        return <SuccessScheduling {...formProps} />
      case SuccessSteps.confirm:
        return <SuccessConfirmation {...formProps} />
      case SuccessSteps.payment:
        return (
          <SuccessPayment
            {...formProps}
            backAction={handlePreviousStep}
            onCancel={cancelFlow}
            isOverLimit={isOverLimit}
          />
        )
      default:
        return <SuccessEntry moveToNext={handleStepSubmit} availability={availability} />
    }
  }, [
    availability,
    cancelFlow,
    currentStep,
    handlePreviousStep,
    handleStepSubmit,
    initialValues,
    pendingVisit,
    provider,
    renderFooter,
    isOverLimit,
  ])

  if (!isSuccessServiceEnabled) {
    return <Redirect to="/" />
  }

  return (
    <div className={classNames('h-full w-full overflow-y-scroll', !!hasFooterBar && 'pb-[72px] sm:pb-[100px]')}>
      {renderStep()}
      <SessionLimitReachedModal
        ref={reachedModalRef}
        isSuccess={true}
        serviceName={DisplayVisitType[VisitTypes.success]}
        onConfirm={() => history.push(DASHBOARD)}
      />
      <Loading variant="big" shown={isLoading} />
    </div>
  )
}

export default SuccessPage
