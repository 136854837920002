import * as Yup from 'yup'
import { allergyTypes } from '../util/allergy'

const AllergySchema = Yup.object().shape({
  memberId: Yup.string().required(),
  current: Yup.boolean().label('Current Allergy').required(),
  allergyType: Yup.string().label('Type').oneOf(allergyTypes).required(),
  allergyDescription: Yup.string().label('Allergy').required(),
  reaction: Yup.string().label('Allergic Reaction').required(),
})

export default AllergySchema
