import Provider from 'types/provider/provider'
import ProviderInfo from '../ProviderInfo/ProviderInfo'
import { forwardRef } from 'react'
import Modal, { ModalRef } from '@ui-components-3/ui/lib/components/Modal'
import ProviderHeader from '../ProviderHeader'

type ProviderInfoDialogProps = {
  provider: Provider
  onSelect?: () => void
  onClose?: () => void
  isSuccess?: boolean
}

const ProviderInfoDialog = forwardRef<ModalRef, ProviderInfoDialogProps>(
  ({ onSelect, onClose, isSuccess, provider }, ref) => {
    return (
      <Modal
        label={`${isSuccess ? 'Coach' : 'Provider'} Bio`}
        ref={ref}
        onClose={onClose}
        size="l"
        footer={({ close }) => (
          <>
            {onSelect && provider ? (
              <button onClick={onSelect} className="btn btn-neutral" type="button">
                Select {isSuccess ? 'Coach' : 'Provider'}
              </button>
            ) : (
              <button className="btn btn-neutral" onClick={close} type="button">
                Close
              </button>
            )}
          </>
        )}
      >
        {!!provider && (
          <div className="flex flex-col items-stretch gap-6">
            <ProviderHeader provider={provider} isSuccess={isSuccess} />
            <hr className="border-neutral-300" />
            <ProviderInfo provider={provider} isSuccess={isSuccess} />
          </div>
        )}
      </Modal>
    )
  },
)

export default ProviderInfoDialog
