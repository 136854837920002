import { createSlice } from '@reduxjs/toolkit'

const defaultState = {
  meta: {},
  records: {} as Record<string, any[]>,
}

const slice = createSlice({
  name: 'paymentsHistory',
  initialState: defaultState,
  reducers: {
    setPaymentsHistory: (state, action) => {
      const { memberId, history } = action.payload
      state.records[memberId] = history
    },
  },
})

const { actions, reducer } = slice
export const { setPaymentsHistory } = actions
export default reducer
