import VisitModalityForm from 'forms/visit-creation/VisitModalityForm'
import NewVisitModalitySchema from 'schemas/NewVisitModalitySchema'
import * as MemberThunks from '../../../redux/thunks/members'
import { StepFormBaseProps } from '../types'
import { useCallback } from 'react'
import { Phone } from 'types/member/member'
import { useDispatch } from 'react-redux'

type SuccessCallTypeProps = StepFormBaseProps & {
  props?: any
}

const SuccessCallType = ({ props, onSubmit, renderFooter, initialValues, pendingVisit }: SuccessCallTypeProps) => {
  const dispatch = useDispatch()

  const handleAddPhone = useCallback(
    async (phone: Phone) => {
      if (!pendingVisit || !pendingVisit.memberId) return null
      return dispatch(MemberThunks.addPhoneNumber(pendingVisit.memberId, phone))
    },
    [dispatch, pendingVisit],
  )

  return (
    <div className="p-8">
      <VisitModalityForm
        {...props}
        validateOnMount
        onSubmit={onSubmit}
        addPhoneNumber={handleAddPhone}
        pendingVisit={pendingVisit}
        memberId={pendingVisit.memberId}
        schema={NewVisitModalitySchema}
        initialValues={initialValues}
        requiredFields={['Visit Type', 'Phone Number']}
        isRequiredLabelVisible={true}
      >
        {renderFooter}
      </VisitModalityForm>
    </div>
  )
}

export default SuccessCallType
