import * as Yup from 'yup'
import configBuilder from '../config-builder'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  params: Yup.object().shape({
    filter: Yup.object(),
    page: Yup.number(),
    limit: Yup.number(),
    sort: Yup.object(),
  }),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => await RequestSchema.validate(params)
export const url = () => '/v1/components'
export const execute = async (params) => api.get(url(), configBuilder(params)).catch(catchApiFailure)
