import { createSlice } from '@reduxjs/toolkit'

const defaultState = {
  meta: {},
  records: {
    // [service_id]: Service
  },
}

const getId = (method) => method.id

const slice = createSlice({
  name: 'payments',
  initialState: defaultState,
  reducers: {
    putPaymentMethods: (state, action) => {
      const { methods } = action.payload
      methods.forEach((method) => {
        state.records[getId(method)] = method
      })
    },
    clearPaymentMethod: (state, action) => {
      const { methodId } = action.payload
      delete state.records[methodId]
    },
    clearPaymentMethods: (state) => {
      state.records = { ...defaultState.records }
    },
  },
})

const { actions, reducer } = slice
export const { putPaymentMethods, clearPaymentMethod, clearPaymentMethods } = actions
export default reducer
