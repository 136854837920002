import * as Yup from 'yup'
import configBuilder from '../config-builder'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  memberId: Yup.string().label('Member ID').required(),
  pharmacyId: Yup.string().label('Pharmacy ID').required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const make = async (params) => {
  return {
    pharmacy_id: params.pharmacyId,
  }
}
export const url = ({ memberId }) => `/v1/members/${memberId}/pharmacies`
export const execute = async (params) =>
  api.post(url(params), await make(params), configBuilder(params)).catch(catchApiFailure)
