import * as Yup from 'yup'
import catchApiError from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  uniqueId: Yup.string().required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const make = () => null
export const url = (params) => `/v1/unsubscribe/email/${params.uniqueId}`
export const execute = async (params) => api.post(url(params)).catch(catchApiError)
