import TestIds from 'util/TestIds'
import Modal, { ModalRef, ModalProps } from '@ui-components-3/ui/lib/components/Modal'
import classNames from '@ui-components-3/ui/lib/utils/classNames'
import { forwardRef } from 'react'
import { Merge } from '@ui-components-3/ui/lib/types'

export type ConfirmCloseDialogProps = Merge<
  ModalProps,
  {
    onConfirm: () => void
    cancelLabel?: string
    confirmLabel?: string
    footer?: never
    className?: string
    backdrop?: boolean
    confirmClassName?: string
    cancelClassName?: string
  }
>

const ConfirmCloseDialog = forwardRef<ModalRef, ConfirmCloseDialogProps>(
  (
    {
      onConfirm,
      className,
      cancelLabel = 'No, cancel',
      confirmLabel = 'Yes, close',
      backdrop = true,
      confirmClassName,
      cancelClassName,
      ...props
    },
    ref,
  ) => {
    return (
      <Modal
        {...props}
        ref={ref}
        className={classNames(backdrop && 'backdrop:backdrop-blur', className)}
        data-testid={TestIds.common.dialogs.confirmClose.view}
        footer={({ close }) => (
          <>
            <button
              onClick={close}
              type="button"
              className={classNames('btn btn-fuchsia-outlined', cancelClassName)}
              data-testid={TestIds.common.dialogs.confirmClose.cancel}
            >
              {cancelLabel}
            </button>
            <button
              type="button"
              className={classNames('btn btn-neutral', confirmClassName)}
              onClick={() => {
                close()
                onConfirm()
              }}
              data-testid={TestIds.common.dialogs.confirmClose.cancel}
            >
              {confirmLabel}
            </button>
          </>
        )}
      />
    )
  },
)
export default ConfirmCloseDialog
