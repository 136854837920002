import * as Yup from 'yup'
import configBuilder from '../config-builder'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import { normalizeJsonToApi } from '../../helpers'

const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  componentId: Yup.string().required(),
  journeyId: Yup.string().required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => await RequestSchema.validate(params)
export const make = async ({ journeyId, componentId }) => {
  return normalizeJsonToApi({
    target: {
      ref: {
        journeyId,
        componentId,
      },
      type: 'journey_component',
    },
    type: 'view',
  })
}
export const url = () => `/v1/reactions`
export const execute = async (params) =>
  api.post(url(params), await make(params), configBuilder(params)).catch(catchApiFailure)
