import * as Yup from 'yup'
import configBuilder from '../config-builder'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'

const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  token: Yup.string().required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const url = ({ token }) => `/v2/surveys/status/${token}`
export const execute = async (params) => api.get(url(params), configBuilder(params)).catch(catchApiFailure)
