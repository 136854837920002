import VisitConfirmationForm from 'forms/visit-creation/VisitConfirmationForm'
import NewVisitSchema from 'schemas/NewVisitSchema'
import { StepFormBaseProps } from '../types'

type SuccessConfirmationProps = {
  [key: string]: any
} & StepFormBaseProps

const SuccessConfirmation = (props: SuccessConfirmationProps) => {
  const { initialValues, onSubmit, renderFooter } = props

  return (
    <div className="p-8">
      <VisitConfirmationForm
        {...props}
        schema={NewVisitSchema}
        initialValues={initialValues}
        isRequiredLabelVisible={false}
        onSubmit={onSubmit}
      >
        {renderFooter}
      </VisitConfirmationForm>
    </div>
  )
}

export default SuccessConfirmation
