type ProviderInfoBulletListProps = {
  items?: string[]
}
const ProviderInfoBulletList = ({ items }: ProviderInfoBulletListProps) => {
  if (!items?.length) {
    return '-'
  }

  return (
    <>
      <ul className="list-disc px-4">
        {items.map((item, index) => (
          <li key={String(`${item}_${index}`)}>{item}</li>
        ))}
      </ul>
    </>
  )
}

export default ProviderInfoBulletList
