import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import configBuilder from '../config-builder'
import { normalizeJsonToApi } from '../../helpers'
import FamilyHistorySchema from '../../../schemas/FamilyHistorySchema'

const api = ApiFactory.getApi(ApiTypes.member)

export const isValid = async (params) => await FamilyHistorySchema.isValid(params)
export const validate = async (params) => FamilyHistorySchema.validate(params)
export const make = async (params) => {
  const { conditions } = FamilyHistorySchema.cast(params)
  return normalizeJsonToApi({ conditions })
}
export const url = ({ memberId }) => `/v1/${memberId}/family/history/conditions`
export const execute = async (params) =>
  api.put(url(params), await make(params), configBuilder(params)).catch(catchApiFailure)
