import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import { normalizeJsonToApi } from '../../helpers'
import configBuilder from '../config-builder'
import ApiFactory, { ApiTypes } from '../../index'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  reportReasons: Yup.array().of(Yup.string()).min(1).required(),
  postId: Yup.string().required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => await RequestSchema.validate(params)
export const make = async (params) => {
  return normalizeJsonToApi(params)
}
export const url = () => '/v1/post-reports'
export const execute = async (params) =>
  api.post(url(), await make(params), configBuilder(params)).catch(catchApiFailure)
