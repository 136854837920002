import { EVisitModalities } from 'util/visit-modalities'

import TestIds from '../../../util/TestIds'
import VisitScheduleType from '../../../util/visit-schedule-types'
import ScheduledVisitConfirmation from '../ScheduledVisitConfirmation'
import { APP_NAME } from 'util/constants'
import PendingVisit from 'types/visit/pendingVisit'
import Provider from 'types/provider/provider'
import { statesByValue } from 'util/states'
import { findCountryByValue } from 'util/countries'
import { useMemo } from 'react'
import VisitConfirmationLocation from '../VisitConfirmationLocation'
import { isSuccess } from 'util/helpers'

type VisitConfirmationChooser = {
  pendingVisit: PendingVisit
  provider?: Provider
  price?: string
}

const VisitConfirmationChooser = (props: VisitConfirmationChooser) => {
  const { pendingVisit } = props
  const { scheduledType, modality, location, locationCountry, visitType } = pendingVisit || {}

  const displayLocation = useMemo(() => {
    if (location) {
      return statesByValue[location]
    }
    return findCountryByValue(locationCountry)
  }, [location, locationCountry])

  const successVisit = useMemo(() => {
    return isSuccess(visitType)
  }, [visitType])

  if (scheduledType === VisitScheduleType.now) {
    const isChatModality = modality === EVisitModalities.chat
    const providerType = successVisit ? 'Success Coach' : 'Provider'
    const chatDescription = `A ${APP_NAME} ${providerType} will chat with you soon. Sessions may run between 25 and 35 minutes. If you don't respond within 5 minutes, they might end the session.${
      !successVisit ? ' In an emergency, a provider may escalate your care.' : ''
    }`
    const defaultDescription = `Once confirmed, a ${APP_NAME} ${providerType} will be contacting you shortly`

    const description = isChatModality ? chatDescription : defaultDescription

    return (
      <>
        {!successVisit && <VisitConfirmationLocation displayLocation={displayLocation} />}
        <div
          className="typography-body bg-pantone-300 rounded-lg px-4 py-2 font-medium text-neutral-600"
          data-testid={TestIds.newVisit.label.confirmInstructions}
        >
          {description}
        </div>
      </>
    )
  }
  return <ScheduledVisitConfirmation {...props} displayLocation={displayLocation} />
}

export default VisitConfirmationChooser
