import * as Yup from 'yup'
import AddressSchema from './AddressSchema'
import PhoneSchema from './PhoneSchema'
import { genderIdentities } from '../util/member'
import moment from 'moment/moment'

export const DependentFormSchema = Yup.object().shape({
  memberId: Yup.string().notRequired().nullable(),
  primaryMemberId: Yup.string().required(),
  groupId: Yup.string().required(),
  clientId: Yup.string().required(),
  address: AddressSchema.required(),
  phone: PhoneSchema.required(),
  relationType: Yup.string()
    .label('Relation')
    .oneOf(['Child', 'Parent', 'Grandparent', 'Cousin', 'Spouse', 'Other'])
    .required(),
  gender: Yup.string().label('Sex').oneOf(['M', 'F', 'X', 'U'], 'Gender must be a valid gender').required(),
  genderIdentity: Yup.string().label('I identify as').oneOf(genderIdentities).nullable().notRequired(),
  genderSelfSelect: Yup.string()
    .label('Self Selected Gender')
    .when('genderIdentity', {
      is: 'Other/not listed',
      then: Yup.string().required('Error: Self Selected Gender is a required field that cannot be left blank'),
      otherwise: Yup.string().notRequired().nullable().strip(),
    }),
  preferredLanguage: Yup.object()
    .label('Preferred Language')
    .shape({
      name: Yup.string(),
      abbrev: Yup.string(),
    })
    .notRequired(),
  firstName: Yup.string()
    .label('First Name')
    .trim('No leading or trailing spaces')
    .max(35, 'First Name is too Long')
    .required(),
  lastName: Yup.string()
    .label('Last Name')
    .trim('No leading or trailing spaces')
    .max(35, 'Last Name is too Long')
    .required(),
  firstNamePreferred: Yup.string()
    .label('Preferred Name')
    .trim('No leading or trailing spaces')
    .max(35, 'Preferred name is too Long')
    .notRequired()
    .nullable(),
  dob: Yup.string()
    .label('Date of Birth')
    .test('test-max-age', 'Age must be be under 120 years old.', function (value) {
      return !value || moment(value).isAfter(moment().startOf('day').subtract(120, 'years'))
    })
    .required('Date of birth is a required field')
    .nullable(),
})

const DependentSchema = Yup.object().shape({
  memberId: Yup.string().strip(),
  lang: Yup.string().strip(),
  groupId: Yup.string().required(),
  clientId: Yup.string().required(),
  primaryMemberId: Yup.string().strip(),
  addresses: Yup.array().of(AddressSchema).required(),
  phones: Yup.array().of(PhoneSchema).required(),
  relationType: Yup.string().oneOf(['Child', 'Parent', 'Grandparent', 'Cousin', 'Spouse', 'Other']).required(),
  gender: Yup.string().label('Gender').oneOf(['M', 'F', 'X', 'U'], 'Gender must be a valid gender').required(),
  genderIdentity: Yup.string().label('I identify as').oneOf(genderIdentities).notRequired(),
  genderSelfSelect: Yup.string().label('Self Selected Gender').when('genderIdentity', {
    is: 'Other/not listed',
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired().nullable().strip(),
  }),
  preferredLanguage: Yup.object()
    .shape({
      name: Yup.string(),
      abbrev: Yup.string(),
    })
    .notRequired(),
  firstName: Yup.string()
    .label('First Name')
    .trim('No leading or trailing spaces')
    .max(35, 'First Name is too Long')
    .required(),
  lastName: Yup.string()
    .label('Last Name')
    .trim('No leading or trailing spaces')
    .max(35, 'Last Name is too Long')
    .required(),
  firstNamePreferred: Yup.string()
    .label('Preferred Name')
    .trim('No leading or trailing spaces')
    .max(35, 'Preferred name is too Long')
    .notRequired()
    .nullable(),
  dob: Yup.string()
    .label('Date of Birth')
    .matches(/\d{4}-\d{2}-\d{2}/, 'Date of Birth must have the format YYYY-MM-DD'),
})

export default DependentSchema
