const FetchTypes = {
  // ENTRY
  login: 'login',
  logout: 'logout',
  forgotPassword: 'forgotPassword',
  verifyAccessCode: 'verifyAccessCode',
  authChallenge: 'authChallenge',
  updatePassword: 'updatePassword',
  resetPassword: 'resetPassword',
  unsubscribeEmail: 'unsubscribeEmail',
  updateTermsConditions: 'updateTermsConditions',
  generateMfa: 'generateMfa',
  verifyMfa: 'verifyMfa',
  postOAuthToken: 'postOAuthToken',
  // PUSH
  updatePushRegistration: 'updatePushRegistration',
  // REGISTRATION
  registerEligibility: 'registerEligibility',
  lookupMember: 'lookupMember',
  registerAccount: 'registerAccount',
  registerMember: 'registerMember',
  validatePhoneNumber: 'validatePhoneNumber',
  // CLIENTS - GROUPS - SERVICES
  getSurveys: 'getSurveys',
  getSurveyQuestions: 'getSurveyQuestions',
  completeSurvey: 'completeSurvey',
  getServices: 'getServices',
  getResources: 'getResources',
  getClient: 'getClient',
  getGroup: 'getGroup',
  getClientByDomain: 'getClientByDomain',
  getVisitRestrictions: 'getVisitRestrictions',
  getClientVisitCount: 'getClientVisitCount',
  // VISITS
  maybeGetPendingVisits: 'maybeGetPendingVisits',
  getPendingVisits: 'getPendingVisits',
  maybeGetPendingVisit: 'maybeGetPendingVisit',
  getPendingVisit: 'getPendingVisit',
  updateCancelVisit: 'updateCancelVisit',
  updateVisitStatus: 'updateVisitStatus',
  getVisits: 'getVisits',
  getAllVisits: 'getAllVisits',
  maybeGetVisit: 'maybeGetVisit',
  getVisit: 'getVisit',
  getVisitByPendingVisit: 'getVisitByPendingVisit',
  addPendingVisit: 'addPendingVisit',
  getPrescription: 'getPrescription',
  updateVisitAssesmentResponse: 'updateVisitAssesmentResponse',
  // VISIT VIDEO
  getVideoTokenV2: 'getVideoTokenV2',
  addVideoParticipant: 'addVideoParticipant',
  postAddDailyCallInterpreter: 'postAddDailyCallInterpreter',
  putRemoveDailyCallInterpreter: 'putRemoveDailyCallInterpreter',
  // MEMBERS
  getMe: 'getMe',
  maybeGetMemberNames: 'maybeGetMemberNames',
  getMemberNames: 'getMemberNames',
  maybeGetMember: 'maybeGetMember',
  getMember: 'getMember',
  addMember: 'addMember',
  updateMember: 'updateMember',
  updateMemberContact: 'updateMemberContact',
  updateMemberAcknowledgment: 'updateMemberAcknowledgment',
  getMemberDuplicates: 'getMemberDuplicates',
  postAccountMergeInit: 'postAccountMergeInit',
  postAccountMergeChallenge: 'postAccountMergeChallenge',
  postAccountMergeSaveForLater: 'postAccountMergeSaveForLater',
  // DEPENDENTS
  addDependent: 'addDependent',
  updateDependent: 'updateDependent',
  // MEDICAL HISTORY
  getConditions: 'getConditions',
  addMedicalHistory: 'addMedicalHistory',
  updateMedicalHistory: 'updateMedicalHistory',
  addMedicalConditions: 'addMedicalConditions',
  getMedicalHistory: 'getMedicalHistory',
  // MEDICATIONS
  addMedication: 'addMedication',
  getMedicationsV2: 'getMedicationsV2',
  getMedicationDetailsV2: 'getMedicationDetailsV2',
  // ALLERGIES
  addAllergy: 'addAllergy',
  updateAllergy: 'updateAllergy',
  removeAllergy: 'removeAllergy',
  getAllergies: 'getAllergies',
  // FAMILY HISTORY
  addFamilyHistory: 'addFamilyHistory',
  updateFamilyConditions: 'updateFamilyConditions',
  getFamilyHistory: 'getFamilyHistory',
  // IMAGES
  removeImageV2: 'removeImageV2',
  addImageV2: 'addImageV2',
  getThumbImageV2: 'getThumbImageV2',
  getImagesV2: 'getImagesV2',
  // PAYMENT METHODS
  getPaymentMethods: 'getPaymentMethods',
  getPaymentTransactions: 'getPaymentTransactions',
  getPaymentTransaction: 'getPaymentTransaction',
  addPaymentMethod: 'addPaymentMethod',
  addPaymentClient: 'addPaymentClient',
  removePaymentMethod: 'removePaymentMethod',
  addPaymentTransaction: 'addPaymentTransaction',
  addPaymentTransactionV2: 'addPaymentTransactionV2',
  // PROVIDERS
  maybeGetAllProviders: 'maybeGetAllProviders',
  getAllProviders: 'getAllProviders',
  maybeGetProviders: 'maybeGetProviders',
  getProviders: 'getProviders',
  maybeGetProvider: 'maybeGetProvider',
  getProvider: 'getProvider',
  getMemberProviders: 'getMemberProviders',
  addProvider: 'addProvider',
  updateProvider: 'updateProvider',
  removeProvider: 'removeProvider',
  getAvailableProviders: 'getAvailableProviders',
  getAvailableQueueProviders: 'getAvailableQueueProviders',
  getProviderAvailability: 'getProviderAvailability',
  // PHARMACIES
  getMemberPharmacies: 'getMemberPharmacies',
  addMemberPharmacy: 'addMemberPharmacy',
  removeMemberPharmacy: 'removeMemberPharmacy',
  getPharmacies: 'getPharmacies',
  // CHATS
  addChatMessage: 'addChatMessage',
  getVisitChat: 'getVisitChat',
  addVisitChat: 'addVisitChat',
  readChatMessage: 'readChatMessage',
  // GPS
  location: 'location',
  // SCHEDULING
  getSchedulings: 'getSchedulings',
  getScheduling: 'getScheduling',
  getSchedulingById: 'getSchedulingById',
  addSchedulingRequest: 'addSchedulingRequest',
  updateSchedulingRequest: 'updateSchedulingRequest',
  // RATINGS
  addProviderRating: 'addProviderRating',
  addVisitRating: 'addVisitRating',
  addRating: 'addRating',
  // MESSAGES
  getMessages: 'getMessages',
  getMessage: 'getMessage',
  putMessageViewedAt: 'putMessageViewedAt',
  putMessageStatus: 'putMessageStatus',
  getUnreadMessageCounts: 'getUnreadMessageCounts',
  // SERVICES
  addServiceCode: 'addServiceCode',
  // NEEDS
  getNeeds: 'getNeeds',
  getNeed: 'getNeed',
  addNeed: 'addNeed',
  removeNeed: 'removeNeed',
  // SESSIONS
  getSessions: 'getSessions',
  // SUPPORT
  addSupportTicket: 'addSupportTicket',
  getFaqQuestions: 'getFaqQuestions',
  // FEEDBACK
  addMemberFeedback: 'addMemberFeedback',
  // COMMUNITY
  getTermsVersion: 'getTermsVersion',
  getAllPosts: 'getAllPosts',
  getNewPost: 'getNewPost',
  getPost: 'getPost',
  addCommunityPost: 'addCommunityPost',
  getPostComments: 'getPostComments',
  getPostNewComment: 'getPostNewComment',
  getPostComment: 'getPostComment',
  deleteCommunityPost: 'deleteCommunityPost',
  addCommunityComment: 'addCommunityComment',
  addCommunityPostReport: 'addCommunityPostReport',
  getPostReports: 'getPostReports',
  deletePostReport: 'deletePostReport',
  togglePostReaction: 'togglePostReaction',
  getMemberReactions: 'getMemberReactions',
  getCommunityRandomPosts: 'getCommunityRandomPosts',
  // EXPLORE
  getSccComponents: 'getSccComponents',
  getSccComponent: 'getSccComponent',
  getSccCategories: 'getSccCategories',
  getSccCategory: 'getSccCategory',
  toggleComponentLike: 'toggleComponentLike',
  getSccJourneys: 'getSccJourneys',
  getSccJourney: 'getSccJourney',
  getSelfCares: 'getSelfCares',
  addSccJourneyComponentView: 'addSccJourneyComponentView',
  // NPS Visit Survey
  getVisitSurveyStatus: 'getVisitSurveyStatus',
  postVisitSurvey: 'postVisitSurvey',
  putVisitSurvey: 'putVisitSurvey',
  // Fast Forms
  getForms: 'getForms',
  // Cancellation Reasons
  getCancellationReasons: 'getCancellationReasons',
  // Signed Urls
  postSignedUrls: 'postSignedUrls',
  // Student Success
  successAvailability: 'successAvailability',
  // Save Care Questionnaire
  saveCareQuestionnaire: 'saveCareQuestionnaire',
  // Member Consents
  getMemberConsents: 'getMemberConsents',
  postMemberSelfConsent: 'postMemberSelfConsent',
} as const

export default FetchTypes
