import * as Yup from 'yup'
import catchApiFailure from 'api/requests/catch-api-error'
import ApiFactory, { ApiTypes } from 'api'
import configBuilder from 'api/requests/config-builder'
import { normalizeJsonToApi } from 'api/helpers'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  /* TODO: Remove the following parameters when configBuilder is typed
    START */
  params: Yup.object(),
  token: Yup.string(),
  /* END */
  code: Yup.string().required(),
  codeVerifier: Yup.string().required(),
})

export type Request = Yup.InferType<typeof RequestSchema>

export type SuccessfulResponse = {
  memberId: string
  accessToken: string
  channelsToken: string
  refreshToken: string
}

export const isValid = async (params: Request) => await RequestSchema.isValid(params)
export const validate = async (params: Request) => RequestSchema.validate(params)
export const make = async (params: Request) => normalizeJsonToApi(params)

export const url = () => `/v1/oauth/token`
export const execute = async (params: Request) =>
  api.post(url(), await make(params), configBuilder(params)).catch(catchApiFailure)
