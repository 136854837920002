import * as Yup from 'yup'
import { normalizeJsonToApi } from '../../helpers'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import PhoneSchema from '../../../schemas/PhoneSchema'
import { accountClassifications, genderIdentities, gradSemesters, gradYears } from '../../../util/member'
const api = ApiFactory.getApi(ApiTypes.member)

const ApiAddress = Yup.object().shape({
  street1: Yup.string()
    .label('Street 1')
    .trim('Street 1 should have no leading or trailing spaces')
    .min(2, 'Street 1 too Short')
    .max(35, 'Street 1 too Long')
    .required(),
  street2: Yup.string()
    .label('Street 2')
    .trim('Street 2 should have no leading or trailing spaces')
    .max(35, 'Street 2 too Long')
    .nullable()
    .notRequired(),
  city: Yup.string()
    .label('City')
    .trim('City should have no leading or trailing spaces')
    .min(2, 'City is too Short')
    .max(35, 'City is too Long')
    .required(),
  zip: Yup.string()
    .label('Zip Code')
    .matches(/\d{5}/, 'Zip Code must be 5 digits')
    .min(5, 'Zip Code is too Short')
    .max(5, 'Zip Code is too Long')
    .required(),
  state: Yup.string().label('State').min(2, 'State is too Short').max(2, 'State is too Long').required(),
  country: Yup.string().label('Country').min(2, 'State is too Short').max(2, 'State is too Long').required(),
})

const RequestSchema = Yup.object().shape({
  memberId: Yup.string().label('Member ID').required(),
  member: Yup.object().shape({
    memberId: Yup.string().notRequired().nullable(),
    mailingAddress: ApiAddress,
    billingAddress: ApiAddress,
    phones: Yup.array().of(PhoneSchema),
    email: Yup.string().label('Email').email('Must be a valid email address').required(),
    gender: Yup.string().label('Gender').oneOf(['M', 'F', 'X', 'U'], 'Gender must be a valid gender').required(),
    genderIdentity: Yup.string().label('I identify as').oneOf(genderIdentities).notRequired().nullable(),
    genderSelfSelect: Yup.string().label('Self Selected Gender').when('genderIdentity', {
      is: 'Other/not listed',
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired().nullable().strip(),
    }),
    pronoun: Yup.string().notRequired().nullable(),
    preferredLanguage: Yup.string(),
    firstName: Yup.string()
      .label('First Name')
      .trim('No leading or trailing spaces')
      .max(35, 'First Name is too Long')
      .required(),
    lastName: Yup.string()
      .label('Last Name')
      .trim('No leading or trailing spaces')
      .max(35, 'Last Name is too Long')
      .required(),
    firstNamePreferred: Yup.string().label('Preferred First Name').notRequired().nullable(),
    ethnicity: Yup.array().of(Yup.string()).default([]).notRequired().nullable(),
    dob: Yup.string()
      .label('Date of Birth')
      .required('Date of Birth is required')
      .matches(/\d{4}-\d{2}-\d{2}/, 'Date of Birth must be a valid date'),
    degreeClassification: Yup.string().label('Classification').oneOf(accountClassifications).notRequired(),
    graduationSemester: Yup.string().label('Graduation Semester').oneOf(gradSemesters).notRequired(),
    graduationYear: Yup.string().label('Graduation Year').oneOf(gradYears).notRequired(),
  }),
})

export const isValid = async (params) => {
  return await RequestSchema.isValid(params)
}
export const validate = async (params) => RequestSchema.validate(params)
export const make = async (params) => {
  return normalizeJsonToApi(params.member)
}
export const url = ({ memberId }) => `/v1/${memberId}/register`
export const execute = async (params) => api.post(url(params), await make(params)).catch(catchApiFailure)
