import * as Yup from 'yup'
import lastIndexOf from 'lodash/lastIndexOf'

Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a) => a) {
  return this.test('unique', message, function (list) {
    const hasError = list.length !== new Set(list.map(mapper)).size
    if (hasError) {
      const countMap = list.reduce((acc, time) => {
        if (time) {
          if (acc[time]) {
            acc[time] = acc[time] + 1
          } else {
            acc[time] = 1
          }
        }
        return acc
      }, {})

      const duplicate = Object.keys(countMap).find((key) => countMap[key] > 1)

      const index = lastIndexOf(list, duplicate)

      return this.createError({
        path: `${this.path}[${index}]`,
        message,
      })
    } else {
      return true
    }
  })
})

const NewVisitSchedulingSchema = Yup.object().shape({
  timezone: Yup.string().label('Timezone').required(),
  scheduledAt: Yup.date().label('Scheduled Date').required(),
})

export default NewVisitSchedulingSchema
