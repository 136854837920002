import { ReactNode } from 'react'
import TestIds from 'util/TestIds'
import { accessCodeInputFormat, cleanAccessCodeInput } from 'util/formatters'
import AddServiceImage from 'assets/images/add_service.svg'
import AccessCodeSchema from 'schemas/AccessCodeSchema'
import FormikFormattedTextField from '@ui-components-3/compat/lib/components/FormikFormattedTextField'
import { Formik } from 'formik'
import Notice from '@ui-components-3/ui/lib/components/Notice'
import { HELP_CENTER } from 'routes/paths'
import { Link } from 'react-router-dom'
import GetCareCard from 'components/visit-creation/GetCareCard/GetCareCard'

type FormProps = {
  children: AccessCodeFormProps['children']
  onSubmit: AccessCodeFormProps['onSubmit']
}

const Form = ({ onSubmit, children }: FormProps) => {
  return (
    <Formik initialValues={{ code: '' }} validationSchema={AccessCodeSchema} onSubmit={onSubmit}>
      {(renderProps) => {
        const { dirty, isValid, handleSubmit } = renderProps
        return (
          <form
            onSubmit={(e) => handleSubmit(e)}
            className="flex flex-col gap-4 text-left"
            data-testid={TestIds.newVisit.view.addServiceForm}
          >
            <p className="text-neutral-700">All fields marked with asterisk are required*</p>
            <FormikFormattedTextField
              data-testid={TestIds.account.input.serviceCode}
              label={'Service Code'}
              fullWidth
              schema={AccessCodeSchema}
              name="code"
              unformatText={cleanAccessCodeInput}
              formatText={accessCodeInputFormat}
              className="[&_input]:text-center"
              placeholder="- - - - -"
            />
            <div className="flex flex-col gap-6">
              <button className="btn btn-primary w-full" disabled={!dirty || !isValid}>
                Add Service
              </button>
              <Notice variant="info">
                <p>
                  If you believe this is an error or need assistance, please reach out to our support team in the{' '}
                  <Link to={HELP_CENTER} className="text-link-primary font-semibold">
                    Help Center
                  </Link>
                  .
                </p>
              </Notice>
            </div>
            {children({ disabled: !isValid })}
          </form>
        )
      }}
    </Formik>
  )
}

type AccessCodeFormProps = {
  children: (values: { disabled: boolean }) => ReactNode
  onSubmit: () => void
}

const AddServiceForm = ({ children, onSubmit }: AccessCodeFormProps) => {
  return (
    <GetCareCard.Wrapper>
      <GetCareCard>
        <GetCareCard.Image src={AddServiceImage} alt="" />
        <GetCareCard.Title dataTestId={TestIds.newVisit.label.addServiceTitle}>
          No services have been added
        </GetCareCard.Title>
        <Form onSubmit={onSubmit}>{children}</Form>
      </GetCareCard>
    </GetCareCard.Wrapper>
  )
}

export default AddServiceForm
