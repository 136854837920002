export interface TrackEventProperties {
  [key: string]: any
}

export function trackClickEvent(eventName: string, properties: TrackEventProperties): void {
  try {
    window.pendo?.track(eventName, properties)
  } catch (error) {
    console.log(`Error tracking event: ${eventName}`, error)
  }
}

export function pendoTrack(eventName: string, properties: TrackEventProperties, retries = 5): void {
  if (window.pendo && window.pendo.isReady && window.pendo.isReady()) {
    return window.pendo.track(eventName, properties)
  }
  if (retries > 0) {
    setTimeout(function () {
      pendoTrack(eventName, properties, retries - 1)
    }, 500)
  } else {
    console.error(`Error tracking event: ${eventName}. Pendo not ready after maximum retries`)
  }
}
