type ProviderInfoDetailSectionProps = { title: string; subtitle?: string; value?: string }

const ProviderInfoDetailSection = ({ title, subtitle, value }: ProviderInfoDetailSectionProps) => {
  return (
    <section>
      <div className="typography-body-l flex flex-col items-start">
        <span className="typography-body font-semibold uppercase text-neutral-500">{title}</span>
        {!!subtitle && <p className="whitespace-pre-wrap font-medium text-neutral-800">{subtitle}</p>}
        {!!value && <p className="typography-body whitespace-pre-wrap font-medium text-neutral-700">{value}</p>}
      </div>
    </section>
  )
}

export default ProviderInfoDetailSection
