import PendingVisit from 'types/visit/pendingVisit'
import VisitTypes, { getActualVisitType } from './visit-types'
import isNumber from 'lodash/isNumber'

export const intakeTypes = ['phq9', 'gad7']

export const isAssessmentAvailableForVisitType = (pendingVisit: PendingVisit) => {
  const { scheduledType, visitType } = pendingVisit
  const actualVisitType = getActualVisitType(visitType, scheduledType)
  return (
    scheduledType === 'scheduled' &&
    actualVisitType &&
    [VisitTypes.therapy, VisitTypes.psychiatry].includes(actualVisitType)
  )
}

export const checkIsVisitAssessmentComplete = (pendingVisit?: PendingVisit) => {
  if (!pendingVisit) {
    return false
  }

  const { intakes } = pendingVisit

  return isNumber(intakes?.gad7?.score) && isNumber(intakes?.phq9?.score)
}
