import { PACKAGE_OPTIONS } from 'types/client/group'
import * as Yup from 'yup'

export const displayReasons = [
  { value: 'scheduling', label: 'Scheduling', digitalOnly: PACKAGE_OPTIONS.CLINICAL_ONLY },
  { value: 'prescriptions', label: 'Prescriptions', digitalOnly: PACKAGE_OPTIONS.CLINICAL_ONLY },
  { value: 'login-issues', label: 'Login Issues', digitalOnly: PACKAGE_OPTIONS.ALL },
  {
    value: 'connectivity-problems',
    label: 'Connectivity Problems',
    digitalOnly: PACKAGE_OPTIONS.CLINICAL_ONLY,
  },
  { value: 'provider-feedback', label: 'Provider Feedback', digitalOnly: PACKAGE_OPTIONS.CLINICAL_ONLY },
] as const

export const filterDisplayReasonsByService = (
  isMemberDigitalOnly: typeof PACKAGE_OPTIONS,
  shouldExcludePrescriptions: boolean,
) => {
  if (isMemberDigitalOnly) {
    return displayReasons.filter(
      (displayReason) =>
        displayReason.digitalOnly === PACKAGE_OPTIONS.ALL || displayReason.digitalOnly === isMemberDigitalOnly,
    )
  }

  if (shouldExcludePrescriptions) {
    return displayReasons.filter((displayReason) => displayReason.value !== 'prescriptions')
  }

  return displayReasons
}

export const contactReasons = displayReasons.map((reason) => reason.value)

export const contactSupportSchema = Yup.object().shape({
  supportReason: Yup.string().label('Contact Support Reason').oneOf(contactReasons).required(),
  supportBody: Yup.string()
    .label('Description')
    .min(20, 'Description should be at least 20 characters')
    // TODO - ugh, bad label
    .required('A description of why you are contacting support is required.'),
})
