import * as Yup from 'yup'
import { stateValues } from '../util/states'
import { MemberSelectSchema } from './MemberSelectSchema'
import NewVisitLocationCountrySchema from './NewVisitLocationCountrySchema'
import { isUSLocation } from 'util/visit-location'

const NewVisitEntrySchema = MemberSelectSchema.concat(NewVisitLocationCountrySchema).concat(
  Yup.object().shape({
    location: Yup.string()
      .label('Where are you located?')
      .when('locationCountry', {
        is: isUSLocation,
        then: (schema) => schema.oneOf(stateValues).required(),
        otherwise: (schema) => schema.notRequired(),
      }),
  }),
)

export default NewVisitEntrySchema
