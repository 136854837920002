import last from 'lodash/last'
import reduce from 'lodash/reduce'
import isObject from 'lodash/isObject'
import isEmpty from 'lodash/isEmpty'
import omitBy from 'lodash/omitBy'
import isString from 'lodash/isString'
import VisitTypes from './visit-types'
import each from 'lodash/each'
import { LanguageFilters } from './languages'
import { clone } from 'lodash'
import Config from '../config'
import moment from 'moment'
import { getPrintableFullName } from './member'
import { createSimpleSelectOptions } from './form'

export const getAvatarUrl = (provider) => {
  if (!provider) return null
  const { imageId, imageExt } = provider
  if (!imageId || !imageExt) return undefined
  return `${Config.s3BaseUrl}/profiles/${imageId}_thumb${imageExt}`
}

export const getPrintableShortProviderName = (provider, isSuccess) => {
  if (isString(provider)) {
    const providerCredentials = provider.split(',')
    const providerFullNameAndPrefix = providerCredentials.shift().split(' ')
    const hasPrefix = providerFullNameAndPrefix.length === 3
    const firstName = hasPrefix ? providerFullNameAndPrefix[1] : providerFullNameAndPrefix[0]
    const lastName = hasPrefix ? providerFullNameAndPrefix[2] : providerFullNameAndPrefix[1]

    return `${firstName} ${lastName}`
  }

  if (!provider) return ''

  const { profile } = provider

  const fullName = getPreferredProviderName(provider, isSuccess)

  if (isSuccess) return fullName

  const licensesString = profile?.licenses?.join?.(', ') || ''

  return [fullName, licensesString].filter(Boolean).join(', ')
}

export const getPreferredProviderName = (provider, isSuccess) => {
  if (provider.profile?.firstNamePreferred && isSuccess) {
    return `${provider.profile.firstNamePreferred || provider.firstName} ${provider.lastName}`
  }
  return getPrintableFullName(provider)
}

export const getProviderOptionalProfileValue = (value, emptyValue = '-') => {
  if (!value || value.toLowerCase() === 'i do not wish to disclose') {
    return emptyValue
  }

  return value
}

export const getProviderDetailString = (provider) => {
  if (!provider) return ''

  const { profile } = provider

  const displayPronouns = getProviderOptionalProfileValue(profile?.pronouns, '')

  return displayPronouns ? `(${displayPronouns})` : '-'
}

export const getProviderInitials = (provider) =>
  `${provider?.firstName?.[0] || ''}${provider?.lastName?.[0] || ''}`.toUpperCase()

export const Specialties = {
  medical: [
    'Emergency Medicine',
    'Family Practice',
    'Internal Medicine',
    'Urgent Care',
    // TODO: Went back to ...MD bc we need to migrate existing values
    'TimelyMD Provider',
    'Nurse Practitioner',
    'Physician Assistant',
    'Pediatrics',
    'Dermatology',
    'Cardiology',
    'Neurology',
  ],
  therapy: [
    'Psychiatry',
    'Psychiatric Nurse Practitioner',
    'Psychology',
    'Marriage and Family Therapy',
    'Professional Counseling',
    'Mental Health Counseling',
    'Clinical Social Work',
  ],
}

export const credentials = [
  'MD',
  'DO',
  'PA',
  'PA-C',
  'NP',
  'FNP',
  'LPC',
  'LMHC',
  'LMFT',
  'MA',
  'MS',
  'PsyD',
  'LCSW',
  'LMSW',

  // 'GP',
  // 'ENT',
  // 'DSN',
  // 'DScPT',
  // 'DPT',
  // 'DPM',
  // 'DMD',
  // 'AuD',
  // 'GYN',
  // 'OB/GYN',
  // 'PhD',
]

const extractPhoneNumber = (phones = [], type = 'office') => {
  const phone = phones.find((phone) => phone.type === type)
  return phone && phone.number
}

export const providerDefaultValues = {
  providerType: 'medical',
  phone: '',
  fax: '',
  email: '',
  firstName: '',
  lastName: '',
  specialty: '',
  suffix: [],
  id: null,
}

export const providerInitialValues = (provider) => {
  if (!provider) return providerDefaultValues

  let firstName
  let lastName

  const parts = provider.name.split(' ')
  if (parts.length > 1) {
    lastName = last(parts)
    firstName = provider.name.replace(` ${lastName}`, '')
  }
  const phone = extractPhoneNumber(provider.phones, 'office')
  const fax = extractPhoneNumber(provider.phones, 'fax')

  return {
    providerType: provider.providerType || providerDefaultValues.providerType,
    phone: phone || providerDefaultValues.phone,
    fax: fax || providerDefaultValues.fax,
    email: provider.email || providerDefaultValues.email,
    firstName: firstName || providerDefaultValues.firstName,
    lastName: lastName || providerDefaultValues.lastName,
    specialty: provider.specialty || providerDefaultValues.specialty,
    suffix: provider.suffix || providerDefaultValues.suffix,
    id: provider.id || provider.providerId || providerDefaultValues.id,
  }
}

export const MemberEthnicity = [
  'American Indian or Native Alaskan',
  'Asian or Asian American',
  'Black or African American',
  'Hispanic or Latino',
  'Middle Eastern/North African (MENA) or Arab Origin',
  'Native Hawaiian or Other Pacific Islander Native',
  'White',
  'Biracial or Multiracial',
  'Unknown/None of the above',
  'Prefer not to answer',
]
Object.freeze(MemberEthnicity)

export const Ethnicity = [
  'Asian or Asian American',
  'Black or African American',
  'Hispanic or Latino',
  'Middle Eastern/North African or Arab Origin',
  'American Indian or Native Alaskan',
  'Native Hawaiian or Other Pacific Islander Native',
  'White',
  'Biracial or Multiracial',
  'Other',
  'All',
]
Object.freeze(Ethnicity)

export const RaceFilterMap = [
  { label: 'Asian or Asian American', value: 'Asian or Asian American' },
  { label: 'Black or African American', value: 'Black or African American' },
  { label: 'Hispanic or Latino', value: 'Hispanic or Latino/a/x' },
  {
    label: 'Middle Eastern/North African or Arab Origin',
    value: 'Middle Eastern/North African (MENA) or Arab Origin',
  },
  { label: 'American Indian or Native Alaskan', value: 'American Indian or Native Alaskan' },
  {
    label: 'Native Hawaiian or Other Pacific Islander Native',
    value: 'Native Hawaiian or Other Pacific Islander Native',
  },
  { label: 'White', value: 'White' },
  { label: 'Biracial or Multiracial', value: 'Biracial or Multiracial' },
  { label: 'All', value: 'All' },
]

export const FilterGenders = [
  'Agender',
  'Gender-Fluid',
  'Non-Binary',
  'Gender Non-Conforming',
  'Transgender',
  'TGNC (Transgender, Gender Non-Conforming)',
  'I do not wish to disclose',
  undefined, // This is for the case where gender is not set on a providers profile (occurs in staging env)
]

export const mapTherapyVisitFiltersToDiagnosticExpertise = {
  Neurodiversity: ['ADD/ADHD', 'Autism Spectrum Disorder'],
  Anxiety: [
    'Generalized Anxiety Disorder (GAD)',
    'Panic Disorder',
    'Social Anxiety Disorder',
    'Obsessive-Compulsive Disorder (OCD)',
  ],
  Depression: [
    'Major Depressive Disorder (MDD)',
    'Persistent Depressive Disorder (Dysthymia)',
    'Postpartum Depression',
    'Bipolar Disorder (I and/or II)',
  ],
  'Eating and Body Image': ['Body Dysmorphic Disorder', 'Anorexia Nervosa', 'Binge Eating Disorder', 'Bulimia Nervosa'],
  'Gender Identity': ['Gender Dysphoria'],
  Loss: ['Grief/Loss'],
  Trauma: ['Post-Traumatic Stress Disorder (PTSD)', 'Adjustment Disorder'],
  'Substance Use': ['Alcohol Use Disorder (AUD)', 'Opioid Use Disorder (OUD)', 'Other Substance Use Disorder (SUD)'],
  'Sleep Difficulties': ['Hypersomnolence Disorder', 'Insomnia Disorder'],
  'Behavioral Addiction': ['Behavior Addiction - Gambling Disorder', 'Behavior Addiction - Internet Gaming Disorder'],
  'Self-Harm': ['Self-Injury', 'Suicidality'],
}
export const SpecialtyFocus = Object.keys(mapTherapyVisitFiltersToDiagnosticExpertise)

Object.freeze(SpecialtyFocus)

export const normalizeFiltersForApi = (filters) => {
  return reduce(
    filters,
    (result, filterValue, filter) => {
      const filterName = filter === 'languages' ? 'languages.abbrev' : filter

      if (isObject(filterValue)) {
        result[filterName] = reduce(
          filterValue,
          (result2, subFilterValue, subFilter) => {
            if (subFilterValue) {
              if (filterName === 'ethnicity' && subFilter === 'ofColor') {
                each(
                  Ethnicity.filter((val) => val !== 'White'),
                  (race) => {
                    result2.push(race)
                  },
                )
              } else {
                result2.push(subFilter)
              }
            }
            return result2
          },
          [],
        )
      } else {
        result[filterName] = filterValue
      }
      return omitBy(result, isEmpty)
    },
    {},
  )
}
export const therapyVisitFiltersOptions = createSimpleSelectOptions(
  Object.keys(mapTherapyVisitFiltersToDiagnosticExpertise),
)
export const availableTherapyVisitFiltersOptions = (availableExpertise) => {
  return therapyVisitFiltersOptions.filter((option) =>
    availableExpertise.some((expertise) =>
      mapTherapyVisitFiltersToDiagnosticExpertise[option.value]?.includes(expertise || ''),
    ),
  )
}

export const isTherapyRelated = (pendingVisit) => {
  return (
    pendingVisit && (pendingVisit.visitType === VisitTypes.therapy || pendingVisit.visitType === VisitTypes.psychiatry)
  )
}

export const providerDefaultFilters = (pendingVisit) =>
  isTherapyRelated(pendingVisit)
    ? {
        specialtyFocus: reduce(
          SpecialtyFocus,
          (result, focus) => {
            result[focus] = false
            return result
          },
          {},
        ),
        gender: '',
        race: '',
      }
    : {
        ethnicity: { ofColor: false },
        gender: '',
      }

export const normalizePreferencesForApi = (filters) => {
  const preferences = clone(filters)
  if (preferences.specialtyFocus) {
    preferences.specialty = preferences.specialtyFocus
    delete preferences.specialtyFocus
  }
  if (preferences['languages.abbrev']) {
    preferences.languages = preferences['languages.abbrev'].map((code) => ({
      abbrev: code,
      name: LanguageFilters[code],
    }))
    delete preferences['languages.abbrev']
  }
  return preferences
}

export const physicianTypes = [
  { label: 'Physician', value: 'medical' },
  { label: 'Therapist', value: 'therapy' },
]

export const filterProvidersByModality = (providers, modality) => {
  return providers.filter((provider) => provider.modalities.includes(modality))
}

export const sortProvidersByAvailability = (a, b) => {
  if (a.schedule?.availableNext && b.schedule?.availableNext) {
    if (a.schedule.availableNext > b.schedule.availableNext) return 1
    return -1
  }
  if (a.schedule?.availableNext) return -1
  if (b.schedule?.availableNext) return 1
  if (a.schedulable?.order > b.schedulable?.order) return -1
  return 1
}

const TWO_WEEKS_FROM_NOW = moment().add(2, 'weeks')

export const isRecommendedProvider = (provider) => {
  return (
    provider.sortPriority === 1 &&
    provider.schedule?.availableNext &&
    moment(provider.schedule.availableNext).isBefore(TWO_WEEKS_FROM_NOW)
  )
}
