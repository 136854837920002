import * as Yup from 'yup'
import catchApiError from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import { normalizeJsonToApi } from 'api/helpers'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  parameters: Yup.object(),
  resource: Yup.string(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const make = async ({ parameters, resource }) => {
  return normalizeJsonToApi({
    parameters,
    resource,
  })
}
export const url = () => `/v1/signed-urls`
export const execute = async (params) => api.post(url(params), await make(params)).catch(catchApiError)
