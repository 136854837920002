import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'

const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  visitId: Yup.string()
    .matches(/^[0-9a-fA-F]{24}$/)
    .required(),
  email: Yup.string().email().required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const make = async ({ email }) => {
  return { email }
}
export const url = ({ visitId }) => `/v1/visits/${visitId}/participants`
export const execute = async (params) => api.post(url(params), await make(params)).catch(catchApiFailure)
