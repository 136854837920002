import configBuilder from '../config-builder'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
const api = ApiFactory.getApi(ApiTypes.member)

export const isValid = async () => true
export const url = () => '/v1/posts/randomize'
export const execute = async () => {
  return api.get(url(), configBuilder()).catch(catchApiFailure)
}
