import * as Yup from 'yup'
import configBuilder from '../config-builder'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  transaction: Yup.object()
    .shape({
      amount: Yup.number().required(),
      currency: Yup.string().default('USD').required(),
      source: Yup.string().required(),
    })
    .required(),
  memberId: Yup.string().label('Member ID').required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => await RequestSchema.validate(params)
export const url = ({ memberId }) => `/v1/${memberId}/payments/transactions`
export const make = (params) => {
  const request = RequestSchema.cast(params)
  return request.transaction
}
export const execute = async (params) =>
  api.post(url(params), make(params), configBuilder(params)).catch(catchApiFailure)
