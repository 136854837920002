import { ChangeEvent, useCallback, useMemo } from 'react'

import withForm from 'hoc/withForm'
import TestIds from 'util/TestIds'
import FormikRadioGroup from '@ui-components-3/compat/lib/components/FormikRadioGroup'
import Languages, { LanguageCodes, languageOptions } from 'util/languages'
import { AnyObjectSchema } from 'yup'
import PendingVisit from 'types/visit/pendingVisit'

const FAITH_BASED_SPECIALTY = 'Faith Based Counseling'

const genderOptions = [
  { value: 'F', label: 'Female' },
  { value: 'M', label: 'Male' },
  { value: 'X', label: 'Self Selected' },
]

const ethnicityOptions = [{ value: 'Of Color', label: 'Provider of Color' }]

const specialtyOptions = [{ value: FAITH_BASED_SPECIALTY, label: FAITH_BASED_SPECIALTY }]

const disableCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
  if (!event) return
  event.preventDefault()
}

type VisitPreferencesFormProps = {
  memberId: string
  initialValues: { gender: string; ethnicity: []; specialty: []; languages: [] }
  schema: AnyObjectSchema
  pendingVisit: PendingVisit
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  handleSubmit: () => void
  values: {
    preferences: { specialty: string[]; ethnicity: string[]; languages: Array<{ abbrev: string; name: string }> }
  }
  errors: object
  enableReinitialize: boolean
  dirty: boolean
  isValid: boolean
  isLoading: boolean
  children: ({ disabled }: { disabled: boolean }) => JSX.Element
}

const VisitPreferencesForm = (props: VisitPreferencesFormProps) => {
  const { handleSubmit, setFieldValue, pendingVisit, values, schema } = props

  const handleLanguageSelection = useCallback(
    (abbrev: keyof typeof Languages) => () => {
      if (values.preferences.languages.some((lang) => lang.abbrev === abbrev)) {
        const languages = values.preferences.languages.filter((lang) => lang.abbrev !== abbrev)
        setFieldValue('preferences.languages', languages)
      } else {
        const languages = [...values.preferences.languages]
        languages.push({
          name: Languages[abbrev],
          abbrev,
        })
        setFieldValue('preferences.languages', languages)
      }
    },
    [values.preferences.languages],
  )

  const toggleOfColor = useCallback(() => {
    if (values.preferences.ethnicity?.length) {
      setFieldValue('preferences.ethnicity', [])
    } else {
      setFieldValue('preferences.ethnicity', ['Of Color'])
    }
  }, [values.preferences.ethnicity])

  const toggleFaithBased = useCallback(() => {
    if (values.preferences.specialty.includes(FAITH_BASED_SPECIALTY)) {
      setFieldValue('preferences.specialty', [])
    } else {
      setFieldValue('preferences.specialty', [FAITH_BASED_SPECIALTY])
    }
  }, [values.preferences.specialty])

  const languageSelections = useMemo(() => {
    return LanguageCodes.reduce((acc: { [key: string]: boolean }, code: string) => {
      acc[code] = values.preferences.languages.some((lang) => lang.abbrev === code)
      return acc
    }, {})
  }, [values.preferences.languages])

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-wrap gap-3">
        <div className="w-full">
          <p className="typography-h5 text-primary-800">If available, what type of provider would you prefer?</p>
          <span className="typography-body text-neutral">
            Visit preferences may not be available, but we will do our best to match your preferences
          </span>
        </div>
        <hr className="w-full" />
        <div className="w-full">
          <FormikRadioGroup
            schema={schema}
            label={'Language(s)'}
            name={'preferences.languages'}
            options={languageOptions}
            multiple
          />
        </div>
        <hr className="w-full" />
        <div className="w-full">
          <FormikRadioGroup
            schema={schema}
            data-testid={TestIds.newVisit.input.preferences.gender}
            label={'Gender'}
            name={'preferences.gender'}
            options={genderOptions}
          />
        </div>
        <hr className="w-full" />

        <div className="flex w-full flex-col gap-6">
          <p className="typography-body-l mb-2 font-medium leading-tight">Other</p>
          <FormikRadioGroup
            schema={schema}
            label="Ethnicity"
            name={'preferences.ethnicity'}
            options={ethnicityOptions}
            multiple
          />
          <FormikRadioGroup
            schema={schema}
            label="Specialty"
            name={'preferences.specialty'}
            options={specialtyOptions}
            multiple
            disabled
          />
        </div>
        {props.children({ disabled: false })}
      </div>
    </form>
  )
}

export default withForm(VisitPreferencesForm)
