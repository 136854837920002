import { useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { putPageTitle } from '../redux/slices/view'

/* prevailOnUnmount is optional, but the idea is to maintain the same title if the component 
using this hook is unmounted. */
function useDocumentTitle(title, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title)
  const dispatch = useDispatch()

  useEffect(() => {
    document.title = `TimelyCare - ${title}` || title
  }, [title])

  useEffect(() => {
    dispatch(putPageTitle({ title }))
  }, [title, dispatch])

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current
      }
    },
    [prevailOnUnmount],
  )
}

export default useDocumentTitle
