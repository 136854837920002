import { useMemo } from 'react'
import Provider from 'types/provider/provider'
import { getPrintableShortProviderName, getProviderDetailString } from 'util/provider'

export const useProviderFullNameAndDetails = (provider?: Provider | null, isSuccess = false) => {
  const fullNameAndCredentials = useMemo(
    () => getPrintableShortProviderName(provider, isSuccess),
    [isSuccess, provider],
  )

  const details = useMemo(() => getProviderDetailString(provider), [provider])

  return { fullNameAndCredentials, details }
}
