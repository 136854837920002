import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '@ui-components-3/ui/lib/components/Loading'
import * as MemberSelectors from '../../redux/selectors/members'
import { putNewVisit } from '../../redux/slices/visits'
import SuccessPage from 'pages/SuccessPage'

type SuccessContainerProps = {
  match: object
  query: { step: string }
}

const SuccessContainer = (props: SuccessContainerProps) => {
  const dispatch = useDispatch()

  const handleClearPayload = useCallback(() => dispatch(putNewVisit({ pendingVisit: {} })), [dispatch])

  const userMemberId = useSelector(MemberSelectors.getLoggedInMemberId)
  const userMember = useSelector(MemberSelectors.getMember(userMemberId))

  return <SuccessPage />
}

export default SuccessContainer
