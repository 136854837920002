import * as Yup from 'yup'
import configBuilder from '../config-builder'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import { AnswerSchema } from '../../../schemas/SurveySchema'
import { normalizeJsonToApi } from '../../helpers'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  memberId: Yup.string().required(),
  surveyId: Yup.number().required(),
  answers: Yup.array().of(AnswerSchema).required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const make = (params) => {
  const clean = RequestSchema.cast(params)
  return normalizeJsonToApi({
    surveyId: clean.surveyId,
    answers: clean.answers,
  })
}
export const url = ({ memberId }) => `/v1/members/${memberId}/surveys`
export const execute = async (params) =>
  api.post(url(params), make(params), configBuilder(params)).catch(catchApiFailure)
