import { ChallengeName } from 'types/account-merge/AccountMergeChallenge'

const CHALLENGE_SCENES = ['selectMfa', 'verifyMfa', 'verifyPassword', 'verifyIdentity'] as const
export const NON_CHALLENGE_SCENES = ['entry', 'terms', 'selectAccount'] as const

type AccountMergeChallengeScene = (typeof CHALLENGE_SCENES)[number]

export const ACCOUNT_MERGE_SCENES = [...NON_CHALLENGE_SCENES, ...CHALLENGE_SCENES] as const

export type AccountMergeScene = (typeof ACCOUNT_MERGE_SCENES)[number]

export const REQUIRED_ATTRIBUTES = {
  password: 'password',
  dob: 'dob',
  firstName: 'first_name',
  lastName: 'last_name',
  mfaType: 'mfa_type',
  email: 'email',
  sms: 'sms',
} as const

export const requiredAccountMergeAttributes = Array.from(Object.values(REQUIRED_ATTRIBUTES))

export const CHALLENGES = {
  verifyPassword: 'verify_password',
  verifyUserAttributes: 'verify_user_attributes',
  selectMfa: 'mfa_select_type',
  verifyMfaCode: 'verify_mfa_code',
} as const

export const CHALLENGE_NAME_SCENE_MAP = {
  verify_password: 'verifyPassword',
  verify_user_attributes: 'verifyIdentity',
  mfa_select_type: 'selectMfa',
  verify_mfa_code: 'verifyMfa',
} as const satisfies Record<ChallengeName, AccountMergeChallengeScene>
