import { createSlice } from '@reduxjs/toolkit'

export type CancellationReasonType = {
  cancellationReasonId: string
  code: number
  order: number
  reason: string
  userTypes: ['member']
  cancelledBy: 'member'
  visitTypes: []
}

export type CancellationReason = {
  meta: {
    lastFetchAt: null | string
    fetching: string[]
    fetches: {
      [sessionId: string]: string | object
      errors: {
        [sessionId: string]: number
      }
    }
  }
  reasons: CancellationReasonType[]
}

const defaultState = {
  meta: {},
  reasons: [],
}

const slice = createSlice({
  name: 'reasons',
  initialState: defaultState,
  reducers: {
    putReasons: (state, action) => {
      const { sessions } = action.payload
      state.reasons = sessions
    },
    clearReasons: (state) => {
      state.reasons = { ...defaultState.reasons }
    },
  },
})

const { actions, reducer } = slice

export const { putReasons, clearReasons } = actions

export default reducer
