import { useRef, useState } from 'react'
import ProvideConsentImage from 'assets/images/provide_consent_image.svg'
import {
  ProvideSelfConsentImage,
  ProvideSelfConsentScene,
} from 'components/visit-creation/ProvideSelfConsentWrappers/ProvideSelfConsentWrappers'
import Checkbox from '@ui-components-3/ui/lib/components/Checkbox'
import SelfConsentTermsModal from 'components/visit-creation/SelfConsentTermsModal/SelfConsentTermsModal'
import { ModalRef } from '@ui-components-3/ui/lib/components/Modal'
import { useForm } from 'react-hook-form'

import { useMinorConsent } from 'hooks/useMinorConsent'
import PendingVisit from 'types/visit/pendingVisit'
import withForm from 'hoc/withForm'
import FetchTypes from 'util/fetch-types'
import { useSelector } from 'react-redux'
import { isTypeFetching } from 'redux/selectors/api'
import Loading from '@ui-components-3/ui/lib/components/Loading'
import FormControl from '@ui-components-3/ui/lib/components/FormControl'

type FormProps = {
  initialValues: object
  pendingVisit: PendingVisit
  onSubmit: () => void
  children: ({ disabled }: { disabled: boolean }) => JSX.Element
  updatePayload: (payload: any) => void
}

const Form = (props: FormProps) => {
  const { onSubmit, children, initialValues, pendingVisit, updatePayload } = props
  const { handleSubmit } = useForm({
    defaultValues: initialValues,
  })
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const guidelinesRef = useRef<ModalRef>(null)
  const { postSelfConsentConfirmation } = useMinorConsent(pendingVisit?.memberId)
  const isProvidingConsent = useSelector(isTypeFetching(FetchTypes.postMemberSelfConsent))

  const handleCheck = () => {
    setIsChecked(!isChecked)
  }

  const showTerms = () => {
    guidelinesRef.current?.open()
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      showTerms()
    }
  }

  const guidelinesLink = (
    <span
      className="text-link-primary typography-body cursor-pointer"
      onClick={showTerms}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
    >
      Review form here
    </span>
  )

  const handleConsentSubmit = async () => {
    if (!pendingVisit.selfConsentPreApprovalId) return
    const { result } = await postSelfConsentConfirmation(pendingVisit.selfConsentPreApprovalId)

    await updatePayload({ ...initialValues, selfConsentConfirmationId: result.selfConsentConfirmationId })

    onSubmit()
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleConsentSubmit)}>
        <p className="typography-h5 sm:max-w-[460px] md:max-w-[460px] lg:max-w-[460px]">
          We need your consent to continue*
        </p>
        <p className="typography-body mb-4 sm:max-w-[460px] md:max-w-[460px] lg:max-w-[460px]">
          By checking the box below you agree to the terms described in the Informed Authorization & Consent to Therapy
          form. {guidelinesLink}
        </p>
        <ProvideSelfConsentImage>
          <img className="h-auto w-full object-contain" src={ProvideConsentImage} alt="" />
        </ProvideSelfConsentImage>
        <div className="mb-8 flex flex-row sm:max-w-[460px] md:max-w-[460px] lg:max-w-[460px]">
          <FormControl
            label="I consent to receive telehealth services under the terms described in the Informed Authorization & Consent to Therapy form."
            labelPlacement="right"
            style={{ alignItems: 'flex-start' }}
            LabelProps={{ className: 'typography-body-l font-medium text-neutral-800' }}
          >
            <input type="checkbox" className="checkbox" checked={isChecked} onChange={handleCheck} />
          </FormControl>
        </div>
        {children({ disabled: !isChecked })}
      </form>
      <SelfConsentTermsModal ref={guidelinesRef} />
      <Loading shown={isProvidingConsent} variant="big" />
    </>
  )
}

type ProvideSelfConsentFormProps = {
  initialValues: object
  pendingVisit: PendingVisit
  handleSubmit: () => void
  children: ({ disabled }: { disabled: boolean }) => JSX.Element
  updatePayload: (payload: any) => void
}

const ProvideSelfConsentForm = ({ children, handleSubmit, updatePayload, ...props }: ProvideSelfConsentFormProps) => {
  const { initialValues, pendingVisit } = props

  return (
    <ProvideSelfConsentScene>
      <Form
        {...props}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        pendingVisit={pendingVisit}
        updatePayload={updatePayload}
      >
        {children}
      </Form>
    </ProvideSelfConsentScene>
  )
}

export default withForm(ProvideSelfConsentForm)
