import * as Yup from 'yup'

export const AnswerSchema = Yup.object().shape({
  surveyAnswerChoiceId: Yup.number().required(),
  surveyQuestionId: Yup.number().required(),
  prompt: Yup.string().required(),
  answer: Yup.string().required(),
})

const SurveySchema = Yup.object().shape({
  surveyId: Yup.number().required(),
  answers: Yup.array()
    .of(Yup.mixed().oneOf([AnswerSchema, Yup.string()]))
    .required(),
})

export default SurveySchema
