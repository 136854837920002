import * as Yup from 'yup'
import { LifestyleSchema } from './MedicalHistorySchema'

const MedicalHistoryVisitValidation = Yup.object().shape({
  memberId: Yup.string().required(),
  heightFt: Yup.number().required(),
  heightIn: Yup.number().required(),
  weightLbs: Yup.number().label('Weight').min(3).max(1000).required(),
  lifestyle: LifestyleSchema(true),
  allergiesCompleted: Yup.boolean().notOneOf([false]).required(),
  medicationsCompleted: Yup.boolean().notOneOf([false]).required(),
  conditionsCompleted: Yup.boolean().notOneOf([false]).required(),
})

export default MedicalHistoryVisitValidation
