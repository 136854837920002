const Config = {
  isProduction: process.env.NODE_ENV === 'production' && process.env.REACT_APP_ENVIRONMENT === 'production',
  squareAppId: process.env.REACT_APP_SQUARE_APP_ID,
  squareLocationId: process.env.REACT_APP_SQUARE_LOCATION,
  memberUrl: process.env.REACT_APP_MEMBER_URL,
  authUrl: process.env.REACT_APP_AUTH_URL,
  providerUrl: process.env.REACT_APP_PROVIDER_URL,
  proxyUrl: process.env.REACT_APP_PROXY_URL,
  consentUrl: process.env.REACT_APP_CONSENT_BASE_URL,
  pusherAppKey: process.env.REACT_APP_PUSHER_APP_KEY,
  pusherCluster: process.env.REACT_APP_PUSHER_CLUSTER,
  pusherAuthEndpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT,
  appHost: process.env.REACT_APP_HOST,
  mainHost: process.env.REACT_APP_MAIN_HOST,
  sentryDSN: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
  appVersion: process.env.REACT_APP_VERSION,
  mobileScheme: process.env.REACT_APP_MOBILE_SCHEME,
  launchDarklyKey: process.env.REACT_APP_LAUNCH_DARKLY_KEY,
  s3BaseUrl: process.env.REACT_APP_S3_BASE_URL,
  firebaseApiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  firebaseAuthDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  firebaseProjectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  firebaseStorageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  fireBaseMessagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  firebaseAppId: process.env.REACT_APP_FIREBASE_APP_ID,
  firebaseVapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  dailyDomain: process.env.REACT_APP_DAILY_DOMAIN,
  dischargeInstructionsS3BucketBaseUrl: process.env.REACT_APP_DISCHARGE_INST_S3_BASE_URL,
} as const

export default Config
