import { ReactNode } from 'react'

type VisitCreationSceneProps = {
  children: ReactNode
}

export const VisitCreationScene = ({ children }: VisitCreationSceneProps) => {
  return <div className="flex flex-col items-start sm:gap-6 md:flex-row">{children}</div>
}

type VisitCreationFormProps = {
  children: ReactNode
}

export const VisitCreationForm = ({ children }: VisitCreationFormProps) => {
  return <div className="md:2/4 w-full flex-shrink-0 pt-10 sm:w-3/5 sm:pb-8 lg:w-2/5">{children}</div>
}

type VisitCreationImageProps = {
  children: ReactNode
}

export const VisitCreationImage = ({ children }: VisitCreationImageProps) => {
  return (
    <div className="static bottom-0 right-0 sm:sticky sm:top-0 sm:ml-auto sm:max-w-[345px] md:right-auto md:max-w-[560px] md:shrink-0 lg:max-w-[680px] xl:w-3/5">
      {children}
    </div>
  )
}
