import * as Yup from 'yup'
import configBuilder from '../config-builder'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  params: Yup.object()
    .shape({
      filter: Yup.object().shape({
        memberId: Yup.mixed().label('Member ID').required(),
        status: Yup.array().of(Yup.string()),
        // memberId: Yup.array().of(Yup.string().label('Member ID')).required(),
      }),
      sort: Yup.object(),
      page: Yup.number().required(),
      limit: Yup.number().required(),
    })
    .required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => await RequestSchema.validate(params)
export const make = () => null
export const url = () => '/v1/visits/pending'
export const execute = async (params) => api.get(url(), configBuilder(params)).catch(catchApiFailure)
