import classNames from '@ui-components-3/ui/lib/utils/classNames'

type RequiredFieldProps = {
  requiredFieldLabels?: string[]
  ignoreFieldsLength?: boolean
  className?: string
}
const RequiredFieldLabel = ({ requiredFieldLabels, ignoreFieldsLength, className }: RequiredFieldProps) => (
  <div className="mb-4 text-left">
    <p className={classNames('typography-body font-medium text-neutral-700', className)}>
      {requiredFieldLabels?.length > 1 || ignoreFieldsLength
        ? 'All fields marked with asterisks are required*'
        : `${requiredFieldLabels[0]} is a required field`}
    </p>
  </div>
)

export default RequiredFieldLabel
