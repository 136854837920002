const SubscriptionStatus = {
  SUBSCRIBED: 1,
  CANCELLED: 2, // Member initially migrated/subscribed then cancelled
  SUBSCRIPTION_OFFERED: 3, // Member has been/will be sent an email to upgrade
  SUBSCRIPTION_OFFERED_UNAFFILIATED: 4, // Member has been/will be sent an email to upgrade, but is an SSO user whose affiliation no longer matches any particular group
  OPT_OUT: 5, // Member was offered, but opted out of upgrading
  OPT_OUT_UNAFFILIATED: 6, // member was offered while unaffiliated, but opted out
}

export const SubscriptionStatusMap = Object.keys(SubscriptionStatus).reduce(
  (acc, key) => ({
    ...acc,
    [SubscriptionStatus[key]]: key,
  }),
  {},
)

export default Object.freeze(SubscriptionStatus)
