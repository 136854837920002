import cloneDeep from 'lodash/cloneDeep'
import AmexIconUrl from '../assets/images/ic_amex.svg'
import VisaIconUrl from '../assets/images/ic_visa.svg'
import MastercardIconUrl from '../assets/images/ic_mastercard.svg'

const MC_RATIO = 1.41538 // 141% wider than tall
const VISA_RATIO = 3.1176 // 311% wider than tall
const AMEX_RATIO = 0.7727 // 77% wide as tall

const RATIOS = {
  VISA: VISA_RATIO,
  MC: MC_RATIO,
  MASTERCARD: MC_RATIO,
  AM: AMEX_RATIO,
  AMX: AMEX_RATIO,
  AMEX: AMEX_RATIO,
}

const sizifier = (methodType, props) => {
  const clone = cloneDeep(props)
  const ratio = RATIOS[methodType]
  if (ratio) {
    if (clone.height && !clone.width) {
      clone.width = clone.height * ratio
    }
    if (clone.width && !clone.height) {
      clone.height = clone.width / ratio
    }
  }
  return clone
}

export const logoForCard = (method, props = {}) => {
  const imageProps = sizifier(method.cardBrand, props)
  switch (method.cardBrand) {
    case 'VISA':
      return <img src={VisaIconUrl} alt={'Visa logo'} {...imageProps} />
    case 'MC':
    case 'MASTERCARD':
      return <img src={MastercardIconUrl} alt={'Mastercard logo'} {...imageProps} />
    case 'AM':
    case 'AMX':
    case 'AMEX':
    case 'AMERICAN_EXPRESS':
      return <img src={AmexIconUrl} alt={'AMEX logo'} {...imageProps} />
    default:
      return
  }
}

export const printableBrand = (cardBrand) => {
  switch (cardBrand) {
    case 'VISA':
      return 'VISA'
    case 'MC':
    case 'MASTERCARD':
      return 'MASTERCARD'
    case 'DISCOVER':
      return 'DISCOVER'
    case 'DISCOVER_DINERS':
      return 'DINERS CLUB'
    case 'JCB':
      return 'JCB'
    case 'AM':
    case 'AMX':
    case 'AMEX':
    case 'AMERICAN_EXPRESS':
      return 'AMEX'
    default:
      return
  }
}

export const obfuscatedCardNumber = (method) => {
  return `${printableBrand(method.cardBrand)}-${method.last4}`
}

export const cardExpiration = (method) => {
  return `Expires ${method.expMonth}/${method.expYear}`
}
