import { useMemo, useRef } from 'react'
import moment from 'moment-timezone'

import ProviderHeader from '../provider-info/ProviderHeader'
import AngleRightIcon from '@ui-components-3/icons/lib/regular/AngleRight'
import ProviderInfoDialog from '../provider-info/ProviderInfoDialog'
import Provider from 'types/provider/provider'
import PendingVisit from 'types/visit/pendingVisit'
import VisitConfirmationLocation from '../VisitConfirmationLocation'
import { isSuccess } from 'util/helpers'
import { useProviderFullNameAndDetails } from 'hooks/useProviderFullNameAndDetails'
import { ModalRef } from '@ui-components-3/ui/lib/components/Modal'

const dateFormat = 'ddd, MMMM Do, YYYY'
const timeFormat = 'h:mm a'

type ScheduledVisitConfirmationProps = {
  price?: string
  pendingVisit: PendingVisit
  provider?: Provider
  displayLocation?: string
}

const ScheduledVisitConfirmation = (props: ScheduledVisitConfirmationProps) => {
  const providerInfoDialogRef = useRef<ModalRef>(null)
  const { pendingVisit, provider, displayLocation } = props

  const successVisit = useMemo(() => {
    return isSuccess(pendingVisit.visitType)
  }, [pendingVisit])

  const displayDateTime = useMemo(() => {
    const dateTime = moment(pendingVisit?.scheduledAt).tz(pendingVisit?.timezone)

    return {
      date: dateTime?.format(dateFormat),
      time: dateTime?.format(timeFormat),
    }
  }, [pendingVisit?.scheduledAt, pendingVisit?.timezone])

  const { fullNameAndCredentials } = useProviderFullNameAndDetails(provider, successVisit)

  return (
    <>
      <div className="flex flex-col gap-x-6 md:flex-row">
        <div className="mb-4">
          <p className="typography-body-l mb-1 font-semibold text-neutral-800">Date</p>
          <p className="typography-body-l text-neutral-800">{displayDateTime.date}</p>
        </div>
        <div className="mb-4">
          <p className="typography-body-l mb-1 font-semibold text-neutral-800">Time</p>
          <p className="typography-body-l text-neutral-800">{displayDateTime.time}</p>
        </div>
      </div>
      {!successVisit && <VisitConfirmationLocation displayLocation={displayLocation} />}
      <div>
        <h3 className="typography-body-l mb-1 font-semibold text-neutral-800">Provider</h3>
        <div className="overflow-hidden rounded-2xl border border-neutral-300">
          <div className="bg-white px-4 py-5">
            <ProviderHeader provider={provider} isSuccess={successVisit} />
          </div>
          <button
            type="button"
            className="btn-small btn-neutral-borderless w-full justify-between rounded-none bg-neutral-200 text-neutral-600"
            onClick={() => providerInfoDialogRef.current?.open()}
            aria-label={`See more details of ${fullNameAndCredentials}`}
          >
            See more details
            <AngleRightIcon className="h-4 w-4" aria-hidden="true" />
          </button>
        </div>
      </div>
      <ProviderInfoDialog ref={providerInfoDialogRef} provider={provider} isSuccess={successVisit} />
    </>
  )
}

export default ScheduledVisitConfirmation
