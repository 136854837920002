import React from 'react'
import Clock from '@ui-components-3/icons/lib/sharp-solid/ClockSeven'

const TimeFrameLabel = ({ timeFrame }: { timeFrame: string }) => {
  return (
    <div className={'mt-2 flex flex-row items-center'}>
      <Clock aria-hidden="true" className={'aria-hidden h-[12px] w-[12px] text-neutral-500'} />
      <p className={'typography-small ml-1.5 font-semibold text-neutral-500'}>{timeFrame}</p>
    </div>
  )
}

export default TimeFrameLabel
