import * as Yup from 'yup'
import configBuilder from '../config-builder'
import catchApiError from '../catch-api-error'
import { normalizeJsonToApi } from '../../helpers'
import ApiFactory, { ApiTypes } from '../../index'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object({
  acknowledgmentKey: Yup.string().required(),
})

export const isValid = (params) => RequestSchema.isValid(params)
export const validate = (params) => RequestSchema.validate(params)
export const make = async (params) => normalizeJsonToApi(params)
export const execute = async (params) =>
  api.put(url(params), await make(params), configBuilder(params)).catch(catchApiError)

export const url = ({ acknowledgmentKey }) => `/v1/acknowledgments/acknowledge/${acknowledgmentKey}`
