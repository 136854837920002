import MinusIcon from '@ui-components-3/icons/lib/regular/Minus'
import PlusIcon from '@ui-components-3/icons/lib/regular/Plus'
import { memo, useRef } from 'react'
import { useSyncExternalStore } from 'use-sync-external-store/shim'
import AccordionState from './model'
import type { AccordionItemSize, AccordionProps } from './types'
import classNames from '@ui-components-3/ui/lib/utils/classNames'

type AccordionItemProps<Item> = {
  item: Item
  state: AccordionState
  itemKey: string
  size: AccordionItemSize
  allowMultipleOpen: boolean
} & Required<Pick<AccordionProps<Item[]>, 'renderLabel' | 'renderContent' | 'isDisabled' | 'unmountCollapsed'>>

const PaddingBySize = {
  s: 'p-xxs',
  m: 'p-xs',
  l: 'p-s',
  xl: 'p-m',
} as const satisfies Record<AccordionItemSize, string>

const TRANSITION_DURATION_CLASS = 'duration-300' as const
const ICON_CLASSES = 'pointer-events-none shrink-0 transition-transform duration-300' as const
const BUTTON_CLASSES =
  'gap-s focus-outline-thick-light flex w-full items-center justify-between text-left hover:bg-neutral-200' as const

const AccordionItem = <T,>({
  state,
  itemKey,
  item,
  isDisabled,
  renderLabel,
  renderContent,
  size,
  allowMultipleOpen,
}: AccordionItemProps<T>) => {
  const getExpandedState = () => state.isActiveKey(itemKey)

  const expandedState = useSyncExternalStore(state.on, getExpandedState, getExpandedState)

  const disabled = isDisabled(item)

  const expanded = !disabled && expandedState

  const paddingClass = PaddingBySize[size]
  const Icon = expanded ? MinusIcon : PlusIcon
  return (
    <li>
      <button
        onClick={disabled ? undefined : () => state.setActiveKey(itemKey, allowMultipleOpen)}
        type="button"
        aria-expanded={expanded ? 'true' : 'false'}
        className={classNames(BUTTON_CLASSES, paddingClass, expanded ? '' : 'border-b')}
        disabled={disabled}
      >
        {renderLabel(item)}
        <Icon aria-hidden="true" className={classNames(ICON_CLASSES, disabled ? '' : 'text-primary-600')} />
      </button>
      <div
        role="region"
        className={classNames(
          `grid transition-[grid-template-rows] ${TRANSITION_DURATION_CLASS}`,
          expanded ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]',
        )}
      >
        <div
          className={classNames(
            `overflow-hidden transition-[padding] ${TRANSITION_DURATION_CLASS}`,
            paddingClass,
            expanded ? '' : 'py-0',
          )}
        >
          {renderContent(item)}
        </div>
      </div>
    </li>
  )
}

const Accordion = <T extends readonly any[] | any[]>({
  id,
  items,
  renderLabel,
  renderContent,
  getKey,
  unmountCollapsed = false,
  isDisabled = () => false,
  itemSize = 'm',
  allowMultipleOpen = false,
  ...props
}: AccordionProps<T>) => {
  const model = (useRef<AccordionState>().current ||= new AccordionState())

  return (
    <ul {...props}>
      {items.map((item, i) => {
        const itemKey = getKey(item, i).toString()

        return (
          <AccordionItem
            key={itemKey}
            state={model}
            itemKey={itemKey}
            size={itemSize}
            item={item}
            isDisabled={isDisabled}
            allowMultipleOpen={allowMultipleOpen}
            renderLabel={renderLabel}
            renderContent={renderContent}
            unmountCollapsed={unmountCollapsed}
          />
        )
      })}
    </ul>
  )
}

// type hack needed to keep items generic working
export default memo(Accordion) as typeof Accordion
