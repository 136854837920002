import * as Yup from 'yup'
import configBuilder from '../config-builder'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  params: Yup.object()
    .shape({
      filter: Yup.object().shape({
        // memberId: Yup.string().label('Member ID').required(),
        memberId: Yup.array().label('Member IDs').of(Yup.string().label('Member ID')).required(),
        pendingVisitId: Yup.array().notRequired().nullable(),
      }),
      sort: Yup.object(),
      page: Yup.number().required(),
      limit: Yup.number().required(),
    })
    .required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const valiidate = async (params) => await RequestSchema.validate(params)
export const url = () => '/v1/visits'
export const execute = async (params) => api.get(url(), configBuilder(params)).catch(catchApiFailure)
