import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type FilterData = {
  [key: string]: any
}

type FiltersState = {
  filters: Record<string, FilterData>
  selectedCategory: string | null
}

const initialState: FiltersState = {
  filters: {},
  selectedCategory: null,
}

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setOrUpdateFilters: (state, action: PayloadAction<Record<string, Partial<FilterData> | FilterData>>) => {
      const newFilters = action.payload
      Object.keys(newFilters).forEach((filterType) => {
        if (!state.filters[filterType]) {
          state.filters[filterType] = {}
        }
        const filterData = newFilters[filterType]
        Object.keys(filterData).forEach((key) => {
          state.filters[filterType][key] = filterData[key]
        })
      })
    },
    clearFilters: (state) => {
      state.filters = {}
    },
    setSelectedCategory: (state, action: PayloadAction<string | null>) => {
      state.selectedCategory = action.payload
    },
    clearSelectedCategory: (state) => {
      state.selectedCategory = null
    },
  },
})

const { actions, reducer } = filtersSlice
export const { setOrUpdateFilters, clearFilters, setSelectedCategory, clearSelectedCategory } = actions
export default reducer
