import { Field, FormikHelpers } from 'formik'
import SchedulingInput from './SchedulingInput'
import { ReactNode } from 'react'

type FormValue = {
  name: string
  value: string
}

type SchedulingFieldProps = {
  name: string
  value?: string
  error?: string
  disabled?: boolean
  dateLabel: string
  timeLabel: string
  timezone: string
  timesByDate: { [key: string]: string[] }
  id?: number
  'data-testid': string
  children: ReactNode
}

const SchedulingField = (props: SchedulingFieldProps) => {
  return (
    <Field disabled={props.disabled} value={props.value} name={props.name}>
      {({ field, form }: { field: FormValue; form: FormikHelpers<FormValue> }) => {
        const handleChange = (datetime: string | null) => {
          form.setFieldValue(field.name, datetime)
        }
        const clearScheduledAt = () => {
          form.setFieldValue(field.name, null)
        }
        return (
          <SchedulingInput
            {...props}
            clearScheduledAt={clearScheduledAt}
            error={props.error}
            value={field.value}
            onChange={handleChange}
          />
        )
      }}
    </Field>
  )
}

export default SchedulingField
