import { ModalRenderPropParams } from '@ui-components-3/ui/lib/components/Modal'
import useAccountMerge, { useAccountMergeContext } from 'hooks/useAccountMerge'
import Input from '@ui-components-3/ui/lib/components/Input'
import FormControl from '@ui-components-3/ui/lib/components/FormControl'
import DateTimePicker from '@ui-components-3/ui/lib/components/DateTimePicker'
import { VerifyIdentityValues } from 'schemas/accountMerge/VerifyIdentitySchema'
import { toast } from 'util/toast'
import { Controller } from 'react-hook-form'
import moment from 'moment'
import { ChallengeResponse } from 'types/account-merge/AccountMergeChallenge'
import { toastMergeFailed } from '../AccountMerge'

const getFormId = (idPrefix: string) => idPrefix + 'form:verify-password'

export const VerifyIdentityFooter = ({ idPrefix }: ModalRenderPropParams) => {
  const formId = getFormId(idPrefix)
  const { handleCancel, verifyIdentityForm } = useAccountMergeContext()
  const { isSubmittingChallenge } = useAccountMerge()

  const {
    formState: { isValid, isSubmitting, isDirty },
  } = verifyIdentityForm

  const isSubmitDisabled = !isValid || isSubmitting || !isDirty || isSubmittingChallenge
  return (
    <>
      <button
        type="button"
        onClick={() => handleCancel()}
        disabled={isSubmittingChallenge}
        className="btn btn-neutral-outlined self-end"
      >
        Cancel
      </button>
      <button type="submit" disabled={isSubmitDisabled} form={formId} className="btn btn-neutral self-end">
        Confirm
      </button>
    </>
  )
}

export const VerifyIdentityContent = ({ idPrefix }: ModalRenderPropParams) => {
  const formId = getFormId(idPrefix)
  const { verifyIdentityForm, handleCancel, selectedMemberId, challenge, setError, resetForms, restart, task } =
    useAccountMergeContext()
  const { submitChallenge, handleFailedChallenge } = useAccountMerge()

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = verifyIdentityForm

  const onSubmit = handleSubmit(async ({ dob: dateOfBirth, ...values }: VerifyIdentityValues) => {
    const dob = moment(new Date(dateOfBirth)).format('yyyy-MM-DD')
    // @ts-ignore
    const { success, data, closeFlow } = await submitChallenge(challenge, {
      dob,
      ...values,
      duplicateMemberId: selectedMemberId,
    })
    if (success) {
      if (success) {
        const { authenticationResult }: ChallengeResponse = data
        if (authenticationResult.success && !data.challengeName) {
          toast.success(
            <p className="m-xxs leading-[18px]">We are starting the process of retrieving your old account.</p>,
          )
          if (closeFlow || task) {
            handleCancel(true)
          } else {
            restart()
          }
        } else if (!authenticationResult.success && authenticationResult?.attemptsRemaining === 0) {
          handleCancel(true)
          toastMergeFailed()
        } else {
          await handleFailedChallenge()
          resetForms()
          setError('Your answer is incorrect – please try again')
        }
      }
    }
  })

  return (
    <form id={formId} onSubmit={onSubmit} className="gap-m flex flex-col">
      <p className="typography-body-l font-medium">Please verify your identity to continue.</p>
      <FormControl
        label="First Name*"
        helperVariant="error"
        helper={errors?.firstName ? errors.firstName.message : undefined}
      >
        <Input autoComplete="off" {...register('firstName')} />
      </FormControl>
      <FormControl
        label="Last Name*"
        helperVariant="error"
        helper={errors?.lastName ? errors.lastName.message : undefined}
      >
        <Input autoComplete="off" {...register('lastName')} />
      </FormControl>
      <Controller
        name="dob"
        control={control}
        render={({ field }) => (
          <FormControl
            label="Date of Birth*"
            helperVariant="error"
            helper={errors?.dob ? errors.dob.message : undefined}
          >
            <DateTimePicker {...field} onChange={(v) => setValue('dob', v)} autoComplete="off" disableFuture />
          </FormControl>
        )}
      ></Controller>
    </form>
  )
}
