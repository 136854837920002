import * as Yup from 'yup'

const PaymentClientSchema = Yup.object().shape({
  memberId: Yup.string().notRequired().nullable(),
  email: Yup.string().label('Email').email('Must be a valid email address').required(),
  firstName: Yup.string()
    .label('First Name')
    .trim('No leading or trailing spaces')
    .max(35, 'First Name is too Long')
    .required(),
  lastName: Yup.string()
    .label('Last Name')
    .trim('No leading or trailing spaces')
    .min(2, 'Last Name is too Short')
    .max(35, 'Last Name is too Long')
    .required(),
})

export default PaymentClientSchema
