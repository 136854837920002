import { setFetching } from '../slices/api'
import FetchType from '../../util/fetch-types'
import { handleRequestFailure, makeRequest } from '../../api/helpers'
import { putSuccessAvailability } from '../slices/success'
import VisitTypes from 'util/visit-types'
import moment from 'moment'

export const fetchSuccessAvailability = () => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.successAvailability, isFetching: true }))
  const params = { params: { filter: { visit_type: VisitTypes.success, enabled: true } } }
  let success = false
  const handleSuccess = async (data) => {
    const { start: startTime, end: endTime } = data[0]?.timeRange || {}
    if (startTime !== undefined && endTime !== undefined) {
      const startTime = data[0].timeRange.start
      const endTime = data[0].timeRange.end
      const start = moment().utc().startOf('day').add(startTime, 'hours').local().format('ha').replace(/\./g, '')
      const end = moment().utc().startOf('day').add(endTime, 'hours').local().format('ha').replace(/\./g, '')
      const date = new Date()
      const offsetInHours = date.getTimezoneOffset() / 60
      const utcToday = moment().utc().subtract(offsetInHours, 'hours').startOf('day')
      const actualStartTime = utcToday.clone().add(startTime, 'hours')
      const addDay = endTime <= startTime
      const actualEndTime = utcToday.clone().add(endTime + (addDay ? 24 : 0), 'hours')
      const isWeekend = actualStartTime.isoWeekday() === 6 || actualStartTime.isoWeekday() === 7
      const online = moment().utc().isBetween(actualStartTime, actualEndTime)
      dispatch(putSuccessAvailability({ availability: { times: `${start} - ${end}`, online: online && !isWeekend } }))
      success = true
    }
  }
  await makeRequest(FetchType.successAvailability, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(setFetching({ fetchType: FetchType.successAvailability, isFetching: false }))
  return { success }
}
