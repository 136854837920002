import * as Yup from 'yup'
import catchApiError from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import { contactReasons } from '../../../schemas/ContactSupportSchema'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  memberId: Yup.string().required(),
  type: Yup.string().oneOf(contactReasons).required(),
  details: Yup.string().required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const make = async ({ type, details }) => {
  return {
    type: type,
    details: details,
  }
}
export const url = ({ memberId }) => `/v1/${memberId}/support-tickets`
export const execute = async (params) => api.post(url(params), await make(params)).catch(catchApiError)
