import FetchTypes from 'util/fetch-types'

import * as PostLoginRequest from './v1/post-member-login'

import * as GetServicesRequest from './v1/get-services'
// Group
import * as GetGroupRequest from './v1/get-group'
// Auth
import * as PostForgotPasswordRequest from './v1/post-forgot-password'
import * as GetAccessCodeVerificationRequest from './v1/get-access-code-verification'
import * as PutUpdatePasswordRequest from './v1/put-update-password'
import * as PostResetPasswordRequest from './v1/post-reset-password'
import * as PostGenerateMfaRequest from './v1/post-generate-mfa'
import * as PostVerifyMfaRequest from './v1/post-verify-mfa'
import * as PostOAuthToken from './v1/post-oauth-token'
import * as PostOAuthLogout from './v1/post-oauth-logout'

// Push
import * as PutPushRegistrationRequest from './v1/put-push-registration'
// Registration
import * as PostAccountRegistrationRequest from './v1/post-account-registration'
import * as PostMemberRegistrationRequest from './v1/post-member-registration'
// Member
import * as GetMeRequest from './v1/get-me'
import * as GetMemberRequest from './v1/get-member'
import * as GetMemberDuplicatesRequest from './v1/get-duplicate-members'
import * as PostAccountMergeInitRequest from './v1/post-account-merge-init'
import * as PostAccountMergeChallengeRequest from './v1/post-account-merge-challenge'
import * as PostAccountMergeSaveForLaterRequest from './v1/post-account-merge-save-for-later'
import * as PostAuthChallenge from './v1/post-auth-challenge'
import * as PostMemberLookupRequest from './v3/post-member-lookup-v3'
import * as PostMemberProviderRequest from './v1/post-member-provider'
import * as PutMemberProviderRequest from './v1/put-member-provider'
import * as PutMemberContactRequest from './v1/put-member-contact'
import * as PutMemberAcknowledgment from './v1/put-acknowledgment'
import * as DeleteMemberProviderRequest from './v1/delete-member-provider'
import * as PostPhoneNumberValidationRequest from './v1/post-phone-number-validation'
import * as PostEmailUnsubscribeRequest from './v1/post-email-unsubscribe'
import * as PutAcceptedTermsRequest from './v1/put-accepted-terms'
// Dependents
import * as PostDependentRequest from './v1/post-dependent'
import * as PutDependentRequest from './v1/put-dependent'
// Medical History
import * as GetConditionsRequest from './v1/get-conditions'
import * as PostMedicalHistoryRequest from './v1/post-medical-history'
import * as PutMedicalHistoryRequestV2 from './v2/put-medical-history-v2'
import * as PutMedicalConditionsRequest from './v1/put-medical-conditions'
import * as GetMedicalHistoryRequest from './v1/get-medical-history'
// Medications
import * as PostMedicationRequestV2 from './v2/post-medication-v2'
import * as GetMedicationsV2 from './v2/get-medications-v2'
import * as GetMedicationDetailsV2 from './v2/get-medication-details-v2'
// Allergies
import * as PostAllergyRequest from './v1/post-allergy'
import * as PutAllergyRequest from './v1/put-allergy'
import * as DeleteAllergyRequest from './v1/delete-allergy'
import * as GetAllergyRequest from './v1/get-allergies'
// Family History
import * as PostFamilyHistoryRequest from './v1/post-family-history'
import * as PutFamilyConditionsRequest from './v1/put-family-conditions'
import * as GetFamilyHistoryRequest from './v1/get-family-history'
// Images
import * as DeleteImageRequestV2 from './v2/delete-image-v2'
import * as PostImageRequestV2 from './v2/post-image-v2'
import * as GetThumbImageRequestV2 from './v2/get-thumb-image-v2'
import * as GetImagesRequestV2 from './v2/get-images-v2'

// Provider
import * as GetProviderRequest from './v1/get-provider'
import * as GetAvailableProvidersRequest from './v1/get-available-providers'
import * as GetProviderAvailabilityRequest from './v1/get-provider-availability'
import * as PostProviderRatingRequest from './v1/post-provider-rating'
import * as GetAvailableQueueProvidersRequest from './v1/get-available-queue-providers'
import * as GetMemberProvidersRequest from './v1/get-member-providers'
// Pharmacies
import * as GetMemberPharmaciesRequest from './v1/get-member-pharmacies'
import * as GetPharmaciesRequest from './v1/get-pharmacies'
import * as DeleteMemberPharmacyRequest from './v1/delete-member-pharmacy'
import * as PostMemberPharmacyRequest from './v1/post-member-pharmacy'
// Payments
import * as PostPaymentMethodRequest from './v1/post-payment-method'
import * as PostPaymentClientRequest from './v1/post-payment-client'
import * as PostPaymentTransactionRequest from './v1/post-payment-transaction'
import * as GetPaymentMethodsRequest from './v1/get-payment-methods'
import * as GetPaymentTransactionsRequest from './v1/get-payment-transactions'
import * as GetPaymentTransactionRequest from './v1/get-payment-transaction'
import * as DeletePaymentMethodRequest from './v1/delete-payment-method'
import * as PostPaymentTransactionV2Request from './v2/post-payment-transaction'
// Visits
import * as GetVisitsRequest from './v1/get-visits'
import * as GetPendingVisitsRequest from './v1/get-pending-visits'
import * as GetPendingVisitRequest from './v1/get-pending-visit'
import * as PutCancelVisitRequest from './v1/put-visit-status'
import * as GetVideoTokenV2Request from './v2/get-video-token-v2'
import * as GetVisitRequest from './v1/get-visit'
import * as PostVisitRatingRequest from './v1/post-visit-rating'
import * as PostVisitRatingV2Request from './v2/post-visit-review'
import * as PostPendingVisitRequest from './v1/post-pending-visit'
import * as PostVideoParticipantRequest from './v1/post-video-participant'
import * as PostAddDailyCallInterpreter from './v2/post-add-daily-call-interpreter'
import * as PutRemoveDailyCallInterpreter from './v2/put-remove-daily-call-interpreter'
import * as PutVisitAssessmentResponseRequest from './v1/put-visit-assessment-response'
// Messages
import * as GetMessagesRequest from './v1/get-messages'
import * as GetMessageRequest from './v1/get-message'
import * as PutMessageViewedAtRequest from './v1/put-message-viewed-at'
import * as PutMessageStatusRequest from './v1/put-message-status'
import * as GetPrescriptionRequest from './v1/get-prescription'
import * as GetUnreadMessageCounts from './v1/get-unread-message-counts'
// Chat
import * as PostVisitChatRequest from './v1/post-visit-chat'
import * as GetVisitChatRequest from './v1/get-visit-chat'
import * as PostChatMessageRequest from './v1/post-chat-message'
import * as PutReadChatMessage from './v1/put-read-chat-message'
// Services
import * as PostServiceCodeRequest from './v1/post-service-code'
import * as GetClientByDomainRequest from './v1/get-client-by-domain'
import * as GetVisitRestrictionsRequest from './v1/get-visit-restrictions'
import * as GetClientVisitCountRequest from './v1/get-client-visit-count'
// Needs
import * as GetMemberNeedsRequest from './v1/get-member-needs'
import * as GetMemberNeedRequest from './v1/get-member-need'
import * as PostMemberNeedRequest from './v1/post-member-need'
import * as DeleteMemberNeedRequest from './v1/delete-member-need'
// Surveys
import * as PostMemberSurveyRequest from './v1/post-survey'
import * as GetClientSurveysRequest from './v1/get-surveys'
import * as GetClientSurveyQuestionsRequest from './v1/get-survey-questions'
// Support
import * as PostSupportRequest from './v1/post-support-ticket'
import * as GetFaqQuestionsRequest from './v1/get-faq-questions'
// Feedback
import * as PostFeedBack from './v1/post-feedback'
// Community
import * as GetCommunityTermsVersionRequest from './v1/get-community-terms-version'
import * as GetAllPostsRequest from './v1/get-all-posts'
import * as PostCommunityPostRequest from './v1/post-community-post'
import * as PostCommunityCommentRequest from './v1/post-community-comment'
import * as GetCommunityPostRequest from './v1/get-post'
import * as GetCommunityPostCommentsRequest from './v1/get-post-comments'
import * as DeleteCommunityPostRequest from './v1/delete-community-post'
import * as PostCommunityPostReportRequest from './v1/post-community-post-report'
import * as GetCommunityPostReportsRequest from './v1/get-community-post-reports'
import * as DeleteCommunityPostReportRequest from './v1/delete-community-post-report'
import * as PostCommunityPostReactionRequest from './v1/post-community-post-reaction'
import * as GetCommunityPostReactionsRequest from './v1/get-community-post-reactions'
import * as GetCommunityRandomPostsRequest from './v1/get-community-random-posts'

// Explore
import * as GetSelfCareComponentsRequest from './v1/get-self-care-components'
import * as GetSelfCareComponentRequest from './v1/get-self-care-component'
import * as GetSelfCareCategoriesRequest from './v1/get-self-care-categories'
import * as GetSelfCareCategoryRequest from './v1/get-self-care-category'
import * as PostSelfCareComponentReactionRequest from './v1/post-self-care-component-reaction'
import * as GetSelfCareJourneysRequest from './v1/get-self-care-journeys'
import * as GetSelfCareJourneyRequest from './v1/get-self-care-journey'
import * as GetSelfCareRandomizedContentRequest from './v1/get-self-cares'
import * as AddSelfCareJourneyComponentViewRequest from './v1/post-self-care-journey-component-reaction'

// Forms
import * as GetFormsRequest from './v1/get-forms'

// Cancellation Reasons
import * as GetCancellationReasonsRequest from './v1/get-cancellation-reasons'

// NPS Visit Survey
import * as GetVisitSurveyStatusV2Request from './v2/get-visit-survey-status-v2'
import * as PostVisitSurveyV2Request from './v2/post-visit-survey-v2'
import * as PutVisitSurveyV2Request from './v2/put-visit-survey-v2'

// Signed Urls
import * as PostSignedUrlsRequest from './v1/post-signed-urls'

// Student Success
import * as GetVisitQueues from './v1/get-visit-queues'

// Save Care Questionnaire
import * as PostCareRoutes from './v1/post-care-routes'

//Member Consent
import * as GetMemberConsentRequest from './v1/get-member-consents'
import * as PostMemberSelfConsentConfirmationRequest from './v1/post-member-self-consent'

const Factory = {
  getRequest: (fetchType: keyof typeof FetchTypes) => {
    if (!fetchType) return null
    switch (fetchType) {
      // Auth
      case FetchTypes.authChallenge:
        return PostAuthChallenge
      case FetchTypes.lookupMember:
        return PostMemberLookupRequest
      case FetchTypes.forgotPassword:
        return PostForgotPasswordRequest
      case FetchTypes.verifyAccessCode:
        return GetAccessCodeVerificationRequest
      case FetchTypes.updatePassword:
        return PutUpdatePasswordRequest
      case FetchTypes.login:
        return PostLoginRequest
      case FetchTypes.resetPassword:
        return PostResetPasswordRequest
      case FetchTypes.updatePushRegistration:
        return PutPushRegistrationRequest
      case FetchTypes.generateMfa:
        return PostGenerateMfaRequest
      case FetchTypes.verifyMfa:
        return PostVerifyMfaRequest
      case FetchTypes.postOAuthToken:
        return PostOAuthToken
      case FetchTypes.logout:
        return PostOAuthLogout
      // Registration
      case FetchTypes.registerAccount:
        return PostAccountRegistrationRequest
      case FetchTypes.registerMember:
        return PostMemberRegistrationRequest
      // Member
      case FetchTypes.getMember:
        return GetMemberRequest
      case FetchTypes.updateMemberContact:
        return PutMemberContactRequest
      case FetchTypes.updateMemberAcknowledgment:
        return PutMemberAcknowledgment
      case FetchTypes.getMe:
        return GetMeRequest
      case FetchTypes.getMemberDuplicates:
        return GetMemberDuplicatesRequest
      case FetchTypes.postAccountMergeInit:
        return PostAccountMergeInitRequest
      case FetchTypes.postAccountMergeChallenge:
        return PostAccountMergeChallengeRequest
      case FetchTypes.postAccountMergeSaveForLater:
        return PostAccountMergeSaveForLaterRequest
      case FetchTypes.addProvider:
        return PostMemberProviderRequest
      case FetchTypes.updateProvider:
        return PutMemberProviderRequest
      case FetchTypes.removeProvider:
        return DeleteMemberProviderRequest
      case FetchTypes.validatePhoneNumber:
        return PostPhoneNumberValidationRequest
      case FetchTypes.getMemberProviders:
        return GetMemberProvidersRequest
      case FetchTypes.unsubscribeEmail:
        return PostEmailUnsubscribeRequest
      case FetchTypes.updateTermsConditions:
        return PutAcceptedTermsRequest
      // Dependents
      case FetchTypes.addDependent:
        return PostDependentRequest
      case FetchTypes.updateDependent:
        return PutDependentRequest
      // Medical History
      case FetchTypes.getConditions:
        return GetConditionsRequest
      case FetchTypes.addMedicalHistory:
        return PostMedicalHistoryRequest
      case FetchTypes.updateMedicalHistory:
        return PutMedicalHistoryRequestV2
      case FetchTypes.addMedicalConditions:
        return PutMedicalConditionsRequest
      case FetchTypes.getMedicalHistory:
        return GetMedicalHistoryRequest
      // Medications
      case FetchTypes.addMedication:
        return PostMedicationRequestV2
      case FetchTypes.getMedicationsV2:
        return GetMedicationsV2
      case FetchTypes.getMedicationDetailsV2:
        return GetMedicationDetailsV2
      // Allergies
      case FetchTypes.addAllergy:
        return PostAllergyRequest
      case FetchTypes.updateAllergy:
        return PutAllergyRequest
      case FetchTypes.removeAllergy:
        return DeleteAllergyRequest
      case FetchTypes.getAllergies:
        return GetAllergyRequest
      // Family History
      case FetchTypes.addFamilyHistory:
        return PostFamilyHistoryRequest
      case FetchTypes.getFamilyHistory:
        return GetFamilyHistoryRequest
      case FetchTypes.updateFamilyConditions:
        return PutFamilyConditionsRequest
      // Images
      case FetchTypes.removeImageV2:
        return DeleteImageRequestV2
      case FetchTypes.addImageV2:
        return PostImageRequestV2
      case FetchTypes.getThumbImageV2:
        return GetThumbImageRequestV2
      case FetchTypes.getImagesV2:
        return GetImagesRequestV2
      // Provider
      case FetchTypes.getProvider:
        return GetProviderRequest
      case FetchTypes.getAvailableProviders:
        return GetAvailableProvidersRequest
      case FetchTypes.getProviderAvailability:
        return GetProviderAvailabilityRequest
      case FetchTypes.getAvailableQueueProviders:
        return GetAvailableQueueProvidersRequest
      // Pharmacies
      case FetchTypes.getMemberPharmacies:
        return GetMemberPharmaciesRequest
      case FetchTypes.getPharmacies:
        return GetPharmaciesRequest
      case FetchTypes.removeMemberPharmacy:
        return DeleteMemberPharmacyRequest
      case FetchTypes.addMemberPharmacy:
        return PostMemberPharmacyRequest
      // Payments
      case FetchTypes.addPaymentClient:
        return PostPaymentClientRequest
      case FetchTypes.addPaymentMethod:
        return PostPaymentMethodRequest
      case FetchTypes.getPaymentMethods:
        return GetPaymentMethodsRequest
      case FetchTypes.getPaymentTransactions:
        return GetPaymentTransactionsRequest
      case FetchTypes.getPaymentTransaction:
        return GetPaymentTransactionRequest
      case FetchTypes.removePaymentMethod:
        return DeletePaymentMethodRequest
      case FetchTypes.addPaymentTransaction:
        return PostPaymentTransactionRequest
      case FetchTypes.addPaymentTransactionV2:
        return PostPaymentTransactionV2Request
      // Client
      case FetchTypes.getServices:
        return GetServicesRequest
      case FetchTypes.getClientByDomain:
        return GetClientByDomainRequest
      case FetchTypes.getVisitRestrictions:
        return GetVisitRestrictionsRequest
      // Group
      case FetchTypes.getGroup:
        return GetGroupRequest
      // Visits
      case FetchTypes.getVisits:
        return GetVisitsRequest
      case FetchTypes.getVisit:
        return GetVisitRequest
      case FetchTypes.addPendingVisit:
        return PostPendingVisitRequest
      case FetchTypes.getPendingVisits:
        return GetPendingVisitsRequest
      case FetchTypes.getPendingVisit:
        return GetPendingVisitRequest
      case FetchTypes.updateCancelVisit:
        return PutCancelVisitRequest
      // Visit Video
      case FetchTypes.getVideoTokenV2:
        return GetVideoTokenV2Request
      case FetchTypes.addVideoParticipant:
        return PostVideoParticipantRequest
      case FetchTypes.postAddDailyCallInterpreter:
        return PostAddDailyCallInterpreter
      case FetchTypes.putRemoveDailyCallInterpreter:
        return PutRemoveDailyCallInterpreter
      // Ratings
      case FetchTypes.addProviderRating:
        return PostProviderRatingRequest
      case FetchTypes.addVisitRating:
        return PostVisitRatingRequest
      case FetchTypes.addRating:
        return PostVisitRatingV2Request
      // Messages
      case FetchTypes.getMessages:
        return GetMessagesRequest
      case FetchTypes.getMessage:
        return GetMessageRequest
      case FetchTypes.putMessageViewedAt:
        return PutMessageViewedAtRequest
      case FetchTypes.putMessageStatus:
        return PutMessageStatusRequest
      case FetchTypes.getPrescription:
        return GetPrescriptionRequest
      case FetchTypes.getUnreadMessageCounts:
        return GetUnreadMessageCounts
      // Visit Chat
      case FetchTypes.addVisitChat:
        return PostVisitChatRequest
      case FetchTypes.getVisitChat:
        return GetVisitChatRequest
      case FetchTypes.addChatMessage:
        return PostChatMessageRequest
      case FetchTypes.readChatMessage:
        return PutReadChatMessage
      // Services
      case FetchTypes.addServiceCode:
        return PostServiceCodeRequest
      case FetchTypes.getClientVisitCount:
        return GetClientVisitCountRequest
      // Needs
      case FetchTypes.addNeed:
        return PostMemberNeedRequest
      case FetchTypes.getNeeds:
        return GetMemberNeedsRequest
      case FetchTypes.getNeed:
        return GetMemberNeedRequest
      case FetchTypes.removeNeed:
        return DeleteMemberNeedRequest
      // Surveys
      case FetchTypes.getSurveys:
        return GetClientSurveysRequest
      case FetchTypes.completeSurvey:
        return PostMemberSurveyRequest
      case FetchTypes.getSurveyQuestions:
        return GetClientSurveyQuestionsRequest
      // Support
      case FetchTypes.addSupportTicket:
        return PostSupportRequest
      case FetchTypes.getFaqQuestions:
        return GetFaqQuestionsRequest
      // Feedback
      case FetchTypes.addMemberFeedback:
        return PostFeedBack
      // Community
      case FetchTypes.getTermsVersion:
        return GetCommunityTermsVersionRequest
      case FetchTypes.getAllPosts:
        return GetAllPostsRequest
      case FetchTypes.addCommunityPost:
        return PostCommunityPostRequest
      case FetchTypes.getPost:
        return GetCommunityPostRequest
      case FetchTypes.getPostComments:
        return GetCommunityPostCommentsRequest
      case FetchTypes.deleteCommunityPost:
        return DeleteCommunityPostRequest
      case FetchTypes.addCommunityComment:
        return PostCommunityCommentRequest
      case FetchTypes.getPostReports:
        return GetCommunityPostReportsRequest
      case FetchTypes.addCommunityPostReport:
        return PostCommunityPostReportRequest
      case FetchTypes.deletePostReport:
        return DeleteCommunityPostReportRequest
      case FetchTypes.togglePostReaction:
        return PostCommunityPostReactionRequest
      case FetchTypes.getMemberReactions:
        return GetCommunityPostReactionsRequest
      case FetchTypes.getCommunityRandomPosts:
        return GetCommunityRandomPostsRequest
      // Explore
      case FetchTypes.getSccComponents:
        return GetSelfCareComponentsRequest
      case FetchTypes.getSccComponent:
        return GetSelfCareComponentRequest
      case FetchTypes.getSccCategories:
        return GetSelfCareCategoriesRequest
      case FetchTypes.getSccCategory:
        return GetSelfCareCategoryRequest
      case FetchTypes.toggleComponentLike:
        return PostSelfCareComponentReactionRequest
      case FetchTypes.getSccJourneys:
        return GetSelfCareJourneysRequest
      case FetchTypes.getSccJourney:
        return GetSelfCareJourneyRequest
      case FetchTypes.getSelfCares:
        return GetSelfCareRandomizedContentRequest
      case FetchTypes.addSccJourneyComponentView:
        return AddSelfCareJourneyComponentViewRequest
      // Forms
      case FetchTypes.getForms:
        return GetFormsRequest
      case FetchTypes.updateVisitAssesmentResponse:
        return PutVisitAssessmentResponseRequest
      // Cancellation Reasons
      case FetchTypes.getCancellationReasons:
        return GetCancellationReasonsRequest
      // NPS Visit Survey
      case FetchTypes.getVisitSurveyStatus:
        return GetVisitSurveyStatusV2Request
      case FetchTypes.postVisitSurvey:
        return PostVisitSurveyV2Request
      case FetchTypes.putVisitSurvey:
        return PutVisitSurveyV2Request
      case FetchTypes.postSignedUrls:
        return PostSignedUrlsRequest
      // Student Success
      case FetchTypes.successAvailability:
        return GetVisitQueues
      // Save Care Questionnaire
      case FetchTypes.saveCareQuestionnaire:
        return PostCareRoutes
      // Member Consents
      case FetchTypes.getMemberConsents:
        return GetMemberConsentRequest
      case FetchTypes.postMemberSelfConsent:
        return PostMemberSelfConsentConfirmationRequest
      default:
        return
    }
  },
}

export default Factory
