import * as Yup from 'yup'

// TODO: Put yup.object().shape({}) once yup resolver is updated
export const MemberSelectSchema = Yup.object({
  memberId: Yup.string().label('Patient').required(),
  memberFirstName: Yup.string().label('Member first name').required(),
  memberLastName: Yup.string().label('Member last name').required(),
  memberDob: Yup.string()
    .label('Date of Birth')
    .matches(/\d{4}-\d{2}-\d{2}/, 'Date of Birth must be a valid date')
    .required(),
  timezone: Yup.string(),
})

export type MemberSelectFormValues = Yup.InferType<typeof MemberSelectSchema>
