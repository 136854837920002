import { useCallback, useEffect, useMemo } from 'react'
import { isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import * as SchedulingSelectors from 'redux/selectors/scheduling'
import * as VisitSelectors from 'redux/selectors/visits'
import VisitTypes from 'util/visit-types'
import VisitStatus from 'util/visit-status'
import withForm from '../../../hoc/withForm'
import SchedulingField from '../../../components/visit-creation/SchedulingField'
import TestIds from '../../../util/TestIds'
import { reduceAvailabilityToTimesByDate, filterAvailabilityBySchedulingLimit } from '../../../util/scheduling'
import { fetchProviderAvailability } from 'redux/thunks/providers'
import { clearAvailability } from 'redux/slices/scheduling'
import { getProvider } from '../../../redux/selectors/providers'
import moment from 'moment-timezone'
import Notice from '@ui-components-3/ui/lib/components/Notice'
import { phoneFormat } from '../../../util/formatters'
import { SUPPORT_NUMBER } from '../../../util/support'
import Member from 'types/member/member'
import PendingVisit from 'types/visit/pendingVisit'
import { AnyObjectSchema } from 'yup'
import { FormikErrors } from 'formik'
import useFeatureFlagToggle from 'components/feature-flag/useFeatureFlagToggle'
import { TcFF } from 'components/feature-flag/flags'

// Member can schedule up to: 12 weeks away
const DAYS_MEMBER_CAN_SCHEDULE_AHEAD = 84
const PSYCHIATRY_SCHEDULING_LIMIT = 30
// GetProviderAvailability is limited to a maximum of 12 weeks ahead
const AVAILABILITY_WINDOW_WEEKS = 12

type FormValue = {
  timezone: string
  scheduledAt: string | null
}

type SchedulingFormProps = {
  member: Member
  pendingVisit: PendingVisit
  initialValues: FormValue
  schema: AnyObjectSchema
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  handleSubmit: () => void
  setErrors: (fields: { [field: string]: string }) => void
  values: FormValue
  errors: FormikErrors<FormValue>
  touched: { [field: string]: boolean }
  enableReinitialize: boolean
  dirty: boolean
  isValid: boolean
  isLoading: boolean
  children: (value: { disabled: boolean }) => JSX.Element
}

const SchedulingForm = (props: SchedulingFormProps) => {
  const { pendingVisit, setFieldValue, handleSubmit, errors, touched, isValid, isLoading } = props
  const dispatch = useDispatch()
  const isPsychScheduling30DayLimitFlagOn = useFeatureFlagToggle(TcFF.tcPsychScheduling30DayLimit)

  const handleFetchProviderAvailability = useCallback(async () => {
    if (isEmpty(pendingVisit) || isEmpty(pendingVisit.providerId)) return null
    dispatch(clearAvailability())
    if (pendingVisit.visitType === VisitTypes.psychiatry) {
      return dispatch(
        fetchProviderAvailability(
          pendingVisit.memberId,
          pendingVisit.providerId,
          pendingVisit.visitType,
          isPsychScheduling30DayLimitFlagOn ? AVAILABILITY_WINDOW_WEEKS : 3,
        ),
      )
    }
    return dispatch(fetchProviderAvailability(pendingVisit.memberId, pendingVisit.providerId, pendingVisit.visitType))
  }, [dispatch, pendingVisit])

  const providerAvailability = useSelector(SchedulingSelectors.getProviderAvailability(pendingVisit.providerId))
  const allVisits = useSelector(VisitSelectors.getPendingRecordArray)

  const scheduledVisitsAffectingPsychLimits = useMemo(() => {
    if (pendingVisit.visitType !== VisitTypes.psychiatry) {
      return []
    }

    const earliestDateThatLimitsScheduling = moment().subtract(PSYCHIATRY_SCHEDULING_LIMIT, 'days')
    const furthestDateThatLimitsScheduling = moment().add(
      DAYS_MEMBER_CAN_SCHEDULE_AHEAD + PSYCHIATRY_SCHEDULING_LIMIT,
      'days',
    )

    return allVisits.filter(
      (visit) =>
        visit.memberId === pendingVisit.memberId &&
        visit.visitType === VisitTypes.psychiatry &&
        visit.status != VisitStatus.cancelled &&
        moment(visit.scheduledAt).isBetween(earliestDateThatLimitsScheduling, furthestDateThatLimitsScheduling),
    )
  }, [allVisits, pendingVisit.visitType, pendingVisit.memberId])

  const psychScheduledAvailability = useMemo(() => {
    if (!Array.isArray(providerAvailability) || !Array.isArray(scheduledVisitsAffectingPsychLimits)) {
      return providerAvailability
    }

    return filterAvailabilityBySchedulingLimit(
      providerAvailability,
      scheduledVisitsAffectingPsychLimits.map((visit) => visit.scheduledAt),
      PSYCHIATRY_SCHEDULING_LIMIT * 24 * 60,
    )
  }, [providerAvailability, scheduledVisitsAffectingPsychLimits])

  const showPsychSchedulingLimitNotice =
    pendingVisit.visitType === VisitTypes.psychiatry &&
    isPsychScheduling30DayLimitFlagOn &&
    scheduledVisitsAffectingPsychLimits.length > 0
  const availability =
    pendingVisit.visitType === VisitTypes.psychiatry && isPsychScheduling30DayLimitFlagOn
      ? psychScheduledAvailability
      : providerAvailability

  const timezone = useMemo(() => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  }, [])

  const availabilityMap = useMemo(() => {
    if (isEmpty(availability)) return {}
    return reduceAvailabilityToTimesByDate(availability, timezone)
  }, [availability, timezone])

  useEffect(() => {
    handleFetchProviderAvailability()
  }, [pendingVisit.providerId])

  useEffect(() => {
    setFieldValue('timezone', timezone)
  }, [timezone])

  const isNextDisabled = !isValid

  const provider = useSelector(getProvider(pendingVisit?.providerId))

  useEffect(() => {
    if (pendingVisit?.scheduledAt && availability?.length && !isLoading) {
      const hasScheduledAvailability = availability.some((time: string) => {
        const availabilityTime = moment(time).tz(timezone)
        const scheduledTime = moment(pendingVisit.scheduledAt).tz(timezone)
        return availabilityTime.isSame(scheduledTime, 'minute')
      })

      if (!hasScheduledAvailability) {
        setFieldValue('scheduledAt', null)
      }
    }
  }, [availability, pendingVisit?.scheduledAt, isLoading])

  if (!provider || !availability) {
    return null
  }

  const hasError = errors && touched.scheduledAt && errors.scheduledAt
  const hasAvailability = availability.length > 0

  return (
    <form className="relative flex w-full flex-1 flex-col" onSubmit={handleSubmit}>
      <div className="pb-6">
        <div className="typography-h5 mb-4 text-neutral-800">Select a date</div>
        <SchedulingField
          error={hasError ? hasError : ''}
          data-testid={TestIds.newVisit.input.scheduledAt}
          timesByDate={availabilityMap}
          timezone={timezone}
          dateLabel={'Date'}
          timeLabel={'Time'}
          name={'scheduledAt'}
        >
          <>
            {showPsychSchedulingLimitNotice && (
              <Notice className="mb-4" variant="info">
                <div className="typography-body text-neutral-800">
                  <p className="font-semibold">30-Day Visit Limit</p>
                  We require 30 days between psychiatry visits. If you believe you need an appointment sooner,{' '}
                  <span className="whitespace-nowrap">
                    call{' '}
                    <a className="text-link font-semibold" href={`tel:+${SUPPORT_NUMBER}`}>
                      {phoneFormat(SUPPORT_NUMBER)}
                    </a>{' '}
                  </span>
                  to connect with our care coordinators.
                </div>
              </Notice>
            )}
            {!hasAvailability && (
              <Notice className="mb-4" variant="warning">
                <div className="typography-body text-neutral-800">
                  This provider has no availability, please select another.
                </div>
              </Notice>
            )}
          </>
        </SchedulingField>
        <div className="typography-body mt-6 text-neutral-800">
          Need help finding an appointment? Call{' '}
          <a className="text-link-primary font-semibold" href={`tel:+${SUPPORT_NUMBER}`}>
            {phoneFormat(SUPPORT_NUMBER)}
          </a>{' '}
          to connect with our care coordinators.
        </div>
      </div>
      {props.children({ disabled: isNextDisabled })}
    </form>
  )
}

export default withForm(SchedulingForm)
