import { outsideUSResources, outsideUSResourcesForSS } from 'util/countries'
import LinkIcon from '@ui-components-3/icons/lib/regular/ArrowUpRightFromSquare'
import classNames from '@ui-components-3/ui/lib/utils/classNames'
import ExternalLink from 'components/common/ExternalLink'
import { useMemo } from 'react'

type ResourceProps = {
  title: string
  description: string
  url: string
  index: number
}
const Resource = ({ title, description, url, index }: ResourceProps) => {
  return (
    <li
      className={classNames(
        'flex h-[90px] list-none items-center rounded-2xl border border-neutral-300 bg-white',
        index % 2 != 0 ? 'col-start-2' : 'col-start-1',
      )}
    >
      <ExternalLink className="flex h-full w-full items-center justify-between gap-2 p-4" href={url}>
        <div className="flex flex-col gap-1">
          <p className="lead typography-body text-secondary-700 font-semibold">{title}</p>
          <p className="typography-small text-neutral-500">{description}</p>
        </div>
        <div className="text-secondary-700 flex h-6 w-6 flex-shrink-0 items-center justify-center p-1">
          <LinkIcon className="h-full w-full" aria-hidden="true" />
        </div>
      </ExternalLink>
    </li>
  )
}

type CrisisResourcesProps = {
  isSuccess?: boolean
}

const CrisisResources = ({ isSuccess }: CrisisResourcesProps) => {
  const resourcesData = useMemo(() => (isSuccess ? outsideUSResourcesForSS : outsideUSResources), [isSuccess])

  return (
    <ul className={classNames('flex flex-col gap-2', isSuccess ? '' : 'md:grid md:grid-cols-2 xl:grid-cols-3')}>
      {resourcesData.map((resource, index) => (
        <Resource
          title={resource.title}
          description={resource.description}
          url={resource.url}
          index={index}
          key={resource.url}
        />
      ))}
    </ul>
  )
}

export default CrisisResources
