import * as Yup from 'yup'
import configBuilder from '../config-builder'
import catchApiError from '../catch-api-error'
import { normalizeJsonToApi } from '../../helpers'
import ApiFactory, { ApiTypes } from '../../index'
const api = ApiFactory.getApi(ApiTypes.auth)

const RequestSchema = Yup.object().shape({
  resetToken: Yup.string().required(),
  password: Yup.string()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/g,
      'Password must have 10 characters, and must contain 1 capital letter,' +
        ' 1 lowercase letter, 1 special character, and 1 number',
    )
    .required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => await RequestSchema.validate(params)
export const make = async (params) => {
  return normalizeJsonToApi(params)
}
export const url = () => '/v1/reset'
export const execute = async (params) => api.post(url(), await make(params), configBuilder(params)).catch(catchApiError)
