import { createSlice } from '@reduxjs/toolkit'

const defaultState = {
  meta: {
    fetching: {
      // [memberid] : lastFetchAt
    },
    lastFetchAt: null,
  },
  availability: {
    // [providerId]: provider availability
  },
}

const slice = createSlice({
  name: 'scheduling',
  initialState: defaultState,
  reducers: {
    putAvailability: (state, action) => {
      const { availability, providerId } = action.payload
      state.availability[providerId] = availability
    },
    clearAvailability: (state) => {
      state.availability = { ...defaultState.availability }
    },
  },
})

const { actions, reducer } = slice
export const { putAvailability, clearAvailability } = actions
export default reducer
